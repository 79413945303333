import { Box, Tab, useColorModeValue, useTab } from "@chakra-ui/react";
import React, { ReactNode, Ref } from "react";
import { statusEnum } from "../../Utils/defaultObjects";

interface CustomTabInterface {
  children?: ReactNode;
  title: string;
  status: number;
  index: number;
  ref?: Ref<HTMLElement>;
  size?: 1 | 2 | 3 | 4;
  disable?: boolean;
  onClick?: () => void;
}

export const CustomTabs: React.FC<CustomTabInterface> = ({
  children,
  status,
  ref,
  title,
  index,
  onClick,
}) => {
  const tabProps = useTab({ children, ref });
  const isSelected = tabProps["aria-selected"];

  const selectedBorderColor = useColorModeValue("blue.700", "white");

  return (
    <Tab
      fontWeight={600}
      fontSize={14}
      key={index}
      {...tabProps}
      onClick={onClick}
      boxSizing="border-box"
      px={1}
      py={2}
    >
      <Box
        outline={isSelected ? "1px solid" : "0px"}
        outlineColor={selectedBorderColor}
        mr={2}
        px={2}
        w="120px"
        color={isSelected ? "white" : "black"}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        bg={
          statusEnum.filter((st) => {
            return st.value === status?.toString();
          })[0]?.color
        }
        borderRadius={5}
      >
        {title}
      </Box>
    </Tab>
  );
};
