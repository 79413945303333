import { Box, HStack, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { WDInput } from "../Common/WDInput";
import { ModalServiceContext } from "./WDServiceModal";
export const TheDisabledInput: React.FC = () => {
  const { currentProject, currentServer } = useContext(ModalServiceContext);
  return (
    <HStack w="100%" py={3}>
      <Box w="100%">
        <Text> Project's Name</Text>
        <WDInput
          isDisabled={true}
          placeholder={currentProject.name}
          _disabled={{ bg: "#F8FAFC", color: "blue.800" }}
          border="1px solid"
          borderColor="neutralGrey"
        />
      </Box>

      <Box w="100%">
        <Text> Server's Name</Text>
        <WDInput
          placeholder={currentServer.name}
          isDisabled={true}
          _disabled={{ bg: "#F8FAFC", color: "blue.800" }}
          border="1px solid"
          borderColor="neutralGrey"
        />
      </Box>
    </HStack>
  );
};
