import React, { useState } from "react";
import { ResourceName } from "../../ResourcePage/ResourceOverview/utils";
import { SelectedInterval, TimeIntervalWrapper } from "../TimeIntervalWrapper";
import AreaGraph from "./AreaGraph";
import { AreaChart } from "./types";
interface CpuSectionProps {
  id: ResourceName | string;
  areaChart?: AreaChart;
  title: string;
  onIntervalChange: (id: ResourceName | string, val: SelectedInterval) => void;
  detailsUrl?: string;
  typeSubEntity?: string;
}
export const CpuSection: React.FC<CpuSectionProps> = ({
  id,
  areaChart,
  title,
  onIntervalChange,
  detailsUrl,
  typeSubEntity,
}) => {
  const [selectedInterval, setSelectedInterval] =
    useState<SelectedInterval>("24h");

  const onIntervalChangeDto = (val: SelectedInterval) => {
    setSelectedInterval(val);
    onIntervalChange(id, val);
  };

  return (
    <>
      <TimeIntervalWrapper
        key={id}
        onIntervalChange={onIntervalChangeDto}
        title={title} //Or core
        seeFullDetailsUrl={detailsUrl}
        isConfigured
      >
        <AreaGraph areaChart={areaChart} selectedInterval={selectedInterval} />
      </TimeIntervalWrapper>
    </>
  );
};
