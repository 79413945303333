import moment from "moment";
import { ModalServiceInterface } from "../type";
import { ServiceModalProps } from "../WDServiceModal";

export const createPostObject = (dataModal: ModalServiceInterface) => {
  return {
    name: dataModal.name,
    ipAddress: dataModal.ipV4,
    iPv6Address: dataModal.ipV6 || "empty",
    isActive: dataModal.enabled,
    portNumber: parseInt(dataModal.port),
    serviceType: dataModal.type,
    serviceJob: {
      jobId: dataModal.jobId || " ",
      isActive: dataModal.enabled,
      startDate: dataModal.startDate,
      recurringTime: dataModal.reccuringTime,
      timeout: dataModal.timeout,
      minimumNotificationLevel: dataModal.minimumNotificationLevel,
      timeoutNotificationLevel: dataModal.timeoutNotificationLevel,
    },
  };
};
export const setCurrentProject = (currentInfo: ServiceModalProps, res: any) => {
  currentInfo.setCurrentProject({
    ...currentInfo.currentProject,
    servers: [
      ...currentInfo.currentProject.servers.map((ser) => {
        if (currentInfo.currentServer.serverId === ser.serverId)
          return {
            ...ser,
            services: [
              ...currentInfo.currentServer.services,
              {
                name: res.data.name,
                portNumber: res.data.portNumber,
                ipAddress: res.data.ipAddress,
                status: res.data.status,
                serviceId: res.data.id,
                serviceType: res.data.serviceType.toString(),
                tasks: [],
              },
            ],
          };
        else return ser;
      }),
    ],
  });
};
export const setDataModalEdit = (
  res: any,
  setDataModal: (dataModal: ModalServiceInterface) => void
) => {
  setDataModal({
    name: res.data.name,
    ipV4: res.data.ipAddress,
    ipV6: res.data.iPv6Address === "empty" ? "" : res.data.iPv6Address,
    port: res.data.portNumber.toString(),
    type: res.data.serviceType,
    jobId: res.data.jobId,
    enabled: res.data.isActive,
    startDate: moment.utc(res.data.startDate).local().format(),
    reccuringTime: res.data.recurringTime,
    minimumNotificationLevel: res.data.minimumNotificationLevel,
    timeout: res.data.timeout,
    timeoutNotificationLevel: res.data.timeoutNotificationLevel,
  });
};
export const setCurrentProjectEdit = (
  currentInfo: ServiceModalProps,
  res: any
) => {
  currentInfo.setCurrentProject({
    ...currentInfo.currentProject,
    servers: [
      ...currentInfo.currentProject.servers.map((ser) => {
        if (currentInfo.currentServer.serverId === ser.serverId)
          return {
            ...ser,
            services: [
              ...ser.services.map((service) => {
                if (service.serviceId === currentInfo.idService)
                  return {
                    ...service,

                    name: res.data.name,
                    portNumber: res.data.portNumber,
                    ipAddress: res.data.ipAddress,
                    isActive: true,
                    serviceId: res.data.serviceId,
                    serviceType: res.data.serviceType.toString(),
                  };
                else return service;
              }),
            ],
          };
        else return ser;
      }),
    ],
  });
};
