import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { ErrorContext } from "../../App";
import { apiClient, authorise } from "../utils/apiClient";
import { TableRow } from "./TableRow";
import { UserInterface } from "./types";
interface UsersProps {
  users: UserInterface[];
  setUsers: (val: UserInterface[]) => void;
  filteredUsers: UserInterface[];
  setFilteredUsers: (val: UserInterface[]) => void;
}

export const UsersTableComponent: React.FC<UsersProps> = ({
  users,
  setUsers,
  filteredUsers,
  setFilteredUsers,
}) => {
  const errorContext = useContext(ErrorContext);
  const handleActivateUserClick = async (id: string) => {
    await apiClient
      .put(`/api/admin/switch-active/?userId=${id}`, {}, authorise())
      .then((res) => {
        console.log(res.data);
        setFilteredUsers(
          filteredUsers.map((user) => {
            if (id === user.id) {
              return {
                ...user,
                isActive: res.data.isActive,
              };
            } else {
              return user;
            }
          })
        );
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
      });
  };

  const handleChangeUserRoleClick = async (id: string) => {
    await apiClient
      .get(`/api/admin/update-user-role/?userId=${id}`, authorise())
      .then((res) => {
        setFilteredUsers(
          filteredUsers.map((user) => {
            if (id === user.id) {
              return {
                ...user,
                roleName: user.roleName === "Admin" ? "User" : "Admin",
              };
            } else {
              return user;
            }
          })
        );
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
      });
  };
  const colorPrimary = useColorModeValue("blue.100", "darkThemeGrey.500");
  const colorTextPrimary = useColorModeValue(
    "neutralDarkGrey",
    "darkThemeGrey.100"
  );

  return (
    <TableContainer h="100%" w="100%" overflowY="auto">
      <Table variant="simple">
        <Thead
          borderRadius="4px"
          bgColor={colorPrimary}
          top="0"
          left="0"
          position="sticky"
          zIndex={2}
        >
          <Tr>
            <Th fontSize="13px" color={colorTextPrimary}>
              NAME
            </Th>
            <Th fontSize="13px" color={colorTextPrimary}>
              ROLE
            </Th>
            <Th fontSize="13px" color={colorTextPrimary}>
              PROJECTS
            </Th>
            <Th fontSize="13px" color={colorTextPrimary}>
              2FA-STATUS
            </Th>
            <Th fontSize="13px" color={colorTextPrimary}>
              ACCOUNT STATUS
            </Th>
            <Th fontSize="13px" color={colorTextPrimary}></Th>
          </Tr>
        </Thead>
        <Tbody minH="fit-content">
          {filteredUsers.map((user, index) => {
            return (
              <React.Fragment key={user.id}>
                <TableRow
                  allUsers={users}
                  setUsers={setUsers}
                  setFilteredUsers={setFilteredUsers}
                  filteredUsers={filteredUsers}
                  index={index}
                  user={user}
                  handleChangeUserRoleClick={handleChangeUserRoleClick}
                  handleActivateUserClick={handleActivateUserClick}
                />
              </React.Fragment>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
