import {
  Badge,
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { theme } from "../../theme";
import { ProjectDetailsInterface } from "./types";
interface PopoverProjectsDetailsInterface {
  designer: ProjectDetailsInterface[];
  watcher: ProjectDetailsInterface[];
  numberOfDesigners: number;
  numberOfWatchers: number;
}
export const PopoverProjectsDetails: React.FC<
  PopoverProjectsDetailsInterface
> = ({ designer, watcher, numberOfDesigners, numberOfWatchers }) => {
  const neutralGrey = theme.colors.neutralGrey;
  const textColor = useColorModeValue("blue.800", "white");
  const bgColor = useColorModeValue("white", "darkThemeGrey.600");
  return (
    <Popover strategy="fixed">
      <PopoverTrigger>
        <Button variant="ghost" color={neutralGrey}>
          {`${numberOfDesigners} Designer ● ${numberOfWatchers} Watcher`}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        boxShadow={`0 0 5px 0 ${neutralGrey}`}
        minW="fit-content"
        maxH={40}
        overflowY="hidden"
        bg={bgColor}
      >
        <PopoverHeader fontWeight={600} textAlign="center" color={textColor}>
          User's Projects
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody overflowY={"auto"} color={textColor}>
          <VStack
            minW="fit-content"
            fontWeight={400}
            justifyContent="flex-start"
            alignContent="flex-start"
            color="neutralDarkGrey"
            h="100%"
            pr={2}
          >
            <>
              {designer.map((d, i) => {
                return (
                  <Flex justify={"flex-start"} w="100%" key={i}>
                    <Box
                      key={d.projectId}
                      textAlign="left"
                      w="100%"
                      h="100%"
                      minW="fit-content"
                    >
                      <Text fontSize={20} color={textColor}>
                        {" "}
                        {d.name}
                      </Text>
                    </Box>
                    <Spacer />

                    <Badge
                      colorScheme={"red"}
                      fontSize={8}
                      ml={2}
                      minW="fit-content"
                    >
                      Designer
                    </Badge>
                  </Flex>
                );
              })}
            </>

            <>
              {watcher.map((w, i) => {
                return (
                  <Flex
                    w="100%"
                    justify={"flex-start"}
                    key={i + designer.length}
                  >
                    <Box
                      key={w.projectId}
                      textAlign="left"
                      w="100%"
                      minW="fit-content"
                    >
                      <Text fontSize={20} minW="fit-content" color={textColor}>
                        {" "}
                        {w.name}
                      </Text>
                    </Box>
                    <Badge colorScheme={"blue"} fontSize={8} ml={2}>
                      Watcher
                    </Badge>
                  </Flex>
                );
              })}
            </>
            {watcher.length + designer.length === 0 && (
              <Box color={textColor}> No projects to show.</Box>
            )}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
