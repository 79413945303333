import { Button, ButtonGroup, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { UserContext } from "../../App";
import { ReactComponent as LogoutIcon } from "../../Assets/Drawer-NavBar/LogoutIcon.svg";
import { theme } from "../../theme";
import { NavBarHeight } from "../Dashboard/DashboardWrapper/DashboardWrapper";
export const Logout: React.FC = () => {
  const userContext = useContext(UserContext);
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.darkThemeGrey[100]}`
  );
  return (
    <Flex
      flexDirection="column"
      align="start"
      onClick={userContext?.logOut}
      minH={NavBarHeight}
    >
      <ButtonGroup ml={2} p={1} mt={2} width="100%">
        <Button
          leftIcon={<LogoutIcon stroke={colorStroke} />}
          colorScheme="blue"
          bg={useColorModeValue("blue.100", "darkThemeGrey.600")}
          color={useColorModeValue("blue.700", "darkThemeGrey.100")}
          variant="link"
          iconSpacing={10}
          mb={3}
        >
          Logout
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
