import { Flex, Spacer, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { ListDrawer } from "./ListDrawer";

import { LogoCST } from "./LogoCST";
import { Logout } from "./Logout";
import { ProfileInfo } from "./ProfileInfo";
interface DrawerProps {
  active: boolean;
}
export const DrawerComponent: React.FC<DrawerProps> = ({ active }) => {
  const MotionFlex = motion(Flex);
  const animationVariants = {
    active: { width: 280, opacity: 1 },
    inactive: { width: 80, opacity: 0 },
  };

  return (
    <MotionFlex
      flexDirection="column"
      h="100vh"
      minH="fit-content"
      variants={animationVariants}
      initial="active"
      animate={active ? "active" : "inactive"}
      transition={{ type: "spring", damping: 60, stiffness: 180 }}
      bg={useColorModeValue("blue.100", "darkThemeGrey.600")}
      boxShadow="3px 0px 30px rgba(0, 0, 0, 0.05)"
    >
      <LogoCST />
      <ProfileInfo />
      <ListDrawer />
      <Spacer />
      <Logout />
    </MotionFlex>
  );
};
