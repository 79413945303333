import { Box, HStack, Spacer, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceDetailsContext } from ".";
import { CpuSection } from "../../Common/AreaGraph/AreaGraphSection";
import { ProcessChartSection } from "../../Common/ProcessChart/ProcessChartSection";
import { CustomSpinner } from "../../Common/Spinner";
export const TabPanelContent: React.FC = () => {
  const { projectId, serverId } = useParams();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`../dashboard/projects/${projectId}/resources/${serverId}`);
  };
  const {
    project,
    onIntervalChangeOverview,
    onIntervalChangeDetails,
    onIntervalChangeProcesses,
  } = useContext(ResourceDetailsContext);

  return (
    <>
      <VStack px={20} w="100%" spacing={4}>
        <HStack color="blue.800" w="100%" pt={6} px={4}>
          <Box fontSize={28} fontWeight={"bold"}>
            Resources Details
          </Box>
          <Spacer />
          <Box
            fontWeight={650}
            fontSize={16}
            cursor="pointer"
            onClick={handleNavigate}
          >
            {"  "}
            {" < Back to all resources"}
          </Box>
        </HStack>
        <CpuSection
          key={project.graphOverview.id}
          onIntervalChange={onIntervalChangeOverview}
          id={project.graphOverview.id}
          title={project.graphOverview.title}
          areaChart={project.graphOverview.areaChart}
        />
        {project.loadingGraphsDetails ? (
          <CustomSpinner />
        ) : (
          <>
            {project?.graphsDetails?.map((g) => {
              return (
                <Box key={g.id} w="90%">
                  <CpuSection
                    title={g.title}
                    onIntervalChange={onIntervalChangeDetails}
                    id={g.id}
                    typeSubEntity="Core"
                    areaChart={g.areaChart}
                  />
                </Box>
              );
            })}{" "}
          </>
        )}
        {project?.graphProcess ? (
          <Box w="100%">
            <ProcessChartSection
              id={project.graphProcess?.id}
              onIntervalChange={onIntervalChangeProcesses}
              lineChart={project.graphProcess?.processChart}
            />
          </Box>
        ) : null}
      </VStack>
    </>
  );
};
