import { SearchIcon } from "@chakra-ui/icons";
import { Box, SimpleGrid, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { HelpCenterContext } from ".";
import { TopicCard } from "./TopicCard";
export const TopicCardsMenu: React.FC = () => {
  const { filteredTopics, filterValue } = useContext(HelpCenterContext);

  return (
    <VStack>
      {filterValue ? (
        <Box
          w="fit-content"
          fontWeight={600}
          fontSize={20}
          mb={5}
          borderBottom={"3px solid"}
          borderColor="blue.800"
        >
          {" "}
          Topics related to: {filterValue}
        </Box>
      ) : null}
      {filteredTopics?.length === 0 ? (
        <VStack justifyContent="center" alignItems="center" p={20} w="100%">
          <SearchIcon boxSize={20} opacity="50%" />
          <Box pl={4} pt={4} fontWeight={600} fontSize={20}>
            I can't seem to find anything realted to"{filterValue}"
          </Box>
          <Box>Please double-check your spelling, try other search items</Box>
        </VStack>
      ) : (
        <>
          <SimpleGrid
            columns={[1, 1, 2, 3, 3, 3]}
            w="100%"
            h="100%"
            gap={8}
            pr={4}
            pl={4}
            pb={20}
          >
            {filteredTopics.map((topic) => {
              return (
                <TopicCard
                  key={topic.id}
                  id={topic.id}
                  title={topic.title}
                  description={topic.description}
                  filterValue={filterValue}
                />
              );
            })}
          </SimpleGrid>
        </>
      )}
    </VStack>
  );
};
