import { Box } from "@chakra-ui/react";
import React, { createContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { CustomSpinner } from "../../Common/Spinner";
import { ResourcePageOverviewHeader } from "./ResourcePageOverviewHeader";
import { TabPanelContent } from "./TabPanelContent";
import { ResourceOverviewContextInterface } from "./types";
import { useResourceOverview } from "./useResourceOverview";
export const ResourceOverviewContext =
  createContext<ResourceOverviewContextInterface>(
    {} as ResourceOverviewContextInterface
  );
export const ResourcePageOverview: React.FC = () => {
  const resourceHook = useResourceOverview();
  const { serverId } = useParams();
  useEffect(() => {
    resourceHook.setInitialProject();
  }, [serverId]);
  return (
    <ResourceOverviewContext.Provider value={resourceHook}>
      {resourceHook.loadingPage ? (
        <CustomSpinner />
      ) : (
        <Box overflowY="auto" mb={10}>
          <ResourcePageOverviewHeader />
          <TabPanelContent />
        </Box>
      )}
    </ResourceOverviewContext.Provider>
  );
};
