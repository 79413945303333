import { Avatar, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { MembersInterface } from "./types";

interface MemberInfoProps {
  member: MembersInterface;
}

export const MemberInfo: React.FC<MemberInfoProps> = ({ member }) => {
  return (
    <Flex minW="fit-content" w={200} direction="column" align="center">
      <Avatar size="xl" name={member.name} mb={2} src={member.pictureSrc} />
      <Text
        color={useColorModeValue("blue.800", "blue.100")}
        fontWeight={600}
        fontSize="xl"
      >
        {member.name}
      </Text>
      <Text fontWeight={600} fontSize="xs" color={"neutralGrey"}>
        {member.role.toUpperCase()}
      </Text>
    </Flex>
  );
};
