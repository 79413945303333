import { Spinner } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";

export const HomeComponent = () => {
  const navigate = useNavigate();
  const contextUser = useContext(UserContext);

  useEffect(() => {
    if (contextUser?.user) {
      navigate("/dashboard/projects");
    } else {
      navigate("/login");
    }
  }, [navigate, contextUser]);

  return (
    <>
      <Spinner />
    </>
  );
};
