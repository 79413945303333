import {
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Spacer,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { ReactComponent as MonitorIcon } from "../../Assets/monitor.svg";
import { ReactComponent as MobileIcon } from "../../Assets/mobile.svg";
import { useNavigate } from "react-router-dom";

export const WhatsNewPage: React.FC = () => {
  const headingColor = useColorModeValue("blue.800", "blue.400");

  const navigate = useNavigate();
  const handleNavigateTopic = (idTopic: string) => {
    navigate(`/dashboard/help/${idTopic}`);
  };
  return (
    <Flex py={6} pl={7} direction="column" gap={4} h="100%">
      <Heading size="xl" color={headingColor} pl={6}>
        What's new
      </Heading>
      <VStack align={"flex-start"} pr={16} pl={6} py={4}>
        <Box>
          <Badge color={"blue.400"} p={1} borderRadius={5}>
            20 October 2022
          </Badge>
        </Box>
        <Box fontWeight={700} fontSize={24}>
          Major updates in how the aplication works.
        </Box>
        <HStack>
          <Box bg="blue.100" px={1} pt={1} borderRadius={5}>
            <Icon as={MonitorIcon} boxSize={12} />
          </Box>
          <VStack align={"flex-start"} spacing={0}>
            {" "}
            <Badge color={"blue.400"} p={1} borderRadius={5}>
              V1.0
            </Badge>
            <Spacer />
            <Box fontWeight={700} fontSize={20}>
              Web updates
            </Box>
          </VStack>
        </HStack>
        <Box
          fontWeight={650}
          fontSize={20}
          textDecoration="underline"
          cursor="pointer"
          _hover={{ color: "blue.400" }}
          onClick={() => {
            handleNavigateTopic("topic4");
          }}
        >
          Creating one or multiple projects.
        </Box>
        <Box>
          We now have the ability to create one or multiple projects in the app
        </Box>
        <Image src="/WhatsNew1.png" w="100%" h="auto" />
        <Box
          fontWeight={650}
          fontSize={20}
          pt={8}
          textDecoration="underline"
          cursor="pointer"
          _hover={{ color: "blue.400" }}
          onClick={() => {
            handleNavigateTopic("topic6");
          }}
        >
          Create servers for projects and add tasks & services.
        </Box>
        <Box pb={2}>
          Each project can now have multiple servers that can be watched, the
          project owner being able to see how his server works, their downtime
          and a lot more info.
        </Box>
        <Image src="/WhatsNew2.png" w="100%" h="auto" />

        <HStack py={8}>
          <Box bg="blue.100" px={1} pt={1} borderRadius={5}>
            <Icon as={MobileIcon} boxSize={12} px={1} pt={1} borderRadius={5} />
          </Box>
          <VStack align={"flex-start"} spacing={0}>
            {" "}
            <Badge color={"blue.400"} p={1} borderRadius={5}>
              V1.0
            </Badge>
            <Spacer />
            <Box fontWeight={700} fontSize={24}>
              Mobile Updates
            </Box>
          </VStack>
        </HStack>

        <Box fontWeight={650} fontSize={20}>
          Project details, log details.
        </Box>
        <Box>
          Watch your project using your phone, verify logs from anywhere you
          are.
        </Box>
        <Image src="/WhatsNew3.png" w="100%" h="auto" />
        <Box fontWeight={650} fontSize={20} pt={8}>
          Notifications for offline servers, services or tasks.
        </Box>
        <Box pb={4}>
          Different alerts send different notifications, based on your level of
          urency you can now receive notifications on your mobile about the
          projects you are watching.
        </Box>
        <Image src="/WhatsNew4.png" w="100%" h="auto" />
        <Box w="100%" pt={20}>
          <Box
            fontWeight={650}
            fontSize={20}
            borderTop="2px solid"
            borderColor="neutralGrey"
            py={8}
          >
            Previous Releases
          </Box>
          <HStack spacing={4}>
            <Image src="/WhatsNew5.png" />
            <VStack align={"flex-start"} justify={"flex-start"} h="100%">
              <Badge p={1} borderRadius={5} color="blue.400">
                11 October 2022
              </Badge>
              <Box fontWeight={600} fontSize={20}>
                Application went live on Production
              </Box>
              <Box>
                After months of work the WatchDog app finally went live in
                production with a major success.
              </Box>
            </VStack>
          </HStack>
        </Box>
      </VStack>
    </Flex>
  );
};
