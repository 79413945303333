import {
  Box,
  Flex,
  Heading,
  HStack,
  Spacer,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { MemberInfo } from "./MemberInfo";
import { MembersInterface } from "./types";

//
interface MembersProps {
  members: MembersInterface[];
  former?: boolean;
}

export const Members: React.FC<MembersProps> = ({ members, former }) => {
  return (
    <Box w="100%">
      <HStack w={"100%"} py={6} pl={8} pr={6}>
        <Heading color={useColorModeValue("blue.700", "blue.100")}>
          {" "}
          {former && "Former "} Team Members
        </Heading>
        <Spacer />
      </HStack>
      <Flex wrap="wrap" justify="center" rowGap={8}>
        {members.map((member) => {
          return <MemberInfo key={member.name} member={member} />;
        })}
      </Flex>
    </Box>
  );
};
