import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { SelectOptionsInterface } from "../../Common/types";
import {
  ProjectsEmailInterface,
  ProjectsNotificationsInterface,
  RolesFromBackend,
  UsersFromBackend,
} from "../../CreateProjectModal/types";
import { ErrorContext, UserContext } from "../../../App";
import { apiClient, authorise } from "../../utils/apiClient";
import { ProjectUsersInterface } from "../types";
import { UserInputGroup } from "./UserInputGroup";

interface ManageUsersProps {
  initialUsers: ProjectUsersInterface[];
  onSuccessUpdateUsers: (val: ProjectUsersInterface[]) => void;
  projectId: string;
}

export const ManageUsersComponent: React.FC<ManageUsersProps> = ({
  initialUsers,
  onSuccessUpdateUsers,
  projectId,
}) => {
  const myId = useContext(UserContext).user?.id;

  const useError = useContext(ErrorContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure();

  const Overlay = () => <ModalOverlay backdropFilter="blur(2px)" />;
  const [overlay, setOverlay] = React.useState(<Overlay />);
  const [loading, setLoading] = useState<boolean>(false);
  const [allUsers, setAllUsers] =
    useState<ProjectUsersInterface[]>(initialUsers);
  const [notifications, setNotifications] = useState<
    ProjectsNotificationsInterface[]
  >([]);
  const [weHaveNotif, setWeHaveNotifications] = useState<boolean>(false);

  const [roles, setRoles] = useState<SelectOptionsInterface[]>([]);
  const [weHaveRoles, setWeHaveRoles] = useState<boolean>(false);

  const [usersEmails, setUsersEmails] = useState<ProjectsEmailInterface[]>([]);
  const [weHaveUsersEmail, setWeHaveUsersEmail] = useState<boolean>(false);

  const getUsersEmails = async () => {
    await apiClient
      .get("/api/private/users/user-emails", authorise())
      .then((res) => {
        setUsersEmails(
          res.data
            .map((user: UsersFromBackend) => {
              return {
                value: user.userId,
                label: user.email,
              };
            })
            .sort((a: ProjectsEmailInterface, b: ProjectsEmailInterface) => {
              if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) {
                return 1;
              } else if (
                a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()
              ) {
                return -1;
              }
              return 0;
            })
        );
        setWeHaveUsersEmail(true);
      })
      .catch((err) => {
        useError?.createError(["EMAIL"]);
      });
  };

  const getUsersRoles = async () => {
    await apiClient
      .get("/api/project-roles/all", authorise())
      .then((res) => {
        setRoles(
          res.data.map((role: RolesFromBackend) => {
            return {
              value: role.roleId,
              display: role.roleName,
              color: "black",
            };
          })
        );
        setWeHaveRoles(true);
      })
      .catch((err) => {
        useError?.createError(["ROLES"]);
      });
  };

  const getNotificationsLevels = async () => {
    await apiClient
      .get("/api/notifications/all", authorise())
      .then((res) => {
        setNotifications(res.data);
        setWeHaveNotifications(true);
      })
      .catch((err) => {
        useError?.createError(["NOTIFICATIONS"]);
      });
  };

  useEffect(() => {
    getUsersEmails();
    getUsersRoles();
    getNotificationsLevels();

    //put myself on the firt position
    if (myId !== allUsers[0].userId) {
      for (let i = 0; i < allUsers.length; i++) {
        if (myId === allUsers[i].userId) {
          [allUsers[0], allUsers[i]] = [allUsers[i], allUsers[0]];
          break;
        }
      }
    }
  }, []);

  const addAnotherUser = () => {
    setAllUsers([
      ...allUsers,
      {
        userId: "",
        email: "",
        firstName: "",
        lastName: "",
        roleName: roles.filter(
          (role) => role.display.toLowerCase() === "watcher"
        )[0].display,
        notificationLevel: "0",
      },
    ]);
  };

  const handleUserDelete = (index: number) => {
    if (allUsers[index].roleName.toLocaleLowerCase() === "watcher")
      setAllUsers(allUsers.filter((user, i) => i !== index));
    else {
      if (
        allUsers.filter(
          (user) => user.roleName.toLocaleLowerCase() === "designer"
        ).length <= 1
      )
        useError.createError(["NO_DESIGNER"]);
      else setAllUsers(allUsers.filter((user, i) => i !== index));
    }
  };

  const handleSaveClick = async () => {
    const usersToSend = allUsers
      .filter((user) => {
        return user.userId !== "";
      })
      .map((user) => {
        return {
          userId: user.userId,
          email: user.email,
          projectRoleId: roles.filter(
            (role) => user.roleName === role.display
          )[0].value,
          minNotificationLevel: parseInt(user.notificationLevel),
        };
      });

    setLoading(true);
    await apiClient
      .put(
        `/api/private/users/manage-users/${projectId}`,
        usersToSend,
        authorise()
      )
      .then((res) => {
        setAllUsers(allUsers.filter((user) => user.userId !== ""));
        onSuccessUpdateUsers(allUsers.filter((user) => user.userId !== ""));
        onClose();
        useError.createToast("You've managed users for this project.", {
          position: "bottom",
        });
      })
      .catch((err) => {
        useError.createError(err.response.data);
      });
    setLoading(false);
  };

  const onCloseBigModal = () => {
    if (JSON.stringify(allUsers) === JSON.stringify(initialUsers)) {
      setAllUsers(initialUsers);
      onClose();
    } else onOpenConfirm();
  };
  const headerColor = useColorModeValue("blue.100", "darkThemeGrey.500");
  const primaryColor = useColorModeValue("white", "darkThemeGrey.600");
  const colorButtons = useColorModeValue("blue.700", "blue.400");
  return (
    <Box>
      <Button
        w={"100%"}
        color={"inherit"}
        backgroundColor={"inherit"}
        textAlign={"left"}
        variant="ghost"
        justifyContent={"start"}
        fontWeight={"normal"}
        onClick={() => {
          setOverlay(<Overlay />);
          onOpen();
        }}
      >
        Manage users
      </Button>
      <Modal
        scrollBehavior={"inside"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onCloseBigModal}
        size={"3xl"}
      >
        {overlay}
        <ModalContent>
          <ModalHeader
            textColor={useColorModeValue("blue.800", "darkThemeGrey.100")}
            bgColor={headerColor}
            fontWeight={"semibold"}
            fontSize="lg"
            borderRadius={6}
          >
            Manage users
          </ModalHeader>
          <ModalCloseButton
            onClick={onCloseBigModal}
            border="2px"
            color={useColorModeValue("blue.800", "darkThemeGrey.100")}
            m={2}
          />
          <ModalBody bg={primaryColor}>
            {weHaveUsersEmail && weHaveNotif && weHaveRoles ? (
              <Flex direction="column">
                {allUsers.map((user, index) => {
                  return (
                    <UserInputGroup
                      usersEmails={usersEmails}
                      setUsersEmails={setUsersEmails}
                      roles={roles}
                      currentUserName={
                        allUsers.filter(
                          (currUser) => currUser.userId === user.userId
                        )[0]?.email || ""
                      }
                      notifications={notifications}
                      value={user}
                      index={index}
                      key={index}
                      handleDeleteUser={() => handleUserDelete(index)}
                      allUsers={allUsers}
                      setAllUsers={setAllUsers}
                      disabled={
                        initialUsers.find(
                          (initialUser) => initialUser.userId === user.userId
                        )
                          ? true
                          : false
                      }
                    />
                  );
                })}
              </Flex>
            ) : (
              <Spinner />
            )}
          </ModalBody>
          <ModalFooter
            display="flex"
            bg={primaryColor}
            justifyContent={"space-between"}
          >
            <Button
              onClick={addAnotherUser}
              color={colorButtons}
              variant={"link"}
              fontSize="sm"
              fontWeight={600}
            >
              +Add another user
            </Button>

            <Button
              w="40%"
              bgColor={colorButtons}
              colorScheme="blue"
              onClick={handleSaveClick}
              isLoading={loading}
              color="white"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalCloseButton
          onClick={onCloseConfirm}
          margin={"5px 8px 0px 0px"}
          color="blue.800"
          borderWidth={2}
          borderRadius={5}
          borderColor={"blue.800"}
        />
        <ModalOverlay backdropFilter="blur(10px)" backdropBlur={"10px"} />

        <ModalContent>
          <ModalBody bg={useColorModeValue("white", "darkThemeGrey.700")}>
            <VStack>
              <Box
                fontSize={20}
                textAlign="left"
                w="100%"
                fontWeight={600}
                py={2}
              >
                Discard all changes?
              </Box>
              <Box fontSize={16} textAlign="left" w="100%" fontWeight={400}>
                We won’t be able to save your data if you move away from this
                page.
              </Box>
              <Spacer />
              <HStack w="100%">
                <Spacer />
                <Box>
                  <Button tabIndex={2} onClick={onCloseConfirm}>
                    {" "}
                    Go back
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      setAllUsers(initialUsers);
                      onClose();
                      onCloseConfirm();
                    }}
                    colorScheme="danger"
                    bg="danger.500"
                    tabIndex={1}
                    autoFocus
                  >
                    Exit
                  </Button>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
