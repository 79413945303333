import { Box, Flex, HStack, Spacer, VStack } from "@chakra-ui/react";
import React from "react";
import { CPUDetailsInterface } from "./types";

interface ProcessorRowProps {
  processor: CPUDetailsInterface;
}
export const ProcessorRow: React.FC<ProcessorRowProps> = ({ processor }) => {
  return (
    <VStack fontSize={16} fontWeight={500}>
      <HStack w="100%" justify={"flex-start"}>
        <Box p={2} w="25%">
          Vendor: {processor.vendor}
        </Box>
        <Spacer />
        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          Model: {processor.model}
        </Box>
        <Spacer />
        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          Model Name: {processor.modelName}
        </Box>
        <Spacer />
        <Box w="25%">Mhz: {processor.mhz}</Box>
      </HStack>
      <HStack w="100%" align={"center"} justify={"flex-start"}>
        <Flex p={2} w="25%">
          CacheKbL1d: {processor.cacheKbL1d}
        </Flex>
        <Spacer />
        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          CacheKbL1i: {processor.cacheKbL1i}
        </Box>
        <Spacer />
        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          CacheKbL2: {processor.cacheKbL2}
        </Box>
        <Spacer />
        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          CacheKbL3: {processor.cacheKbL3}
        </Box>
      </HStack>
      <HStack w="100%" justify={"flex-start"} align={"center"}>
        <Box h="100%" w="25%" p={2}>
          Sockets: {processor.sockets}
        </Box>

        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          CoresPerSocket: {processor.cacheKbL1i}
        </Box>

        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          CacheKbL2: {processor.cacheKbL2}
        </Box>

        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          ThreadsPerCore: {processor.threadsPerCore}
        </Box>
      </HStack>
      <HStack w="100%" justify={"flex-start"} align={"center"}>
        <Box noOfLines={2} textOverflow="ellipsis" w="25%" p={2}>
          Family: {processor.family}
        </Box>
        <Spacer />
        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          VirtualizationType: {processor.virtualizationType}
        </Box>
        <Spacer />

        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          VirtualizationVendor: {processor.virtualizationVendor}
        </Box>
        <Spacer />

        <Box noOfLines={2} textOverflow="ellipsis" w="25%">
          BogoMips: {processor.bogoMips}
        </Box>
      </HStack>
    </VStack>
  );
};
