import { ArrowBackIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Accordion,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { ReactComponent as TimerIcon } from "../../Assets/security-time.svg";
import { ReactComponent as UsersButtonIcon } from "../../Assets/Users/UsersButtonIcon.svg";
import { theme } from "../../theme";

import { CustomSpinner } from "../Common/Spinner";
import {
  SelectedInterval,
  TimeIntervalWrapper,
} from "../Common/TimeIntervalWrapper";
import { WDConfirmDelete } from "../Common/WDConfirmDelete";
import { WDModalServer } from "../CreateServerModal";
import { WDServiceModal } from "../CreateServicesModal/WDServiceModal";
import { CustomTabs } from "../ProjectsComponent/CustomTabs";
import { OnlineStatus } from "../ProjectsComponent/OnlineStatus/OnlineStatus";
import { ThresholdTemplateInterface } from "../ThresholdTemplates/types";
import { ConfirmDisableModal } from "./ConfirmDisableModal";
import { EditProjectModal } from "./EditProjectModal";
import { ManageThresholdsModal } from "./ManageThresholdsModal";
import { ChooseTemplateModal } from "./ManageThresholdsModal/ChooseTemplateModal";
import { ThresholdsInterface } from "./ManageThresholdsModal/types";
import { ManageUsersComponent } from "./ManageUsersModal/ManageUsersComponent";
import { ServiceComponent } from "./ServiceComponent";
import { useProjectDetailsLogic } from "./useProjectDetailsLogic";
import { UsersPopOver } from "./UsersPopOver";

export const ProjectDetails: React.FC = () => {
  const allData = useProjectDetailsLogic();

  return allData.loading ? (
    <CustomSpinner />
  ) : (
    <Box height="100%" overflowY="auto">
      <HStack w={"100%"} px={6} py={6} align="center" justify={"center"}>
        <Flex alignItems={"center"}>
          <Flex
            mr={4}
            alignItems={"center"}
            justifyContent={"center"}
            w={8}
            h={8}
            borderRadius="100%"
            bgColor={"blue.100"}
            cursor="pointer"
            onClick={() => {
              allData.navigate("/dashboard/projects");
            }}
          >
            <ArrowBackIcon
              w={5}
              h={5}
              cursor="pointer"
              stroke={"blue.700"}
              color="blue.700"
            />
          </Flex>
          <Box flex={1}>
            <OnlineStatus status={allData.projectDetails.status} type={5} />
          </Box>

          <Flex
            ml={4}
            alignItems="center"
            h="100%"
            fontWeight="bold"
            fontSize="2xl"
          >
            {allData.projectDetails?.name}
          </Flex>

          <Flex direction={"row"}>
            <Flex justify={"center"} align={"center"} h="100%">
              <Badge
                borderRadius={20}
                w={24}
                h={6}
                ml={3}
                mt={1}
                variant="outline"
                colorScheme={
                  allData.projectDetails.projectRole === "Designer"
                    ? "green"
                    : "gray"
                }
                display="flex"
                alignItems={"center"}
                justifyContent="center"
              >
                {allData.projectDetails.projectRole}
              </Badge>
            </Flex>
          </Flex>
        </Flex>
        <Spacer />

        <Flex justifyContent={"center"} alignItems={"center"}>
          <Box mt={3}>
            <UsersPopOver projectUsers={allData.projectUsers} />
          </Box>
          {(allData.projectDetails.projectRole === "Designer" ||
            allData.userContext.user.roleName === "Admin") && (
            <Box paddingLeft={2} paddingRight={2}>
              <WDModalServer
                currentProject={allData.projectDetails}
                setCurrentProject={allData.setProjectDetails}
              />
            </Box>
          )}
          {(allData.projectDetails.projectRole === "Designer" ||
            allData.userContext.user.roleName === "Admin") && (
            <Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<UsersButtonIcon stroke={allData.colorStrokePrimary} />}
                  variant="outline"
                />
                <MenuList bg={allData.colorMenu}>
                  <ManageUsersComponent
                    initialUsers={allData.projectUsers}
                    onSuccessUpdateUsers={allData.onSuccessUpdateUsers}
                    projectId={allData.projectId as string}
                  />

                  <EditProjectModal
                    setLoading={allData.setLoading}
                    modalData={allData.projectDetails}
                    setModalData={allData.setProjectDetails}
                  />
                  <WDConfirmDelete
                    handleDelete={allData.handleDeleteProject}
                    id={allData.projectId ?? "1"}
                  >
                    <MenuItem color="danger.400">Delete project</MenuItem>
                  </WDConfirmDelete>
                </MenuList>
              </Menu>
            </Box>
          )}
        </Flex>
      </HStack>

      <Box w={"100%"} px={6}>
        <Text fontWeight={"bold"} color={allData.colorTextPrimary}>
          Description
        </Text>
        <Text color={"neutralGrey"}>
          {allData.projectDetails?.description}{" "}
        </Text>
      </Box>
      {allData.projectDetails.servers.length ? (
        <Box height="60vh" width="100%" px={6} marginTop={5}>
          <Tabs
            onChange={(index) => allData.setTabIndex(index)}
            index={allData.tabIndex}
          >
            <TabList color={theme.colors.neutralGrey}>
              {allData.projectDetails.servers.map((server, i) => {
                return (
                  <CustomTabs
                    index={i}
                    title={server.name}
                    status={server.status}
                    key={server.serverId + i}
                    size={4}
                  />
                );
              })}
            </TabList>

            <ChooseTemplateModal
              onClickTemplate={allData.onClickTemplate}
              onClickNoTemplate={allData.onClickNoTemplate}
              isOpenChooseTemplate={allData.isOpenChooseTemplate}
              setIsOpenChooseTemplate={allData.setIsOpenChooseTemplate}
              setIsOpenThresholds={allData.setIsOpen}
              isRendered={allData.isRendered}
            />
            <ManageThresholdsModal
              onSuccess={(
                val: ThresholdsInterface | ThresholdTemplateInterface
              ) => {
                allData.putThreshold(allData.serverIdState, val);
              }}
              isOpen={allData.isOpen}
              setIsOpen={allData.setIsOpen}
              initialValues={allData.initialValues}
              setInitialValues={allData.setInitialValues}
              serverId={allData.serverIdState}
              isTemplateSelected={allData.isTemplateSelected}
            />

            <TabPanels>
              {allData.projectDetails.servers.map((server, i) => {
                return (
                  <TabPanel key={server.serverId}>
                    <HStack w={"100%"} color={allData.colorTextPrimary}>
                      <Box textAlign="left">
                        <Text fontSize={20} fontWeight="bold">
                          {server.name}'s services
                        </Text>
                        <Text
                          fontSize={15}
                          fontWeight="600"
                          color="neutralGrey"
                        >
                          {server.ipAddress}
                          {server.isProduction ? "-Production" : ""}
                        </Text>
                      </Box>
                      <Spacer />

                      <Flex justifyContent={"center"} alignItems={"center"}>
                        <Button
                          color={allData.colorStrokePrimary}
                          fontWeight="600"
                          display="flex"
                          alignItems="center"
                          variant={"link"}
                          onClick={allData.handleNavigate}
                        >
                          {<TimerIcon />} Check server logs
                        </Button>
                        {(allData.projectDetails.projectRole === "Designer" ||
                          allData.userContext.user.roleName === "Admin") && (
                          <Box paddingLeft={2} paddingRight={2}>
                            <WDServiceModal
                              currentServer={server}
                              currentProject={allData.projectDetails}
                              setCurrentProject={allData.setProjectDetails}
                            />
                          </Box>
                        )}
                        {(allData.projectDetails.projectRole === "Designer" ||
                          allData.userContext.user.roleName === "Admin") && (
                          <Box>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                aria-label="Options"
                                icon={
                                  <UsersButtonIcon
                                    stroke={allData.colorStrokePrimary}
                                  />
                                }
                                variant="outline"
                              />
                              <MenuList bg={allData.colorMenu}>
                                <WDModalServer
                                  currentProject={allData.projectDetails}
                                  setCurrentProject={allData.setProjectDetails}
                                  serverId={server.serverId}
                                />

                                <ConfirmDisableModal
                                  state={!(server.status === 40)}
                                  toBeDisabled={server.name}
                                  id={server.serverId}
                                  handleDisable={() => {
                                    allData.handleDisableServer(
                                      server.serverId
                                    );
                                  }}
                                >
                                  <MenuItem>
                                    {!(server.status === 40)
                                      ? "Disable"
                                      : "Enable"}{" "}
                                    server
                                  </MenuItem>
                                </ConfirmDisableModal>
                                {/* <MenuItem onClick={() => {}}>
                                  Manage jobs
                                </MenuItem> */}

                                <MenuItem
                                  w={"100%"}
                                  color={"inherit"}
                                  backgroundColor={"inherit"}
                                  textAlign={"left"}
                                  justifyContent={"start"}
                                  fontWeight={"normal"}
                                  onClick={() => {
                                    allData.setServerIdState(server.serverId);
                                    allData.setIsRendered(true);
                                    allData.setIsOpenChooseTemplate(true);
                                  }}
                                >
                                  Manage thresholds
                                </MenuItem>

                                <WDConfirmDelete
                                  handleDelete={allData.handleDeleteServer}
                                  id={server.serverId}
                                >
                                  <MenuItem color="danger.400">
                                    Delete server
                                  </MenuItem>
                                </WDConfirmDelete>
                              </MenuList>
                            </Menu>
                          </Box>
                        )}
                      </Flex>
                    </HStack>
                    <Flex mt={3}>
                      <TimeIntervalWrapper
                        isConfigured={server.isConfigured}
                        title="Resources overview"
                        onIntervalChange={(
                          selectedInterval: SelectedInterval
                        ) => {
                          allData.setSelectedInterval(selectedInterval);
                        }}
                        initialInterval={allData.selectedInterval}
                        seeFullDetailsUrl={`../dashboard/projects/${allData.projectId}/resources/${server.serverId}`}
                      >
                        <Flex direction="row" justifyContent={"left"} my={1}>
                          <Flex direction={"column"} mr={60}>
                            <Text
                              fontSize={"md"}
                              color="gray.500"
                              fontWeight={"semibold"}
                            >
                              CPU Usage
                            </Text>
                            <Text fontSize={"md"} fontWeight="medium">
                              {allData.selectedInterval === "24h"
                                ? `${server.usages[0]?.cpu.toFixed(2)}%`
                                : allData.selectedInterval === "7d"
                                ? `${server.usages[1]?.cpu.toFixed(2)}%`
                                : `${server.usages[2]?.cpu.toFixed(2)}%`}
                            </Text>
                          </Flex>
                          <Flex direction={"column"} mr={60}>
                            <Text
                              fontSize={"md"}
                              color="gray.500"
                              fontWeight={"semibold"}
                            >
                              Memory Usage
                            </Text>
                            <Text fontSize={"md"} fontWeight="medium">
                              {allData.selectedInterval === "24h"
                                ? `${server.usages[0]?.mem.toFixed(2)}%`
                                : allData.selectedInterval === "7d"
                                ? `${server.usages[1]?.mem.toFixed(2)}%`
                                : `${server.usages[2]?.mem.toFixed(2)}%`}
                            </Text>
                          </Flex>
                          <Flex direction={"column"}>
                            <Text
                              fontSize={"md"}
                              color="gray.500"
                              fontWeight={"semibold"}
                            >
                              Disk Usage
                            </Text>
                            <Text fontSize={"md"} fontWeight="medium">
                              {allData.selectedInterval === "24h"
                                ? `${server.usages[0]?.disk.toFixed(2)}%`
                                : allData.selectedInterval === "7d"
                                ? `${server.usages[1]?.disk.toFixed(2)}%`
                                : `${server.usages[2]?.disk.toFixed(2)}%`}
                            </Text>
                          </Flex>
                        </Flex>
                      </TimeIntervalWrapper>
                    </Flex>
                    {server.services.length ? (
                      <Accordion allowToggle>
                        {server.services.map((service) => {
                          return (
                            <ServiceComponent
                              key={service.serviceId}
                              service={service}
                              project={allData.projectDetails}
                              setProject={allData.setProjectDetails}
                              server={server}
                              allServers={allData.projectDetails.servers}
                            />
                          );
                        })}
                      </Accordion>
                    ) : (
                      <Text
                        color={theme.colors.neutralGrey}
                        width="100%"
                        mt={10}
                        textAlign="center"
                      >
                        There are no services!
                      </Text>
                    )}
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      ) : (
        <VStack justifyContent="center" alignItems="center" p={20}>
          <ViewOffIcon boxSize={20} opacity="50%" />
          <Box pl={4} pt={4} fontWeight={600} fontSize={20}>
            You don't have any servers.
          </Box>
          <Box fontSize={16}>Create one!</Box>
        </VStack>
      )}
    </Box>
  );
};
