import moment from "moment";
import { ModalServerInterface } from "../types";

export const createServerObject = (modalDataServer: ModalServerInterface) => {
  return {
    name: modalDataServer.serverName,
    description: "",
    ipAddress: modalDataServer.IPv4Address,
    iPv6Address: "",
    status: modalDataServer.status,
    isProduction: modalDataServer.isProduction,
    serverType: modalDataServer.enviroments.map((enviroment) => {
      return {
        name: enviroment,
        description: "",
      };
    }),

    job: {
      isActive: modalDataServer.isEnabled,
      startDate: modalDataServer.startDateTime,
      recurringTime: parseInt(modalDataServer.reccuringTime),
      timeout: parseInt(modalDataServer.timeout),
      minimumNotificationLevel: parseInt(modalDataServer.notificationLevel),
      timeoutNotificationLevel: parseInt(
        modalDataServer.levelTimeoutNotification
      ),
    },
  };
};
export const createUpdatedServerObject = (
  modalDataServer: ModalServerInterface
) => {
  return {
    name: modalDataServer.serverName,
    description: "",
    ipAddress: modalDataServer.IPv4Address,
    iPv6Address: "",
    status: modalDataServer.status,
    isProduction: modalDataServer.isProduction,
    serverType: modalDataServer.enviroments.map((enviroment) => {
      return {
        name: enviroment,
      };
    }),
    serverJob: {
      id: modalDataServer.jobId,
      isActive: modalDataServer.isEnabled,
      startDate: modalDataServer.startDateTime,
      recurringTime: parseInt(modalDataServer.reccuringTime),
      timeout: parseInt(modalDataServer.timeout),
      minimumNotificationLevel: parseInt(modalDataServer.notificationLevel),
      timeoutNotificationLevel: parseInt(
        modalDataServer.levelTimeoutNotification
      ),
    },
  };
};
export const getInitialValuesObject = (data: any): ModalServerInterface => {
  return {
    projectId: data.projectId,
    serverId: data.id,
    jobId: data.job.jobId,
    projectName: data.projectName,
    serverName: data.name,
    enviroments: data.serverEnvironments.map((env: any) => env.name),
    IPv4Address: data.ipAddress,
    IPv6Address: data.iPv6Address ?? "",
    status: data.job.status,
    isProduction: data.isProduction,
    isEnabled: data.isActive,
    startDateTime: moment.utc(data.job.startDate).local().format(),
    reccuringTime: data.job.recurringTime.toString(),
    notificationLevel: data.job.minimumNotificationLevel.toString(),
    timeout: data.job.timeout.toString(),
    levelTimeoutNotification: data.job.timeoutNotificationLevel.toString(),
  };
};
