import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import React from "react";
import { useParams } from "react-router-dom";
import { contentObject } from "./contentObject";
import { DescriptionInterface } from "./types";

export const TopicPage: React.FC = () => {
  const colorText = useColorModeValue("blue.700", "darkThemeGrey.100");
  const topicId = useParams().helpTopic;
  const currentTopic = contentObject.filter((topic) => topic.id === topicId)[0];

  return (
    <VStack w="100%" align={"flex-start"}>
      <HStack fontWeight={600}>
        <Box color="neutralGrey">Help Center</Box>
        <Box color={colorText}> {"> " + currentTopic.title}</Box>
      </HStack>
      <Box w="100%" pt={4}>
        <Text
          color={colorText}
          textAlign={"start"}
          fontWeight={600}
          fontSize={24}
          ml={4}
          textDecoration="underline"
        >
          {currentTopic.title}
        </Text>
      </Box>
      <VStack p={2} alignItems="flex-start" w="100%" spacing={1}>
        {currentTopic.description.map(
          (des: DescriptionInterface, index: number) => {
            return (
              <Box key={index} w="100%">
                <Text
                  fontSize={16}
                  pl={(des.marginRight || 1) * 2}
                  w="100%"
                  fontWeight={des.bold ? 600 : 500}
                  color={colorText}
                >
                  &nbsp; &nbsp;
                  {des.text}
                </Text>
                <Flex justify={"center"} w="100%">
                  <Image
                    src={des.imageURL}
                    w={800}
                    h="auto"
                    borderRadius={10}
                  />
                </Flex>
              </Box>
            );
          }
        )}
      </VStack>
    </VStack>
  );
};
