import {
  Avatar,
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { UserContext } from "../../App";
import { ReactComponent as Crown } from "../../Assets/IconsComponent/crown.svg";
export const ProfileInfo: React.FC = () => {
  const contextUser = useContext(UserContext);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      maxH={174}
      justifyContent="center"
      borderBottomWidth={1}
      borderColor={useColorModeValue("neutralGrey", "darkThemeGrey.200")}
      mb={3}
      mt={7}
    >
      <Flex direction="column" align="center" position={"relative"}>
        {contextUser?.user?.roleName === "Admin" ? (
          <Icon as={Crown} stroke={"warning.600"} boxSize={6} />
        ) : null}
        <Avatar
          fontWeight={600}
          name={
            contextUser?.user?.lastName + " " + contextUser?.user?.firstName
          }
          bg={useColorModeValue("blue.700", "blue.400")}
          color={"white"}
          mb={2}
          src={contextUser?.user?.profilePictureUrl ?? ""}
        />
      </Flex>
      <Box>
        <Text
          fontFamily="Montserrat"
          fontWeight="600"
          fontSize={18}
          lineHeight={5}
          color={useColorModeValue("blue.700", "blue.400")}
        >
          {contextUser?.user?.firstName + " " + contextUser?.user?.lastName}
        </Text>
      </Box>
      <Box>
        <Text
          fontFamily="Montserrat"
          color={useColorModeValue("neutralGrey", "darkThemeGrey.100")}
        >
          {contextUser?.user?.projects?.designer} designers &#9679;{" "}
          {contextUser?.user?.projects?.watcher} watchers
        </Text>
      </Box>
    </Flex>
  );
};
