import { Box, MenuItem, useColorModeValue } from "@chakra-ui/react";
import React, { createContext } from "react";
import { MotionButton } from "../../Utils/MotionComponents";
import { ProjectInterface } from "../Common/types";
import { ModalServer } from "./ModalServer";
import { ModalServerContextInterface, ModalServerInterface } from "./types";
import { useServerModal } from "./useServerModal";
export const ModalServerContext = createContext<ModalServerContextInterface>(
  {} as ModalServerContextInterface
);

interface WDModalServerProps {
  serverId?: string;
  currentProject: ProjectInterface;
  setCurrentProject?: (project: ProjectInterface) => void;
  setProjects?: (modalDataServer: ModalServerInterface) => void;
}

export const WDModalServer: React.FC<WDModalServerProps> = ({
  serverId,
  currentProject,
  setCurrentProject,
  setProjects,
}) => {
  const modalHook = useServerModal(
    currentProject,
    setCurrentProject,
    setProjects,
    serverId
  );
  const colorPrimary = useColorModeValue("blue.700", "blue.400");

  const colorSchemeButton = useColorModeValue("blue.600", "blue.300");
  const colorSchemeButtonMenuItem = useColorModeValue(
    "blue.100",
    "darkThemeGrey.500"
  );
  const colorMenuItem = useColorModeValue("white", "darkThemeGrey.600");
  const handleOpen = () => {
    modalHook.onOpen();
    if (serverId) modalHook.getServerDetails();
  };
  const colorTextMenuItem = useColorModeValue("blue.700", "darkThemeGrey.100");
  return (
    <ModalServerContext.Provider value={modalHook}>
      {setProjects ? (
        <MenuItem
          onClick={() => {
            handleOpen();
          }}
          _hover={{
            bg: colorSchemeButtonMenuItem,
          }}
          bg={colorMenuItem}
          color={colorTextMenuItem}
        >
          Add new server
        </MenuItem>
      ) : (
        <Box w="100%" h="50%">
          <MotionButton
            w={"232px"}
            color={serverId ? "inherit" : "white"}
            _hover={{
              bg: serverId ? colorSchemeButtonMenuItem : colorSchemeButton,
            }}
            backgroundColor={serverId ? "inherit" : colorPrimary}
            textAlign={"center"}
            variant="ghost"
            justifyContent={serverId ? "start" : "center"}
            fontWeight={"normal"}
            borderRadius="6px"
            whileHover={serverId ? {} : { scale: 1.05 }}
            whileTap={serverId ? {} : { scale: 0.9 }}
            transition={serverId ? {} : { type: "spring", bounce: 0.4 }}
            onClick={() => {
              handleOpen();
            }}
          >
            {serverId
              ? "Edit server"
              : serverId
              ? "Add new server"
              : "+ Add new server"}
          </MotionButton>
        </Box>
      )}
      <ModalServer />
    </ModalServerContext.Provider>
  );
};
