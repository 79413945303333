import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { createContext } from "react";
import { ErrorCatcherInterface } from "./Components/Common/ErrorService/types";
import { useError } from "./Components/Common/ErrorService/useError";
import { RoutesComponent } from "./Components/Routes";
import { useAuth } from "./Components/utils/useAuth";
import { theme } from "./theme";
import { UserContextInterface } from "./types";

export const ErrorContext = createContext<ErrorCatcherInterface>(
  {} as ErrorCatcherInterface
);

export const UserContext = createContext<UserContextInterface>(
  {} as UserContextInterface
);

export const App = () => {
  const createError = useError();
  const createUser = useAuth();

  return (
    <ChakraProvider theme={theme}>
      <ErrorContext.Provider value={createError}>
        <UserContext.Provider value={createUser}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          {createUser.didMount && <RoutesComponent />}
        </UserContext.Provider>
      </ErrorContext.Provider>
    </ChakraProvider>
  );
};
