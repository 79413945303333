import { Box, HStack, Spacer, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { MotionButton } from "../../../Utils/MotionComponents";
import { ModalServiceContext } from "../WDServiceModal";

export const FirstStepFooter: React.FC = () => {
  const modalContext = useContext(ModalServiceContext);

  return (
    <HStack w="100%" bg={useColorModeValue("white", "darkThemeGrey.700")}>
      <Spacer />
      <Box>
        <MotionButton
          tabIndex={2}
          color="white"
          colorScheme={useColorModeValue("blue", "blue")}
          backgroundColor={useColorModeValue("blue.700", "blue.400")}
          w={"280px"}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", bounce: 0.4 }}
          onClick={() => {
            modalContext?.handleNext(modalContext?.step + 1);
          }}
        >
          Next
        </MotionButton>
      </Box>
    </HStack>
  );
};
