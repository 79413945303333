import {
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Spacer,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { ResourceOverviewContext } from ".";
import { CustomTabs } from "../../ProjectsComponent/CustomTabs";
import { OnlineStatus } from "../../ProjectsComponent/OnlineStatus/OnlineStatus";
export const ResourcePageOverviewHeader: React.FC = () => {
  const { project, onTabChange, tabIndex } = useContext(
    ResourceOverviewContext
  );

  return (
    <>
      <HStack
        w={"100%"}
        px={6}
        py={6}
        align="center"
        justify={"center"}
        h="10%"
      >
        <Flex justifyContent={"center"} alignItems={"flex-end"} h="100%" mt={7}>
          <Box>
            <Heading
              h={"46px"}
              marginRight={2}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              maxW="400px"
            >
              {project?.name} -{" "}
            </Heading>
          </Box>
          <Flex direction={"row"}>
            <Flex h="100%" align="center">
              <OnlineStatus status={project?.status || 0} type={5} />
            </Flex>

            <Flex justify={"center"} align={"center"} h="100%">
              {project.projectRole !== null ? (
                <Badge
                  borderRadius={20}
                  w={24}
                  h={6}
                  ml={3}
                  mt={1}
                  variant="outline"
                  colorScheme={
                    project.projectRole === "Designer" ? "green" : "gray"
                  }
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                >
                  {project.projectRole}
                </Badge>
              ) : (
                <></>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Spacer />
      </HStack>
      <Box w={"100%"} px={6}>
        <Text fontWeight={"bold"} color={"neutralGrey"}>
          Description
        </Text>
        <Text color={"neutralGrey"}>{project?.description} </Text>
      </Box>

      <Box width="100%" px={6} marginTop={5}>
        <Tabs onChange={onTabChange} index={tabIndex}>
          <TabList color={"neutralGrey"}>
            {project.servers.map((server, i) => {
              return (
                <CustomTabs
                  index={i}
                  title={server.name}
                  status={server.status}
                  key={server.serverId}
                  size={4}
                />
              );
            })}
          </TabList>
        </Tabs>
      </Box>
    </>
  );
};
