import { Box } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { NavBarHeight } from "../../Dashboard/DashboardWrapper/DashboardWrapper";

export const LogoCST: React.FC = () => {
  const origin = "/dashboard/projects";
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      h={NavBarHeight}
      width="100%"
      justifyContent="center"
      onClick={() => {
        navigate(origin);
      }}
      cursor="pointer"
      borderBottomWidth={1}
      borderColor="neutralGrey"
    >
      <Box
        color="blue.700"
        fontWeight="600"
        fontSize={25}
        lineHeight={4}
        fontFamily="Montserrat"
      >
        &#128021;
      </Box>
    </Box>
  );
};
