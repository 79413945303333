import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { ResourceName } from "../../ResourcePage/ResourceOverview/utils";
import { SelectedInterval, TimeIntervalWrapper } from "../TimeIntervalWrapper";
import { LineChart } from "./types";
import { WDProcessChart } from "./WDProcessChart";
interface ProcessChartSectionProps {
  lineChart?: LineChart;
  id: ResourceName | undefined;

  onIntervalChange: (id: ResourceName, val: SelectedInterval) => void;
}
export const ProcessChartSection: React.FC<ProcessChartSectionProps> = ({
  onIntervalChange,
  lineChart,
  id,
}) => {
  const [selectedInterval, setSelectedInterval] =
    useState<SelectedInterval>("24h");

  const onIntervalChangeDto = (val: SelectedInterval) => {
    setSelectedInterval(val);
    if (id) onIntervalChange(id, val);
  };

  return (
    <Box w="100%">
      <TimeIntervalWrapper
        onIntervalChange={onIntervalChangeDto}
        title={"Processes"}
      >
        <WDProcessChart
          lineChart={lineChart}
          selectedInterval={selectedInterval}
        />
      </TimeIntervalWrapper>
    </Box>
  );
};
