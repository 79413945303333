import React, { createContext } from "react";
import { ServerLogs } from "./ServerLogsComponent";
import { LogsDataContextInterface } from "./types";
import { useLogsData } from "./useLogsData";

export const LogsDataContext = createContext<LogsDataContextInterface>(
  {} as LogsDataContextInterface
);

export const ServerLogsPage: React.FC = () => {
  const logsData = useLogsData();
  return (
    <LogsDataContext.Provider value={logsData}>
      <ServerLogs />
    </LogsDataContext.Provider>
  );
};
