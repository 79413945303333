import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { theme } from "../../theme";

interface TaskDetailsProps {
  title: string;
  content: string | undefined;
}

export const TaskDetails: React.FC<TaskDetailsProps> = ({ title, content }) => {
  return (
    <Box textAlign="left">
      <Text fontWeight="600" color={theme.colors.neutralGrey} fontSize={9}>
        {title}
      </Text>
      <Text
        fontWeight={title === "TASK NAME" ? "600" : "500"}
        fontSize={12}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        maxW="100px"
      >
        {content}
      </Text>
    </Box>
  );
};
