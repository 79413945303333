import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { TableRow } from "./TableRow";
//   import { TableRow } from "./TableRow";
import { ClientInterface } from "./types";

interface UsersProps {
  users: ClientInterface[];
  setUsers: (val: ClientInterface[]) => void;
  filteredUsers: ClientInterface[];
  setFilteredUsers: (val: ClientInterface[]) => void;
}

export const UsersTableComponent: React.FC<UsersProps> = ({
  filteredUsers,
  setFilteredUsers,
}) => {
  const colorPrimary = useColorModeValue("blue.100", "darkThemeGrey.500");
  const colorTextPrimary = useColorModeValue(
    "neutralDarkGrey",
    "darkThemeGrey.100"
  );

  return (
    <TableContainer h="100%" w="100%" overflowY="auto">
      <Table variant="simple">
        <Thead
          borderRadius="4px"
          bgColor={colorPrimary}
          top="0"
          left="0"
          position="sticky"
          zIndex={2}
        >
          <Tr>
            <Th fontSize="13px" color={colorTextPrimary}>
              NAME
            </Th>
            <Th width={10} fontSize="13px" color={colorTextPrimary}>
              ROLE
            </Th>
            <Th width={10} fontSize="13px" color={colorTextPrimary}>
              PROJECTS
            </Th>
            <Th width={10} fontSize="13px" color={colorTextPrimary}></Th>
          </Tr>
        </Thead>
        <Tbody minH="fit-content">
          {filteredUsers.map((user, index) => {
            return (
              <React.Fragment key={user.id}>
                <TableRow
                  clients={filteredUsers}
                  setClients={setFilteredUsers}
                  index={index}
                  user={user}
                />
              </React.Fragment>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
