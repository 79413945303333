import { Box, Flex, PinInput, PinInputProps, Text } from "@chakra-ui/react";

import { ReactNode } from "react";

interface WDPinInputProps extends PinInputProps {
  error?: string;
  children: ReactNode;
}

export const WDPinInput: React.FC<WDPinInputProps> = (props) => {
  const { error, children, ...others } = props;

  return (
    <Flex direction={"column"}>
      <Box p={2}>
        <PinInput
          colorScheme={"blue"}
          errorBorderColor={"danger.500"}
          isInvalid={!!error}
          {...others}
        >
          {children}
        </PinInput>
      </Box>

      <Box>{error && <Text color={"danger.500"}>{error}</Text>}</Box>
    </Flex>
  );
};
