import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  useColorModeValue,
  VStack,
  Text,
} from "@chakra-ui/react";
import React, { ChangeEvent, useContext } from "react";
import { ModalServerContext } from ".";
import { formatIpV4 } from "../../Utils/formaters";
import {
  requiredField,
  validateIPv4Address,
} from "../../Utils/ValidatorsForInput";
import { WDInput } from "../Common/WDInput";
import { WDMultiSelect } from "../Common/WDMultiSelect";

export const StepOneAddNewServerComponent: React.FC = () => {
  const {
    errorServerDataModal,
    setErrorServerDataModal,
    modalDataServer,
    setModalDataServer,
  } = useContext(ModalServerContext);
  const options = [
    { value: "development", label: "Development" },
    { value: "qa", label: "QA" },
    { value: "production", label: "Production" },
  ];

  const handleServerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorServerDataModal({
      ...errorServerDataModal,
      errorServerName: requiredField(e.target.value),
    });

    setModalDataServer({ ...modalDataServer, serverName: e.target.value });
  };

  const handleEnviromentsChange = (value: string[]) => {
    if (value.length === 0) {
      setErrorServerDataModal({
        ...errorServerDataModal,
        errorEnviroments: "This field should not be empty",
      });
    } else {
      setErrorServerDataModal({
        ...errorServerDataModal,
        errorEnviroments: "",
      });
    }
    setModalDataServer({ ...modalDataServer, enviroments: value });
  };

  const handleIPv4AddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorServerDataModal({
        ...errorServerDataModal,
        errorIPv4Address: "This field should not be empty",
      });
    } else {
      if (!validateIPv4Address(formatIpV4(e.target.value))) {
        setErrorServerDataModal({
          ...errorServerDataModal,
          errorIPv4Address: "IP Address is invalid",
        });
      } else {
        setErrorServerDataModal({
          ...errorServerDataModal,
          errorIPv4Address: "",
        });
      }
    }
    setModalDataServer({
      ...modalDataServer,
      IPv4Address: formatIpV4(e.target.value),
    });
  };

  const handleIPv6AddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalDataServer({ ...modalDataServer, IPv6Address: e.target.value });
  };

  const handleIsProductionServerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModalDataServer({
      ...modalDataServer,
      isProduction: !modalDataServer.isProduction,
    });
  };

  const colorPrimary = useColorModeValue("blue.800", "darkThemeGrey.100");
  const colorDisabledInput = useColorModeValue("blue.100", "darkThemeGrey.200");
  const focusBorderColor = useColorModeValue("blue.500", "blue.400");
  return (
    <Flex direction="column">
      <FormControl mt={4}>
        <Flex>
          <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
            Project's name
          </FormLabel>
          <Text color="danger.500">*</Text>
        </Flex>
        <WDInput
          _disabled={{
            bgColor: colorDisabledInput,
            color: "black",
            fontWeight: 400,
          }}
          isDisabled
          defaultValue={modalDataServer.projectName}
          focusBorderColor={focusBorderColor}
          placeholder="Enter project's name.."
        />
      </FormControl>

      <FormControl mt={4}>
        <HStack w="100%" h="100%" justify={"flex-start"} align="flex-start">
          <VStack align={"flex-start"} w="100%" spacing={0}>
            <Flex>
              <FormLabel
                fontWeight={"normal"}
                textColor={colorPrimary}
                p={0}
                m={0}
              >
                Server's name
              </FormLabel>
              <Text color="danger.500">*</Text>
            </Flex>
            <WDInput
              value={modalDataServer.serverName}
              focusBorderColor={focusBorderColor}
              onChange={handleServerNameChange}
              placeholder="Enter server's name.."
              error={errorServerDataModal.errorServerName}
            />
          </VStack>
          <VStack align={"flex-start"} w="40%" h="100%" spacing={0}>
            <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
              Is Production Server?
            </FormLabel>
            <Flex w="100%" align={"center"} justify="center">
              <Switch
                isChecked={modalDataServer.isProduction}
                size={"lg"}
                mr={8}
                onChange={handleIsProductionServerChange}
              />
            </Flex>
          </VStack>
        </HStack>
      </FormControl>

      <FormControl mt={4}>
        <Flex>
          <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
            Enviroments
          </FormLabel>
          <Text color="danger.500">*</Text>
        </Flex>
        <WDMultiSelect
          value={modalDataServer.enviroments}
          onChange={handleEnviromentsChange}
          options={options}
          error={errorServerDataModal.errorEnviroments}
        ></WDMultiSelect>
      </FormControl>

      <Flex>
        <FormControl mt={4} mr={2}>
          <Flex>
            <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
              IPv4 Address
            </FormLabel>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDInput
            value={formatIpV4(modalDataServer.IPv4Address)}
            focusBorderColor={focusBorderColor}
            onChange={handleIPv4AddressChange}
            placeholder="Enter IP Address"
            error={errorServerDataModal.errorIPv4Address}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
            IPv6 Address
          </FormLabel>
          <WDInput
            value={modalDataServer.IPv6Address}
            focusBorderColor={focusBorderColor}
            onChange={handleIPv6AddressChange}
            placeholder="Enter IP Address"
            error={errorServerDataModal.errorIPv6Address}
          />
        </FormControl>
      </Flex>
    </Flex>
  );
};
