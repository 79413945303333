import { ErrrorModalDataTaskInterface, tasksIdFieldsInterface } from "../types";

export const checkForErrors = (
  errorModalData: ErrrorModalDataTaskInterface,
  step: number
) => {
  if (step === 0) {
    if (errorModalData.errorName !== "") {
      handleFieldFocus(tasksIdFieldsInterface.idName);
      return false;
    }
    if (errorModalData.errorRequestGeneric !== "") {
      handleFieldFocus(tasksIdFieldsInterface.idRequestGeneric);
      return false;
    }
    if (errorModalData.errorExpectedResponse !== "") {
      handleFieldFocus(tasksIdFieldsInterface.idExpectedResponse);
      return false;
    }
    if (errorModalData.errorHttpMethods !== "") {
      handleFieldFocus(tasksIdFieldsInterface.idRequestMethod);
      return false;
    }

    if (errorModalData.errorRequestBody !== "") {
      handleFieldFocus(tasksIdFieldsInterface.idRequestBody);
      return false;
    }
    if (errorModalData.errorExpectedBody !== "") {
      handleFieldFocus(tasksIdFieldsInterface.idExpectedBody);
      return false;
    }
    if (errorModalData.errorExpectedStatus !== "") {
      handleFieldFocus(tasksIdFieldsInterface.idExpectedStatus);
      return false;
    }
    if (errorModalData.errorRoute !== "") {
      handleFieldFocus(tasksIdFieldsInterface.idRoute);
      return false;
    }
  } else {
    if (errorModalData?.errorDate !== "") return false;

    if (errorModalData?.errorNotificationLevel !== "") return false;
    if (errorModalData?.errorReccuring !== "") return false;
    if (errorModalData?.errorTimeout !== "") return false;
  }
  return true;
};
const handleFieldFocus = (idField: string) => {
  document.getElementById(idField)?.focus();
};
