import { Box, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { theme } from "../../../theme";
import { WDInput } from "../../Common/WDInput";
import { WDThresholdsInputCard } from "../../Common/WDThresholdsInputCard";
import { FolderPathInterface } from "./types";

interface ThirdStepProps {
  folders: FolderPathInterface[];
  addAnotherFolder: (val: string) => void;
  setFolderYellow: (i: number, val: number) => void;
  setFolderRed: (i: number, val: number) => void;
  handleDeleteFolder: (val: string) => void;
}

export const ThirdStep: React.FC<ThirdStepProps> = ({
  folders,
  setFolderRed,
  setFolderYellow,
  handleDeleteFolder,
  addAnotherFolder,
}) => {
  const [folderPath, setFolderPath] = useState<string>("");
  const colorPrimary = useColorModeValue("blue.700", "blue.400");

  return (
    <Flex direction="column" mt={5} align="center">
      <Box width="50%">
        <Formik
          initialValues={{}}
          onSubmit={() => {
            addAnotherFolder(folderPath);
            setFolderPath("");
          }}
        >
          {() => (
            <Form>
              <Flex>
                <WDInput
                  variant="flushed"
                  borderRadius={0}
                  mb={5}
                  value={folderPath}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFolderPath(e.target.value);
                  }}
                  placeholder="Folder path"
                />
                <Button
                  mt="2"
                  type="submit"
                  variant="ghost"
                  color={colorPrimary}
                >
                  Add
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
      <Flex
        wrap="wrap"
        justify="space-evenly"
        align="center"
        rowGap={5}
        minH={200}
        minW="100%"
      >
        {folders.length ? (
          folders.map((f, i) => {
            return (
              <WDThresholdsInputCard
                type="number"
                canBeDeleted={true}
                onDelete={(path) => {
                  handleDeleteFolder(path);
                }}
                key={f.path + "-" + i}
                name={f.path}
                value={f.threshold}
                setValueYellow={(val) => {
                  setFolderYellow(i, val);
                }}
                setValueRed={(val) => {
                  setFolderRed(i, val);
                }}
              />
            );
          })
        ) : (
          <Text color={theme.colors.neutralGrey}>No folders yet.</Text>
        )}
      </Flex>
    </Flex>
  );
};
