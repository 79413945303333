import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useState, FocusEvent } from "react";
import { ErrorContext } from "../../App";
import { MotionButton } from "../../Utils/MotionComponents";
import { isValidClientEmail } from "../../Utils/ValidatorsForInput";
import { WDDropdown } from "../Common/WDDropdown";
import { WDInput } from "../Common/WDInput";
import { apiClient, authorise } from "../utils/apiClient";
import {
  ClientInterface,
  ErrorNewCLientInterface,
  NewClientInterface,
} from "./types";

interface CreateNewClientProps {
  setClients: (val: ClientInterface[]) => void;
  clients: ClientInterface[];
}

export const CreateNewClient: React.FC<CreateNewClientProps> = ({
  setClients,
  clients,
}) => {
  const errorContext = useContext(ErrorContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const colorBackground = useColorModeValue("blue.700", "blue.400");
  const Overlay = () => <ModalOverlay backdropFilter="blur(2px)" />;
  const [overlay, setOverlay] = React.useState(<Overlay />);
  const [loading, setLoading] = useState<boolean>(false);
  const [newClientValues, setNewClientValues] = useState<NewClientInterface>({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [errorValues, setErrorValues] = useState<ErrorNewCLientInterface>({
    errorEmail: "",
    errorFirstName: "",
    errorLastName: "",
  });

  const handleCloseClick = () => {
    setNewClientValues({
      email: "",
      firstName: "",
      lastName: "",
    });
    setErrorValues({
      errorEmail: "",
      errorFirstName: "",
      errorLastName: "",
    });

    onClose();
  };

  const handleNewUserErrors = () => {
    const errors = {
      errorEmail: "",
      errorFirstName: "",
      errorLastName: "",
    };
    if (!newClientValues.email) {
      errors.errorEmail = "This field should not be empty";
    } else {
      if (!isValidClientEmail(newClientValues.email)) {
        errors.errorEmail = "Email is invalid";
      }
    }

    if (newClientValues.firstName === "") {
      errors.errorFirstName = "This field should not be empty";
    }

    if (newClientValues.lastName === "") {
      errors.errorLastName = "This field should not be empty";
    }

    return errors;
  };

  const postNewUser = async (newClientValues: NewClientInterface) => {
    setLoading(true);
    await apiClient
      .post("/api/client/create", newClientValues, authorise())
      .then((res) => {
        setClients([res.data, ...clients]);

        setNewClientValues({
          email: "",
          firstName: "",
          lastName: "",
        });

        handleCloseClick();
      })
      .catch((err) => {
        errorContext?.createError([...err.response.data]);
      });

    setLoading(false);
  };

  const handleSaveClick = () => {
    const errors = handleNewUserErrors();
    if (
      errors.errorEmail === "" &&
      errors.errorFirstName === "" &&
      errors.errorLastName === ""
    ) {
      postNewUser(newClientValues);
    }
    setErrorValues(errors);
  };

  const handleAutoComplete = (e: FocusEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (isValidClientEmail(email)) {
      const firstName1 = email.split("@")[0].split(".")[0];
      const lastName1 = email.split("@")[0].split(".")[1];
      if (firstName1 && lastName1) {
        setNewClientValues({
          ...newClientValues,
          email: email,
          firstName: firstName1.charAt(0).toUpperCase() + firstName1.slice(1),
          lastName: lastName1.charAt(0).toUpperCase() + lastName1.slice(1),
        });

        setErrorValues({
          errorEmail: "",
          errorFirstName: "",
          errorLastName: "",
        });
      }
    }
  };

  const handleEmailInputValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.value) {
      setErrorValues({
        ...errorValues,
        errorEmail: "This field should not be empty",
      });
    } else {
      if (!isValidClientEmail(e.target.value)) {
        setErrorValues({ ...errorValues, errorEmail: "Email is invalid" });
      } else {
        setErrorValues({ ...errorValues, errorEmail: "" });
      }
    }
    setNewClientValues({ ...newClientValues, email: e.target.value });
  };

  const handlefirstNameInputValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.value) {
      setErrorValues({
        ...errorValues,
        errorFirstName: "This field should not be empty",
      });
    } else {
      setErrorValues({ ...errorValues, errorFirstName: "" });
    }
    setNewClientValues({ ...newClientValues, firstName: e.target.value });
  };
  const handlefirstNameInputValueBlur = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.value) {
      setErrorValues({
        ...errorValues,
        errorFirstName: "This field should not be empty",
      });
    } else {
      setErrorValues({ ...errorValues, errorFirstName: "" });

      setNewClientValues({
        ...newClientValues,
        firstName:
          e.target.value[0].toUpperCase() + e.target.value.substring(1),
      });
    }
  };

  const handleLastNameInputValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.value) {
      setErrorValues({
        ...errorValues,
        errorLastName: "This field should not be empty",
      });
    } else {
      setErrorValues({ ...errorValues, errorLastName: "" });
    }
    setNewClientValues({ ...newClientValues, lastName: e.target.value });
  };
  const handleLastNameInputValueBlur = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.value) {
      setErrorValues({
        ...errorValues,
        errorLastName: "This field should not be empty",
      });
    } else {
      setErrorValues({ ...errorValues, errorLastName: "" });

      setNewClientValues({
        ...newClientValues,
        lastName: e.target.value[0].toUpperCase() + e.target.value.substring(1),
      });
    }
  };

  return (
    <Box>
      <MotionButton
        leftIcon={<AddIcon w={3} />}
        m={2}
        colorScheme={"blue"}
        bgColor={colorBackground}
        textColor={"white"}
        onClick={() => {
          setOverlay(<Overlay />);
          onOpen();
        }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: "spring", bounce: 0.4 }}
      >
        Add client
      </MotionButton>

      <Modal isCentered isOpen={isOpen} onClose={handleCloseClick}>
        {overlay}
        <ModalContent>
          <ModalHeader bg={useColorModeValue("blue.100", "darkThemeGrey.600")}>
            Add a new client
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            pb={6}
            bg={useColorModeValue("white", "darkThemeGrey.700")}
          >
            <FormControl>
              <FormLabel>Email</FormLabel>
              <WDInput
                tabIndex={1}
                onBlur={handleAutoComplete}
                value={newClientValues.email}
                onChange={handleEmailInputValueChange}
                placeholder="Email"
                error={errorValues.errorEmail}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>First name</FormLabel>
              <WDInput
                value={newClientValues.firstName ?? ""}
                onChange={handlefirstNameInputValueChange}
                onBlur={handlefirstNameInputValueBlur}
                placeholder="First name"
                error={errorValues.errorFirstName}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Last name</FormLabel>
              <WDInput
                value={newClientValues.lastName ?? ""}
                onChange={handleLastNameInputValueChange}
                onBlur={handleLastNameInputValueBlur}
                placeholder="Last name"
                error={errorValues.errorLastName}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Role</FormLabel>
              <WDDropdown
                onChange={() => {}}
                options={[{ value: "1", display: "Client" }]}
                placeholder="Client"
                value={"1"}
                isDisabled={true}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter
            bg={useColorModeValue("white", "darkThemeGrey.600")}
            pt={2}
            pb={3}
          >
            <MotionButton
              colorScheme="blue"
              color="white"
              bg={useColorModeValue("blue.700", "blue.400")}
              mr={3}
              onClick={handleSaveClick}
              isLoading={loading}
              tabIndex={2}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", bounce: 0.4 }}
            >
              Save
            </MotionButton>
            <Button onClick={handleCloseClick}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
