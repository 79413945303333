import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, HStack, Icon, VStack } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const errorMsg = useLocation().pathname;
  return (
    <VStack
      w="100%"
      h="100%"
      alignContent="center"
      justifyContent="center"
      py={10}
      color="blue.800"
    >
      <HStack>
        <Icon as={InfoOutlineIcon} boxSize={16} />
        <Box fontSize={80} fontWeight={600}>
          404 - Page Not Found
        </Box>
      </HStack>
      <Box fontSize={40} fontWeight={500}>
        Ooops! You weren't supposed to see this
      </Box>
      <Box fontSize={28} fontWeight={400}>
        Page {errorMsg} doesn't exist!
      </Box>
      <Flex fontSize={28}>
        <Box>Return to the </Box>
        <Button
          variant="link"
          fontSize={28}
          pl={1}
          color="blue.400"
          onClick={() => {
            navigate("/");
          }}
        >
          {" "}
          homepage
        </Button>
      </Flex>

      <iframe
        src="https://rob3rtu.github.io/XsiZero/"
        height="100%"
        width="100%"
        title="x & o"
        style={{
          overflow: "hidden",
          scrollBehavior: "unset",
        }}
      ></iframe>
    </VStack>
  );
};
