import { Box } from "@chakra-ui/react";
import React, { MouseEventHandler } from "react";

interface ArrowsNavInterface {
  className?: string;
  style?: any;
  onClick?: MouseEventHandler<HTMLDivElement>;
}
export const ArrowNext: React.FC<ArrowsNavInterface> = ({
  className,
  style,
  onClick,
}) => {
  return (
    <Box
      className={className}
      style={{
        ...style,

        borderRadius: "500px",
      }}
      onClick={onClick}
    ></Box>
  );
};
export const ArrowPrev: React.FC<ArrowsNavInterface> = ({
  className,
  style,
  onClick,
}) => {
  return (
    <Box
      className={className}
      style={{
        ...style,

        borderRadius: "500px",
      }}
      onClick={onClick}
    ></Box>
  );
};
