import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ModalServerContext } from ".";

export const StepBarModal: React.FC = () => {
  const colorButtonPrimary = useColorModeValue("blue.800", "blue.400");
  const colorButtonSecondary = useColorModeValue(
    "neutralGrey",
    "darkThemeGrey.100"
  );
  const { handleNext, step } = useContext(ModalServerContext);
  return (
    <Flex>
      <Box
        onClick={() => {
          handleNext(0);
        }}
        width={"16"}
        backgroundColor={step === 0 ? colorButtonPrimary : colorButtonSecondary}
        height={2}
        borderRadius={5}
        style={{ cursor: "pointer" }}
      />
      <Box
        onClick={() => {
          handleNext(1);
        }}
        width={"16"}
        backgroundColor={
          !(step === 0) ? colorButtonPrimary : colorButtonSecondary
        }
        height={2}
        borderRadius={5}
        marginLeft={1}
        style={{ cursor: "pointer" }}
      />
    </Flex>
  );
};
