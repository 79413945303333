import { Box, Flex, GridItem, Image, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TopicInterface } from "./types";
interface TopicCardProps extends TopicInterface {
  filterValue: string;
}
export const TopicCard: React.FC<TopicCardProps> = ({
  title,
  description,
  id,
}) => {
  const firstImage = description.filter((des) => des.imageURL)[0]?.imageURL;
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/dashboard/help/${id}`);
  };
  const MotionGridItem = motion(GridItem);
  return (
    <MotionGridItem
      onClick={() => {
        handleNavigate();
      }}
      cursor="pointer"
      pb={4}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", bounce: 0.4 }}
      _hover={{ boxShadow: "-1px 8px 12px 1px rgba(0,0,0,0.75)" }}
      borderRadius={5}
    >
      <Flex justifyContent={"center"}>
        <VStack align={"center"} w="280px">
          <Image
            src={firstImage}
            w="100%"
            h="auto"
            borderRadius={4}
            bg="darkThemeGrey.700"
          />
          <Box fontWeight={600} fontSize={16} pl={1}>
            {" "}
            {title}
          </Box>

          <Box noOfLines={2} pl={2} fontWeight={500}>
            {description[0].text.replaceAll("•", "")}
          </Box>
        </VStack>
      </Flex>
    </MotionGridItem>
  );
};
