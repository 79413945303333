export const requiredField = (value: string) => {
  if (value === "") return "This field can't be empty.";

  return "";
};
export const requiredFieldForRequestAndResponse = (value: string) => {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  if (value === "") {
    return "This field can't be empty.";
  } else {
    if (base64regex.test(value) === false)
      return "This field should be encoded in base64.";
  }
  return "";
};

export const validatorNumericField = (value: string) => {
  if (value !== "" && /[a-z]/i.test(value))
    return "This field can't contain letters.";
  return "";
};

export const validatorEmailField = (value: string) => {
  if (!value.includes("@cst.ro")) return "This email isn't valid.";
  return "";
};

export const validateIPv4Address = (IPv4Address: string) => {
  return true;
  // const regexIPv4Address =
  //   /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  // if (regexIPv4Address.test(IPv4Address)) {
  //   return true;
  // }
  // return false;
};

export const isValidEmail = (email: string) => {
  const isExact = email.endsWith("@cst.ro");
  const regexEmail = /.+[a-z0-9]+@cst.ro/;

  if (regexEmail.test(email) && isExact) {
    return true;
  }
  return false;
};

export const isValidClientEmail = (email: string) => {
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  if (regexEmail.test(email)) {
    return true;
  }
  return false;
};

export const isValidFolderPath = (path: string) => {
  //eslint-disable-next-line
  const folderPathRegex = /^\/([A-z0-9-_+]+\/)*([A-z0-9]+)$/;

  if (folderPathRegex.test(path)) {
    return true;
  }
  return false;
};
