import { Box } from "@chakra-ui/react";
import React, { createContext } from "react";
import {
  ProjectInterface,
  ServerInterface,
  ServiceInterface,
} from "../Common/types";
import { ButtonModal } from "./ButtonModal";
import { ModalTask } from "./ModalTask";
import { ModalTaskContextInterface } from "./types";
import { useTaskModal } from "./useTaskModal";
export const ModalTaskContext = createContext<ModalTaskContextInterface>(
  {} as ModalTaskContextInterface
);
export interface TaskInfoInterface {
  currentServer: ServerInterface;
  currentProject: ProjectInterface;
  setCurrentProject: (project: ProjectInterface) => void;
  currentService: ServiceInterface;
  idTask?: string;
}
export const WDTaskModal: React.FC<TaskInfoInterface> = (props) => {
  const modalHook = useTaskModal({ ...props });

  return (
    <Box w="100%">
      <ModalTaskContext.Provider value={modalHook}>
        <ButtonModal />
        <ModalTask />
      </ModalTaskContext.Provider>
    </Box>
  );
};
