import { useDisclosure } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { ErrorContext } from "../../App";
import { ProjectInterface } from "../Common/types";
import { apiClient, authorise } from "../utils/apiClient";
import {
  ErrorsModalServerInterface,
  ModalServerContextInterface,
  ModalServerInterface,
} from "./types";

import { defaultData, defaultErrorModalData } from "./utils/defaultObjects";
import {
  createServerObject,
  createUpdatedServerObject,
  getInitialValuesObject,
} from "./utils/handleApiClient";
import {
  checkErrorsModalServer,
  createErrorOBjectForValidation,
} from "./utils/handleBussinessLogic";

export const useServerModal = (
  currentProject: ProjectInterface,
  setCurrentProject?: (project: ProjectInterface) => void,
  setProjects?: (modalDataServer: ModalServerInterface) => void,
  serverId?: string
): ModalServerContextInterface => {
  const { createToast, createError } = useContext(ErrorContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure();
  const [initialServerValues, setInitialServerValues] =
    useState<ModalServerInterface>({
      ...defaultData,
      projectName: currentProject.name,
      projectId: currentProject.projectId,
    });
  const [modalDataServer, setModalDataServer] = useState<ModalServerInterface>({
    ...defaultData,
    projectName: currentProject.name,
    projectId: currentProject.projectId,
  });

  const [errorServerDataModal, setErrorServerDataModal] =
    useState<ErrorsModalServerInterface>(defaultErrorModalData);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(true);
  const [step, setStep] = useState<number>(0);
  const reset = () => {
    onClose();
    setLoading(false);
    setLoadingUpdate(true);
    setStep(0);
    setModalDataServer({
      ...defaultData,
      projectName: currentProject.name,
      projectId: currentProject.projectId,
    });
    setErrorServerDataModal(defaultErrorModalData);
  };

  const onCloseBigModal = () => {
    if (!serverId) {
      if (
        JSON.stringify({
          ...modalDataServer,
          startDateTime: "",
          projectName: "",
          projectId: "",
        }) ===
        JSON.stringify({
          ...defaultData,
          startDateTime: "",
          projectName: "",
          projectId: "",
        })
      ) {
        reset();
      } else {
        onOpenConfirm();
      }
    } else {
      if (
        JSON.stringify(modalDataServer) === JSON.stringify(initialServerValues)
      )
        reset();
      else {
        onOpenConfirm();
      }
    }
  };

  const handleSave = async () => {
    setErrorServerDataModal(
      createErrorOBjectForValidation(modalDataServer, step)
    );
    if (
      checkErrorsModalServer(
        createErrorOBjectForValidation(modalDataServer, step),
        step
      )
    ) {
      setLoading(true);
      await apiClient
        .post(
          `/api/servers/${currentProject.projectId}`,
          createServerObject(modalDataServer),
          authorise()
        )
        .then((res) => {
          reset();
          if (setProjects) setProjects(modalDataServer);
          else if (setCurrentProject)
            setCurrentProject({
              ...currentProject,
              servers: [
                ...currentProject.servers,
                {
                  serverId: res.data.id,
                  status: res.data.status,
                  name: res.data.name,
                  ipAddress: res.data.ipAddress,
                  isProduction: res.data.isProduction,
                  services: [],
                  isConfigured: res.data.isConfigured,
                  usages: res.data.usages,
                },
              ],
            });
          createToast("You have created a server.");
        })
        .catch((err) => {
          reset();
          createError([...err.response.data]);
        });
    }
  };

  const handleUpdate = async () => {
    setErrorServerDataModal(
      createErrorOBjectForValidation(modalDataServer, step)
    );
    if (
      checkErrorsModalServer(
        createErrorOBjectForValidation(modalDataServer, step),
        step
      )
    ) {
      setLoading(true);
      await apiClient
        .put(
          `/api/servers/${serverId}`,
          createUpdatedServerObject(modalDataServer),
          authorise()
        )
        .then((res) => {
          reset();
          if (setCurrentProject)
            setCurrentProject({
              ...currentProject,
              servers: [
                ...currentProject.servers.map((ser) => {
                  if (ser.serverId === serverId)
                    return {
                      ...ser,
                      isEnabled: modalDataServer.isEnabled,
                      serverId: serverId,
                      status: res.data.status,
                      name: modalDataServer.serverName,
                      ipAddress: modalDataServer.IPv4Address,
                      isProduction: modalDataServer.isProduction,
                    };
                  else return ser;
                }),
              ],
            });
          createToast("You have updated a server.");
        })
        .catch((err) => {
          reset();
          createError(err.response.data);
        });
    }
  };
  const handleNext = (nextStep: number) => {
    setErrorServerDataModal(
      createErrorOBjectForValidation(modalDataServer, step)
    );
    if (
      checkErrorsModalServer(
        createErrorOBjectForValidation(modalDataServer, step),
        step
      )
    ) {
      setStep(nextStep);
    }
  };
  const getServerDetails = async () => {
    await apiClient
      .get(`/api/servers/${currentProject.projectId}/${serverId}`, authorise())
      .then((res) => {
        setLoadingUpdate(false);
        setInitialServerValues(
          getInitialValuesObject({ ...res.data, ...res.data.job })
        );
        setModalDataServer(
          getInitialValuesObject({ ...res.data, ...res.data.job })
        );
      })
      .catch((err) => {
        createError([...err.response.data]);
        reset();
      });
  };
  return {
    serverId,
    modalDataServer,
    setModalDataServer,
    errorServerDataModal,
    setErrorServerDataModal,
    step,
    isOpen,
    onOpen,
    onClose,
    onCloseBigModal,
    isOpenConfirm,
    onCloseConfirm,
    reset,
    handleNext,
    handleSave,
    handleUpdate,
    loading,
    loadingUpdate,
    getServerDetails,
  };
};
