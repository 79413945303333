import {
  Box,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useContext } from "react";
import { TaskLogsDataContext } from ".";
import { statusCodeEnum } from "../../Utils/defaultObjects";
import { ResponseBodyComponent } from "./ResponseBodyComponent";
import { TaskLogGridItem } from "./TaskLogGridItem";

export const TabsTaskPage: React.FC = () => {
  const taskLogsContext = useContext(TaskLogsDataContext);

  const colorBgMain = useColorModeValue("gray.100", "darkThemeGrey.600");
  const colorBgSecondary = useColorModeValue("gray.200", "darkThemeGrey.500");
  const colorText = useColorModeValue("neutralGrey", "darkThemeGrey.300");
  const colorTaskInfo = useColorModeValue("blue.900", "blue.400");
  const colorWarning = useColorModeValue("warning.200", "warning.300");
  const colorDanger = useColorModeValue("danger.200", "danger.300");

  return taskLogsContext.loadingTaskLogs ? (
    <Flex h="40vh" justifyContent={"center"} alignItems={"center"}>
      <Spinner
        size="xl"
        color="blue.500"
        thickness="4px"
        speed="0.65s"
        emptyColor="blue.100"
      />
    </Flex>
  ) : (
    <>
      <Box minW="600px" my={4}>
        <Grid
          templateColumns="repeat(2, 50%)"
          border="1px solid"
          px={4}
          py={3}
          borderColor={"neutralGrey"}
          bg={colorBgMain}
          textColor={colorText}
          borderRadius={"8px 8px 0px 0px"}
        >
          <GridItem w="100%" h="100%" fontSize="small" fontWeight={"semibold"}>
            {taskLogsContext.timeFrame.start}
          </GridItem>

          <GridItem
            w="100%"
            h="100%"
            fontSize="small"
            fontWeight={"semibold"}
            textAlign="end"
          >
            {taskLogsContext.timeFrame.end}
          </GridItem>
        </Grid>
        <Grid
          templateColumns={`repeat(${taskLogsContext.numberOfCells},1fr)`}
          px={4}
          py={3}
          border="1px solid"
          bg={colorBgSecondary}
          textColor={colorText}
          borderColor={"neutralGrey"}
          h="44px"
          borderRadius={"0px 0px 8px 8px"}
        >
          {taskLogsContext
            .getLogBar(
              taskLogsContext.taskDetails.downtime,
              taskLogsContext.taskDetails.disabled,
              taskLogsContext.taskDetails.unexpectedBehaviour,
              []
            )
            .slice(
              0,
              taskLogsContext.getLogBar(
                taskLogsContext.taskDetails.downtime,
                taskLogsContext.taskDetails.disabled,
                taskLogsContext.taskDetails.unexpectedBehaviour,
                []
              ).length
            )
            ?.map((el, index) => {
              return (
                <TaskLogGridItem
                  key={index}
                  index={index}
                  bg={el.color}
                  height={"12px"}
                  colSpan={el.colSpan}
                  lengthOfArrayWithColorAndColSpan={
                    taskLogsContext.getLogBar(
                      taskLogsContext.taskDetails.downtime,
                      taskLogsContext.taskDetails.disabled,
                      taskLogsContext.taskDetails.unexpectedBehaviour,
                      []
                    ).length
                  }
                ></TaskLogGridItem>
              );
            })}
        </Grid>
      </Box>
      {taskLogsContext.taskDetails.logInfo.length === 0 ? (
        <Flex justifyContent={"center"} mt={16}>
          <Text fontSize={"xl"}>Everything seems to be working fine!</Text>
        </Flex>
      ) : (
        taskLogsContext.taskDetails.logInfo.map((info, index) => {
          const startDay = moment.utc(info.start).local().format("Do MMM");
          const endDay = moment.utc(info.end).local().format("Do MMM");
          const startTime = moment.utc(info.start).local().format("HH:mm");
          const endTime = moment.utc(info.end).local().format("HH:mm");
          return (
            <Flex
              key={index}
              direction={"column"}
              border="2px solid"
              borderColor={info.logStatus === 20 ? colorDanger : colorWarning}
              p={6}
              my={4}
              borderRadius={"8px"}
            >
              <Flex>
                <Flex direction="column" mr={28}>
                  <Text fontWeight={"semibold"} color={colorTaskInfo}>
                    {taskLogsContext.tabIndex === 0
                      ? `${startTime} - ${endTime}`
                      : `${startDay} ${startTime} - ${endDay} ${endTime}`}
                  </Text>
                </Flex>
                {info.isGenericTask ? (
                  <></>
                ) : (
                  <Flex direction="column" mr={36}>
                    <Text
                      fontSize={"small"}
                      fontWeight="semibold"
                      color={colorTaskInfo}
                    >
                      REQUEST METHOD
                    </Text>
                    <Text
                      fontWeight={"medium"}
                      color={colorText}
                      textAlign="right"
                    >
                      {
                        statusCodeEnum.filter((st) => {
                          return st.value === info.httpMethod.toString();
                        })[0].display
                      }
                    </Text>
                  </Flex>
                )}
                {info.isGenericTask ? (
                  <></>
                ) : (
                  <Flex direction="column" mr={28}>
                    <Text
                      fontSize={"small"}
                      fontWeight="semibold"
                      color={colorTaskInfo}
                    >
                      STATUS CODE
                    </Text>
                    <Text
                      fontWeight={"medium"}
                      color={colorText}
                      textAlign="right"
                    >
                      {info.statusCode}
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Text
                fontSize={"small"}
                fontWeight="semibold"
                color={colorTaskInfo}
                mt={4}
              >
                REQUEST BODY
              </Text>
              <Text fontWeight={"medium"} color={colorText}>
                {info.requestBody}
              </Text>

              <ResponseBodyComponent
                title="EXPECTED RESPONSE BODY"
                isGenericTask={info.isGenericTask}
                responseBody={info.expectedResponseBody}
              />
              <ResponseBodyComponent
                title="RESPONSE BODY"
                isGenericTask={info.isGenericTask}
                responseBody={info.responseBody}
              />
            </Flex>
          );
        })
      )}
    </>
  );
};
