import {
  Box,
  Flex,
  HStack,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { customNotifications } from "../../../Utils/defaultObjects";
import {
  requiredField,
  validatorNumericField,
} from "../../../Utils/ValidatorsForInput";
import { SelectOptionsInterface } from "../../Common/types";
import { WDDatePicker } from "../../Common/WDDatePicker";
import { WDDropdown } from "../../Common/WDDropdown";
import { WDInput } from "../../Common/WDInput";
import { DisabledInput } from "../DisabledInput";
import { StepBar } from "../StepBar";
import { ModalTaskContext } from "../WDTaskModal";
import { SecondStepFooter } from "./SecondStepFooter";

export const SecondStep: React.FC = () => {
  const { modalData, changeData, errorModalData } =
    useContext(ModalTaskContext);
  const optionEnable: SelectOptionsInterface[] = [
    { value: "Yes", display: "Yes", color: "black" },
    { value: "No", display: "No", color: "black" },
  ];
  const handleEnable = (value: string) => {
    changeData({ isActive: value === "Yes" ? true : false }, {});
  };
  const handleDateTime = (value: string) => {
    changeData({ startDate: value }, { errorDate: requiredField(value) });
  };
  const handleReccuringTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeData(
      { recurringTime: parseInt(e.target.value) },
      {
        errorReccuring:
          requiredField(e.target.value) ||
          validatorNumericField(e.target.value),
      }
    );
  };
  const handleNotificationLevel = (value: string) => {
    changeData(
      { minimumNotificationLevel: parseInt(value) },
      { errorNotificationLevel: requiredField(value) }
    );
  };
  const handleLevelTimeoutNotification = (value: string) => {
    changeData({ timeoutNotificationLevel: parseInt(value) }, {});
  };
  const handleTimeout = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeData(
      { timeout: parseInt(e.target.value) },
      {
        errorTimeout:
          requiredField(e.target.value) ||
          validatorNumericField(e.target.value),
      }
    );
  };
  return (
    <VStack w="100%">
      <StepBar />
      <DisabledInput />
      <HStack w="100%">
        <Box w="100%">
          <Flex>
            <Text>Enabled</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDropdown
            options={optionEnable}
            onChange={handleEnable}
            value={modalData.isActive ? "Yes" : "No"}
          />
        </Box>
        <Box w="100%">
          <Flex>
            <Text> Start Date Time </Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDatePicker
            onChange={handleDateTime}
            value={modalData.startDate}
            error={errorModalData.errorDate}
          />
        </Box>
        <Box w="100%">
          <Flex>
            <Text>Reccuring Time</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <InputGroup borderRadius={4} size={"lg"}>
            <InputRightElement pointerEvents="none" justifyContent="center">
              min
            </InputRightElement>
            <WDInput
              placeholder="5"
              onChange={handleReccuringTime}
              value={
                modalData.recurringTime
                  ? modalData?.recurringTime.toString()
                  : ""
              }
              error={errorModalData?.errorReccuring}
            />
          </InputGroup>
        </Box>
      </HStack>
      <HStack w="100%" py={3}>
        <Box w="100%">
          <Flex>
            <Text>Notification Level</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDropdown
            tabIndex={1}
            options={customNotifications}
            value={modalData?.minimumNotificationLevel.toString()}
            onChange={handleNotificationLevel}
            placeholder="Notification Level"
            error={errorModalData?.errorNotificationLevel}
          />
        </Box>
        <Box w="100%">
          <Flex>
            <Text>Timeout</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <InputGroup borderRadius={4} size={"lg"}>
            <InputRightElement pointerEvents="none" justifyContent="center">
              min
            </InputRightElement>
            <WDInput
              placeholder="15"
              onChange={handleTimeout}
              value={modalData?.timeout ? modalData?.timeout.toString() : ""}
              error={errorModalData?.errorTimeout}
            />
          </InputGroup>
        </Box>
        <Box w="100%">
          <Flex>
            <Text>Notification level timeout</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDropdown
            tabIndex={2}
            options={customNotifications}
            onChange={handleLevelTimeoutNotification}
            value={modalData?.timeoutNotificationLevel.toString()}
          />
        </Box>
      </HStack>
      <SecondStepFooter />
    </VStack>
  );
};
