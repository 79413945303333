import {
  Box,
  Flex,
  Text,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  SliderMark,
  InputGroup,
  InputRightElement,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { TwoColorsInterface } from "../ProjectDetailsPage/ManageThresholdsModal/types";
import { ReactComponent as Trash } from "../../Assets/ModalTrashIcon/trash.svg";
import { theme } from "../../theme";
import { WDInput } from "./WDInput";
import { validatorNumericField } from "../../Utils/ValidatorsForInput";
import { ThresholdUnitsInterface } from "./types";

interface WDInputCardProps {
  name: string;
  value: TwoColorsInterface;
  setValueYellow: (val: number) => void;
  setValueRed: (val: number) => void;
  canBeDeleted?: boolean;
  onDelete?: (name: string) => void;
  type: "slider" | "number";
}

export const WDThresholdsInputCard: React.FC<WDInputCardProps> = ({
  name,
  value,
  setValueYellow,
  setValueRed,
  canBeDeleted,
  onDelete,
  type,
}) => {
  const yellowColor = "yellow.500";
  const redColor = theme.colors.danger[500];

  const [unitYellow, setUnitYellow] = useState<number>(1);
  const [unitRed, setUnitRed] = useState<number>(1);

  const [displayValue, setDisplayValue] = useState<TwoColorsInterface>(value);

  const units:ThresholdUnitsInterface = {
    1: 'KB',
    1024: 'MB',
    1048576: 'GB'
  }

  const handleYellowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validatorNumericField(e.target.value) === "") {
      if (e.target.value === "") {
        setDisplayValue({
          ...displayValue,
          yellow: 0,
        });
      } else {
        setDisplayValue({
          ...displayValue,
          yellow: parseInt(e.target.value),
        });

        if (unitYellow === 1) {
          setValueYellow(parseInt(e.target.value));
        } else if (unitYellow === 1024) {
          setValueYellow(parseInt(e.target.value) * 1024);
        } else if (unitYellow === 1048576) {
          setValueYellow(parseInt(e.target.value) * 1048576);
        }
      }
    }
  };

  const handleRedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validatorNumericField(e.target.value) === "") {
      if (e.target.value === "") {
        setDisplayValue({
          ...displayValue,
          red: 0,
        });
      } else {
        setDisplayValue({
          ...displayValue,
          red: parseInt(e.target.value),
        });

        if (unitRed === 1) {
          setValueRed(parseInt(e.target.value));
        } else if (unitRed === 1024) {
          setValueRed(parseInt(e.target.value) * 1024);
        } else if (unitRed === 1048576) {
          setValueRed(parseInt(e.target.value) * 1048576);
        }
      }
    }
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="space-evenly"
      width={type === "number" ? 300 : 250}
      p={6}
      pt={2}
      border="1px"
      borderColor="neutralGrey"
      borderRadius={10}
      boxShadow="3px 3px 8px 2px rgba(100,116,139,0.37)"
      minHeight="fit-content"
      h={type === "slider" ? 150 : 200}
      position="relative"
    >
      {canBeDeleted && (
        <Box position="absolute" top={2} right={2}>
          <Trash
            onClick={() => {
              onDelete?.(name);
            }}
            cursor="pointer"
            stroke={theme.colors.danger[500]}
          />
        </Box>
      )}
      <Text
        noOfLines={1}
        textOverflow="ellipsis"
        fontSize={20}
        fontWeight="700"
        color="neutralDarkGrey"
        maxW="80%"
      >
        {name}
      </Text>
      {type === "slider" ? (
        <Box width="100%">
          <Slider
            mb={4}
            aria-label="yellowSlider"
            defaultValue={value.yellow}
            value={value.yellow}
            colorScheme="yellow"
            min={0}
            max={100}
            onChange={(val: number) => {
              setValueYellow(val);
            }}
          >
            <SliderMark
              color="yellow.500"
              value={50}
              mt="1"
              ml="-2.5"
              fontSize="sm"
            >
              {value.yellow}
              {"%"}
            </SliderMark>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb borderWidth={2} borderColor="yellow.500" />
          </Slider>

          <Slider
            aria-label="redSlider"
            defaultValue={value.red}
            value={value.red}
            min={0}
            max={100}
            onChange={(val: number) => {
              setValueRed(val);
            }}
          >
            <SliderMark
              color={redColor}
              value={50}
              mt="1"
              ml="-2.5"
              fontSize="sm"
            >
              {value.red}
              {"%"}
            </SliderMark>
            <SliderTrack>
              <SliderFilledTrack bg={redColor} />
            </SliderTrack>
            <SliderThumb borderWidth={2} borderColor={redColor} />
          </Slider>
        </Box>
      ) : (
        <Box width="100%">
          <InputGroup borderRadius={4} size={"lg"}>
            <InputRightElement overflowX="hidden">
              <Text position="absolute">{units[unitYellow as keyof typeof units]}</Text>
              <Select
                iconSize="0"
                border="none"
                focusBorderColor="none"
                cursor="pointer"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setUnitYellow(Number(e.target.value));
                  setDisplayValue({
                    ...displayValue,
                    yellow: unitYellow * displayValue.yellow / parseInt(e.target.value) 
                  })

                }}
              >
                <option value={1}>KB</option>
                <option value={1024}>MB</option>
                <option value={1048576}>GB</option>
              </Select>
            </InputRightElement>
            <WDInput
              value={displayValue.yellow.toString()}
              onChange={handleYellowChange}
              borderWidth={2}
              borderColor={yellowColor}
              colorScheme={yellowColor}
              _hover={{
                borderColor: yellowColor,
              }}
              focusBorderColor={yellowColor}
            />
          </InputGroup>

          <InputGroup borderRadius={4} size={"lg"}>
            <InputRightElement overflowX="hidden">
              <Text position="absolute">{units[unitRed as keyof typeof units]}</Text>
              <Select
                iconSize="0"
                border="none"
                focusBorderColor="none"
                cursor="pointer"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setUnitRed(Number(e.target.value));
                  setDisplayValue({
                    ...displayValue,
                    red: unitRed * displayValue.red / parseInt(e.target.value) 
                  })
                }}
              >
                <option value={1}>KB</option>
                <option value={1024}>MB</option>
                <option value={1048576}>GB</option>
              </Select>
            </InputRightElement>
            <WDInput
              value={displayValue.red.toString()}
              onChange={handleRedChange}
              borderWidth={2}
              borderColor={redColor}
              colorScheme={redColor}
              _hover={{
                borderColor: redColor,
              }}
              focusBorderColor={redColor}
            />
          </InputGroup>
        </Box>
      )}
    </Flex>
  );
};
