import { Spacer, useColorModeValue, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { ListDrawer } from "./CollapsedComponents/ListDrawer";
import { LogoCST } from "./CollapsedComponents/LogoCST";
import { Logout } from "./CollapsedComponents/Logout";
import { ProfileInfo } from "./CollapsedComponents/ProfileInfo";
interface DrawerProps {
  active: boolean;
}
export const CollapsedDrawer: React.FC<DrawerProps> = ({ active }) => {
  const MotionVStack = motion(VStack);

  const animationVariants = {
    active: { width: 280, opacity: 0 },
    inactive: { width: 80, opacity: 1 },
  };
  return (
    <MotionVStack
      flexDirection="column"
      h="100vh"
      variants={animationVariants}
      initial="active"
      animate={active ? "active" : "inactive"}
      transition={{ type: "spring", damping: 60, stiffness: 180 }}
      bg={useColorModeValue("blue.100", "darkThemeGrey.600")}
      boxShadow="3px 0px 30px rgba(0, 0, 0, 0.05)"
    >
      <LogoCST />
      <ProfileInfo />
      <ListDrawer />
      <Spacer />
      <Logout />
    </MotionVStack>
  );
};
