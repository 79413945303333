import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { WDIconComponent } from "../Common/WDIconComponent";
import { WDInput } from "../Common/WDInput";
import { WDTextArea } from "../Common/WDTextArea";
import { ModalTypesInterface } from "./types";
import { errorMessageInterface } from "./WDProjectModal";

interface StepOnePageProps {
  changeModalDataHandler: (val: ModalTypesInterface) => void;
  error?: errorMessageInterface;
  value: ModalTypesInterface;
}

export const StepOnePage: React.FC<StepOnePageProps> = ({
  changeModalDataHandler,
  error,
  value,
}) => {
  const onChangeEventHandler = (
    val: string | React.ChangeEvent<HTMLInputElement> | number
  ) => {
    if (typeof val === "object") {
      changeModalDataHandler({
        ...value,
        name: val.target.value,
      });
    } else if (typeof val === "number") {
      changeModalDataHandler({
        ...value,
        icon: val,
      });
    } else if (typeof val === "string") {
      changeModalDataHandler({
        ...value,
        description: val,
      });
    }
  };

  return (
    <Flex flexDirection={"column"}>
      <Box marginTop={5}>
        <Flex>
          <Text>Project's name</Text>
          <Text color="danger.500">*</Text>
        </Flex>
        <WDInput
          value={value.name}
          error={error?.name}
          onChange={onChangeEventHandler}
          placeholder="Add project's name here.."
        ></WDInput>
      </Box>
      <Box marginTop={5}>
        <WDIconComponent value={value.icon} onChange={onChangeEventHandler} />
      </Box>
      <Box marginTop={5}>
        <Flex>
          <Text>Project's description</Text>
          <Text color="danger.500">*</Text>
        </Flex>

        <WDTextArea
          value={value.description}
          placeholder="Add your description here.."
          error={error?.description}
          onChange={onChangeEventHandler}
        />
      </Box>
    </Flex>
  );
};
