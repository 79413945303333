import {
  Box,
  HStack,
  Spacer,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { FocusEvent, useContext } from "react";
import { requiredField } from "../../../Utils/ValidatorsForInput";
import { WDTextArea } from "../../Common/WDTextArea";
import { FirstPageContext } from "../ModalTask";
import { PopoverWarning } from "./PopoverWarning";
import { tasksIdFieldsInterface } from "../types";
import { ModalTaskContext } from "../WDTaskModal";

export const ReqResWeb: React.FC = () => {
  const {
    formatExpectedResponse,
    formatRequestBody,
    setFormatRequestBody,
    setFormatExpectedResponse,
  } = useContext(FirstPageContext);
  const { modalData, changeData, errorModalData, warningModalData } =
    useContext(ModalTaskContext);
  const format = require("xml-formatter");
  const handleTransformRequestBody = (e: FocusEvent<HTMLTextAreaElement>) => {
    if (formatRequestBody === 0)
      try {
        changeData(
          {
            requestBody: JSON.stringify(
              JSON.parse(e.target.value) ?? " ",
              null,
              2
            ),
          },
          {},
          { warningRequestBody: "" }
        );
      } catch (err) {
        changeData(
          {
            requestBody: e.target.value,
          },
          {},
          {
            warningRequestBody: "Warning! There are some issues with your JSON",
          }
        );
      }
    else if (formatRequestBody === 10)
      try {
        changeData(
          { requestBody: format(e.target.value) },
          {},
          { warningRequestBody: "" }
        );
      } catch {
        changeData(
          {
            requestBody: e.target.value,
          },
          {},
          {
            warningRequestBody: "Warning! There are some issues with your XML",
          }
        );
      }
    else handleRequestBody(e.target.value);
  };

  const handleTransformExpectedBody = (e: FocusEvent<HTMLTextAreaElement>) => {
    if (formatExpectedResponse === 0)
      try {
        changeData(
          {
            expectedBody: JSON.stringify(
              JSON.parse(e.target.value) ?? "",
              null,
              2
            ),
          },
          {
            errorExpectedBody: modalData.ignoreResponse
              ? ""
              : requiredField(e.target.value),
          },
          { warningExpectedBody: "" }
        );
      } catch (err) {
        changeData(
          {
            expectedBody: e.target.value,
          },
          {
            errorExpectedBody: modalData.ignoreResponse
              ? ""
              : requiredField(e.target.value),
          },
          {
            warningExpectedBody:
              "Warning! There are some issues with your JSON",
          }
        );
      }
    else if (formatExpectedResponse === 10)
      try {
        changeData(
          { expectedBody: format(e.target.value) },
          {
            errorExpectedBody: modalData.ignoreResponse
              ? ""
              : requiredField(e.target.value),
          },
          { warningExpectedBody: "" }
        );
      } catch {
        changeData(
          {
            expectedBody: e.target.value,
          },
          {
            errorExpectedBody: modalData.ignoreResponse
              ? ""
              : requiredField(e.target.value),
          },
          {
            warningExpectedBody: "Warning! There are some issues with your XML",
          }
        );
      }
    else handleExpectedBody(e.target.value);
  };
  const handleRequestBody = (value: string) => {
    changeData(
      { requestBody: value },
      { errorRequestBody: requiredField(value) }
    );
  };
  const handleExpectedBody = (value: string) => {
    changeData(
      { expectedBody: value },
      {
        errorExpectedBody: modalData.ignoreResponse ? "" : requiredField(value),
      }
    );
  };
  const handleSwitch = () => {
    changeData({ ignoreResponse: !modalData.ignoreResponse }, {});
  };
  const colorButton = useColorModeValue("blue.700", "blue.400");
  return (
    <>
      <HStack w="100%">
        <VStack spacing={0} align="flex-start" w="100%" py={2}>
          <HStack>
            <HStack w="100%" justify={"flex-start"}>
              <Box>
                <Text>Request Body</Text>
              </Box>
              <Box>
                {warningModalData.warningRequestBody &&
                formatRequestBody !== 20 ? (
                  <PopoverWarning
                    warning={warningModalData.warningRequestBody}
                  />
                ) : null}
              </Box>
            </HStack>

            <HStack w="100%" justify={"flex-end"} pb={2}>
              <Box
                borderRadius={500}
                w={16}
                textAlign="center"
                cursor={"pointer"}
                fontWeight={600}
                bg={formatRequestBody === 0 ? colorButton : "blue.100"}
                color={formatRequestBody === 0 ? "white" : "neutralDarkGrey"}
                onClick={() => {
                  setFormatRequestBody(0);
                }}
              >
                JSON
              </Box>
              <Box
                bg={formatRequestBody === 10 ? colorButton : "blue.100"}
                color={formatRequestBody === 10 ? "white" : "neutralDarkGrey"}
                fontWeight={600}
                borderRadius={500}
                w={16}
                textAlign="center"
                cursor={"pointer"}
                onClick={() => {
                  setFormatRequestBody(10);
                }}
              >
                XML
              </Box>
              <Box
                borderRadius={500}
                w={16}
                textAlign="center"
                cursor={"pointer"}
                fontWeight={600}
                bg={formatRequestBody === 20 ? colorButton : "blue.100"}
                color={formatRequestBody === 20 ? "white" : "neutralDarkGrey"}
                onClick={() => {
                  setFormatRequestBody(20);
                }}
              >
                RAW
              </Box>
            </HStack>
          </HStack>

          <WDTextArea
            id={tasksIdFieldsInterface.idRequestBody}
            onChange={handleRequestBody}
            value={modalData.requestBody}
            placeholder="Request Body"
            error={errorModalData.errorRequestBody}
            onBlur={handleTransformRequestBody}
          />
          <HStack
            opacity={0}
            justify="center"
            align={"center"}
            cursor="default"
          >
            <Box>space</Box>
            <Switch cursor="default" />
          </HStack>
        </VStack>
        <VStack spacing={0} w="100%" align={"flex-start"} py={2}>
          <HStack w="100%" fontWeight={600}>
            <HStack w="100%" justify={"flex-start"}>
              <Box>
                <Text>Expected Body</Text>
              </Box>
              <Box>
                {warningModalData.warningExpectedBody &&
                formatExpectedResponse !== 20 ? (
                  <PopoverWarning
                    warning={warningModalData.warningExpectedBody}
                  />
                ) : null}
              </Box>
              <Spacer />
            </HStack>
            <Spacer />

            <HStack w="100%" justify={"flex-end"} pb={2}>
              <Box
                borderRadius={500}
                w={16}
                textAlign="center"
                cursor={"pointer"}
                fontWeight={600}
                bg={formatExpectedResponse === 0 ? colorButton : "blue.100"}
                color={
                  formatExpectedResponse === 0 ? "white" : "neutralDarkGrey"
                }
                onClick={() => {
                  setFormatExpectedResponse(0);
                }}
              >
                JSON
              </Box>
              <Box
                bg={formatExpectedResponse === 10 ? colorButton : "blue.100"}
                color={
                  formatExpectedResponse === 10 ? "white" : "neutralDarkGrey"
                }
                fontWeight={600}
                borderRadius={500}
                w={16}
                textAlign="center"
                cursor={"pointer"}
                onClick={() => {
                  setFormatExpectedResponse(10);
                }}
              >
                XML
              </Box>
              <Box
                borderRadius={500}
                w={16}
                textAlign="center"
                cursor={"pointer"}
                fontWeight={600}
                bg={formatExpectedResponse === 20 ? colorButton : "blue.100"}
                color={
                  formatExpectedResponse === 20 ? "white" : "neutralDarkGrey"
                }
                onClick={() => {
                  setFormatExpectedResponse(20);
                }}
              >
                RAW
              </Box>
            </HStack>
          </HStack>
          <WDTextArea
            id={tasksIdFieldsInterface.idExpectedBody}
            onChange={handleExpectedBody}
            value={modalData.expectedBody}
            placeholder="Expected Body"
            error={errorModalData.errorExpectedBody}
            onBlur={handleTransformExpectedBody}
          />
          <HStack justify="center" align={"center"} pt={3}>
            <Box>Ignore response?</Box>
            <Switch
              isChecked={modalData.ignoreResponse}
              onChange={() => {
                handleSwitch();
              }}
            />
          </HStack>
        </VStack>
      </HStack>
    </>
  );
};
