import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../../App";
import { CustomSpinner } from "../Common/Spinner";
import { NavBarHeight } from "../Dashboard/DashboardWrapper/DashboardWrapper";
import { apiClient, authorise } from "../utils/apiClient";

import { UserInterface } from "./types";
import { UsersTableComponent } from "./UsersTableComponent";

export const UsersComponent: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserInterface[]>([]);
  const errorContext = useContext(ErrorContext);
  const [filterValue, setFilterValue] = useState<string>("");

  const handleSearchInputValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterValue(e.target.value);
  };

  const getUsers = async () => {
    await apiClient
      .get("/api/private/users/all", authorise())
      .then((res) => {
        setUsers(res.data);
        setFilteredUsers(res.data);
      })
      .catch((err) => {
        errorContext?.createError([...err.response.data]);
      });

    setLoading(false);
  };

  useEffect(() => {
    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterUsers = () => {
    if (filterValue) {
      setFilteredUsers(
        users.filter((user) =>
          `${user.lastName} ${user.firstName} ${user.email}`
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        )
      );
    } else {
      setFilteredUsers(users);
    }
  };

  // useEffect(
  //   () => {
  //     setFilteredUsers(users);
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [users]
  // );
  useEffect(() => {
    if (filterValue) {
      const delaySearch = setTimeout(() => {
        filterUsers();
      }, 1000);
      return () => {
        clearTimeout(delaySearch);
      };
    } else setFilteredUsers(users);
  }, [filterValue]);
  const colorPrimary = useColorModeValue("blue.700", "darkThemeGrey.100");

  return (
    <Box px={6}>
      {loading ? (
        <CustomSpinner />
      ) : (
        <VStack maxH={`calc(100vh - ${NavBarHeight})`} w="100%">
          <Flex justifyContent={"space-between"} m={4} h="10%" w="100%">
            <Heading color={colorPrimary}>Developers</Heading>
            <Spacer />
            {/* <CreateNewUserComponent users={users} setUsers={setUsers} /> */}
          </Flex>

          <Box h="10%" w="100%">
            <InputGroup w={"45%"} my={3}>
              <InputRightElement children={<SearchIcon color="blue.800" />} />
              <Input
                color="black"
                bg="white"
                type="search"
                value={filterValue}
                onChange={handleSearchInputValueChange}
                placeholder="Search user by name or email"
              />
            </InputGroup>
          </Box>

          <UsersTableComponent
            filteredUsers={filteredUsers}
            setFilteredUsers={setFilteredUsers}
            users={users}
            setUsers={setUsers}
          />

          {filteredUsers?.length === 0 ? (
            <VStack justifyContent="center" alignItems="center" p={20}>
              <SearchIcon boxSize={20} opacity="50%" />
              <Box pl={4} pt={4} fontWeight={600} fontSize={20}>
                I can't seem to find "{filterValue}".
              </Box>
              <Box>
                Please double-check your spelling, try other search items.
              </Box>
            </VStack>
          ) : null}
        </VStack>
      )}
    </Box>
  );
};
