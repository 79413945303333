import React, { createContext } from "react";
import { ProjectInterface, ServerInterface } from "../Common/types";
import { ButtonEditServices } from "./TriggerButtonsForOpening/ButtonEditServices";
import { ButtonModal } from "./TriggerButtonsForOpening/ButtonModal";
import { ModalService } from "./ModalService";
import { ModalServiceContextInterface } from "./type";
import { useModal } from "./useServiceModal";
export const ModalServiceContext = createContext<ModalServiceContextInterface>(
  {} as ModalServiceContextInterface
);

export interface ServiceModalProps {
  idService?: string;
  currentProject: ProjectInterface;
  currentServer: ServerInterface;
  setCurrentProject: (project: ProjectInterface) => void;
}
export const WDServiceModal: React.FC<ServiceModalProps> = ({
  currentServer,
  currentProject,
  setCurrentProject,
  idService,
}) => {
  const modalHook = useModal({
    currentServer,
    currentProject,
    setCurrentProject,
    idService,
  });
  return (
    <>
      <ModalServiceContext.Provider value={modalHook}>
        {" "}
        {idService ? (
          <>
            <ButtonEditServices />
          </>
        ) : (
          <>
            <ButtonModal />
          </>
        )}
        <ModalService />
      </ModalServiceContext.Provider>
    </>
  );
};
