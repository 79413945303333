import { ChangeEvent, useEffect, useState } from "react";
import { contentObject } from "./contentObject";
import {
  DescriptionInterface,
  HelpCenterContextInterface,
  TopicInterface,
} from "./types";

export const useHelpCenter = (): HelpCenterContextInterface => {
  const [filteredTopics, setFilteredTopics] =
    useState<TopicInterface[]>(contentObject);
  const [filterValue, setFilterValue] = useState<string>("");
  const handleFilterValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };
  useEffect(() => {
    if (filterValue === "") setFilteredTopics(contentObject);
    else {
      const delaySearch = setTimeout(() => {
        setFilteredTopics(
          contentObject.filter(
            (topic) =>
              topic.title.toLowerCase().includes(filterValue.toLowerCase()) ||
              topic.description
                .reduce((alltext: string, des: DescriptionInterface) => {
                  return alltext + des.text;
                }, "")
                .toLowerCase()
                .includes(filterValue)
          )
        );
      }, 1000);
      return () => {
        clearTimeout(delaySearch);
      };
    }
  }, [filterValue]);
  return {
    filteredTopics,
    filterValue,
    setFilterValue,
    handleFilterValueChange,
  };
};
