import { useColorModeValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {
  ProjectInterface,
  ResourcesOverviewInterface,
  ServerInterface,
} from "../Common/types";
import { ProjectUsersInterface } from "./types";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorContext, UserContext } from "../../App";
import { defaultThresholdData } from "./ManageThresholdsModal/defaultThresholdObject";
import { SelectedInterval } from "../Common/TimeIntervalWrapper";
import { ThresholdsInterface } from "./ManageThresholdsModal/types";
import { apiClient, authorise } from "../utils/apiClient";
import { theme } from "../../theme";
import { ThresholdTemplateInterface } from "../ThresholdTemplates/types";

export const useProjectDetailsLogic = () => {
  const { projectId } = useParams();
  const [serverIdState, setServerIdState] = useState<string>("");
  const errorContext = useContext(ErrorContext);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedInterval, setSelectedInterval] =
    useState<SelectedInterval>("24h");
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [projectDetails, setProjectDetails] = useState<ProjectInterface>(
    {} as ProjectInterface
  );
  const [resourcesOverview, setResourcesOverview] = useState<
    ResourcesOverviewInterface[][]
  >([[]] as ResourcesOverviewInterface[][]);
  const [projectUsers, setProjectUsers] = useState<ProjectUsersInterface[]>([]);
  const [isOpenChooseTemplate, setIsOpenChooseTemplate] =
    useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] =
    useState<ThresholdsInterface>(defaultThresholdData);

  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [isTemplateSelected, setIsTemplateSelected] = useState<boolean>(false);

  const onSuccessUpdateUsers = (res: ProjectUsersInterface[]) => {
    setProjectUsers(res);
  };

  const handleNavigate = () => {
    navigate(
      "/dashboard/" +
        projectDetails.projectId +
        "/" +
        projectDetails.servers[tabIndex].serverId +
        "/logs"
    );
  };

  useEffect(() => {
    apiClient
      .get(`/api/private/projects/${projectId}`, authorise())
      .then((res) => {
        console.log(res.data);
        setProjectDetails({
          ...res.data,
          projectId: projectId,
          projectRole: res.data.roleName,
        });
        setResourcesOverview(
          res.data.servers.map((server: ServerInterface) => server.usages)
        );
        setProjectUsers(
          res.data.users.map((user: any) => {
            return {
              ...user,
              notificationLevel: user.notificationLevel.toString(),
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
      });
  }, []);

  const handleDisableServer = (id: string) => {
    apiClient
      .put(`/api/servers/switch-all/${id}`, {}, authorise())
      .then((res) => {
        setProjectDetails({
          ...projectDetails,
          servers: projectDetails.servers.map((s) => {
            if (s.serverId === id) {
              return {
                ...s,
                ...res.data,
              };
            } else {
              return s;
            }
          }),
        });
      })
      .catch((err) => errorContext.createError([...err.response.data]));
  };

  const handleDeleteProject = async (id: string, onClose: () => void) => {
    await apiClient
      .delete(`/api/private/projects/${projectId}`, authorise())
      .then(() => {
        navigate("/dashboard/projects");
        onClose();
      })
      .catch((err) => {
        errorContext?.createError([...err.response.data]);
      });
  };

  const handleDeleteServer = (id: string, onClose: () => void) => {
    apiClient
      .delete(`/api/servers/${id}`, authorise())
      .then(() => {
        setTabIndex(0);
        setProjectDetails({
          ...projectDetails,
          servers: projectDetails.servers.filter((s) => id !== s.serverId),
        });
        errorContext.createToast("You have deleted the server succesufully");
        onClose();
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
  };

  const colorStrokePrimary = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.blue[400]}`
  );

  const colorTextPrimary = useColorModeValue(
    theme.colors.blue[800],
    theme.colors.darkThemeGrey[100]
  );

  const colorMenu = useColorModeValue("white", "darkThemeGrey.600");

  const onClickTemplate = (val: ThresholdsInterface) => {
    setInitialValues(val);
    setIsTemplateSelected?.(true);
    setIsOpen(true);
    setIsRendered(false);
    setIsOpenChooseTemplate(false);
  };

  const onClickNoTemplate = () => {
    setIsTemplateSelected?.(false);
    setIsOpen(true);
    setIsRendered(false);
    setIsOpenChooseTemplate(false);
  };

  const putThreshold = (
    id: string,
    val: ThresholdsInterface | ThresholdTemplateInterface
  ) => {
    apiClient
      .put(`/api/servers/manage-thresholds/${id}`, val, authorise())
      .then((res) => {
        errorContext.createToast("Thresholds updated successfully!");
        setIsOpen(false);
      })
      .catch((err) => console.log(err));
  };

  return {
    projectId,
    errorContext,
    userContext,
    navigate,
    selectedInterval,
    setSelectedInterval,
    tabIndex,
    setTabIndex,
    loading,
    setLoading,
    projectDetails,
    setProjectDetails,
    resourcesOverview,
    projectUsers,
    isOpenChooseTemplate,
    setIsOpenChooseTemplate,
    isOpen,
    setIsOpen,
    initialValues,
    setInitialValues,
    isRendered,
    setIsRendered,
    isTemplateSelected,
    onSuccessUpdateUsers,
    handleNavigate,
    handleDisableServer,
    handleDeleteProject,
    handleDeleteServer,
    colorStrokePrimary,
    colorTextPrimary,
    colorMenu,
    onClickTemplate,
    onClickNoTemplate,
    putThreshold,
    serverIdState,
    setServerIdState,
  };
};
