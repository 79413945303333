import {
  Avatar,
  Box,
  Flex,
  Td,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { DesignerInterface } from "../typesForProjects";
export const Designer: React.FC<DesignerInterface> = (props) => {
  const colorPrimary = useColorModeValue("blue.700", "darkThemeGrey.100");
  return (
    <Td>
      <Flex>
        <Avatar
          size="sm"
          name={`${props.firstName} ${props.lastName}`}
          mr={2}
          mt={1}
        />
        <VStack justifyContent="flex-start" w="100%">
          <Box
            color={colorPrimary}
            textAlign="left"
            w="100%"
          >{`${props.firstName} ${props.lastName}`}</Box>
          <Box
            fontWeight="normal"
            color="neutralGrey"
            fontSize="sm"
            textAlign="left"
            w="100%"
          >
            {props.email}
          </Box>
        </VStack>
      </Flex>
    </Td>
  );
};
