import { ArrowForwardIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HelpCenterContext } from ".";
import { ReactComponent as BookIcon } from "../../Assets/BookIcon.svg";
import { contentObject } from "./contentObject";

export const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const borderColor = useColorModeValue("gray.300", "darkThemeGrey.100");
  const navigateToTopic = (id?: string) => {
    if (id) navigate(`/dashboard/help/${id}`);
    else navigate("/dashboard/help");
  };
  const { handleFilterValueChange, filterValue } =
    useContext(HelpCenterContext);
  const colorTextPrimary = useColorModeValue("blue.700", "darkThemeGrey.100");
  const colorIconBg = useColorModeValue("blue.100", "darkThemeGrey.500");
  const colorTextSecondary = useColorModeValue(
    "neutralGrey",
    "darkThemGrey.100"
  );
  return (
    <VStack
      w="100%"
      align={"flex-start"}
      color={colorTextPrimary}
      borderRight={"1px solid"}
      borderColor="neutralGrey"
      h="100%"
    >
      <Box fontSize={28} fontWeight={600}>
        Help Center
      </Box>
      <Box w="100%">
        <InputGroup>
          <InputLeftElement children={<SearchIcon stroke={"#000"} />} />
          <Input
            color="black"
            bg="white"
            borderColor={borderColor}
            borderRadius={4}
            type="search"
            placeholder="Search help topics.."
            value={filterValue}
            onChange={handleFilterValueChange}
          />
        </InputGroup>
      </Box>
      <HStack
        pl={4}
        cursor="pointer"
        onClick={() => {
          navigateToTopic();
        }}
      >
        <Box pt={2} px={2} bg={colorIconBg}>
          <Icon
            as={BookIcon}
            bg={colorIconBg}
            stroke={colorTextPrimary}
            boxSize={8}
          />
        </Box>
        <Spacer />
        <VStack align={"flex-start"} spacing={0} w="100%">
          <Box cursor={"pointer"} fontWeight={600} fontSize={20}>
            Content Table
          </Box>
          <Box fontWeight={600} fontSize={12} color={colorTextSecondary} pl={1}>
            Learn & get help
          </Box>
        </VStack>
      </HStack>
      {contentObject.map((topic) => {
        return (
          <HStack
            key={topic.id + "sideBar"}
            onClick={() => {
              navigateToTopic(topic.id);
            }}
            cursor="pointer"
            fontWeight={500}
            _hover={{ color: "blue.600" }}
          >
            <ArrowForwardIcon />
            <Box>{topic.title}</Box>
          </HStack>
        );
      })}
    </VStack>
  );
};
