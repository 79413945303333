import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { WDThresholdsInputCard } from "../../Common/WDThresholdsInputCard";
import { SettersInterface } from "./types";

interface FirstStepProp {
  allData: SettersInterface;
}

export const FirstStep: React.FC<FirstStepProp> = ({ allData }) => {
  return (
    <Flex wrap="wrap" justify="space-evenly" align="space-between">
      <Box marginTop={5}>
        <WDThresholdsInputCard
          type="slider"
          value={allData.modalData.cpu}
          setValueYellow={allData.setCpuYellow}
          setValueRed={allData.setCpuRed}
          name="CPU"
        />
      </Box>
      <Box marginTop={5}>
        <WDThresholdsInputCard
          type="slider"
          value={allData.modalData.memory}
          setValueYellow={allData.setMemoryYellow}
          setValueRed={allData.setMemoryRed}
          name="Memory"
        />
      </Box>
      <Box marginTop={5}>
        <WDThresholdsInputCard
          type="number"
          value={allData.modalData.disk}
          setValueYellow={allData.setDiskYellow}
          setValueRed={allData.setDiskRed}
          name="Disk"
        />
      </Box>
    </Flex>
  );
};
