import {
  Box,
  Flex,
  HStack,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { customNotifications } from "../../../Utils/defaultObjects";
import {
  requiredField,
  validatorNumericField,
} from "../../../Utils/ValidatorsForInput";
import { SelectOptionsInterface } from "../../Common/types";
import { WDDatePicker } from "../../Common/WDDatePicker";
import { WDDropdown } from "../../Common/WDDropdown";
import { WDInput } from "../../Common/WDInput";
import { FirstStepFooter } from "../FirstStep/FirstStepFooter";
import { TheDisabledInput } from "../TheDisabledInput";
import { ModalServiceContext } from "../WDServiceModal";
import { SecondStepFooter } from "./SecondStepFooter";

export const SecondStep: React.FC = () => {
  const modalContext = useContext(ModalServiceContext);
  const options: SelectOptionsInterface[] = [
    { value: "true", display: "Yes", color: "black" },
    { value: "false", display: "No", color: "black" },
  ];
  const handleReccuringTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    modalContext?.changeData(
      {
        reccuringTime: parseInt(e.target.value),
      },
      {
        errorReccuringTime:
          requiredField(e.target.value) ||
          validatorNumericField(e.target.value),
      }
    );
  };
  const handleEnable = (value: string) => {
    modalContext?.changeData(
      {
        enabled: value === "0",
      },
      {}
    );
  };
  const handleDateTime = (value: string) => {
    modalContext?.changeData(
      {
        startDate: value,
      },
      {}
    );
  };
  const handleNotificationType = (value: string) => {
    modalContext?.changeData(
      {
        minimumNotificationLevel: parseInt(value),
      },
      {
        errorNotificationType: value === "5" ? "Please select an option" : "",
      }
    );
  };

  const handleTimeout = (e: React.ChangeEvent<HTMLInputElement>) => {
    modalContext?.changeData(
      {
        timeout: parseInt(e.target.value),
      },
      {
        errorTimeout:
          requiredField(e.target.value) ||
          validatorNumericField(e.target.value),
      }
    );
  };
  const handleTimeoutNotification = (value: string) => {
    modalContext?.changeData(
      {
        timeoutNotificationLevel: parseInt(value),
      },
      {}
    );
  };

  return (
    <React.Fragment>
      <VStack w="100%">
        <TheDisabledInput />
        <HStack w="100%">
          <Box w="100%">
            <Flex>
              <Text>Enabled</Text>
              <Text color="danger.500">*</Text>
            </Flex>
            <WDDropdown
              options={options}
              onChange={handleEnable}
              value={modalContext.dataModal.enabled.toString()}
            />
          </Box>
          <Box w="100%">
            <Flex>
              <Text> Start Date Time </Text>
              <Text color="danger.500">*</Text>
            </Flex>
            <WDDatePicker
              onChange={handleDateTime}
              value={modalContext?.dataModal?.startDate}
            />
          </Box>
          <Box w="100%">
            <Flex>
              <Text>Reccuring Time</Text>
              <Text color="danger.500">*</Text>
            </Flex>
            <InputGroup borderRadius={4} size={"lg"}>
              <InputRightElement pointerEvents="none" justifyContent="center">
                min
              </InputRightElement>
              <WDInput
                placeholder="1"
                value={
                  modalContext?.dataModal?.reccuringTime
                    ? modalContext?.dataModal?.reccuringTime.toString()
                    : ""
                }
                onChange={handleReccuringTime}
                error={modalContext?.errorsData?.errorReccuringTime}
              />
            </InputGroup>
          </Box>
        </HStack>
        <HStack w="100%" py={3}>
          <Box w="100%">
            <Flex>
              <Text>Notification Level</Text>
              <Text color="danger.500">*</Text>
            </Flex>
            <WDDropdown
              error={modalContext?.errorsData?.errorNotificationType}
              tabIndex={1}
              options={customNotifications}
              onChange={handleNotificationType}
              value={modalContext?.dataModal?.minimumNotificationLevel?.toString()}
              placeholder="Notification Level"
            />
          </Box>

          <Box w="100%">
            <Flex>
              <Text>Timeout</Text>
              <Text color="danger.500">*</Text>
            </Flex>
            <InputGroup borderRadius={4} size={"lg"}>
              <InputRightElement pointerEvents="none" justifyContent="center">
                min
              </InputRightElement>
              <WDInput
                placeholder="15"
                onChange={handleTimeout}
                value={
                  modalContext?.dataModal?.timeout
                    ? modalContext?.dataModal?.timeout?.toString()
                    : ""
                }
                error={modalContext?.errorsData?.errorTimeout}
              />
            </InputGroup>
          </Box>
          <Box w="100%">
            <Flex>
              <Text>Notification level timeout</Text>
              <Text color="danger.500">*</Text>
            </Flex>

            <WDDropdown
              tabIndex={2}
              options={customNotifications}
              onChange={handleTimeoutNotification}
              value={modalContext?.dataModal?.timeoutNotificationLevel?.toString()}
            />
          </Box>
        </HStack>
        {(modalContext?.step ?? 0) + 1 === modalContext?.maxStep ? (
          <SecondStepFooter />
        ) : (
          <FirstStepFooter />
        )}
      </VStack>
    </React.Fragment>
  );
};
