import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ErrorContext } from "../../../App";
import { ReactComponent as Trash } from "../../../Assets/ModalTrashIcon/trash.svg";
import { theme } from "../../../theme";
import { customNotifications } from "../../../Utils/defaultObjects";
import { SelectOptionsInterface } from "../../Common/types";
import { WDDropdown } from "../../Common/WDDropdown";
import { WDSearchSelect } from "../../Common/WDSearchSelect";
import {
  ProjectsEmailInterface,
  ProjectsNotificationsInterface,
} from "../../CreateProjectModal/types";
import { ProjectUsersInterface } from "../types";

interface UserUpdateProps {
  usersEmails: ProjectsEmailInterface[];
  setUsersEmails: (val: ProjectsEmailInterface[]) => void;
  roles: SelectOptionsInterface[];
  notifications: ProjectsNotificationsInterface[];
  value: ProjectUsersInterface;
  index: number;
  currentUserName: string;
  handleDeleteUser: (val: number) => void;
  allUsers: ProjectUsersInterface[];
  setAllUsers: (val: ProjectUsersInterface[]) => void;
  disabled: boolean;
}

export const UserInputGroup: React.FC<UserUpdateProps> = ({
  usersEmails,
  setUsersEmails,
  roles,
  notifications,
  value,
  index,
  currentUserName,
  handleDeleteUser,
  allUsers,
  setAllUsers,
  disabled,
}) => {
  const errorContext = useContext(ErrorContext);
  const handleUserEmailChange = (val: string | undefined) => {
    const email = usersEmails.filter((email, i) => {
      return email.value === val;
    })[0]?.label;

    setAllUsers(
      allUsers.map((user, i) => {
        if (i === index) {
          return {
            ...user,
            userId: val ?? "",
            roleName: value.roleName,
            notificationLevel: value.notificationLevel,
            email: email,
            firstName: `${
              email?.split("@")[0].split(".")[0].charAt(0).toUpperCase() +
              email?.split("@")[0].split(".")[0].slice(1)
            }`,
            lastName: email?.split("@")[0].includes(".")
              ? `${
                  email?.split("@")[0].split(".")[1].charAt(0).toUpperCase() +
                  email?.split("@")[0].split(".")[1].slice(1)
                }`
              : "",
          };
        } else {
          return user;
        }
      })
    );
  };

  const handleRoleChange = (val: string) => {
    const roleName = roles.filter((role, i) => {
      return role.value === val;
    })[0].display;
    if (
      roleName.toLocaleLowerCase() === "watcher" &&
      allUsers.filter(
        (user) => user.roleName.toLocaleLowerCase() === "designer"
      ).length <= 1
    ) {
      setAllUsers(allUsers);
      errorContext.createError(["NO_DESIGNER"]);
    } else
      setAllUsers(
        allUsers.map((user, i) => {
          if (i === index) {
            return {
              ...user,
              userId: value.userId,
              roleName: `${roleName}`,
              notificationLevel: value.notificationLevel,
            };
          } else {
            return user;
          }
        })
      );
  };

  const handleNotificationLevelChange = (val: string) => {
    setAllUsers(
      allUsers.map((user, i) => {
        if (i === index) {
          return {
            ...user,
            userId: value.userId,
            roleName: value.roleName,
            notificationLevel: val,
          };
        } else {
          return user;
        }
      })
    );
  };

  const handleTrashClick = () => {
    handleDeleteUser(index);
  };
  const allUsersId = allUsers
    .filter((user) => user.userId)
    .map((u) => {
      return u.userId;
    });

  return (
    <Flex justifyContent={"space-between"} marginTop={5}>
      <Box width={"220px"}>
        <Text>User's email</Text>
        <Box h="60%">
          <WDSearchSelect
            isDisabled={disabled}
            onChange={handleUserEmailChange}
            placeholder="Select user's email"
            options={[
              ...usersEmails.filter((user) => !allUsersId.includes(user.value)),
              {
                value: allUsers[index].userId || "",
                label: currentUserName,
              },
            ]}
            value={value.userId || "5"}
          />
        </Box>
      </Box>
      <Box>
        <Text>Role</Text>
        <WDDropdown
          onChange={handleRoleChange}
          options={roles}
          placeholder="Select role"
          width={"220px"}
          value={
            value
              ? `${
                  roles.filter((role) => {
                    return role.display === value.roleName;
                  })[0].value
                }`
              : ""
          }
        />
      </Box>
      <Box>
        <Text>Notification Level</Text>
        <WDDropdown
          onChange={handleNotificationLevelChange}
          options={customNotifications}
          placeholder="Select notification level"
          width={"220px"}
          value={value ? value.notificationLevel : ""}
        />
      </Box>
      <Flex alignItems={"center"} direction="column">
        <Box style={{ backgroundColor: "white", height: "2em" }}></Box>
        <Trash
          onClick={handleTrashClick}
          cursor={"pointer"}
          stroke={theme.colors.danger[500]}
        />
      </Flex>
    </Flex>
  );
};
