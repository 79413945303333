import { WarningIcon } from "@chakra-ui/icons";
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React from "react";
interface PopoverWarningProps {
  warning: string;
}
export const PopoverWarning: React.FC<PopoverWarningProps> = ({ warning }) => {
  return (
    <>
      {warning ? (
        <Popover defaultIsOpen>
          <PopoverTrigger>
            <WarningIcon color="warning.500" cursor={"pointer"} />
          </PopoverTrigger>
          <PopoverContent boxShadow={"0 0 3px black"}>
            <PopoverBody>
              <Text textColor="warning.600" fontWeight={600}>
                {warning}
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : null}
    </>
  );
};
