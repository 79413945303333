import { TooltipItem } from "chart.js";
import { theme } from "../../../theme";
import { SelectedInterval } from "../TimeIntervalWrapper";
import { CallbackFunctionsInterface, DataPoint } from "./types";
import {
  label24h,
  label30d,
  label7d,
  ticksCallback24h,
  ticksCallback30d,
  ticksCallback7d,
  toolTipCallback24h,
  toolTipCallback30d,
  toolTipCallback7d,
} from "./utils";

export const labels = () => {
  const newArr = Array.from(Array(288)).map((_el, i) => {
    return i + 1;
  });
  return newArr;
};

export const createOptions = (
  interval: SelectedInterval,
  maxPossibleValue?: number,
  maxHeight?: number,
  values?: DataPoint[]
) => {
  return {
    devicePixelRatio: 1.5,
    responsive: true,
    layout: {
      padding: { top: 50, left: 10, right: 20, bottom: 10 },
    },
    plugins: {
      tooltip: {
        interaction: {
          mode: "nearest",
        },

        callbacks: {
          title: () => {},
          label: (tool: TooltipItem<"line">) => {
            return callbackFunctions[interval].toolTipCallBack(tool, values);
          },
        },
      },
      legend: { display: false },
    },
    scales: {
      y: {
        min: 0,
        max: maxHeight || maxPossibleValue,
        grid: { tickColor: `${theme.colors.blue[800]}` },
        ticks: {
          callback: (value: number | string, index: number, ticks: any) => {
            return value + "%";
          },
        },
      },
      x: {
        min: 0,
        grid: {
          display: true,
          drawTicks: true,
          tickColor: `${theme.colors.blue[800]}`,
        },
        ticks: {
          callback: (value: number | string, index: number, ticks: any) => {
            if (values)
              return callbackFunctions[interval].ticksCallBack(
                value,
                index,
                ticks,
                values
              );
          },
        },
      },
    },

    elements: { point: { radius: 0.5 } },
  };
};

export const callbackFunctions: Record<
  SelectedInterval,
  CallbackFunctionsInterface
> = {
  "24h": {
    toolTipCallBack: toolTipCallback24h,
    labelCallback: label24h,
    ticksCallBack: ticksCallback24h,
  },
  "7d": {
    toolTipCallBack: toolTipCallback7d,
    labelCallback: label7d,
    ticksCallBack: ticksCallback7d,
  },
  "30d": {
    toolTipCallBack: toolTipCallback30d,
    labelCallback: label30d,
    ticksCallBack: ticksCallback30d,
  },
};
