import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion, Variants } from "framer-motion";
import React from "react";
import { ReactComponent as UsersButtonIcon } from "../../Assets/Users/UsersButtonIcon.svg";
import { theme } from "../../theme";
import { apiClient, authorise } from "../utils/apiClient";
import { PopoverProjectsDetails } from "./PopoverProjectsDetails";
//   import { PopoverProjectsDetails } from "./PopoverProjectsDetails";
import { ClientInterface } from "./types";
interface TableRowProps {
  index: number;
  user: ClientInterface;
  clients: ClientInterface[];
  setClients: (val: ClientInterface[]) => void;
}

export const TableRow: React.FC<TableRowProps> = ({
  index,
  user,
  clients,
  setClients,
}) => {
  const colorTextSecondary = useColorModeValue("blue.700", "darkThemeGrey.100");

  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.blue[400]}`
  );
  const menuListColor = useColorModeValue("white", "darkThemeGrey.600");
  const rowVariants: Variants = {
    offscreen: {
      y: 100,
    },
    onscreen: {
      y: 0,

      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  const MotionTr = motion(Tr);

  const handleDelteClient = (id: string) => {
    apiClient
      .delete(`/api/client/delete/${id}`, authorise())
      .then((res) => {
        setClients(clients.filter((client) => client.id !== id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MotionTr
      fontWeight="600"
      initial={index <= 7 ? "onscreen" : "offscreen"}
      variants={rowVariants}
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      <Td>
        <Flex>
          <Avatar
            size="sm"
            name={`${user.firstName} ${user.lastName}`}
            mr={2}
            mt={1}
          />
          <Flex direction={"column"}>
            <Box
              color={colorTextSecondary}
            >{`${user.firstName} ${user.lastName}`}</Box>
            <Box fontWeight="normal" color="neutralGrey" fontSize="sm">
              {user.email}
            </Box>
          </Flex>
        </Flex>
      </Td>
      <Td color="neutralGrey">{user.roleName}</Td>
      <Td color="neutralGrey" textAlign="right">
        <PopoverProjectsDetails
          numberOfProjects={user.projects}
          projects={user.projectsDetails}
        />
      </Td>
      <Td>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<UsersButtonIcon stroke={colorStroke} />}
            variant="outline"
          />
          <MenuList bg={menuListColor}>
            <MenuItem
              color={theme.colors.notificationsColor.red}
              onClick={() => {
                handleDelteClient(user.id);
              }}
            >
              Delete client
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </MotionTr>
  );
};
