import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

export const formatJSON = (str: string) => {
  try {
    const x = JSON.stringify(JSON.parse(str) ?? "", null, 2);
    return x;
  } catch {
    return str;
  }
};

export const formatHTML = (str: string) => {
  const HTMLRegex = /<\/?[a-z][\s\S]*>/i;
  if (HTMLRegex.test(str) === true) {
    return <div dangerouslySetInnerHTML={{ __html: str }}></div>;
  } else {
    return (
      <Text fontWeight={"medium"} color={"warning.500"} fontSize="medium">
        The response could not be shown in HTML format!
      </Text>
    );
  }
};

interface ResponseBodyProps {
  title: string;
  isGenericTask: boolean;
  responseBody: string;
}

export const ResponseBodyComponent: React.FC<ResponseBodyProps> = ({
  title,
  isGenericTask,
  responseBody,
}) => {
  const colorText = useColorModeValue("neutralGrey", "darkThemeGrey.300");
  const colorTaskInfo = useColorModeValue("blue.900", "blue.400");

  return (
    <>
      {isGenericTask ? (
        <Flex direction="column" mt={4}>
          <Text
            fontSize={"small"}
            fontWeight="semibold"
            color={colorTaskInfo}
            pr={2}
          >
            {title}
          </Text>
          {formatJSON(responseBody) === responseBody ? (
            <Text fontWeight={"medium"} color={colorText}>
              {formatJSON(responseBody)}
            </Text>
          ) : (
            <pre>{formatJSON(responseBody)}</pre>
          )}
        </Flex>
      ) : (
        <Tabs variant="unstyled" mt={4}>
          <TabList>
            <Box>
              <Text
                fontSize={"small"}
                fontWeight="semibold"
                color={colorTaskInfo}
                pr={2}
              >
                {title}
              </Text>
            </Box>
            <Tab
              borderRadius={500}
              w={16}
              h={5}
              fontSize="small"
              textAlign="center"
              cursor={"pointer"}
              fontWeight={600}
              bg={"blue.100"}
              color={"neutralDarkGrey"}
              _selected={{
                color: "blue.600",
                fontWeight: "bold",
                backgroundColor: "blue.300",
              }}
              mr={2}
            >
              STRING
            </Tab>
            <Tab
              bg={"blue.100"}
              color={"neutralDarkGrey"}
              fontWeight={600}
              fontSize="small"
              borderRadius={500}
              w={16}
              h={5}
              textAlign="center"
              cursor={"pointer"}
              _selected={{
                color: "blue.600",
                fontWeight: "bold",
                backgroundColor: "blue.300",
              }}
              mr={2}
            >
              JSON
            </Tab>
            <Tab
              borderRadius={500}
              w={16}
              h={5}
              textAlign="center"
              fontSize="small"
              cursor={"pointer"}
              fontWeight={600}
              bg={"blue.100"}
              color={"neutralDarkGrey"}
              _selected={{
                color: "blue.600",
                fontWeight: "bold",
                backgroundColor: "blue.300",
              }}
              mr={2}
            >
              HTML
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel pl={0} py={0} fontWeight={"medium"} color={colorText}>
              {responseBody}
            </TabPanel>
            <TabPanel pl={0} py={0} fontWeight={"medium"} color={colorText}>
              {formatJSON(responseBody) === responseBody ? (
                <Text
                  fontWeight={"medium"}
                  color={"warning.500"}
                  fontSize="medium"
                >
                  The response could not be shown in JSON format!
                </Text>
              ) : (
                <pre>{formatJSON(responseBody)}</pre>
              )}
            </TabPanel>
            <TabPanel
              mt={2}
              pl={0}
              py={0}
              fontWeight={"medium"}
              color={colorText}
              maxH="500px"
              maxW={"800px"}
              overflow="auto"
            >
              {formatHTML(responseBody)}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};
