import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../Assets/NavBar/SearchIcon.svg";
import { WDProjectModal } from "../CreateProjectModal/WDProjectModal";
import { ProjectInterface } from "./typesForProjects";

interface ProjectsFilterProps {
  unfiltredProjects: ProjectInterface[] | undefined;
  setFilter: (unfiltredProjects: ProjectInterface[] | undefined) => void;
  setNameFilter: (name: string | undefined) => void;
}

export const AllProjectsHeader: React.FC<ProjectsFilterProps> = (props) => {
  const [filterValue, setFilterValue] = useState<string>("");
  const handleChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };
  useEffect(() => {
    props.setNameFilter(filterValue);
    if (filterValue && filterValue !== " ")
      props.setFilter(
        props.unfiltredProjects?.filter((pro) => {
          return pro.name.toLowerCase().includes(filterValue.toLowerCase());
        })
      );
    else props.setFilter(props.unfiltredProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);
  return (
    <VStack w="100%">
      <Flex w="100%" pb={6}>
        <Heading>All Projects</Heading>
        <Spacer />
        <Box>
          <WDProjectModal />
        </Box>
      </Flex>
      <Box w="100%">
        <InputGroup
          bg="white"
          width={644}
          mr={4}
          borderRadius={5}
          w="100%"
          borderColor="neutralGrey"
        >
          <InputLeftElement children={<SearchIcon />} />
          <Input
            color="black"
            type="search"
            placeholder="Search a project..."
            value={filterValue}
            onChange={handleChangeFilter}
          />
        </InputGroup>
      </Box>
    </VStack>
  );
};
