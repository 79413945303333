export const dataObject = [
    {
      pictureUrl: "/AboutPageImages/Logs.png",
      text: "Watchdog is a monitoring , analysis and response system designed to proactively detect flaws in other applications' desired operation.",
    },
    {
      pictureUrl: "/AboutPageImages/ProjectCard.png",
      text: "The main purpose of Watchdog will be to monitor different kinds of servers, both physical servers and services in terms of being up/down, monitor and analyze responses from a Web API, detect abnormal operation and notify a group of users when there is a problem.",
    },
    {
      pictureUrl: "/AboutPageImages/Notifications.png",
      text: "Watchdog is proactive and aim to detect any problem before the client does and give specific and useful information to address the problem as quickly as possible.",
    },
    {
      pictureUrl: "/AboutPageImages/Users.png",
      text: "There are 2 types of users: Admins and Users. Admin have full access to the application, Users have access only to their projects. Both can be Designers and Watchers . Designers will have full access on specific projects and Watchers will have only read access to specific projects.",
    },
    {
      pictureUrl: "/AboutPageImages/Services.png",
      text: "A project will consist of all entities related to an application (servers, services, web APIs and other types of services ). A project can have multiple servers (either for production or both production and development).",
    },
    {
      pictureUrl: "/AboutPageImages/Server.png",
      text: "Servers are physical/virtual machines, containers or any entity that has its own IP address and services are applications that listen to internet connections like SQL servers, REDIS, Web APIs etc.",
    },
  ];
  
export const teamMembers = [
  {name: "Petru Cioinica", role: "Team Leader", pictureSrc: '/profilePictures/petru.jpeg'},
  {name: 'Teodor Ghinea', role: 'Team Leader', pictureSrc: '/profilePictures/teo.png'},
  {name: 'Denisa Iordache', role: 'Backend developer', pictureSrc: '/profilePictures/denisa.jpeg'},
  {name: 'Cristina Monea', role: 'Backend developer', pictureSrc: '/profilePictures/cristina.jpeg'},
  {name: 'Bianca Balasa', role: 'Frontend Developer', pictureSrc: '/profilePictures/bianca.jpeg'},
  {name: 'Sebastian Virtopeanu', role: 'Frontend Developer', pictureSrc: '/profilePictures/sebi.png'},
  {name: 'Robert Udrea', role: 'Frontend Developer', pictureSrc: '/profilePictures/robert.jpeg'},
];

export const formerTeamMembers = [
  {name: 'Dumitru Bangheorghe', role: 'Backend developer', pictureSrc: '/profilePictures/dumi.png'},
  {name: 'Alexandru Micu', role: 'Backend developer', pictureSrc: ''},
  {name: 'Andreea Fertu', role: 'Backend developer', pictureSrc: ''}
]