import { Box } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { ArrowNext, ArrowPrev } from "./ReactSlicker/CustomArrowsForSlicker";
export const PlayGround: React.FC = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
  };

  return (
    <Box w={400} p={50}>
      <Slider {...settings}>
        <Box>
          <Box>1</Box>
        </Box>
        <Box>
          <Box>2</Box>
        </Box>
        <Box>
          <Box>3</Box>
        </Box>
      </Slider>
    </Box>
  );
};
