import { useDisclosure } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { ErrorContext } from "../../App";
import { apiClient, authorise } from "../utils/apiClient";
import {
  ErrrorModalDataTaskInterface,
  ModalDataTaskInterface,
  ModalTaskContextInterface,
  TaskHeader,
  WarnigModalDataFormatting,
} from "./types";

import {
  defaultErrorModalData,
  defaultModalData,
  defaultWarnigs,
} from "./Utils/defaultObjects";
import {
  buildPostBody,
  createErrorObject,
  setModalDataFromPost,
  updateStateTaskEditModalData,
  updateStateTaskModalData,
} from "./Utils/handleApiClient";
import { checkForErrors } from "./Utils/validators";

import { TaskInfoInterface } from "./WDTaskModal";
export const useTaskModal = (
  currentInfo: TaskInfoInterface
): ModalTaskContextInterface => {
  const { currentService } = {
    ...currentInfo,
  };
  const errorContext = useContext(ErrorContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [loadingTest, setLoadingTest] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure();
  const [headerNumber, setHeaderNumber] = useState<number>(2);
  const [warningModalData, setWarningModalData] =
    useState<WarnigModalDataFormatting>(defaultWarnigs);
  const [errorModalData, setErrorModalData] =
    useState<ErrrorModalDataTaskInterface>(defaultErrorModalData);
  const [modalData, setModalData] =
    useState<ModalDataTaskInterface>(defaultModalData);
  const [initialModalDataEdit, setInitialModalDataEdit] =
    useState<ModalDataTaskInterface>(defaultModalData);
  const changeData = (
    partialModalData: Partial<ModalDataTaskInterface>,
    partialErrorModalData: Partial<ErrrorModalDataTaskInterface>,
    partialWarnigModalData?: Partial<WarnigModalDataFormatting>
  ) => {
    setModalData({ ...modalData, ...partialModalData });
    setErrorModalData({ ...errorModalData, ...partialErrorModalData });
    setWarningModalData({
      ...defaultWarnigs,
      ...warningModalData,
      ...partialWarnigModalData,
    });
  };

  const handleNext = (nextStep: number) => {
    if (
      checkForErrors(
        createErrorObject(modalData, setErrorModalData, step),
        step
      )
    ) {
      setWarningModalData(defaultWarnigs);
      setStep(nextStep);
    }
  };

  const handleSave = () => {
    if (
      checkForErrors(
        createErrorObject(modalData, setErrorModalData, step),
        step
      )
    ) {
      setLoading(true);
      apiClient
        .post(
          `/api/tasks/create/${currentService.serviceId}`,
          buildPostBody(modalData),

          authorise()
        )

        .then((res) => {
          updateStateTaskModalData(currentInfo, res);
          errorContext.createToast("You have created a Task");
          reset();
        })
        .catch((err) => {
          reset();
          errorContext.createError([...err.response.data]);
        });
    }
  };

  const handleChangeHeader = (partialHeader: Partial<TaskHeader>) => {
    setModalData({
      ...modalData,
      taskHeaders: [
        ...modalData.taskHeaders.map((tH) => {
          if (tH.index === partialHeader.index)
            return { ...tH, ...partialHeader };
          else return tH;
        }),
      ],
    });
  };
  const deleteHeader = (id: string) => {
    if (modalData.taskHeaders.length === 1)
      setModalData({
        ...modalData,
        taskHeaders: defaultModalData.taskHeaders,
      });
    else
      setModalData({
        ...modalData,
        taskHeaders: modalData.taskHeaders.filter((th) => th.index !== id),
      });
  };
  const addHeader = () => {
    setModalData({
      ...modalData,
      taskHeaders: [
        ...modalData.taskHeaders,
        { keyV: "", value: "", index: headerNumber.toString() },
      ],
    });
    setHeaderNumber(headerNumber + 1);
  };
  const reset = () => {
    setLoading(false);
    setStep(0);
    setErrorModalData(defaultErrorModalData);
    setModalData(defaultModalData);
    setWarningModalData(defaultWarnigs);
    onClose();
  };

  const onOpenEdit = () => {
    apiClient
      .get(`/api/tasks/${currentInfo.idTask}`, authorise())
      .then((res) => {
        onOpen();
        setModalDataFromPost(setModalData, {
          data: { ...res.data, ...res.data.taskJob },
        });
        setModalDataFromPost(setInitialModalDataEdit, {
          data: { ...res.data, ...res.data.taskJob },
        });
      })
      .catch((err) => {
        reset();
        errorContext.createError([...err.response.data]);
        errorContext.createError([...err.response.data]);
      });
  };

  const saveEditChanges = () => {
    setLoading(true);
    apiClient
      .put(
        `/api/tasks/update/${currentInfo.idTask}`,
        buildPostBody(modalData),
        authorise()
      )
      .then((res) => {
        errorContext.createToast("You have edited the task successfully");
        updateStateTaskEditModalData(currentInfo, res);
        reset();
      })
      .catch((err) => {
        reset();
        errorContext.createError([...err.response.data]);
      });
  };

  const onCloseBigModal = () => {
    if (!currentInfo.idTask) {
      if (JSON.stringify(modalData) === JSON.stringify(defaultModalData))
        reset();
      else onOpenConfirm();
    } else {
      if (JSON.stringify(modalData) === JSON.stringify(initialModalDataEdit))
        reset();
      else onOpenConfirm();
    }
  };
  const testRequest = () => {
    setLoadingTest(true);
    console.log({
      taskType: parseInt(modalData.taskType),
      route: modalData.route,
      ipAdress: currentService.ipAddress,
      port: parseInt(currentService.portNumber),
      httpMethod: parseInt(
        modalData.httpMethods === "5" ? "10" : modalData.httpMethods
      ),
      queryParameters: modalData.queryParameters,
      mediaType: modalData.mediaType,
      requestBody:
        modalData.taskType === "0"
          ? modalData.requestGeneric
          : modalData.requestBody,
      headers: [
        ...modalData.taskHeaders.map((th) => {
          return { key: th.keyV, value: th.value };
        }),
      ],
    });

    apiClient
      .post(
        "/api/tasks/test-request",
        {
          taskType: parseInt(modalData.taskType),
          route: modalData.route,
          ipAdress: currentService.ipAddress,
          port: parseInt(currentService.portNumber),
          httpMethod:
            modalData.taskType === "10"
              ? parseInt(
                  modalData.httpMethods === "5" ? "10" : modalData.httpMethods
                )
              : 0,
          queryParamaters: modalData.queryParameters,
          mediaType: modalData.mediaType,
          requestBody:
            modalData.taskType === "0"
              ? btoa(modalData.requestGeneric)
              : modalData.requestBody,
          headers: [
            ...modalData.taskHeaders.map((th) => {
              return { key: th.keyV, value: th.value };
            }),
          ],
        },
        authorise()
      )
      .then((res) => {
        console.log(res.data.response);

        if (res.data.status.toString().includes("40"))
          errorContext.createToast("Status code request:" + res.data.status, {
            status: "warning",
          });

        modalData.taskType === "0"
          ? setModalData({
              ...modalData,
              expectedStatus: res.data?.status.toString(),
              expectedResponse: res.data?.response,
            })
          : setModalData({
              ...modalData,
              expectedStatus: res.data.status.toString(),
              expectedBody: res.data.response
                ? JSON.stringify(JSON.parse(res.data.response) ?? "", null, 2)
                : "",
            });
        setLoadingTest(false);
      })
      .catch((err) => {
        console.log(err);
        try {
          errorContext.createError([...err.response.data]);
        } catch {
          errorContext.createError([err.response.data]);
        }
        setLoadingTest(false);
      });
  };
  return {
    testRequest,
    onCloseBigModal,
    isOpenConfirm,
    onCloseConfirm,
    setHeaderNumber,
    currentInfo,
    errorModalData,
    warningModalData,
    handleChangeHeader,
    setModalData,
    addHeader,
    loadingTest,
    deleteHeader,
    loading,
    changeData,
    setWarningModalData,
    reset,
    step,
    setStep,
    onOpenEdit,
    saveEditChanges,
    modalData,
    isOpen,
    onOpen,
    handleNext,
    handleSave,
  };
};
