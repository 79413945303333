import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import {
  requiredField,
  validatorNumericField,
} from "../../../Utils/ValidatorsForInput";
import { WDDropdown } from "../../Common/WDDropdown";
import { WDInput } from "../../Common/WDInput";
import { ReqResWeb } from "./ReqResWeb";

import { optionTaskRequestMethod, tasksIdFieldsInterface } from "../types";
import { ModalTaskContext } from "../WDTaskModal";
import { TaskHeaderComponent } from "./TaskHeader";

export const InputsForWebApiType: React.FC = () => {
  const { modalData, changeData, errorModalData, addHeader } =
    useContext(ModalTaskContext);

  const handleRequestMethod = (value: string) => {
    changeData(
      { httpMethods: value },
      { errorHttpMethods: requiredField(value) }
    );
  };
  const handleQueryParms = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeData(
      { queryParameters: e.target.value },
      { errorQueryParams: requiredField(e.target.value) }
    );
  };

  const handleExpectedStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeData(
      { expectedStatus: e.target.value },
      {
        errorExpectedStatus:
          requiredField(e.target.value) ||
          validatorNumericField(e.target.value),
      }
    );
  };
  const handleTaskURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeData(
      { route: e.target.value },
      { errorRoute: requiredField(e.target.value) }
    );
  };

  const handleTaskName = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeData(
      { name: e.target.value },
      { errorName: requiredField(e.target.value) }
    );
  };
  const colorText = useColorModeValue("blue.700", "blue.400");
  return (
    <>
      <HStack w="100%" py={2}>
        <Box w="40%">
          <Flex>
            <Text> Task Name</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDInput
            id={tasksIdFieldsInterface.idName}
            onChange={handleTaskName}
            value={modalData.name}
            placeholder="Task Name"
            error={errorModalData.errorName}
          />
        </Box>
        <Box w="100%">
          <Flex>
            <Text>Task URL</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDInput
            id={tasksIdFieldsInterface.idRoute}
            onChange={handleTaskURL}
            value={modalData.route}
            placeholder="Task URL"
            error={errorModalData.errorRoute}
          />
        </Box>
        <Box w="22.5%">
          <Flex>
            <Text>Expected Status</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDInput
            id={tasksIdFieldsInterface.idExpectedStatus}
            onChange={handleExpectedStatus}
            value={modalData.expectedStatus}
            placeholder="Expected Status"
            error={errorModalData.errorExpectedStatus}
          />
        </Box>
      </HStack>
      <HStack w="100%" h="100%" pt={2} pb={6}>
        <Box w="18%">
          <Flex>
            <Text>Request Method</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDropdown
            options={optionTaskRequestMethod}
            id={tasksIdFieldsInterface.idRequestMethod}
            onChange={handleRequestMethod}
            value={modalData.httpMethods}
            placeholder="Request Method"
            error={errorModalData.errorHttpMethods}
          />
        </Box>
        <Box w="100%">
          <Text>Request Query Params</Text>

          <WDInput
            id={tasksIdFieldsInterface.idQueryParams}
            onChange={handleQueryParms}
            value={modalData.queryParameters || ""}
            placeholder="Request Query Params"
            error={errorModalData.errorQueryParams}
          />
        </Box>
      </HStack>
      <VStack
        align={"flex-start"}
        justify="flex-start"
        w="100%"
        minH="fit-content"
        py={2}
      >
        <Text> Task Headers</Text>

        {modalData.taskHeaders.map((th, index) => {
          return (
            <TaskHeaderComponent
              index={th.index}
              keyV={th.keyV}
              value={th.value}
              key={index + "header"}
            />
          );
        })}
        <Button
          variant="link"
          fontWeight={600}
          color={colorText}
          textAlign={"center"}
          onClick={addHeader}
        >
          Add header +
        </Button>
      </VStack>
      <ReqResWeb />
    </>
  );
};
