import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tbody,
  Td,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion, Variants } from "framer-motion";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorContext } from "../../App";
import { ReactComponent as OptionButton } from "../../Assets/Users/UsersButtonIcon.svg";
import { theme } from "../../theme";
import { IconProjectChooser } from "../Common/DictForIcons";
import { WDConfirmDelete } from "../Common/WDConfirmDelete";
import { OnlineStatus } from "../ProjectsComponent/OnlineStatus/OnlineStatus";
import { apiClient, authorise } from "../utils/apiClient";
import { Designer } from "./SmallComponents/Designer";
import { ProjectInterface, UserProject } from "./typesForProjects";
interface ProjectsTableDataProps {
  projectsToShow: ProjectInterface[] | undefined;
  deleteProjects: (id: string) => void;
  onSuccesManageUser: (val: UserProject[], id: string) => void;
}
export const TableBody: React.FC<ProjectsTableDataProps> = (props) => {
  const errorContext = useContext(ErrorContext);

  const handleDelete = async (id: string, onClose: () => void) => {
    await apiClient
      .delete(`/api/private/projects/${id}`, authorise())
      .then(() => {
        props.deleteProjects(id);
        onClose();
      })
      .catch((err) => {
        errorContext?.createError([...err.reponse.delete]);
      });
  };
  const colorPrimary = useColorModeValue("blue.700", "blue.50");
  const colorSecondary = useColorModeValue(
    "neutralDarkGrey",
    "darkThemeGrey.100"
  );
  const colorThird = useColorModeValue("neutralGrey", "darkThemeGrey.100");
  const colorStrokePrimary = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.blue[400]}`
  );
  const rowVariants: Variants = {
    offscreen: {
      y: 30,
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };
  const MotionTr = motion(Tr);
  const menuListColor = useColorModeValue("white", "darkThemeGrey.600");
  const bgColorMenuItem = useColorModeValue("blue.100", "darkThemeGrey.500");
  const navigate = useNavigate();
  return (
    <Tbody>
      {props.projectsToShow?.map((pro, i) => {
        return (
          <MotionTr
            key={pro.projectId}
            fontWeight={600}
            color={colorPrimary}
            fontSize={14}
            lineHeight={20}
            borderBottom="1px solid"
            borderColor={colorSecondary}
            minW="fit-content"
            initial={i <= 10 ? "onscreen" : "offscreen"}
            whileInView="onscreen"
            viewport={{ once: true }}
            minHeight="fit-content"
            variants={rowVariants}
          >
            <Td>
              <Flex alignItems="center" justifyContent="flex-start">
                <IconProjectChooser
                  iconIndex={pro.icon}
                  options={{ borderRadius: "500px" }}
                />

                <Box
                  ml={3}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  maxW="150px"
                  textAlign={"center"}
                >
                  {pro.name}
                </Box>
              </Flex>
            </Td>
            <Designer
              lastName={pro.designer?.lastName || "undefined"}
              firstName={pro.designer?.firstName || "undefined"}
              email={pro.designer?.email || "undefined"}
              userId={pro.designer?.userId || ""}
            />
            <Td color="neutralGrey" textAlign="right" textColor={colorThird}>
              {pro.servers}
            </Td>
            <Td color="neutralGrey" textAlign="right" textColor={colorThird}>
              {pro.services}
            </Td>
            <Td color="neutralGrey" textAlign="right" textColor={colorThird}>
              {pro.tasks}
            </Td>
            <Td color="neutralGrey" textColor={colorThird}>
              {pro.usersCount?.designers} Designer ● {pro.usersCount?.watchers}{" "}
              Watcher
            </Td>
            <Td minW={"fit-content"}>
              <OnlineStatus status={pro.status} type={2} />
            </Td>
            <Td>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<OptionButton stroke={colorStrokePrimary} />}
                  variant="outline"
                />
                <MenuList bg={menuListColor}>
                  <MenuItem
                    _hover={{
                      bg: bgColorMenuItem,
                    }}
                    fontSize={16}
                    p={3}
                    onClick={() => {
                      navigate("../dashboard/projects/" + pro.projectId, {
                        replace: true,
                      });
                    }}
                  >
                    See all project details
                  </MenuItem>
                  <WDConfirmDelete
                    id={pro.projectId}
                    handleDelete={handleDelete}
                  >
                    <MenuItem fontSize={16} p={3}>
                      Delete project
                    </MenuItem>
                  </WDConfirmDelete>
                </MenuList>
              </Menu>
            </Td>
          </MotionTr>
        );
      })}
    </Tbody>
  );
};
