import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { formatIpV4 } from "../../../Utils/formaters";
import {
  requiredField,
  validateIPv4Address,
  validatorNumericField,
} from "../../../Utils/ValidatorsForInput";
import { SelectOptionsInterface } from "../../Common/types";
import { WDDropdown } from "../../Common/WDDropdown";
import { WDInput } from "../../Common/WDInput";
import { FirstStepFooter } from "./FirstStepFooter";
import { TheDisabledInput } from "../TheDisabledInput";
import { ModalServiceContext } from "../WDServiceModal";
export const optionsTypesService: SelectOptionsInterface[] = [
  { value: "0", display: "Generic Service", color: "darkThemeGrey.700" },
  { value: "10", display: "WebAPI", color: "darkThemeGrey.700" },
  { value: "20", display: "MySQL", color: "darkThemeGrey.700" },
  { value: "30", display: "MSSQL", color: "darkThemeGrey.700" },
  { value: "40", display: "Redis", color: "darkThemeGrey.700" },
  { value: "50", display: "Elastic Search", color: "darkThemeGrey.700" },
];
export const FirstStep: React.FC = () => {
  const modalContext = useContext(ModalServiceContext);
  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    modalContext?.changeData(
      { name: e.target.value },
      {
        errorName: requiredField(e.target.value),
      }
    );
  };
  const handleIpV4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    modalContext?.changeData(
      { ipV4: formatIpV4(e.target.value) },
      {
        errorIpV4:
          requiredField(e.target.value) ||
          validateIPv4Address(formatIpV4(e.target.value) || "")
            ? ""
            : "This ip isn't valid.",
      }
    );
  };
  const handleIpV6 = (e: React.ChangeEvent<HTMLInputElement>) => {
    modalContext?.changeData({ ipV6: e.target.value }, {});
  };
  const handlePort = (e: React.ChangeEvent<HTMLInputElement>) => {
    modalContext?.changeData(
      { port: e.target.value },
      {
        errorPort:
          requiredField(e.target.value) ||
          validatorNumericField(e.target.value),
      }
    );
  };
  const handleType = (value: string) => {
    modalContext?.changeData({ type: parseInt(value) }, {});
  };

  return (
    <React.Fragment>
      <VStack w="100%">
        <TheDisabledInput />
        <Box w="100%">
          <Flex>
            <Text>Service's name</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDInput
            tabIndex={1}
            placeholder="Service's name"
            value={modalContext?.dataModal?.name ?? ""}
            onChange={handleName}
            error={modalContext?.errorsData?.errorName}
          />
        </Box>{" "}
        <HStack w="100%" py={3} h="100%">
          <Box w="100%">
            <Flex>
              <Text>IPv4 Address </Text>
              <Text color="danger.500">*</Text>
            </Flex>
            <WDInput
              tabIndex={2}
              placeholder="IP Adress"
              value={modalContext?.dataModal?.ipV4 ?? ""}
              onChange={handleIpV4}
              error={modalContext?.errorsData?.errorIpV4}
            />
          </Box>
          <Box w="100%">
            <Text>IPv6 Adress </Text>
            <WDInput
              tabIndex={2}
              placeholder="IP Adress"
              value={modalContext?.dataModal?.ipV6 ?? ""}
              onChange={handleIpV6}
              error={modalContext?.errorsData?.errorIpV6}
            />
          </Box>
        </HStack>
        <HStack w="100%" py={3} h="100%">
          <Box w="100%">
            <Flex>
              <Text> Port Number </Text>
              <Text color="danger.500">*</Text>
            </Flex>
            <WDInput
              tabIndex={3}
              placeholder="Port number"
              value={modalContext?.dataModal?.port ?? ""}
              onChange={handlePort}
              error={modalContext?.errorsData?.errorPort}
            />
          </Box>
          <Box w="100%" h="100%">
            <Flex>
              <Text>Type </Text>
              <Text color="danger.500">*</Text>
            </Flex>
            <WDDropdown
              value={modalContext?.dataModal?.type?.toString() ?? ""}
              options={optionsTypesService}
              onChange={handleType}
              placeholder="Select type"
              error={modalContext?.errorsData?.errorType}
            />
          </Box>
        </HStack>
        <FirstStepFooter />
      </VStack>
    </React.Fragment>
  );
};
