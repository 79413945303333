import { Box, Flex, GridItem } from "@chakra-ui/react";
import React from "react";

interface TaskLogGridItemProps {
  index: number;
  bg: string;
  colSpan?: number;
  height: string;
  lengthOfArrayWithColorAndColSpan: number;
}

export const TaskLogGridItem: React.FC<TaskLogGridItemProps> = ({
  index,
  bg,
  colSpan,
  height,
  lengthOfArrayWithColorAndColSpan,
}) => {
  return (
    <>
      <GridItem w={"100%"} colSpan={colSpan}>
        <Flex width="100%" height="100%" alignItems={"center"}>
          {
            <Box
              w="100%"
              h={height}
              bg={bg}
              borderRadius={
                lengthOfArrayWithColorAndColSpan === 1
                  ? "16px 16px 16px 16px"
                  : index === 0
                  ? "16px 0px 0px 16px"
                  : index === lengthOfArrayWithColorAndColSpan - 1
                  ? "0px 16px 16px 0px"
                  : ""
              }
            ></Box>
          }
        </Flex>
      </GridItem>
    </>
  );
};
