import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Flex,
  Heading,
  HStack,
  PinInputField,
  Text,
  Image,
  Box,
} from "@chakra-ui/react";
import { WDPinInput } from "../Common/WDPinInput";
import { apiClient } from "../utils/apiClient";
import { ErrorContext, UserContext } from "../../App";
import { CustomSpinner } from "../Common/Spinner";
export interface QRPageProps {
  QRCode: string;
  clientJWT: string;
}
export const QRComponent: React.FC<QRPageProps> = ({ QRCode, clientJWT }) => {
  const userContext = useContext(UserContext);
  const errorContext = useContext(ErrorContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [valueInput, setValueInput] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const handleCompleteQRCodeInput = (value: string): void => {
    setLoading(true);
    apiClient
      .get(
        `/api/authentication/login?clientJWT=${clientJWT}&securityCode=${value}`
      )
      .then((res) => {
        localStorage.setItem("accesToken", res.data.jwtToken);
        userContext.logIn();
        setValueInput("");
        setLoading(false);
      })

      .catch((err) => {
        console.log(err.response.data[0]);
        setValueInput("");
        errorContext.createError([...err.response.data]);
        if (inputRef.current) inputRef.current.focus();
        setLoading(false);
      });
  };
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);
  const handleInputPin = (value: string) => {
    setValueInput(value);
  };
  return (
    <Flex
      bgColor={"blue.100"}
      maxW="600px"
      borderRadius={8}
      borderColor={"neutralGrey"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      textAlign={"center"}
      p={4}
      color="blue.800"
    >
      {loading ? (
        <Box
          position="absolute"
          opacity={"50%"}
          bg="blue.700"
          h="100%"
          w="100%"
        >
          <CustomSpinner />
        </Box>
      ) : null}
      <Heading size="lg">Set up your 2FA </Heading>
      <Text m={4} fontSize="xl">
        Download Google Authentificator and scan the QR Code, or enter the code
        below
      </Text>
      <Flex
        border="1px"
        borderColor={"neutralGrey"}
        borderRadius={6}
        flexDirection={"column"}
        h="70%"
        w="70%"
        alignItems={"center"}
        p={2}
      >
        <Image src={QRCode} boxSize={250} />
        <HStack>
          <WDPinInput
            size="lg"
            type="number"
            onChange={handleInputPin}
            value={valueInput}
            onComplete={handleCompleteQRCodeInput}
          >
            <PinInputField borderColor={"neutralGrey"} mr={3} ref={inputRef} />
            <PinInputField borderColor={"neutralGrey"} mr={3} />
            <PinInputField borderColor={"neutralGrey"} mr={3} />
            <PinInputField borderColor={"neutralGrey"} mr={3} />
            <PinInputField borderColor={"neutralGrey"} mr={3} />
            <PinInputField borderColor={"neutralGrey"} mr={3} />
          </WDPinInput>
        </HStack>
      </Flex>
    </Flex>
  );
};
