import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { theme } from "../../theme";
import { ClientProject } from "./types";
interface PopoverProjectsDetailsInterface {
  numberOfProjects: number;
  projects: ClientProject[];
}
export const PopoverProjectsDetails: React.FC<
  PopoverProjectsDetailsInterface
> = ({ numberOfProjects, projects }) => {
  const neutralGrey = theme.colors.neutralGrey;
  const textColor = useColorModeValue("blue.800", "white");
  const bgColor = useColorModeValue("white", "darkThemeGrey.600");
  return (
    <Popover strategy="fixed">
      <PopoverTrigger>
        <Button variant="ghost" color={neutralGrey}>
          {numberOfProjects}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        boxShadow={`0 0 5px 0 ${neutralGrey}`}
        minW="fit-content"
        maxH={40}
        overflowY="hidden"
        bg={bgColor}
      >
        <PopoverHeader fontWeight={600} textAlign="center" color={textColor}>
          Client's Projects
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody overflowY={"auto"} color={textColor}>
          <VStack
            minW="fit-content"
            fontWeight={400}
            justifyContent="flex-start"
            alignContent="flex-start"
            color="neutralDarkGrey"
            h="100%"
            pr={2}
          >
            {projects.map((p, i) => {
              return (
                <Flex w="100%" justify={"flex-start"} key={i + p.projectId}>
                  <Box
                    key={p.projectId}
                    textAlign="center"
                    w="100%"
                    minW="fit-content"
                  >
                    <Text fontSize={20} minW="fit-content" color={textColor}>
                      {" "}
                      {p.name}
                    </Text>
                  </Box>
                </Flex>
              );
            })}

            {projects.length === 0 && (
              <Box color={textColor}> No projects to show.</Box>
            )}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
