import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { createContext, useContext } from "react";
import { MotionButton } from "../../Utils/MotionComponents";

import { FirstStep } from "./FirstStep/FirstStep";
import { SecondStep } from "./SecondStep/SecondStep";
import { FirstPageModalContext } from "./types";
import { useFirstPageInfo } from "./useFirstPage";
import { ModalTaskContext } from "./WDTaskModal";
export const FirstPageContext = createContext<FirstPageModalContext>(
  {} as FirstPageModalContext
);
export const ModalTask: React.FC = () => {
  const modalContext = useContext(ModalTaskContext);

  const firstPageHook = useFirstPageInfo();
  return (
    <>
      <FirstPageContext.Provider value={firstPageHook}>
        <Modal
          isOpen={modalContext.isOpen}
          onClose={modalContext.onCloseBigModal}
          isCentered
          size={"6xl"}
          motionPreset="slideInRight"
        >
          <ModalOverlay backdropFilter="blur(10px)" />
          <ModalContent>
            <ModalHeader
              borderRadius={"8px 8px 0px 0px"}
              backgroundColor={useColorModeValue(
                "blue.100",
                "darkThemeGrey.600"
              )}
              color={useColorModeValue("blue.800", "darkThemeGrey.100")}
            >
              {modalContext.step === 0
                ? modalContext.currentInfo.idTask
                  ? "Edit task"
                  : "Add new task"
                : modalContext.currentInfo.idTask
                ? "Edit task's job"
                : "Create task's job"}
            </ModalHeader>
            <ModalCloseButton
              onClick={modalContext.onCloseBigModal}
              margin={"5px 8px 0px 0px"}
              color={useColorModeValue("blue.800", "darkThemeGrey.100")}
              borderWidth={2}
              borderRadius={5}
              borderColor={useColorModeValue("blue.800", "darkThemeGrey.100")}
            />
            <ModalBody
              px={4}
              py={0}
              bg={useColorModeValue("white", "darkThemeGrey.700")}
            >
              {modalContext.step === 0 ? <FirstStep /> : <SecondStep />}
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={modalContext.isOpenConfirm}
          onClose={modalContext.onCloseConfirm}
          isCentered
          blockScrollOnMount={false}
        >
          <ModalCloseButton
            onClick={modalContext.onCloseConfirm}
            margin={"5px 8px 0px 0px"}
            color={useColorModeValue("blue.800", "darkThemeGrey.100")}
            borderWidth={2}
            borderRadius={5}
            borderColor={useColorModeValue("blue.800", "darkThemeGrey.100")}
          />
          <ModalOverlay backdropFilter="blur(10px)" backdropBlur={"10px"} />

          <ModalContent>
            <ModalBody bg={useColorModeValue("white", "darkThemeGrey.700")}>
              <VStack>
                <Box
                  fontSize={20}
                  textAlign="left"
                  w="100%"
                  fontWeight={600}
                  py={2}
                >
                  Discard all changes?
                </Box>
                <Box fontSize={16} textAlign="left" w="100%" fontWeight={400}>
                  We won’t be able to save your data if you move away from this
                  page.
                </Box>
                <Spacer />
                <HStack w="100%">
                  <Spacer />
                  <Box>
                    <MotionButton
                      tabIndex={2}
                      onClick={modalContext.onCloseConfirm}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{ type: "spring", bounce: 0.4 }}
                    >
                      {" "}
                      Go back
                    </MotionButton>
                  </Box>
                  <Box>
                    <MotionButton
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{ type: "spring", bounce: 0.4 }}
                      onClick={() => {
                        modalContext?.reset();
                        modalContext.onCloseConfirm();
                      }}
                      colorScheme="danger"
                      bg="danger.500"
                      tabIndex={1}
                      autoFocus
                    >
                      Exit
                    </MotionButton>
                  </Box>
                </HStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </FirstPageContext.Provider>
    </>
  );
};
