import {
  Box,
  Flex,
  HStack,
  IconButton,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { ReactComponent as AreaIcon } from "../../../Assets/AreaChartIcon.svg";
import { ReactComponent as SystemIcon } from "../../../Assets/HasSystemIcon.svg";
import { ReactComponent as ScaleIcon } from "../../../Assets/ScaleIcon.svg";
import { theme } from "../../../theme";
import { SelectedInterval } from "../TimeIntervalWrapper";
import { callbackFunctions, createOptions, labels } from "./settingsObjects";

import { recordNameEnum } from "../../ResourcePage/ResourceOverview/utils";
import { AreaChart } from "./types";
import { createGradient } from "./utils";

interface AreaGraphProps {
  selectedInterval: SelectedInterval;
  areaChart?: AreaChart;
}

const AreaGraph: React.FC<AreaGraphProps> = ({
  selectedInterval,
  areaChart,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );
  // console.log(systemName);

  // console.log(chartData);

  const chartRef = useRef<any>(null);
  const [data, setData] = useState<ChartData<"line">>({ datasets: [] });
  const [isScaledMax, setIsScaledMax] = useState<boolean>(false);
  const [options, setOptions] = useState<ChartOptions<any>>(
    createOptions(selectedInterval, areaChart?.maxPossibleValue)
  ); //THIS FUNCTIONS CREATES OPTIONS BASED ON ARRAY OF CALLBACKFUNCTIONS

  const updateDataGraph = (interval: SelectedInterval, scale?: boolean) => {
    const chart = chartRef.current;
    if (chart) {
      const data = {
        labels: areaChart?.chartData
          ? callbackFunctions[interval].labelCallback(areaChart?.chartData)
          : [{}],
        datasets: [
          {
            label: " ",
            data: areaChart?.systemData
              ? areaChart?.systemData?.map((s, i) => {
                  return { x: i, y: s.percentage };
                })
              : [{ x: 0, y: 0 }],
            borderColor: "#000",
            borderWidth: 1,
          },
          {
            label: "",
            data: labels().map((l) => ({ x: l, y: 0 })),
            borderColor: "white",
            backgroundColor: "white",
            borderWidth: 0,
          },
          {
            fill: true,
            label: " ",
            data: areaChart?.chartData
              ? areaChart?.chartData.map((g, i) => {
                  return { x: i, y: g.percentage };
                })
              : [{ x: 0, y: 0 }],
            borderColor: `${theme.colors.blue[800]}`,
            borderWidth: 0.2,
            backgroundColor: createGradient(
              chart.ctx,
              chart.chartArea,
              areaChart?.thresholds,
              scale ? areaChart?.maxValue : undefined
            ),
          },
        ],
      };
      if (areaChart?.chartData)
        setOptions(
          createOptions(
            interval,
            areaChart?.maxPossibleValue,
            scale ? areaChart?.maxValue : undefined,
            areaChart?.chartData
          )
        );
      setData(data);
    }
  };
  const handleScale = () => {
    setIsScaledMax(!isScaledMax);
    updateDataGraph(selectedInterval, !isScaledMax);
  };

  useEffect(() => {
    if (selectedInterval === "24h") {
      updateDataGraph(selectedInterval, false);
      setIsScaledMax(false);
    } else if (selectedInterval === "7d") {
      setIsScaledMax(false);
      updateDataGraph(selectedInterval, false);
    } else if (selectedInterval === "30d") {
      updateDataGraph(selectedInterval, false);
      setIsScaledMax(false);
    }
  }, [areaChart]);
  const shadowColor = useColorModeValue("#000", "#fff");
  const colorText = useColorModeValue("blue.700", "darkThemeGrey.100");
  const title = recordNameEnum[areaChart?.resourceName || 0];
  return (
    <VStack
      w="100%"
      h="100%"
      spacing={0}
      align={"start"}
      boxShadow={`0px 0px 6px ${shadowColor}`}
      position={"relative"}
    >
      {areaChart?.hasSystem ? (
        <HStack position={"absolute"} top={2} left={8}>
          <AreaIcon />
          <Box>User {title}</Box>
          <SystemIcon />
          <Box>System {title}</Box>
        </HStack>
      ) : null}
      <Flex
        position={"absolute"}
        top={2}
        right={6}
        bg="rgb(0,0,0,0)"
        justify={"center"}
        align="center"
        cursor={"pointer"}
        onClick={() => {
          handleScale();
        }}
      >
        <IconButton
          aria-label="scale button"
          boxSize={4}
          icon={<ScaleIcon />}
          p={0}
          stroke={colorText}
          variant={"unstyled"}
        />
        <Box color={colorText} fontWeight={600} ml={-5}>
          Scale
        </Box>
      </Flex>

      <Line ref={chartRef} options={options} data={data} height={70} />
    </VStack>
  );
};
export default React.memo(AreaGraph);
