import { SelectOptionsInterface } from "../Common/types";
import { TaskInfoInterface } from "./WDTaskModal";
export interface ModalTaskContextInterface {
  step: number;
  setStep: (step: number) => void;
  isOpenConfirm: boolean;
  onCloseConfirm: () => void;
  setModalData: (ModalData: ModalDataTaskInterface) => void;
  modalData: ModalDataTaskInterface;
  errorModalData: ErrrorModalDataTaskInterface;
  warningModalData: WarnigModalDataFormatting;
  onCloseBigModal: () => void;
  setWarningModalData: (warningModalData: WarnigModalDataFormatting) => void;
  currentInfo: TaskInfoInterface;
  isOpen: boolean;
  loading: boolean;
  onOpen: () => void;
  onOpenEdit: () => void;
  handleNext: (nextStep: number) => void;
  handleSave: () => void;
  saveEditChanges: () => void;
  reset: () => void;
  setHeaderNumber: (headerNumber: number) => void;
  changeData: (
    partialModalData: Partial<ModalDataTaskInterface>,
    partialErrorModalData: Partial<ErrrorModalDataTaskInterface>,

    partialWarnigModalData?: Partial<WarnigModalDataFormatting>
  ) => void;
  testRequest: () => void;
  handleChangeHeader: (partialHeader: Partial<TaskHeader>) => void;
  addHeader: () => void;
  deleteHeader: (id: string) => void;
  loadingTest: boolean;
}

export interface ErrrorModalDataTaskInterface {
  errorName: string;
  errorRoute?: string;
  errorRequestGeneric: string;
  errorExpectedResponse: string;
  errorHttpMethods: string;
  errorQueryParams: string;
  errorRequestBody: string;
  errorExpectedBody: string;
  errorExpectedStatus: string;
  errorDate: string;
  errorReccuring: string;
  errorNotificationLevel: string;
  errorTimeout: string;
}
export interface WarnigModalDataFormatting {
  warningRequestBody: string;
  warningExpectedBody: string;
  warningRequestGeneric: string;

  warningExpectedResponse: string;
}
export interface ModalDataTaskInterface {
  name: string;
  jobId?: string;
  taskType: string;
  route: string;
  ignoreResponse: boolean;
  requestGeneric: string;
  httpMethods: string;
  expectedResponse: string;
  queryParameters?: string;
  requestBody: string;
  mediaType: number;
  expectedBody: string;
  expectedStatus: string;
  isActive: boolean;
  startDate: string;
  recurringTime: number;
  minimumNotificationLevel: number;
  timeout: number;
  timeoutNotificationLevel: number;
  taskHeaders: TaskHeader[];
}

export const tasksIdFieldsInterface = {
  idName: "modalTask__name--field",
  idRequestGeneric: "modalTask__requestGeneric--field",
  idExpectedResponse: "modalTask__expectedResponse--field",
  idRequestMethod: "modalTask__requestMethod--field",
  idQueryParams: "modalTask__queryParams--field",
  idRequestBody: "modalTask__requestBody--field",
  idExpectedBody: "modalTask__expectedBody--field",
  idExpectedStatus: "modalTask__expectedStatus--field",
  idRoute: "modalTask__route--field",
  idOrder: "modalTask__order--field",
};
export const optionTaskRequestMethod: SelectOptionsInterface[] = [
  { value: "10", display: "GET", color: "#61affe" },
  { value: "20", display: "POST", color: "#49cc90" },
  { value: "30", display: "PUT", color: "#fca130" },
  { value: "40", display: "PATCH", color: "#57e3c3" },
  { value: "50", display: "OPTIONS", color: "#0d5ca7" },
  { value: "60", display: "DELETE", color: "#f93e3e" },
];
export interface FirstPageModalContext {
  requestFileName: string;
  setRequestFileName: (requestFileName: string) => void;
  responseFileName: string;
  setResponseFileName: (responseFileName: string) => void;
  disabledRequest: boolean;
  setDisabledRequest: (disabledRequestBody: boolean) => void;
  disabledResponse: boolean;
  setDisabledResponse: (disabledResponse: boolean) => void;
  formatRequestBody: number;
  formatExpectedResponse: number;
  setFormatExpectedResponse: (formatExpectedBody: number) => void;
  setFormatRequestBody: (formatRequestBody: number) => void;
}
export interface TaskHeader {
  keyV: string;
  value: string;
  id?: string;
  index: string;
}
export interface FirstPageModalContext {
  requestFileName: string;
  setRequestFileName: (requestFileName: string) => void;
  responseFileName: string;
  setResponseFileName: (responseFileName: string) => void;
  disabledRequest: boolean;
  setDisabledRequest: (disabledRequestBody: boolean) => void;
  disabledResponse: boolean;
  setDisabledResponse: (disabledResponse: boolean) => void;
  formatRequestBody: number;
  formatExpectedResponse: number;
  setFormatExpectedResponse: (formatExpectedBody: number) => void;
  setFormatRequestBody: (formatRequestBody: number) => void;
}
export interface TaskHeader {
  keyV: string;
  value: string;
  id?: string;
  index: string;
}
