import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as GreenStatus } from "../../../Assets/ProjectsIcon/GreenStatus.svg";
import { statusEnum } from "../../../Utils/defaultObjects";
interface OnlineInterface {
  status: number;
  type: 1 | 2 | 3 | 4 | 5;
}
export const OnlineStatus: React.FC<OnlineInterface> = ({ type, status }) => {
  return (
    <Box w="100%" maxH="fit-content" width={"fit-content"}>
      {
        {
          1: (
            <Flex
              justifyContent="center"
              bg={
                statusEnum.filter((st) => {
                  return st.value === status?.toString();
                })[0].color
              }
              color="white"
              h={10}
              minW="fit-content"
              minH="fit-content"
              alignItems="center"
              borderRadius={5}
              p={4}
            >
              <Box textAlign="center" fontWeight={600} fontSize={16}>
                &#9679;{" "}
                {
                  statusEnum.filter((st) => {
                    return st.value === status?.toString();
                  })[0].display
                }
              </Box>
            </Flex>
          ),
          2: (
            <Flex
              justifyContent="center"
              bg={
                statusEnum.filter((st) => {
                  return st.value === status?.toString();
                })[0].color
              }
              color="white"
              h={7}
              alignItems="center"
              borderRadius={5}
              p={3}
            >
              <Box fontWeight={600} fontSize={12}>
                {
                  statusEnum.filter((st) => {
                    return st.value === status?.toString();
                  })[0].display
                }
              </Box>
            </Flex>
          ),
          3: (
            <Flex
              justifyContent="center"
              alignItems="center"
              h={18}
              w={18}
              mr={2}
            >
              {/* {online ? <GreenStatus /> : <RedStatus />} */}
              <GreenStatus
                stroke={
                  statusEnum.filter((st) => {
                    return st.value === status?.toString();
                  })[0].color
                }
                fill={
                  statusEnum.filter((st) => {
                    return st.value === status?.toString();
                  })[0].color
                }
              />
            </Flex>
          ),
          4: (
            <Flex
              justifyContent="center"
              bg={
                statusEnum.filter((st) => {
                  return st.value === status?.toString();
                })[0].color
              }
              color="white"
              w={"108px"}
              h={7}
              alignItems="center"
              borderRadius={5}
            >
              <Box textAlign="center" fontWeight={600} fontSize={13}>
                &#9679;{" "}
                {
                  statusEnum.filter((st) => {
                    return st.value === status?.toString();
                  })[0].display
                }
              </Box>
            </Flex>
          ),
          5: (
            <Flex
              justifyContent="center"
              bg={
                statusEnum.filter((st) => {
                  return st.value === status?.toString();
                })[0].color
              }
              color="white"
              h="32px"
              w={"120px"}
              alignItems="center"
              borderRadius={5}
            >
              <Box textAlign="center" fontWeight={600} fontSize={14}>
                &#9679;{" "}
                {
                  statusEnum.filter((st) => {
                    return st.value === status?.toString();
                  })[0].display
                }
              </Box>
            </Flex>
          ),
        }[type]
      }
    </Box>
  );
};
