import { ModalFooter, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ModalServerContext } from ".";
import { MotionButton } from "../../Utils/MotionComponents";
export const ModalServerFooter: React.FC = () => {
  const colorPrimary = useColorModeValue("blue.700", "blue.400");
  const colorSchemeButton = useColorModeValue("blue.600", "blue.300");
  const { step, handleNext, serverId, handleSave, handleUpdate, loading } =
    useContext(ModalServerContext);

  return (
    <ModalFooter bg={useColorModeValue("white", "darkThemeGrey.700")}>
      {step === 0 ? (
        <MotionButton
          w="40%"
          bgColor={colorPrimary}
          color="white"
          colorScheme={colorSchemeButton}
          onClick={handleNext}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", bounce: 0.4 }}
        >
          Next
        </MotionButton>
      ) : !serverId ? (
        <MotionButton
          w="40%"
          color="white"
          bgColor={colorPrimary}
          colorScheme={colorSchemeButton}
          onClick={handleSave}
          isLoading={loading}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", bounce: 0.4 }}
        >
          Save
        </MotionButton>
      ) : (
        <MotionButton
          w="40%"
          color="white"
          bgColor={colorPrimary}
          colorScheme={colorSchemeButton}
          onClick={handleUpdate}
          isLoading={loading}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", bounce: 0.4 }}
        >
          Update
        </MotionButton>
      )}
    </ModalFooter>
  );
};
