import moment from "moment";
import {
  requiredField,
  validatorNumericField,
} from "../../../Utils/ValidatorsForInput";
import {
  ErrrorModalDataTaskInterface,
  ModalDataTaskInterface,
  optionTaskRequestMethod,
} from "../types";
import { TaskInfoInterface } from "../WDTaskModal";
import { defaultErrorModalData } from "./defaultObjects";

export const buildPostBody = (modalData: ModalDataTaskInterface) => {
  return {
    httpMethod: parseInt(modalData.httpMethods) || 5,
    name: modalData.name,
    taskType: parseInt(modalData.taskType),
    route: modalData.route || "empty",
    queryParameters: modalData.queryParameters,
    requestBody:
      modalData.taskType === "0"
        ? !modalData.requestGeneric.includes("data:text/")
          ? btoa(modalData.requestGeneric)
          : modalData.requestGeneric
        : modalData.requestBody,
    expectedResponseBody:
      modalData.taskType === "0"
        ? !modalData.expectedBody.includes("data:text/")
          ? btoa(modalData.expectedResponse)
          : modalData.expectedResponse
        : modalData.expectedBody,
    ignoreResponse: modalData.ignoreResponse,
    expectedHttpStatus: parseInt(modalData.expectedStatus || "0"),
    headers: modalData.taskHeaders
      .filter((th) => th.keyV !== "" && th.value !== "")
      .map((th) => {
        return { key: th.keyV, value: th.value };
      }),
    taskJob: {
      jobId: modalData.jobId,
      isActive: modalData.isActive,
      startDate: modalData.startDate,
      recurringTime: modalData.recurringTime,
      timeout: modalData.timeout,
      minimumNotificationLevel: modalData.minimumNotificationLevel,
      timeoutNotificationLevel: modalData.timeoutNotificationLevel,
    },
  };
};
export const updateStateTaskEditModalData = (
  currentInfo: TaskInfoInterface,
  res: any
) => {
  const { setCurrentProject, currentProject, currentServer, currentService } = {
    ...currentInfo,
  };

  setCurrentProject({
    ...currentProject,
    servers: [
      ...currentProject?.servers.map((ser) => {
        if (ser.serverId === currentServer?.serverId) {
          return {
            ...currentServer,
            services: [
              ...currentServer.services.map((services) => {
                if (services.serviceId === currentService?.serviceId)
                  return {
                    ...currentService,
                    tasks: [
                      ...currentService.tasks.map((t) => {
                        if (t.id === currentInfo.idTask)
                          return {
                            ...t,
                            name: res.data.name,
                            taskType: res.data.taskType,
                            status: res.data.status,
                            id: res.data.id,
                            requestBody: res.data.requestBody,
                            route: res.data.route,

                            httpMethods: optionTaskRequestMethod.filter(
                              (opt) =>
                                parseInt(opt.value) === res.data.httpMethods
                            )[0]?.value,
                            expectedResponseBody: res.data.expectedResponseBody,
                          };
                        else return t;
                      }),
                    ],
                  };
                else return services;
              }),
            ],
          };
        } else return ser;
      }),
    ],
  });
};
export const updateStateTaskModalData = (
  currentInfo: TaskInfoInterface,
  res: any
) => {
  const { setCurrentProject, currentProject, currentServer, currentService } = {
    ...currentInfo,
  };
  setCurrentProject({
    ...currentProject,
    servers: [
      ...currentProject?.servers.map((ser) => {
        if (ser.serverId === currentServer?.serverId) {
          return {
            ...currentServer,
            services: [
              ...currentServer.services.map((services) => {
                if (services.serviceId === currentService?.serviceId)
                  return {
                    ...currentService,
                    tasks: [
                      ...currentService.tasks,

                      {
                        name: res.data.name,
                        taskType: res.data.taskType,
                        status: res.data.status,
                        id: res.data.id,
                        isEnabled: res.data.isEnabled,
                        requestBody: res.data.requestBody,
                        route: res.data.route,
                        httpMethods: optionTaskRequestMethod.filter(
                          (opt) => parseInt(opt.value) === res.data.httpMethods
                        )[0]?.value,
                        expectedResponseBody: res.data.expectedResponse,
                      },
                    ],
                  };
                else return services;
              }),
            ],
          };
        } else return ser;
      }),
    ],
  });
};

export const setModalDataFromPost = (
  setModalData: (modalData: ModalDataTaskInterface) => void,
  res: any
) => {
  setModalData({
    jobId: res.data.jobId,
    requestGeneric: res.data.requestBody,
    requestBody: res.data.requestBody,
    taskType: res.data.taskType.toString(),
    httpMethods: res.data.httpMethod.toString(),
    isActive: res.data.isActive,
    ignoreResponse: res.data.ignoreResponse,
    queryParameters: res.data.queryParameters,
    route: res.data.route === "empty" ? "" : res.data.route,
    name: res.data.name,
    taskHeaders: res.data.headers.map((th: any, index: number) => {
      return { keyV: th.key, value: th.value, id: th.id, index: index };
    }),
    mediaType: res.data.mediaType,
    expectedBody: res.data.expectedResponseBody,
    expectedResponse: res.data.expectedResponseBody,
    expectedStatus: res.data.expectedHttpStatus.toString() || "",
    timeout: res.data.timeout,
    timeoutNotificationLevel: res.data.timeoutNotificationLevel,
    minimumNotificationLevel: res.data.minimumNotificationLevel,
    startDate: moment.utc(res.data.startDate).local().format(),
    recurringTime: res.data.recurringTime,
  });
};
export const createErrorObject = (
  modalData: ModalDataTaskInterface,
  setErrorModalData: (errorsModalData: ErrrorModalDataTaskInterface) => void,
  step: number
) => {
  let errorCatcher: ErrrorModalDataTaskInterface = defaultErrorModalData;
  if (modalData.taskType === "0")
    errorCatcher = {
      ...errorCatcher,
      errorName: requiredField(modalData.name || ""),
      errorRequestGeneric: requiredField(modalData.requestGeneric),
      errorExpectedResponse: modalData.ignoreResponse
        ? ""
        : requiredField(modalData.expectedResponse),
    };
  else if (modalData.taskType === "10") {
    errorCatcher = {
      ...errorCatcher,
      errorName: requiredField(modalData.name || ""),
      errorRoute: requiredField(modalData.route || ""),
      // errorExpectedBody: requiredField(modalData.expectedBody || ""),
      errorExpectedStatus:
        requiredField(modalData.expectedStatus || "") ||
        validatorNumericField(modalData.expectedStatus || ""),

      errorHttpMethods: requiredField(modalData.httpMethods || ""),
    };
  }

  if (step !== 0)
    errorCatcher = {
      ...errorCatcher,
      errorDate: requiredField(modalData.startDate),
      errorTimeout:
        requiredField(modalData.timeout.toString()) ||
        validatorNumericField(modalData.timeout.toString()) ||
        modalData.timeout === 0
          ? "Timeout cannot be 0"
          : "",
      errorReccuring:
        requiredField(modalData.recurringTime.toString()) ||
        validatorNumericField(modalData.recurringTime.toString()) ||
        modalData.recurringTime === 0
          ? "Reccuring time cannot be 0"
          : "",
      errorNotificationLevel: requiredField(
        modalData.minimumNotificationLevel === 5 ? "" : "Not empty"
      ),
    };
  setErrorModalData(errorCatcher);
  return errorCatcher;
};
