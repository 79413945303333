import { Box, Flex, Heading, PinInputField, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ErrorContext, UserContext } from "../../App";
import { CustomSpinner } from "../Common/Spinner";
import { WDPinInput } from "../Common/WDPinInput";
import { apiClient } from "../utils/apiClient";
import { QRPageProps } from "./QRComponent";

export const CodeComponent: React.FC<QRPageProps> = ({ clientJWT }) => {
  const userContext = useContext(UserContext);
  const [valueInput, setValueInput] = useState<string>("");
  const errorContext = useContext(ErrorContext);
  const [loading, setLoading] = useState<boolean>(false);
  const handleCompleteCodeInput = (value: string): void => {
    setLoading(true);
    apiClient
      .get(
        `/api/authentication/login?clientJWT=${clientJWT}&securityCode=${value}`
      )
      .then((res) => {
        localStorage.setItem("accesToken", res.data.jwtToken);

        userContext.logIn();
        setValueInput("");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data[0]);
        setValueInput("");
        if (inputRef.current) inputRef.current.focus();
        errorContext.createError([...err.response.data]);
        setLoading(false);
      });
  };
  const handleInputPin = (value: string) => {
    setValueInput(value);
  };
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);
  return (
    <Flex
      bgColor={"blue.100"}
      borderRadius={8}
      borderColor={"neutralGrey"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      textAlign={"center"}
      color="blue.800"
      p={10}
    >
      {loading ? (
        <Box
          position="absolute"
          opacity={"50%"}
          bg="blue.700"
          h="100%"
          w="100%"
        >
          {" "}
          <CustomSpinner />
        </Box>
      ) : null}
      <Heading size="lg">Enter your 2FA code </Heading>
      <Text m={4} fontSize="xl">
        You're a dev, you know what to do.
      </Text>
      <Flex
        border="1px"
        borderColor={"neutralGrey"}
        borderRadius={6}
        alignItems={"center"}
        p={6}
        mb={4}
      >
        <WDPinInput
          size="lg"
          type="number"
          onChange={handleInputPin}
          value={valueInput}
          onComplete={handleCompleteCodeInput}
        >
          <PinInputField borderColor={"neutralGrey"} mr={3} ref={inputRef} />
          <PinInputField borderColor={"neutralGrey"} mr={3} />
          <PinInputField borderColor={"neutralGrey"} mr={3} />
          <PinInputField borderColor={"neutralGrey"} mr={3} />
          <PinInputField borderColor={"neutralGrey"} mr={3} />
          <PinInputField borderColor={"neutralGrey"} mr={3} />
        </WDPinInput>
      </Flex>
    </Flex>
  );
};
