type ErrorDictionary = Record<string, string>;
export const ResponseDic: ErrorDictionary = {
  ROLE_DOES_NOT_EXIST: "The selected role doesn't exist",
  ERR_BAD_REQUEST: "Something went wrong",
  ERR_NO_JOB_SERVICE: "You need to have at least 1 job.",
  EMAIL: "Could not get the emails",
  ROLES: "Could not get the roles",
  NOTIFICATIONS: "Could not get the notifications",
  ONE_PROJECT_HAS_THIS_NAME_ALREADY: "One project has this name already",
  ONE_TASK_HAS_THIS_NAME_ALREADY: "One task has this name already",
  ONE_SERVICE_HAS_THIS_NAME_ALREADY: "One service has this name already",
  ONE_SERVER_HAS_THIS_NAME_ALREADY: "One server has this name already",
  FIRST_HEADER: "You cannot delete all headers",
  CURL_NOT_VALID: "Curl is not valid",
  JSON_NOT_VALID: "JSON is not valid",
  INVALID_IP: "Ip is not valid",
  CANNOT_INACTIVATE_YOURSELF: "You cannot deactivate yourself",
  CANNOT_MAKE_YOURSELF_USER: "You cannot change your role",
  USER_ALREADY_EXISTS: "User already exists",
  USER_NOT_FOUND: "User not found",
  NO_DESIGNER: "You need to have at least 1 designer",
  CLIENT_ALREADY_EXISTS: "Clients already exists",
  INVALID_TWO_FACTOR_PIN: "Invalid code for 2FA authentication",
  NO_RESET_2FA: "Cannot reset 2FA",
  PROCESS_ALREADY_EXIST: "Process already exists",
  FOLDER_ALREADY_EXIST: "Folder already exists",
  BAD_FOLDER_PATH: "Bad folder path",
  ONE_TEMPLATE_HAS_THIS_NAME_ALREADY:
    "A template with same name already exists",
  EMPTY_PROCESS_NAME: "Process name can't be empty",
  FOLDERS_NOT_ADDED: "Folders weren't added",
  FOLDERS_NOT_REMOVED: "Folders weren't removed",
  PROCESSES_NOT_ADDED: "Processes weren't added",
  PROCESSES_NOT_REMOVED: "Processed weren't removed",
  DISK_DATA_INCORRECT: "Disk data is incorrect",
  SERVER_HAS_NO_DISK_CONFIGURED: "Server has no disk configured",
  FOLDER_NOT_FOUND: "Folder not found",
  CORE_NOT_FOUND: "Core not found",
  RESOURCES_NOT_FOUND: "Resources not found",
  TEMPLATE_NOT_FOUND: "Template not found",
  THRESHOLDS_DATA_REQUIRED: "Thresholds data is required",
  PROVIDER_NOT_FOUND: "Provider not found",
  EXTERNAL_PROVIDER_DATA_REQUIRED: "External provider data required",
  ONLY_ONE_IP_ALLOWED: "Only one ip is allowed",
};
