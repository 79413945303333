import { Box, Flex, Text, BoxProps, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ReactComponent as Crown } from "../../Assets/IconsComponent/crown.svg";
import { ReactComponent as Flag } from "../../Assets/IconsComponent/flag.svg";
import { ReactComponent as Flag2 } from "../../Assets/IconsComponent/flag-2.svg";
import { ReactComponent as Cup } from "../../Assets/IconsComponent/cup.svg";
import { ReactComponent as EmojiHappy } from "../../Assets/IconsComponent/emoji-happy.svg";
import { ReactComponent as EmojiNormal } from "../../Assets/IconsComponent/emoji-normal.svg";
import { ReactComponent as EmojiSad } from "../../Assets/IconsComponent/emoji-sad.svg";
import { ReactComponent as TagCross } from "../../Assets/IconsComponent/tag-cross.svg";
import { ReactComponent as Menu } from "../../Assets/IconsComponent/menu.svg";
import { ReactComponent as CD } from "../../Assets/IconsComponent/cd.svg";
import { theme } from "../../theme";

interface WDIconComponentProps extends Omit<BoxProps, "onChange"> {
  onChange: (val: number) => void;
  value?: number;
}

export const WDIconComponent: React.FC<WDIconComponentProps> = (props) => {
  const { onChange, value, ...others } = props;

  const colorPrimary = useColorModeValue(
    `${theme.colors.blue[800]}`,
    `${theme.colors.blue[400]}`
  );
  const colorSecondary = useColorModeValue(
    `${theme.colors.neutralGrey}`,
    `${theme.colors.darkThemeGrey[100]}`
  );
  const [valueState, setValueState] = useState<number>(value ?? 0);
  const [didMount, setDidMount] = useState<boolean>(false);

  const iconsIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    if (valueState < 0 || valueState > iconsIndex.length) {
      setValueState(0);
    }

    setDidMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(valueState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueState]);

  const getColor = (val: number) => {
    return valueState === val ? colorPrimary : colorSecondary;
  };

  return didMount ? (
    <Box {...others}>
      <Text marginBottom={2}>Project's icon</Text>
      <Flex justify={"space-between"} marginRight={16}>
        {iconsIndex.map((index) => {
          return (
            <Box
              key={index}
              onClick={() => {
                setValueState(index);
              }}
            >
              {
                {
                  0: (
                    <Crown key={0} cursor="pointer" stroke={getColor(index)} />
                  ),
                  1: <Flag key={1} cursor="pointer" stroke={getColor(index)} />,
                  2: (
                    <Flag2 key={2} cursor="pointer" stroke={getColor(index)} />
                  ),
                  3: <Cup key={3} cursor="pointer" stroke={getColor(index)} />,
                  4: (
                    <EmojiHappy
                      key={4}
                      cursor="pointer"
                      stroke={getColor(index)}
                    />
                  ),
                  5: (
                    <EmojiNormal
                      key={5}
                      cursor="pointer"
                      stroke={getColor(index)}
                    />
                  ),
                  6: (
                    <EmojiSad
                      key={6}
                      cursor="pointer"
                      stroke={getColor(index)}
                    />
                  ),
                  7: (
                    <TagCross
                      key={7}
                      cursor="pointer"
                      stroke={getColor(index)}
                    />
                  ),
                  8: <Menu key={8} cursor="pointer" stroke={getColor(index)} />,
                  9: <CD key={9} cursor="pointer" stroke={getColor(index)} />,
                }[index]
              }
            </Box>
          );
        })}
      </Flex>
    </Box>
  ) : null;
};
