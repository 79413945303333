import React, { createContext } from "react";
import { TaskLogs } from "./TaskLogsComponent";
import { TasksLogsDataContextInterface } from "./types";
import { useTaskLogsData } from "./useTaskLogsData";

export const TaskLogsDataContext = createContext<TasksLogsDataContextInterface>(
  {} as TasksLogsDataContextInterface
);

export const TaskLogsPage: React.FC = () => {
  const taskLogsData = useTaskLogsData();
  return (
    <TaskLogsDataContext.Provider value={taskLogsData}>
      <TaskLogs />
    </TaskLogsDataContext.Provider>
  );
};
