import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorContext } from "../../../App";
import { SelectedInterval } from "../../Common/TimeIntervalWrapper";
import { apiClient, authorise } from "../../utils/apiClient";
import { ServerInterface } from "../ResourceOverview/types";
import { recordNameEnum, ResourceName } from "../ResourceOverview/utils";
import {
  GraphDetailsInterface,
  GraphOverviewInterface,
  ProjectResourceDetails,
  ResourceDetailsContextInterface,
} from "./types";

export const useResourceDetails = (): ResourceDetailsContextInterface => {
  const navigate = useNavigate();
  const { projectId, serverId, graphOverviewType } = useParams();
  const errorContext = useContext(ErrorContext);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState(0);

  const [project, setProject] = useState<ProjectResourceDetails>(
    {} as ProjectResourceDetails
  );

  const initializePage = async () => {
    var projectHeader: any;
    var graphOverview: GraphOverviewInterface = {} as GraphOverviewInterface;
    var graphsDetails: GraphDetailsInterface[] = [] as GraphDetailsInterface[];
    var cores: string[] = [];
    var folders: string[] = [];

    await apiClient
      .get(
        `/api/private/projects/resources-overview-header/${projectId}`,
        authorise()
      )
      .then((res) => {
        console.log(res.data);

        projectHeader = res.data;
        setTabIndex(
          res.data.servers.findIndex(
            (s: ServerInterface, i: number) => s.serverId === serverId
          )
        );
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
    await apiClient
      .get(
        `/api/resources/graphs/${graphOverviewType?.toLowerCase()}/${serverId}/1`,
        authorise()
      )
      .then((res) => {
        graphOverview = {
          id: graphOverviewType?.toLowerCase(),
          currentInterval: "24h",
          title: `${graphOverviewType} Overview`,
          ...res.data,
        };
        cores = res.data.cores;
        folders = res.data.folders;
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
    var graphDetailsResponses: any[] = [];
    if (cores)
      cores.map(async (c, i) => {
        graphDetailsResponses[i] = apiClient.get(
          `/api/resources/graphs/core/${serverId}/${c}/1`,
          authorise()
        );
      });

    if (folders)
      folders.map(async (f, i) => {
        graphDetailsResponses[i] = apiClient.post(
          "/api/resources/graphs/folder",
          {
            serverId: serverId,
            folderId: f,
            logPeriod: 1,
          },
          authorise()
        );
      });

    var processReponse;
    if (graphOverviewType?.toLowerCase() !== "disk")
      processReponse = apiClient.get(
        `/api/resources/graphs/processes-${graphOverviewType?.toLowerCase()}/${serverId}/1`,
        authorise()
      );
    console.log(graphDetailsResponses);

    await axios
      .all([processReponse, ...graphDetailsResponses])
      .then(
        axios.spread((...responses) => {
          console.log(responses);

          responses.forEach((r, i) => {
            console.log(r);

            if (i >= 1)
              graphsDetails.push({
                currentInterval: "24h",
                title: `${recordNameEnum[r.data.resourceName]} ${
                  !isNaN(parseInt(r.data.id) + 1)
                    ? parseInt(r.data.id) + 1
                    : r.data.id
                }`,
                ...r.data,
              });

            setProject({
              ...projectHeader,
              graphOverview: graphOverview,
              graphProcess:
                graphOverviewType !== "Disk"
                  ? {
                      currentInterval: "24h",
                      id: "cpu",
                      lineChart: responses[0].data,
                    }
                  : undefined,
              graphsDetails: graphsDetails.sort((a, b) => {
                return parseInt(a.id) - parseInt(b.id);
              }),
            });
            setLoadingPage(false);
          });
        })
      )
      .catch((errors) => {
        console.log(errors);

        // errorContext.createError([...errors.response.data]); //in caz de 404
      });
  };

  const onIntervalChangeOverview = async (
    id: ResourceName | string,
    val: SelectedInterval
  ) => {
    const interval = val === "24h" ? "1" : val === "7d" ? "7" : "30";

    await apiClient
      .get(
        `/api/resources/graphs/${graphOverviewType?.toLowerCase()}/${serverId}/${interval}`,
        authorise()
      )
      .then((res) => {
        setProject({
          ...project,
          graphOverview: {
            ...project.graphOverview,
            currentInterval: val,
            areaChart: res.data.areaChart,
          },
        });
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
  };
  const onIntervalChangeDetails = async (id: string, val: SelectedInterval) => {
    if (graphOverviewType === "CPU") {
      const interval = val === "24h" ? "1" : val === "7d" ? "7" : "30";

      await apiClient
        .get(
          `/api/resources/graphs/core/${serverId}/${id}/${interval}`,
          authorise()
        )
        .then((res) => {
          setProject({
            ...project,
            graphsDetails: project.graphsDetails.map((g) => {
              if (g.id === id) {
                return {
                  ...g,
                  currentInterval: val,
                  areaChart: res.data.areaChart,
                };
              } else return g;
            }),
          });
        })
        .catch((err) => {
          errorContext.createError(err.response.data);
        });
    }
  };
  const onIntervalChangeProcesses = async (
    id: ResourceName,
    val: SelectedInterval
  ) => {
    const interval = val === "24h" ? "1" : val === "7d" ? "7" : "30";

    await apiClient
      .get(
        `/api/resources/graphs/processes-${id.toLowerCase()}/${serverId}/${interval}`,
        authorise()
      )
      .then((res) => {
        setProject({
          ...project,
          graphProcess: { id: id, title: "Process", processChart: res.data },
        });
      })

      .catch((err) => {
        errorContext.createError(err.response.data);
      });
  };
  const onTabChange = (index: number) => {
    setTabIndex(index);
    if (project.servers[index].isConfigured)
      navigate(
        `../dashboard/projects/${projectId}/resources/${project.servers[index].serverId}`
      );
    else navigate(`../dashboard/projects/${projectId}`);
  };

  return {
    onIntervalChangeProcesses,
    onIntervalChangeOverview,
    onIntervalChangeDetails,
    initializePage,
    project,
    tabIndex,
    onTabChange,
    loadingPage,
  };
};
