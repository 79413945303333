import {
  AccordionItem,
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { ErrorContext, UserContext } from "../../App";
import { ReactComponent as UsersButtonIcon } from "../../Assets/Users/UsersButtonIcon.svg";
import { theme } from "../../theme";
import {
  ProjectInterface,
  ServerInterface,
  ServiceInterface,
  TaskInterface,
} from "../Common/types";
import { WDConfirmDelete } from "../Common/WDConfirmDelete";
import { optionTaskRequestMethod } from "../CreateTaskModal/types";
import { WDTaskModal } from "../CreateTaskModal/WDTaskModal";
import { OnlineStatus } from "../ProjectsComponent/OnlineStatus/OnlineStatus";
import { apiClient, authorise } from "../utils/apiClient";
import { ConfirmDisableModal } from "./ConfirmDisableModal";
import { TaskDetails } from "./TaskDetails";

interface TaskComponentProps {
  task: TaskInterface;
  service: ServiceInterface;
  currentProject: ProjectInterface;
  currentServer: ServerInterface;
  setCurrentProject: (currentProject: ProjectInterface) => void;
}

export const TaskComponent: React.FC<TaskComponentProps> = ({
  task,
  service,
  currentProject,
  currentServer,
  setCurrentProject,
}) => {
  const userContext = useContext(UserContext);
  const errorContext = useContext(ErrorContext);
  const handleDeleteTask = (id: string) => {
    apiClient
      .delete(`/api/tasks/delete/${id}`, authorise())
      .then((res) => {
        setCurrentProject({
          ...currentProject,
          servers: [
            ...currentProject.servers.map((ser) => {
              if (ser.serverId === currentServer.serverId)
                return {
                  ...currentServer,
                  services: [
                    ...currentServer.services.map((services) => {
                      if (services.serviceId === service.serviceId)
                        return {
                          ...service,
                          tasks: [
                            ...service.tasks.filter((t) => t.id !== task.id),
                          ],
                        };
                      else return services;
                    }),
                  ],
                };
              else return ser;
            }),
          ],
        });
        errorContext.createToast("You have deleted the task succesufully");
      })
      .catch((err) => errorContext.createError(err.response.data));
  };
  const colorBg = useColorModeValue("white", "darkThemeGrey.600");

  const handleDisableTask = () => {
    apiClient
      .put(`/api/tasks/switch-active/${task.id}`, {}, authorise())
      .then((res) => {
        console.log(res.data);

        setCurrentProject({
          ...currentProject,
          servers: currentProject.servers.map((s) => {
            if (s.serverId === currentServer.serverId) {
              return {
                ...s,
                services: currentServer.services.map((se) => {
                  if (se.serviceId === service.serviceId)
                    return {
                      ...service,
                      tasks: service.tasks.map((tsk) => {
                        if (tsk.id === task.id) {
                          return res.data;
                        } else {
                          return tsk;
                        }
                      }),
                    };
                  else return se;
                }),
              };
            } else {
              return s;
            }
          }),
        });
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
      });
  };

  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.blue[400]}`
  );
  return (
    <AccordionItem
      key={task.id}
      marginBottom={5}
      borderWidth="0 0 1px 0"
      py={2}
    >
      <Flex>
        <Grid width={"100%"} templateColumns="repeat(4, 1fr)" gap={4}>
          <GridItem w="100%">
            <TaskDetails title="TASK NAME" content={task.name} />
          </GridItem>

          <GridItem w="100%">
            <TaskDetails
              title="TYPE"
              content={task.taskType === 0 ? "Generic" : "WebApi"}
            />
          </GridItem>

          <GridItem w="100%">
            <TaskDetails
              title={task.taskType !== 0 ? "URL" : "REQUEST"}
              content={task.taskType !== 0 ? task.route : task.requestBody}
            />
          </GridItem>

          <GridItem w="100%">
            <TaskDetails
              title={task.taskType !== 0 ? "METHOD" : "RESPONSE"}
              content={
                task.taskType !== 0
                  ? optionTaskRequestMethod.filter((opt) => {
                      return opt.value === task.httpMethods?.toString();
                    })[0]?.display
                  : task.expectedResponseBody
              }
            />
          </GridItem>
        </Grid>

        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          marginLeft={10}
        >
          <OnlineStatus
            status={
              service.status === 40 || currentServer.status === 40
                ? 40
                : task.status
            }
            type={4}
          />

          {currentProject.projectRole?.toLowerCase() === "designer" ||
          userContext.user.roleName === 'Admin' ? (
            <Box marginLeft={3}>
              <Menu>
                <MenuButton
                  aria-expanded={true}
                  as={IconButton}
                  aria-label="Options"
                  icon={<UsersButtonIcon width="30px" stroke={colorStroke} />}
                  variant="outline"
                  border="none"
                  height="30px"
                />
                <MenuList bg={colorBg}>
                  <WDTaskModal
                    idTask={task.id}
                    currentProject={currentProject}
                    setCurrentProject={setCurrentProject}
                    currentServer={currentServer}
                    currentService={service}
                  />
                  <ConfirmDisableModal
                    disable={service.status === 40}
                    state={!(task.status === 40)}
                    toBeDisabled={task.name}
                    id={task.id}
                    handleDisable={() => {
                      handleDisableTask();
                    }}
                  >
                    <MenuItem
                      cursor={service.status === 40 ? "not-allowed" : "pointer"}
                      _hover={{
                        backgroundColor: `${"auto"}`,
                      }}
                    >
                      {!(task.status === 40) ? "Disable" : "Enable"} task
                    </MenuItem>
                  </ConfirmDisableModal>
                  <WDConfirmDelete handleDelete={handleDeleteTask} id={task.id}>
                    <MenuItem color="danger.400">Delete Task</MenuItem>
                  </WDConfirmDelete>
                </MenuList>
              </Menu>
            </Box>
          ) : null}
        </Flex>
      </Flex>
    </AccordionItem>
  );
};
