import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion, Variants } from "framer-motion";
import React, { useContext } from "react";
import { ErrorContext } from "../../App";
import { ReactComponent as UsersButtonIcon } from "../../Assets/Users/UsersButtonIcon.svg";
import { theme } from "../../theme";
import { apiClient, authorise } from "../utils/apiClient";
import { PopoverProjectsDetails } from "./PopoverProjectsDetails";
import { UserInterface } from "./types";
interface TableRowProps {
  index: number;
  user: UserInterface;
  allUsers: UserInterface[];
  setUsers: (user: UserInterface[]) => void;
  filteredUsers: UserInterface[];
  setFilteredUsers: (user: UserInterface[]) => void;
  handleActivateUserClick: (id: string) => void;
  handleChangeUserRoleClick: (id: string) => void;
}

export const TableRow: React.FC<TableRowProps> = ({
  index,
  user,
  handleActivateUserClick,
  handleChangeUserRoleClick,
  setUsers,
  allUsers,
  setFilteredUsers,
  filteredUsers,
}) => {
  const colorTextSecondary = useColorModeValue("blue.700", "darkThemeGrey.100");
  const colorOnline = useColorModeValue("green.600", "green.600");
  const errorContext = useContext(ErrorContext);
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.blue[400]}`
  );
  const menuListColor = useColorModeValue("white", "darkThemeGrey.600");
  const rowVariants: Variants = {
    offscreen: {
      y: 100,
    },
    onscreen: {
      y: 0,

      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };
  if (user.firstName === "Sebastian") console.log(user.id);

  const reset2FA = async () => {
    await apiClient
      .put(`/api/admin/disable-tfa?userId=${user.id}`, {}, authorise())
      .then((res) => {
        console.log(res);

        setFilteredUsers([
          ...filteredUsers.map((us) => {
            if (us.id === user.id)
              return {
                ...user,
                hasTwoFactorAuth: false,
              };
            else return us;
          }),
        ]);
        errorContext.createToast("2FA reseted successfully");
      })
      .catch((err) => {
        console.log(err.response);
        debugger;
        errorContext.createError(["NO_2FA_RESET"]);
      });
  };
  const MotionTr = motion(Tr);
  return (
    <MotionTr
      fontWeight="600"
      initial={index <= 7 ? "onscreen" : "offscreen"}
      variants={rowVariants}
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      <Td>
        <Flex>
          <Avatar
            size="sm"
            name={`${user.firstName} ${user.lastName}`}
            mr={2}
            mt={1}
            src={user.profilePictureUrl ?? ""}
          />
          <Flex direction={"column"}>
            <Box
              color={colorTextSecondary}
            >{`${user.firstName} ${user.lastName}`}</Box>
            <Box fontWeight="normal" color="neutralGrey" fontSize="sm">
              {user.email}
            </Box>
          </Flex>
        </Flex>
      </Td>
      <Td color="neutralGrey">{user.roleName}</Td>
      <Td color="neutralGrey">
        <PopoverProjectsDetails
          numberOfDesigners={user.projects.designer}
          numberOfWatchers={user.projects.watcher}
          designer={user.projectDetails.designer}
          watcher={user.projectDetails.watcher}
        />
      </Td>
      <Td textColor={"white"}>
        {user.hasTwoFactorAuth ? (
          <Flex
            borderRadius={"16px"}
            p={1}
            w={"96px"}
            fontSize={15}
            bgColor={colorOnline}
            justifyContent="center"
          >
            <Text>● Active</Text>
          </Flex>
        ) : (
          <Flex
            borderRadius={"16px"}
            fontSize={15}
            p={1}
            w={"96px"}
            bgColor={"danger.500"}
            justifyContent="center"
          >
            <Text>● Inactive</Text>
          </Flex>
        )}
      </Td>
      <Td textColor="white">
        {user.isActive ? (
          <Flex
            borderRadius={"16px"}
            fontSize={15}
            p={1}
            w={"96px"}
            bgColor={colorOnline}
            justifyContent="center"
          >
            <Text>● Active</Text>
          </Flex>
        ) : (
          <Flex
            borderRadius={"16px"}
            fontSize={15}
            p={1}
            w={"96px"}
            bgColor={"danger.500"}
            justifyContent="center"
          >
            <Text>● Inactive</Text>
          </Flex>
        )}
      </Td>
      <Td>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<UsersButtonIcon stroke={colorStroke} />}
            variant="outline"
          />
          <MenuList bg={menuListColor}>
            {user.isActive ? (
              <MenuItem onClick={() => handleActivateUserClick(user.id)}>
                Deactivate user
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleActivateUserClick(user.id)}>
                Activate user
              </MenuItem>
            )}
            {user.roleName === "Admin" ? (
              <MenuItem onClick={() => handleChangeUserRoleClick(user.id)}>
                Change admin in user
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleChangeUserRoleClick(user.id)}>
                Change user in admin
              </MenuItem>
            )}
            {user.hasTwoFactorAuth ? (
              <MenuItem
                onClick={() => {
                  reset2FA();
                }}
              >
                Reset 2FA
              </MenuItem>
            ) : null}
          </MenuList>
        </Menu>
      </Td>
    </MotionTr>
  );
};
