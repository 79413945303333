import {
  Box,
  Flex,
  Grid,
  GridItem,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LogsDataContext } from ".";
import { OnlineStatus } from "../ProjectsComponent/OnlineStatus/OnlineStatus";
import { LogGridItem } from "./LogGridItem";

export const TabsPage: React.FC = () => {
  const logsContext = useContext(LogsDataContext);
  const navigate = useNavigate();

  const tabName =
    logsContext.tabIndex === 0
      ? "last-24h"
      : logsContext.tabIndex === 1
      ? "last-week"
      : "last-month";
  const handleTaskClick = (taskId: string) => {
    navigate(
      "/dashboard/" +
        logsContext.projectAndServerDetails.projectId +
        "/" +
        logsContext.projectAndServerDetails.serverId +
        "/" +
        taskId +
        "/logs/" +
        tabName
    );
  };
  const colorBgMain = useColorModeValue("gray.100", "darkThemeGrey.600");
  const colorBgSecondary = useColorModeValue("gray.200", "darkThemeGrey.500");
  const colorTextPrimary = useColorModeValue("grey.700", "darkThemeGrey.100");
  const colorTextSecondary = useColorModeValue("gray.500", "darkThemeGrey.200");
  const borderColor = useColorModeValue("neutralGrey", "darkThemeGrey.100");
  return logsContext.loadingLogs ? (
    <Flex h="50vh" justifyContent={"center"} alignItems={"center"}>
      <Spinner
        size="xl"
        color="blue.500"
        thickness="4px"
        speed="0.65s"
        emptyColor="blue.100"
      />
    </Flex>
  ) : (
    <Box minW="600px" my={4}>
      <Grid
        templateColumns="20% repeat(2, 40%)"
        border="1px solid"
        px={4}
        py={3}
        borderColor={borderColor}
        bg={colorBgMain}
        textColor={colorTextPrimary}
        borderRadius={"8px 8px 0px 0px"}
      >
        <GridItem w="100%" h="100%" fontSize="small" fontWeight={"semibold"}>
          NAME
        </GridItem>

        <GridItem w="100%" h="100%" fontSize="small" fontWeight={"semibold"}>
          {logsContext.timeFrame.start}
        </GridItem>

        <GridItem
          w="100%"
          h="100%"
          fontSize="small"
          fontWeight={"semibold"}
          textAlign="end"
        >
          {logsContext.timeFrame.end}
        </GridItem>
      </Grid>

      <Grid
        templateColumns={`20% repeat(${logsContext.numberOfCells},1fr)`}
        px={4}
        py={3}
        border="1px solid"
        bg={colorBgMain}
        textColor={colorTextPrimary}
        borderColor={borderColor}
        h="64px"
      >
        <GridItem
          w="100%"
          h="100%"
          fontSize="small"
          fontWeight={"semibold"}
          display={"flex"}
          justifyContent={"center"}
        >
          <Flex w="100%" justifyContent="center" alignItems="center">
            <Box w="10%" mr={1}>
              {
                <OnlineStatus
                  status={logsContext.projectAndServerDetails.status}
                  type={3}
                />
              }
            </Box>
            <Box w="90%">{logsContext.projectAndServerDetails.serverName}</Box>
          </Flex>
        </GridItem>

        {logsContext
          .getLogBar(
            logsContext.projectAndServerDetails.downtime,
            logsContext.projectAndServerDetails.disabled,
            [],
            logsContext.projectAndServerDetails.deploy,
            logsContext.projectAndServerDetails.unknown
          )
          .slice(
            0,
            logsContext.getLogBar(
              logsContext.projectAndServerDetails.downtime,
              logsContext.projectAndServerDetails.disabled,
              [],
              logsContext.projectAndServerDetails.deploy,
              logsContext.projectAndServerDetails.unknown
            ).length
          )
          ?.map((el, index) => {
            return (
              <LogGridItem
                entityId={logsContext.projectAndServerDetails.serverId}
                key={index}
                index={index}
                startGrid={el.start}
                endGrid={el.end}
                bg={el.color}
                height={"17px"}
                colSpan={el.colSpan}
                lengthOfArrayWithColorAndColSpan={
                  logsContext.getLogBar(
                    logsContext.projectAndServerDetails.downtime,
                    logsContext.projectAndServerDetails.disabled,
                    [],
                    logsContext.projectAndServerDetails.deploy,
                    logsContext.projectAndServerDetails.unknown
                  ).length
                }
              ></LogGridItem>
            );
          })}
      </Grid>
      {logsContext.projectAndServerDetails.services?.map((service) => {
        return (
          <Box key={service.serviceId}>
            <Grid
              templateColumns={`20% repeat(${logsContext.numberOfCells},1fr)`}
              px={4}
              py={3}
              border="1px solid"
              bg={colorBgMain}
              textColor={colorTextPrimary}
              borderColor={borderColor}
              h="64px"
            >
              <GridItem
                w="100%"
                h="100%"
                fontSize="small"
                fontWeight={"semibold"}
                px="4"
                display={"flex"}
                justifyContent={"center"}
              >
                <Flex w="100%" justifyContent="center" alignItems="center">
                  <Box w="10%" mr={2}>
                    {<OnlineStatus type={3} status={service.status} />}
                  </Box>
                  <Box w="90%">{service.serviceName}</Box>
                </Flex>
              </GridItem>

              {logsContext
                .getLogBar(
                  service.downtime,
                  service.disabled,
                  [],
                  service.deploy,
                  service.unknown
                )
                .slice(
                  0,
                  logsContext.getLogBar(
                    service.downtime,
                    service.disabled,
                    [],
                    service.deploy,
                    service.unknown
                  ).length
                )
                ?.map((el, index) => {
                  return (
                    <LogGridItem
                      entityId={service.serviceId}
                      key={index}
                      index={index}
                      startGrid={el.start}
                      endGrid={el.end}
                      bg={el.color}
                      height={"12px"}
                      colSpan={el.colSpan}
                      lengthOfArrayWithColorAndColSpan={
                        logsContext.getLogBar(
                          service.downtime,
                          service.disabled,
                          [],
                          service.deploy,
                          service.unknown
                        ).length
                      }
                    ></LogGridItem>
                  );
                })}
            </Grid>

            {service.tasks?.map((task) => (
              <Grid
                key={task.taskId}
                templateColumns={`20% repeat(${logsContext.numberOfCells},1fr)`}
                px={4}
                py={3}
                border="1px solid"
                onClick={() => {
                  handleTaskClick(task.taskId);
                }}
                bg={colorBgSecondary}
                cursor="pointer"
                borderColor={borderColor}
                h="64px"
              >
                <GridItem
                  w="100%"
                  h="100%"
                  fontSize="small"
                  fontWeight={"semibold"}
                  textColor={colorTextSecondary}
                  px="8"
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Flex w="100%" justifyContent="center" alignItems="center">
                    <Box w="10%" mr={2}>
                      {<OnlineStatus type={3} status={task.status} />}
                    </Box>
                    <Box w="90%">{task.taskName}</Box>
                  </Flex>
                </GridItem>
                {logsContext
                  .getLogBar(
                    task.downtime,
                    task.disabled,
                    task.unexpectedBehaviour,
                    [],
                    task.unknown
                  )
                  .slice(
                    0,
                    logsContext.getLogBar(
                      task.downtime,
                      task.disabled,
                      task.unexpectedBehaviour,
                      [],
                      task.unknown
                    ).length
                  )
                  ?.map((el, index) => {
                    return (
                      <LogGridItem
                        entityId={task.taskId}
                        key={index}
                        startGrid={el.start}
                        endGrid={el.end}
                        index={index}
                        bg={el.color}
                        height={"9px"}
                        colSpan={el.colSpan}
                        cursor="pointer"
                        lengthOfArrayWithColorAndColSpan={
                          logsContext.getLogBar(
                            task.downtime,
                            task.disabled,
                            task.unexpectedBehaviour,
                            [],
                            task.unknown
                          ).length
                        }
                      ></LogGridItem>
                    );
                  })}
              </Grid>
            ))}
          </Box>
        );
      })}
    </Box>
  );
};
