import {
  requiredField,
  validateIPv4Address,
  validatorNumericField,
} from "../../../Utils/ValidatorsForInput";
import { ModalServiceErrorsInterface, ModalServiceInterface } from "../type";
import { defaultErrorsDataModal } from "./DefaultObject";

export const checkInfoForTheFirstPage = (
  dataModal: ModalServiceInterface,
  setErrorsData: (errorsData: ModalServiceErrorsInterface) => void
) => {
  let errorCatcher: ModalServiceErrorsInterface = defaultErrorsDataModal;

  errorCatcher = {
    ...errorCatcher,
    errorName: requiredField(dataModal.name.replaceAll(" ", "")),
    errorIpV4:
      requiredField(dataModal.ipV4.replaceAll(" ", "")) ||
      validateIPv4Address(dataModal.ipV4)
        ? ""
        : "This ip isn't valid.",

    errorPort:
      requiredField(dataModal.port.replaceAll(" ", "")) ||
      validatorNumericField(dataModal.port) ||
      (parseInt(dataModal.port) > 65535
        ? "Port number can't be bigger than 65535"
        : ""),
    errorType: requiredField(dataModal.type.toString()),
  };
  setErrorsData({ ...errorCatcher });
  return errorCatcher;
};
export const checkInfoForJobPage = (
  dataModal: ModalServiceInterface,
  setErrorsData: (errorsData: ModalServiceErrorsInterface) => void
) => {
  let errorCatcher: ModalServiceErrorsInterface = defaultErrorsDataModal;

  errorCatcher = {
    ...errorCatcher,

    ...defaultErrorsDataModal,
    errorDate: requiredField(dataModal.startDate),
    errorNotificationType:
      dataModal.minimumNotificationLevel === 5 ? "Please select an option" : "",
    errorTimeout:
      requiredField(dataModal.timeout.toString().replaceAll(" ", "")) ||
      validatorNumericField(dataModal.timeout.toString()) ||
      dataModal.timeout === 0
        ? "Timeout cannot be 0"
        : "",
    errorReccuringTime:
      requiredField(dataModal.reccuringTime.toString().replaceAll(" ", "")) ||
      validatorNumericField(dataModal.reccuringTime.toString()) ||
      dataModal.reccuringTime === 0
        ? "Reccuring time cannot be 0"
        : "",
  };

  setErrorsData(errorCatcher);
  return errorCatcher;
};
export const checkErrors = (
  errorsData: ModalServiceErrorsInterface,
  step: number
) => {
  if (step === 0) {
    if (errorsData.errorIpV4 !== "") return false;

    if (errorsData.errorIpV6 !== "") return false;
    if (errorsData.errorName !== "") return false;
    if (errorsData.errorPort !== "") return false;
    if (errorsData.errorType !== "") return false;
  } else {
    if (errorsData?.errorNotificationType !== "") return false;
    if (errorsData?.errorTimeout !== "") return false;

    if (errorsData?.errorReccuringTime !== "") return false;
  }
  return true;
};
