import { MenuItem, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ModalServiceContext } from "../WDServiceModal";
export const ButtonEditServices: React.FC = () => {
  const { onOpenEdit } = useContext(ModalServiceContext);
  const handleEditService = () => {
    onOpenEdit();
  };
  return (
    <MenuItem
      onClick={handleEditService}
      bg={useColorModeValue("white", "darkThemeGrey.600")}
      _hover={{ bg: useColorModeValue("blue.100", "darkThemeGrey.500") }}
    >
      Edit service
    </MenuItem>
  );
};
