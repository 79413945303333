import { Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { UserContext } from "../../../App";
import { ReactComponent as LogoutIcon } from "../../../Assets/Drawer-NavBar/LogoutIcon.svg";
import { theme } from "../../../theme";
export const Logout: React.FC = () => {
  const userContext = useContext(UserContext);
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.darkThemeGrey[100]}`
  );
  return (
    <Flex
      flexDirection="column"
      align="start"
      onClick={userContext?.logOut}
      pb={3}
      bg={useColorModeValue("blue.100", "darkThemeGrey.600")}
    >
      <Icon
        as={LogoutIcon}
        width="100%"
        stroke={colorStroke}
        fontFamily="Montserrat"
        boxSize={6}
      ></Icon>
    </Flex>
  );
};
