import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { TableRow } from "./TableRow";
import { ThresholdTemplateInterface } from "./types";

interface ThresholdTemplatesTableProps {
  templates: ThresholdTemplateInterface[];
  editTemplate: (val: ThresholdTemplateInterface[]) => void;
}

export const ThresholdTemplatesTable: React.FC<
  ThresholdTemplatesTableProps
> = ({ templates, editTemplate }) => {
  const colorPrimary = useColorModeValue("blue.100", "darkThemeGrey.500");
  const colorTextPrimary = useColorModeValue(
    "neutralDarkGrey",
    "darkThemeGrey.100"
  );
  return (
    <TableContainer h="100%" w="100%" overflowY="auto">
      <Table variant="simple">
        <Thead
          borderRadius="4px"
          bgColor={colorPrimary}
          top="0"
          left="0"
          position="sticky"
          zIndex={2}
        >
          <Tr>
            <Th fontSize="13px" color={colorTextPrimary}>
              NAME
            </Th>
            <Th width={10} fontSize="13px" color={colorTextPrimary}>
              EDIT
            </Th>
            <Th width={10} fontSize="13px" color={colorTextPrimary}>
              DELETE
            </Th>
          </Tr>
        </Thead>
        <Tbody minH="fit-content">
          {templates.map((t, i) => {
            return (
              <React.Fragment key={t.name}>
                <TableRow
                  allTemplates={templates}
                  template={t}
                  index={i}
                  editTemplate={editTemplate}
                />
              </React.Fragment>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
