import React from "react";
import { ProjectUsersInterface } from "./types";

import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  VStack,
  Box,
  Spacer,
  Badge,
  Text,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { theme } from "../../theme";

interface UsersPopOverProps {
  projectUsers: ProjectUsersInterface[];
}

export const UsersPopOver: React.FC<UsersPopOverProps> = ({ projectUsers }) => {
  const neutralGrey = theme.colors.neutralGrey;
  return (
    <Popover>
      <PopoverTrigger>
        <AvatarGroup size={"sm"} max={4} cursor="pointer">
          {projectUsers.map((user) => {
            return (
              <Avatar
                key={user.userId}
                name={user.firstName + " " + user.lastName}
              >
                {user.roleName === "Designer" && (
                  <AvatarBadge boxSize="1em" bg="green.500" />
                )}
              </Avatar>
            );
          })}
        </AvatarGroup>
      </PopoverTrigger>
      <PopoverContent
        boxShadow={`0 0 5px 0 ${neutralGrey}`}
        minW="fit-content"
        maxH={380}
        bg={useColorModeValue("white", "darkThemeGrey.600")}
        overflowY="hidden"
      >
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader
          fontWeight={600}
          textAlign="center"
          color={useColorModeValue("blue.700", "darkThemeGrey.100")}
        >
          Project's Users
        </PopoverHeader>
        <PopoverBody overflowY={"auto"} h={"fit-content"}>
          <VStack
            minW="fit-content"
            fontWeight={400}
            justifyContent="flex-start"
            alignContent="flex-start"
            color={useColorModeValue("neutralDarkGrey", "darkThemeGrey.100")}
            pr={2}
          >
            <>
              {projectUsers
                .filter((user) => {
                  return user.roleName === "Designer";
                })
                .map((user, i) => {
                  return (
                    <Flex w="280px" key={i} align="center">
                      <Box
                        key={user.userId}
                        textAlign="left"
                        w="100%"
                        h="100%"
                        minW="fit-content"
                      >
                        <Text fontSize={16}>
                          {user.firstName + " " + user.lastName}
                        </Text>
                      </Box>
                      <Spacer />
                      <Badge
                        colorScheme={"red"}
                        fontSize={9}
                        ml={2}
                        minW="fit-content"
                      >
                        Designer
                      </Badge>
                    </Flex>
                  );
                })}
            </>

            <>
              {projectUsers
                .filter((user) => {
                  return user.roleName === "Watcher";
                })
                .map((user, i) => {
                  return (
                    <Flex
                      w="280px"
                      justify={"flex-start"}
                      key={i + user.userId}
                      align="center"
                    >
                      <Box
                        key={user.userId}
                        textAlign="left"
                        w="100%"
                        minW="fit-content"
                      >
                        <Text fontSize={16}>
                          {user.firstName + " " + user.lastName}
                        </Text>
                      </Box>
                      <Badge colorScheme={"blue"} fontSize={9} ml={2}>
                        Watcher
                      </Badge>
                    </Flex>
                  );
                })}
            </>
            <>
              {projectUsers
                .filter((user) => {
                  return user.roleName === "Client";
                })
                .map((user, i) => {
                  return (
                    <Flex
                      w="100%"
                      justify={"flex-start"}
                      key={i + user.userId}
                      align="center"
                    >
                      <Box
                        key={user.userId}
                        textAlign="left"
                        w="100%"
                        minW="fit-content"
                      >
                        <Text fontSize={20}>
                          {user.firstName + " " + user.lastName}
                        </Text>
                      </Box>
                      <Badge colorScheme={"green"} fontSize={8} ml={2}>
                        Client
                      </Badge>
                    </Flex>
                  );
                })}
            </>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
