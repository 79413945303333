import {
  Text,
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  useDisclosure,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";

interface ConfirmDisableModalProps {
  id: string;
  handleDisable: (val: string) => void;
  children: ReactNode;
  toBeDisabled: string;
  state: boolean;
  disable?: boolean;
}

export const ConfirmDisableModal: React.FC<ConfirmDisableModalProps> = ({
  id,
  handleDisable,
  children,
  toBeDisabled,
  state,
  disable,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const openModal = () => {
    setLoading(false);
    onOpen();
  };
  const handleDisableButton = () => {
    onClose();
    setLoading(true);
    handleDisable(id);
  };
  const colorButton = useColorModeValue("white", "darkThemeGrey.600");
  const colorButtonHover = useColorModeValue("blue.100", "darkThemeGrey.500");
  const colorBody = useColorModeValue("white", "darkThemeGrey.600");
  return (
    <Box w="100%">
      <Flex
        onClick={!disable ? openModal : undefined}
        w="100%"
        textAlign={"left"}
        h="100%"
        alignItems={"flex-start"}
        bg={colorButton}
        _hover={{ bg: colorButtonHover }}
      >
        {children}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent bg={colorBody}>
          <ModalBody>
            <VStack w="100%">
              <VStack w="100%">
                <Box
                  textAlign={"left"}
                  fontSize={20}
                  pt={4}
                  w="100%"
                  fontWeight={600}
                >
                  {state ? "Disable" : "Enable"} {toBeDisabled} ?
                </Box>
                <Flex w="100%">
                  {" "}
                  Are you sure you want to &nbsp;
                  {state && (
                    <Text color={"danger.500"}> temporary disable &nbsp;</Text>
                  )}
                  {!state && <Text color={"green.500"}> enable &nbsp;</Text>}
                  this?
                </Flex>
              </VStack>
              <HStack w="100%" pt={4}>
                <Spacer />
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  isLoading={loading ?? false}
                  backgroundColor={"danger.600"}
                  _hover={{ backgroundColor: "danger.700" }}
                  color={"white"}
                  onClick={() => {
                    handleDisableButton();
                  }}
                  autoFocus
                >
                  Yes!
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
