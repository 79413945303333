import { Box, HStack, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { SelectOptionsInterface } from "../Common/types";
import { WDDropdown } from "../Common/WDDropdown";
import { WDInput } from "../Common/WDInput";
import { ModalTaskContext } from "./WDTaskModal";
export const DisabledInput: React.FC = () => {
  const { modalData, changeData, currentInfo, step } =
    useContext(ModalTaskContext);
  const optionsType: SelectOptionsInterface[] = [
    { value: "0", display: "Generic Task", color: "black" },
    { value: "10", display: "Web API Task", color: "black" },
  ];
  const handleTaskType = (value: string) => {
    changeData({ taskType: value }, {});
  };
  return (
    <HStack w="100%" h="100%">
      <Box w="100%" h="100%">
        <Text>Server Name</Text>
        <WDInput
          value={currentInfo.currentServer.name}
          isDisabled={true}
          placeholder={"ASDA"}
          _disabled={{ bgColor: "blue.100", color: "black" }}
        />
      </Box>
      <Box w="100%" h="100%">
        <Text>Service Name</Text>
        <WDInput
          value={currentInfo.currentService.name}
          isDisabled={true}
          placeholder={"ertq"}
          _disabled={{ bgColor: "blue.100", color: "black" }}
          focusBorderColor="blue.500"
        />
      </Box>
      {currentInfo.idTask ? (
        <>
          {step === 0 ? (
            <Box w="100%" h="100%">
              <Text>Task Type</Text>
              <WDInput
                value={
                  modalData.taskType === "0" ? "Generic Task" : "Web API Task"
                }
                isDisabled={true}
                color="black"
                placeholder={"ertq"}
                _disabled={{ bgColor: "blue.100" }}
                focusBorderColor="blue.500"
              />
            </Box>
          ) : null}
        </>
      ) : (
        <Box w="100%" h="100%">
          <Text> Task Type</Text>
          <WDDropdown
            options={optionsType}
            onChange={handleTaskType}
            value={modalData.taskType}
          />
        </Box>
      )}
    </HStack>
  );
};
