import { Box, Text } from "@chakra-ui/react";
import React from "react";

interface ServiceDetailsProps {
  title: string;
  content: string;
}

export const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  title,
  content,
}) => {
  return (
    <Box
      textAlign={
        title === "PORT" || title === "TASKS RUNNING" ? "right" : "left"
      }
      marginRight={
        title === "PORT" || title === "TASKS RUNNING" ? "20px" : "0px"
      }
    >
      <Text fontWeight="600" color="neutralGrey" fontSize={10}>
        {title}
      </Text>
      <Text
        fontWeight={title === "SERVICE NAME" ? "700" : "500"}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        maxW="200px"
      >
        {content}
      </Text>
    </Box>
  );
};
