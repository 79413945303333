import { Flex } from "@chakra-ui/react";
import React from "react";
import { SelectOptionsInterface } from "../Common/types";

import { ModalTypesInterface, ProjectsEmailInterface } from "./types";
import { UserInput } from "./UserInput";

interface StepTwoPageProps {
  changeModalDataHandler: (val: ModalTypesInterface) => void;
  values: ModalTypesInterface;
  onDelete: (val: number) => void;
  users: ProjectsEmailInterface[];
  roles: SelectOptionsInterface[];
}

export const StepTwoPage: React.FC<StepTwoPageProps> = ({
  changeModalDataHandler,
  values,
  onDelete,
  users,
  roles,
}) => {
  return (
    <Flex direction={"column"} h="100%">
      {values.users.map((user, index) => {
        return (
          <UserInput
            roles={roles}
            users={users}
            currentUserName={
              users.filter((currUser) => currUser.value === user.id)[0]
                ?.label || ""
            }
            initialData={values}
            key={index + "-" + values.users.length}
            index={index}
            onChange={changeModalDataHandler}
            onDelete={() => {
              onDelete(index);
            }}
            value={user}
            disabled={index === 0 ? true : false}
          />
        );
      })}
    </Flex>
  );
};
