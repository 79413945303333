import { Table, TableContainer } from "@chakra-ui/react";
import React from "react";
import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";
import { ProjectInterface, UserProject } from "./typesForProjects";
interface ProjectsTableDataProps {
  projectsToShow: ProjectInterface[] | undefined;
  deleteProjects: (id: string) => void;
  onSuccesManageUser: (val: UserProject[], id: string) => void;
}
export const AllProjectsTable: React.FC<ProjectsTableDataProps> = (props) => {
  return (
    <TableContainer maxHeight="100%" overflowY={"auto"}>
      <Table variant="simple" size="sm">
        <TableHead />

        <TableBody
          onSuccesManageUser={props.onSuccesManageUser}
          projectsToShow={props.projectsToShow}
          deleteProjects={props.deleteProjects}
        />
      </Table>
    </TableContainer>
  );
};
