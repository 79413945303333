import {
  Box,
  Flex,
  GridItem,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { LogsDataContext } from ".";
import { ErrorContext } from "../../App";
import { apiClient, authorise } from "../utils/apiClient";
import { TooltipIntervalsInterface } from "./types";
import {
  MINUTES_PER_24H,
  MINUTES_PER_30D,
  MINUTES_PER_7D,
} from "./useLogsData";

interface LogGridItemProps {
  entityId: string;
  index: number;
  startGrid: number;
  endGrid: number;
  bg: string;
  colSpan?: number;
  height: string;
  cursor?: string;
  lengthOfArrayWithColorAndColSpan: number;
}

export const LogGridItem: React.FC<LogGridItemProps> = ({
  entityId,
  index,
  startGrid,
  endGrid,
  bg,
  colSpan,
  height,
  cursor,
  lengthOfArrayWithColorAndColSpan,
}) => {
  const logsContext = useContext(LogsDataContext);
  const errorContext = useContext(ErrorContext);
  const [tooltipIntervals, setTooltipIntervals] =
    useState<TooltipIntervalsInterface>({} as TooltipIntervalsInterface);

  const getObjectToSendForTooltip = () => {
    const minPerGrid =
      logsContext.tabIndex === 0
        ? MINUTES_PER_24H
        : logsContext.tabIndex === 1
        ? MINUTES_PER_7D
        : MINUTES_PER_30D;
    return {
      start: startGrid * minPerGrid,
      end: endGrid * minPerGrid,
      interval: logsContext.tabIndex,
      entityId: entityId,
      resolution: minPerGrid,
    };
  };

  const getTooltipInfo = async () => {
    await apiClient
      .post(
        "api/private/log/exact-date",
        getObjectToSendForTooltip(),
        authorise()
      )
      .then((res) => {
        setTooltipIntervals(res.data);
      })
      .catch((err) => errorContext.createError(err.response.data));
  };
  useEffect(() => {
    if (bg === "warning.400" || bg === "danger.400" || bg === "gray.400") {
      getTooltipInfo();
    }
  }, [logsContext.tabIndex]);
  const bgColorMain = useColorModeValue("gray.300", "darkThemeGrey.500");
  const colorText = useColorModeValue("blue.800", "darkThemeGrey.100");

  return (
    <>
      <GridItem w={"100%"} colSpan={colSpan}>
        <Flex width="100%" height="100%" alignItems={"center"}>
          {bg === "warning.400" || bg === "danger.400" || bg === "gray.400" ? (
            <Popover placement="top" arrowSize={8} trigger="hover">
              <PopoverTrigger>
                <Box
                  w="100%"
                  h={height}
                  bg={bg}
                  borderRadius={
                    lengthOfArrayWithColorAndColSpan === 1
                      ? "16px 16px 16px 16px"
                      : index === 0
                      ? "16px 0px 0px 16px"
                      : index === lengthOfArrayWithColorAndColSpan - 1
                      ? "0px 16px 16px 0px"
                      : ""
                  }
                  cursor={cursor}
                ></Box>
              </PopoverTrigger>
              <PopoverContent
                color={colorText}
                bg={bgColorMain}
                fontWeight={"medium"}
                width={"240px"}
                maxH={40}
              >
                <Box mb={1} p={1}>
                  <PopoverCloseButton />
                </Box>

                <PopoverBody mt={4} overflowY={"auto"}>
                  {!!tooltipIntervals.downtimes?.length && (
                    <Flex direction={"column"} alignItems="center">
                      <Text>Downtimes:</Text>
                      {tooltipIntervals.downtimes?.map((downtime, index) => {
                        const startDay = moment
                          .utc(downtime.start)
                          .local()
                          .format("Do");
                        const endDay = moment
                          .utc(downtime.end)
                          .local()
                          .format("Do");
                        const startTime = moment
                          .utc(downtime.start)
                          .local()
                          .format("HH:mm");
                        const endTime = moment
                          .utc(downtime.end)
                          .local()
                          .format("HH:mm");

                        return (
                          <Flex
                            key={index}
                            marginTop={"0px"}
                            direction="column"
                          >
                            <Text>
                              {logsContext.tabIndex === 0
                                ? `${startTime} - ${endTime}`
                                : `${startDay} ${startTime} - ${endDay} ${endTime}`}
                            </Text>
                          </Flex>
                        );
                      })}
                    </Flex>
                  )}
                  {!!tooltipIntervals.unexpectedBehaviours?.length && (
                    <Flex direction="column" alignItems="center">
                      <Text>Unexpected Behaviours:</Text>
                      {tooltipIntervals.unexpectedBehaviours?.map((ub, i) => {
                        const startDay = moment
                          .utc(ub.start)
                          .local()
                          .format("Do");
                        const endDay = moment.utc(ub.end).local().format("Do");
                        const startTime = moment
                          .utc(ub.start)
                          .local()
                          .format("HH:mm");
                        const endTime = moment
                          .utc(ub.end)
                          .local()
                          .format("HH:mm");
                        return (
                          <Flex key={i} direction="column" marginTop={"0px"}>
                            <Text>
                              {logsContext.tabIndex === 0
                                ? `${startTime} - ${endTime}`
                                : `${startDay} ${startTime} - ${endDay} ${endTime}`}
                            </Text>
                          </Flex>
                        );
                      })}
                    </Flex>
                  )}
                  {!!tooltipIntervals.disabled?.length && (
                    <Flex direction="column" alignItems="center">
                      <Text>Disabled:</Text>
                      {tooltipIntervals.disabled?.map((dis, i) => {
                        const startDay = moment
                          .utc(dis.start)
                          .local()
                          .format("Do");
                        const endDay = moment.utc(dis.end).local().format("Do");
                        const startTime = moment
                          .utc(dis.start)
                          .local()
                          .format("HH:mm");
                        const endTime = moment
                          .utc(dis.end)
                          .local()
                          .format("HH:mm");

                        return (
                          <Flex key={i} direction="column" marginTop={"0px"}>
                            <Text>
                              {logsContext.tabIndex === 0
                                ? `${startTime} - ${endTime}`
                                : `${startDay} ${startTime} - ${endDay} ${endTime}`}
                            </Text>
                          </Flex>
                        );
                      })}
                    </Flex>
                  )}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            <Box
              w="100%"
              h={height}
              bg={bg}
              borderRadius={
                lengthOfArrayWithColorAndColSpan === 1
                  ? "16px 16px 16px 16px"
                  : index === 0
                  ? "16px 0px 0px 16px"
                  : index === lengthOfArrayWithColorAndColSpan - 1
                  ? "0px 16px 16px 0px"
                  : ""
              }
            ></Box>
          )}
        </Flex>
      </GridItem>
    </>
  );
};
