import {
  ErrrorModalDataTaskInterface,
  ModalDataTaskInterface,
  WarnigModalDataFormatting,
} from "../types";

export const defaultModalData: ModalDataTaskInterface = {
  name: "",
  taskType: "0",
  route: "",
  httpMethods: "",
  requestBody: "",
  queryParameters: "",
  expectedBody: "",
  taskHeaders: [{ keyV: "", value: "", index: "1" }],
  mediaType: 0,
  expectedStatus: "200",
  requestGeneric: "",
  ignoreResponse: false,
  expectedResponse: "",
  isActive: true,
  startDate: new Date().toISOString(),
  recurringTime: 1,
  minimumNotificationLevel: 5,
  timeout: 15,
  timeoutNotificationLevel: 20,
};
export const defaultWarnigs: WarnigModalDataFormatting = {
  warningRequestBody: "",
  warningExpectedBody: "",
  warningExpectedResponse: "",
  warningRequestGeneric: "",
};
export const defaultErrorModalData: ErrrorModalDataTaskInterface = {
  errorExpectedBody: "",
  errorName: "",
  errorRoute: "",
  errorExpectedResponse: "",
  errorExpectedStatus: "",
  errorQueryParams: "",
  errorRequestBody: "",
  errorRequestGeneric: "",
  errorHttpMethods: "",
  errorDate: "",
  errorReccuring: "",
  errorTimeout: "",
  errorNotificationLevel: "",
};
