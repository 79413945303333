import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { ErrorContext } from "../../../App";
import { MotionButton } from "../../../Utils/MotionComponents";

import { WDTextArea } from "../../Common/WDTextArea";
import { FirstPageContext } from "../ModalTask";
import { extractDataFromCurl } from "../Utils/formaters";
import { ModalTaskContext } from "../WDTaskModal";
export const CurlAutocomplete: React.FC = () => {
  const { createError } = useContext(ErrorContext);
  const { setModalData, modalData, setHeaderNumber } =
    useContext(ModalTaskContext);
  const [curl, setCurl] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleCurlChange = (value: string) => {
    setCurl(value);
  };
  const { setFormatRequestBody } = useContext(FirstPageContext);
  const handleSubmitCurl = () => {
    extractDataFromCurl(
      curl,
      createError,
      setModalData,
      setFormatRequestBody,
      modalData,
      setHeaderNumber
    );
    reset();
  };
  const reset = () => {
    setCurl("");
    onClose();
  };
  return (
    <Box w="100%">
      <MotionButton
        onClick={onOpen}
        bg={useColorModeValue("blue.700", "blue.400")}
        colorScheme={"blue"}
        color="white"
        w="100%"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: "spring", bounce: 0.4 }}
      >
        Import Curl
      </MotionButton>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        isCentered
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalHeader
            borderRadius={"8px 8px 0px 0px"}
            backgroundColor={useColorModeValue("blue.100", "darkThemeGrey.500")}
            color={useColorModeValue("blue.800", "darkThemeGrey.100")}
          >
            Insert Curl
          </ModalHeader>
          <ModalCloseButton
            onClick={reset}
            margin={"5px 8px 0px 0px"}
            color={useColorModeValue("blue.800", "darkThemeGrey.100")}
            borderWidth={2}
            borderRadius={5}
            borderColor={useColorModeValue("blue.700", "darkThemeGrey.100")}
          />
          <ModalBody bg={useColorModeValue("white", "darkThemeGrey.600")}>
            <WDTextArea
              value={curl}
              onChange={handleCurlChange}
              placeholder={"Import curl..."}
            />
            <HStack w="100%" pt={4}>
              <Spacer />
              <Button onClick={reset}>Cancel</Button>
              <Button
                backgroundColor={"danger.600"}
                _hover={{ backgroundColor: "danger.700" }}
                color={"white"}
                onClick={() => handleSubmitCurl()}
                autoFocus
              >
                Import
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
