import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorContext } from "../../App";
import { apiClient, authorise } from "../utils/apiClient";
import {
  GridObjectInterface,
  LogGridPropsInterface,
  LogsDataContextInterface,
  ServerListInterface,
  ServerLogsInterface,
  StartEndInterface,
  TimeFrameInterface,
} from "./types";

export const MINUTES_PER_24H = 5;
export const MINUTES_PER_7D = 20;
export const MINUTES_PER_30D = 120;

export const NO_OF_CELLS_24H = 1440 / MINUTES_PER_24H;
export const NO_OF_CELLS_7D = 10080 / MINUTES_PER_7D;
export const NO_OF_CELLS_30D = 43200 / MINUTES_PER_30D;

export const useLogsData = (): LogsDataContextInterface => {
  const errorContext = useContext(ErrorContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [projectAndServerDetails, setProjectAndServerDetails] =
    useState<ServerLogsInterface>({} as ServerLogsInterface);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [serverTabIndex, setServerTabIndex] = useState<number>(0);
  const { projectId, serverId } = useParams();
  const [numberOfCells, setNumberOfCells] = useState<number>(0);
  const [serversList, setServersList] = useState<ServerListInterface[]>(
    [] as ServerListInterface[]
  );
  const [timeFrame, setTimeFrame] = useState<TimeFrameInterface>({
    start: "",
    end: "",
  });
  const [loadingLogs, setLoadingLogs] = useState<boolean>(true);

  const getIndexesFunction = (
    startEnd: StartEndInterface[]
  ): GridObjectInterface[] => {
    const numberToDivide =
      tabIndex === 0
        ? MINUTES_PER_24H
        : tabIndex === 1
        ? MINUTES_PER_7D
        : MINUTES_PER_30D;
    return startEnd?.map((startEndObject) => {
      return {
        startOfGrid: Math.floor(startEndObject.start / numberToDivide),
        endOfGrid: Math.ceil(startEndObject.end / numberToDivide),
      };
    });
  };

  const getColorArray = (
    arrayForRedColoring: GridObjectInterface[],
    arrayForGreyColoring: GridObjectInterface[],
    arrayForYellowColoring?: GridObjectInterface[],
    arrayForBlueColoring?: GridObjectInterface[],
    arrayForDarkGreyColoring?: GridObjectInterface[]
  ): string[] => {
    const arrWithColors = new Array<string>(
      tabIndex === 0
        ? NO_OF_CELLS_24H
        : tabIndex === 1
        ? NO_OF_CELLS_7D
        : NO_OF_CELLS_30D
    ).fill("green.500");
    const nmbOfCells =
      tabIndex === 0
        ? NO_OF_CELLS_24H
        : tabIndex === 1
        ? NO_OF_CELLS_7D
        : NO_OF_CELLS_30D;

    arrayForDarkGreyColoring?.forEach((unknown) => {
      const endOfDarkGrey = Math.min(unknown.endOfGrid, nmbOfCells - 1);
      for (let i = unknown.startOfGrid; i <= endOfDarkGrey; i++) {
        arrWithColors[i] = "neutralDarkGrey";
      }
    });
    arrayForYellowColoring?.forEach((warning) => {
      const endOfYellow = Math.min(warning.endOfGrid, nmbOfCells - 1);
      for (let i = warning.startOfGrid; i <= endOfYellow; i++) {
        arrWithColors[i] = "warning.400";
      }
    });
    arrayForRedColoring?.forEach((downtime) => {
      const endOfRed = Math.min(downtime.endOfGrid, nmbOfCells - 1);
      for (let i = downtime.startOfGrid; i <= endOfRed; i++) {
        arrWithColors[i] = "danger.400";
      }
    });
    arrayForGreyColoring?.forEach((disabled) => {
      const endOfGrey = Math.min(disabled.endOfGrid, nmbOfCells - 1);
      for (let i = disabled.startOfGrid; i <= endOfGrey; i++) {
        arrWithColors[i] = "gray.400";
      }
    });
    arrayForBlueColoring?.forEach((deploy) => {
      const endOfBlue = Math.min(deploy.endOfGrid, nmbOfCells - 1);
      for (let i = deploy.startOfGrid; i <= endOfBlue; i++) {
        arrWithColors[i] = "blue.400";
      }
    });

    return arrWithColors;
  };

  const getLogGridColorAndColSpan = (
    arrWithColor: string[]
  ): LogGridPropsInterface[] => {
    const arrWithColorAndColSpan = new Array<LogGridPropsInterface>();
    const nmbOfCells =
      tabIndex === 0
        ? NO_OF_CELLS_24H
        : tabIndex === 1
        ? NO_OF_CELLS_7D
        : NO_OF_CELLS_30D;
    let len = 1;
    let sum = 0;
    for (let i = 0; i < arrWithColor.length; i++) {
      if (arrWithColor[i] === arrWithColor[i + 1]) {
        len++;
      } else {
        sum = sum + len;
        if (sum <= nmbOfCells) {
          arrWithColorAndColSpan.push({
            start: i - len + 1,
            end: i,
            color: arrWithColor[i],
            colSpan: len,
          });
          len = 1;
        } else {
          break;
        }
      }
    }
    return arrWithColorAndColSpan;
  };

  const getLogBar = (
    downtime: StartEndInterface[],
    disabled: StartEndInterface[],
    unexpectedBehaviour: StartEndInterface[],
    deploy: StartEndInterface[],
    unknown: StartEndInterface[]
  ) => {
    const indexesDowntimeArray = getIndexesFunction(downtime);
    const indexesDisabledArray = getIndexesFunction(disabled);
    const indexesUnexpectedBehaviourArray =
      getIndexesFunction(unexpectedBehaviour);
    const indexesDeployArray = getIndexesFunction(deploy);
    const indexesUnknownArray = getIndexesFunction(unknown);
    const colorArray = getColorArray(
      indexesDowntimeArray,
      indexesDisabledArray,
      indexesUnexpectedBehaviourArray,
      indexesDeployArray,
      indexesUnknownArray
    );
    return getLogGridColorAndColSpan(colorArray);
  };

  useEffect(() => {
    if (tabIndex === 0) {
      setNumberOfCells(NO_OF_CELLS_24H);
      getProjectAndServerLogsForLast24h();
      setTimeFrame({
        start: moment().subtract(24, "hours").format("HH:mm"),
        end: moment().format("HH:mm"),
      });
      getProjectAndServerLogsForLast24h();
    }
    if (tabIndex === 1) {
      setNumberOfCells(NO_OF_CELLS_7D);
      getProjectAndServerLogsForLastWeek();
      setTimeFrame({
        start: moment().subtract(1, "week").format("dddd D MMM"),
        end: moment().format("dddd D MMM"),
      });
      getProjectAndServerLogsForLastWeek();
    }
    if (tabIndex === 2) {
      setNumberOfCells(NO_OF_CELLS_30D);
      getProjectAndServerLogsForLastMonth();
      setTimeFrame({
        start: moment().subtract(1, "months").format("dddd D MMM"),
        end: moment().format("dddd D MMM"),
      });
      getProjectAndServerLogsForLastMonth();
    }
  }, [tabIndex, serverId]);

  const getProjectAndServerLogsForLast24h = async () => {
    setLoadingLogs(true);
    await apiClient
      .get(`api/public/log/last-24h/${serverId}`, authorise())
      .then((res) => {
        setProjectAndServerDetails(res.data);
        setServersList(res.data.serversList);
        setServerTabIndex(
          res.data.serversList.findIndex(
            (element: ServerListInterface) => element.id === serverId
          )
        );
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
    setLoading(false);
    setLoadingLogs(false);
  };

  const getProjectAndServerLogsForLastWeek = async () => {
    setLoadingLogs(true);
    await apiClient
      .get(`api/public/log/last-week/${serverId}`, authorise())
      .then((res) => {
        setProjectAndServerDetails(res.data);
        setServersList(res.data.serversList);
        setServerTabIndex(
          res.data.serversList.findIndex(
            (element: ServerListInterface) => element.id === serverId
          )
        );
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
    setLoading(false);
    setLoadingLogs(false);
  };

  const getProjectAndServerLogsForLastMonth = async () => {
    setLoadingLogs(true);
    await apiClient
      .get(`api/public/log/last-month/${serverId}`, authorise())
      .then((res) => {
        setProjectAndServerDetails(res.data);
        setServersList(res.data.serversList);
        setServerTabIndex(
          res.data.serversList.findIndex(
            (element: ServerListInterface) => element.id === serverId
          )
        );
      })
      .catch((err) => {
        errorContext.createError(err.response.data);
      });
    setLoading(false);
    setLoadingLogs(false);
  };

  return {
    projectId: projectId as string,
    serverId: serverId as string,
    loading,
    projectAndServerDetails,
    tabIndex,
    setTabIndex,
    numberOfCells,
    timeFrame,
    getLogBar,
    loadingLogs,
    serversList,
    serverTabIndex,
    setServerTabIndex,
  };
};
