import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ModalTaskContext } from "./WDTaskModal";

export const StepBar: React.FC = () => {
  const modalContext = useContext(ModalTaskContext);
  const colorPrimary = useColorModeValue("blue.800", "blue.400");
  const colorSecondary = useColorModeValue("neutralGrey", "darkThemeGrey.100");
  return (
    <Flex cursor="pointer" w={"100%"} pt={2}>
      {[...Array(2)].map((_, i) => {
        return (
          <Box
            key={i}
            h={2}
            borderRadius={5}
            bg={i === modalContext?.step ? colorPrimary : colorSecondary}
            w={16}
            mr={0.5}
            onClick={() => {
              modalContext?.handleNext(i);
            }}
          ></Box>
        );
      })}
    </Flex>
  );
};
