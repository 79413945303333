import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { ServiceInterface } from "../Common/types";
import { optionsTypesService } from "../CreateServicesModal/FirstStep/FirstStep";
import { OnlineStatus } from "./OnlineStatus/OnlineStatus";
import { Task } from "./Task";
interface ServiceProps {
  service: ServiceInterface[];
}
export const Services: React.FC<ServiceProps> = (props) => {
  return (
    <Accordion h="100%" allowToggle>
      {props.service.map((ser, i) => {
        return (
          <AccordionItem
            key={ser.serviceId}
            border="0px solid"
            borderBottom="1px solid"
            borderColor="neutralGrey"
          >
            <AccordionButton w="100%">
              <Flex
                justifyContent="space-between"
                w="100%"
                fontWeight={600}
                lineHeight={8}
                fontSize={14}
                gap={4}
              >
                <Box
                  pl={4}
                  textAlign="left"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  w="120px"
                  maxW="30%"
                >
                  {ser.name}
                </Box>

                <Box
                  textAlign={"right"}
                  overflow="hidden"
                  w="300px"
                  maxW="30%"
                  textOverflow={"ellipsis"}
                  whiteSpace="nowrap"
                >
                  {ser.ipAddress.length <= 30 ? (
                    ser.ipAddress
                  ) : (
                    <Tooltip label={ser.ipAddress}>{ser.ipAddress}</Tooltip>
                  )}
                </Box>

                <Box textAlign={"right"} pr={8} maxW="20%">
                  {ser.portNumber}
                </Box>

                <Box textAlign={"left"} maxW="20%">
                  {ser.serviceType.toString() === "0"
                    ? "Generic"
                    : optionsTypesService.filter((opt) => {
                        return opt.value === ser.serviceType.toString();
                      })[0]?.display}
                </Box>
                <OnlineStatus status={ser.status} type={2} />
              </Flex>
            </AccordionButton>

            <Task tasks={ser.tasks} />
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
