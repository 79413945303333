import { useContext, useEffect, useState } from "react";
import { FirstPageModalContext } from "./types";
import { ModalTaskContext } from "./WDTaskModal";

export const useFirstPageInfo = (): FirstPageModalContext => {
  const { setModalData, modalData, setWarningModalData, warningModalData } =
    useContext(ModalTaskContext);
  const { isOpen } = useContext(ModalTaskContext);
  const [requestFileName, setRequestFileName] =
    useState<string>("No selected file");
  const [responseFileName, setResponseFileName] =
    useState<string>("No selected file");
  const [disabledRequest, setDisabledRequest] = useState<boolean>(false);
  const [disabledResponse, setDisabledResponse] = useState<boolean>(false);
  const [formatRequestBody, setFormatRequestBody] = useState<number>(0);
  const [formatExpectedResponse, setFormatExpectedResponse] =
    useState<number>(0);
  useEffect(() => {
    setWarningModalData({ ...warningModalData, warningRequestBody: "" });
    setModalData({ ...modalData, mediaType: formatRequestBody });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatRequestBody]);
  useEffect(() => {
    setWarningModalData({ ...warningModalData, warningExpectedBody: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatExpectedResponse]);
  useEffect(() => {
    setRequestFileName("No selected file");
    setResponseFileName("No selected file");
    setDisabledRequest(false);
    setDisabledResponse(false);
    setFormatRequestBody(0);
    setFormatExpectedResponse(0);
  }, [isOpen]);
  return {
    requestFileName,
    setRequestFileName,
    setResponseFileName,
    responseFileName,
    disabledRequest,
    setDisabledRequest,
    disabledResponse,
    setDisabledResponse,
    setFormatExpectedResponse,
    setFormatRequestBody,
    formatExpectedResponse,
    formatRequestBody,
  };
};
