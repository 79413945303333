import React, { useContext, useEffect, useState } from "react";
import { Text, Flex, Box } from "@chakra-ui/react";

import { ReactComponent as Vector1Icon } from "../../Assets/Vector1Icon.svg";
import { ReactComponent as Vector2Icon } from "../../Assets/Vector2Icon.svg";
import { WelcomeBackComponent } from "./WelcomeBackComponent";
import { QRComponent } from "./QRComponent";
import { CodeComponent } from "./CodeComponent";
import { ErrorContext, UserContext } from "../../App";

export type currentStepEnum = "WelcomeBack" | "QR" | "2FACode";

export const LogInComponent: React.FC = () => {
  const errorContext = useContext(ErrorContext);
  const userContext = useContext(UserContext);
  // eslint-disable-next-line
  const [currentStep, setCurrentStep] =
    useState<currentStepEnum>("WelcomeBack");
  const [QRCode, setQRCode] = useState<string>("");
  const [clientJWT, setClientJWT] = useState<string>("");
  useEffect(() => {
    if (localStorage.getItem("USER") === "USER_NOT_FOUND")
      errorContext.createError(["USER_NOT_FOUND"]);
    localStorage.removeItem("USER");
    if (localStorage.getItem("accesToken")) {
      userContext.logIn();
    }
  }, []);
  return (
    <Flex
      bgColor={"blue.800"}
      w="100vw"
      h="100vh"
      alignItems={"center"}
      direction={"column"}
    >
      <Flex alignItems={"center"} p={2} mt={14}>
        <Text pr={1} fontSize="2xl">
          &#128021;
        </Text>
        <Text color="neutralGrey" fontSize="3xl" fontWeight="medium" pr={2}>
          CST
        </Text>
        <Text color="neutralGrey" fontSize="3xl" fontWeight="bold">
          WatchDog
        </Text>
      </Flex>

      <Flex
        justifyContent={"space-between"}
        w="100vw"
        h="100vh"
        position={"absolute"}
        overflow="hidden"
      >
        <Box w="50%" objectFit={"cover"}>
          <Vector1Icon width={"100%"} />
        </Box>

        <Box w="50%" objectFit={"cover"}>
          <Vector2Icon width={"100%"} />
        </Box>
      </Flex>

      <Flex
        position="absolute"
        w="100%"
        h="100%"
        justifyContent={"center"}
        alignItems={"center"}
      >
        {currentStep === "WelcomeBack" && (
          <WelcomeBackComponent
            setCurrentStep={setCurrentStep}
            setQRCode={setQRCode}
            setClientJWT={setClientJWT}
          />
        )}
        {currentStep === "QR" && (
          <QRComponent QRCode={QRCode} clientJWT={clientJWT} />
        )}
        {currentStep === "2FACode" && (
          <CodeComponent QRCode={QRCode} clientJWT={clientJWT} />
        )}
      </Flex>
    </Flex>
  );
};
