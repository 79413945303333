import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../../../App";
import { MotionButton } from "../../../Utils/MotionComponents";
import { WDInput } from "../../Common/WDInput";
import { ThresholdTemplateInterface } from "../../ThresholdTemplates/types";
import { apiClient, authorise } from "../../utils/apiClient";
import { defaultThresholdData } from "./defaultThresholdObject";
import { FirstStep } from "./FirstStep";
import { SecondStep } from "./SecondStep";
import { StepBar } from "./StepBar";
import { ThirdStep } from "./ThirdStep";
import { ThresholdsInterface } from "./types";
import { useSetters } from "./useSetters";

interface ManageThresholdsModalProps {
  initialValues?: ThresholdsInterface;
  setInitialValues?: (val: ThresholdsInterface) => void;
  onSuccess: (val: ThresholdTemplateInterface | ThresholdsInterface) => void;
  templateName?: string;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  template?: boolean;
  serverId?: string;
  isTemplateSelected?: boolean;
}

export const ManageThresholdsModal: React.FC<ManageThresholdsModalProps> = ({
  initialValues,
  onSuccess,
  templateName,
  isOpen,
  setIsOpen,
  template,
  serverId,
  isTemplateSelected,
  setInitialValues,
}) => {
  const errorContext = useContext(ErrorContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const colorPrimary = useColorModeValue("blue.700", "blue.400");
  const colorSecondary = useColorModeValue("blue.800", "darkThemeGrey.100");
  const bgColor = useColorModeValue("white", "darkThemeGrey.700");

  const allData = useSetters(initialValues ?? defaultThresholdData);

  const [templateNameState, setTemplateNameState] = useState<string>(
    templateName ?? ""
  );

  const [myThresholds, setMyThresholds] = useState<ThresholdsInterface>(
    {} as ThresholdsInterface
  );

  const resetModal = () => {
    setStep(0);
    setInitialValues?.(myThresholds);
    setTemplateNameState(templateName ?? "");
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      if (serverId) {
        apiClient
          .get(`/api/servers/get-thresholds/${serverId}`, authorise())
          .then((res) => {
            setMyThresholds(res.data);
            if (!isTemplateSelected) {
              allData.setModalData(res.data);
              setLoading(false);
            } else {
              allData.setModalData(initialValues ?? defaultThresholdData);
              setLoading(false);
            }
          })
          .catch((err) => {
            errorContext.createError([...err.response.data]);
          });
      } else {
        allData.setModalData(initialValues ?? defaultThresholdData);
        setLoading(false);
      }
    }
  }, [isOpen]);

  const handleNextClick = () => {
    setStep(step + 1);
  };

  return (
    <Modal
      blockScrollOnMount={false}
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
      size={"3xl"}
      isOpen={isOpen}
      onClose={() => {
        resetModal();
      }}
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalHeader
          borderRadius={"8px 8px 0px 0px"}
          backgroundColor={useColorModeValue("blue.100", "darkThemeGrey.600")}
          color={colorSecondary}
        >
          {step === 0 ? "Server global" : step === 1 ? "Processes" : "Folders"}
        </ModalHeader>
        <ModalCloseButton
          margin={"5px 8px 0px 0px"}
          borderWidth={2}
          borderRadius={5}
          color={colorSecondary}
          borderColor={colorSecondary}
        />
        {loading ? (
          <Flex h={300} align="center" justify="center">
            <Spinner />
          </Flex>
        ) : (
          <ModalBody padding={5} bg={bgColor}>
            <Flex width="100%" align="center" h={10}>
              <StepBar step={step} setStep={setStep} />
              <Spacer />
              {step === 0 && template && (
                <Box w="40%">
                  <WDInput
                    variant="flushed"
                    borderRadius={0}
                    value={templateNameState}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setTemplateNameState(e.target.value);
                    }}
                    placeholder="Template Name"
                    p={0}
                    fontWeight={600}
                    fontSize="lg"
                  />
                </Box>
              )}
            </Flex>
            {step === 0 ? (
              <FirstStep allData={allData} />
            ) : step === 1 ? (
              <SecondStep
                handleDeleteProcess={allData.handleDeleteProcess}
                setProcessYellow={allData.setProcessYellow}
                setProcessRed={allData.setProcessRed}
                addAnotherProcess={allData.addAnotherProcess}
                processes={allData.modalData.processes}
              />
            ) : (
              <ThirdStep
                folders={allData.modalData.foldersPath}
                addAnotherFolder={allData.addAnotherFolder}
                setFolderYellow={allData.setFolderYellow}
                setFolderRed={allData.setFolderRed}
                handleDeleteFolder={allData.handleDeleteFolder}
              />
            )}
          </ModalBody>
        )}

        <ModalFooter bg={useColorModeValue("white", "darkThemeGrey.700")}>
          <>
            <MotionButton
              tabIndex={1}
              backgroundColor={colorPrimary}
              colorScheme={"blue"}
              color={"white"}
              fontSize={"sm"}
              width={"20%"}
              mr={3}
              onClick={() => {
                if (template) {
                  onSuccess({
                    name:
                      templateNameState === ""
                        ? "Template Name"
                        : templateNameState,
                    value: allData.modalData,
                  } as ThresholdTemplateInterface);
                } else {
                  onSuccess(allData.modalData);
                }
                resetModal();
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", bounce: 0.4 }}
            >
              Save
            </MotionButton>
            {step !== 2 && (
              <MotionButton
                variant="outline"
                tabIndex={1}
                colorScheme={"blue"}
                color={colorPrimary}
                borderColor={colorPrimary}
                borderWidth="2px"
                fontSize={"sm"}
                width={"20%"}
                mr={3}
                onClick={handleNextClick}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", bounce: 0.4 }}
              >
                Next
              </MotionButton>
            )}
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
