import moment from "moment";
import { ModalServiceErrorsInterface, ModalServiceInterface } from "../type";

export const defaultDataModal: ModalServiceInterface = {
  name: "",
  ipV4: "",
  port: "",
  type: 0,
  enabled: true,
  startDate: moment(new Date()).toISOString(),
  reccuringTime: 1,
  minimumNotificationLevel: 5,
  timeout: 15,
  timeoutNotificationLevel: 20,
};

export const defaultErrorsDataModal: ModalServiceErrorsInterface = {
  errorName: "",
  errorIpV4: "",
  errorIpV6: "",
  errorPort: "",
  errorType: "",
  errorDate: "",
  errorNotificationType: "",
  errorEnabled: "",
  errorReccuringTime: "",
  errorTimeout: "",
  errorTimeoutNotification: "",
};
