import { Box, HStack, Spacer, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceOverviewContext } from ".";
import { CpuSection } from "../../Common/AreaGraph/AreaGraphSection";
import { CustomSpinner } from "../../Common/Spinner";
import { ServerMetrics } from "./ServersMetrics";
export const TabPanelContent: React.FC = () => {
  const { projectId, serverId } = useParams();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`../dashboard/projects/${projectId}`);
  };
  const { project, onIntervalChange, loadingGraphs, serverMetrics } =
    useContext(ResourceOverviewContext);
  return (
    <>
      {loadingGraphs ? (
        <CustomSpinner />
      ) : (
        <VStack px={20} w="100%" spacing={4} justify={"center"}>
          <HStack color="blue.800" w="100%" px={6} pt={4}>
            <Box fontSize={28} fontWeight={"bold"}>
              Resources Overview
            </Box>
            <Spacer />
            <Box
              fontWeight={650}
              fontSize={16}
              cursor="pointer"
              onClick={handleNavigate}
            >
              {" "}
              {" < Back"}
            </Box>
          </HStack>
          {serverMetrics ? <ServerMetrics /> : null}

          <Box w="100%">
            {project?.graphs?.map((g, i) => {
              return (
                <CpuSection
                  detailsUrl={`../dashboard/projects/${projectId}/resources/${serverId}/${g.id}`}
                  title={g.title}
                  key={g.id + i}
                  onIntervalChange={onIntervalChange}
                  id={g.id}
                  areaChart={g.areaChart}
                />
              );
            })}
          </Box>
        </VStack>
      )}
    </>
  );
};
