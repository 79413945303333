import React from "react";
import {
  Box,
  Textarea,
  TextareaProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

interface WDTextAreaProps extends Omit<TextareaProps, "onChange"> {
  error?: string;
  onChange: (val: string) => void;
  value: string;
}

export const WDTextArea: React.FC<WDTextAreaProps> = (props) => {
  const { value, onChange, error, ...others } = props;

  const changeEventHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };
  const colorFocusBorder = useColorModeValue("blue.800", "blue.400");
  const borderColor = useColorModeValue("gray.300", "darkThemeGrey.100");
  const dangerColor = useColorModeValue("danger.500", "darkThemeRed.600");
  return (
    <Box w="100%" h="100%">
      <Textarea
        value={value}
        onChange={changeEventHandler}
        minHeight={200}
        borderRadius={4}
        borderColor={error ? dangerColor : borderColor}
        focusBorderColor={error ? dangerColor : colorFocusBorder}
        _placeholder={{ weight: "400", color: "gray.400" }}
        {...others}
      />
      <Box h={2}>{error && <Text color={"danger.500"}>{error}</Text>}</Box>
    </Box>
  );
};
