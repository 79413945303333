import { InfoOutlineIcon, QuestionIcon, StarIcon } from "@chakra-ui/icons";
import { Box, Flex, Icon, useColorModeValue, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../App";
import { ReactComponent as AllProjects } from "../../../Assets/Drawer-NavBar/AllProjects.svg";
import { ReactComponent as ClientsIcon } from "../../../Assets/Drawer-NavBar/clients.svg";
import { ReactComponent as ProjectsIcon } from "../../../Assets/Drawer-NavBar/ProjectsIcon.svg";
import { ReactComponent as UsersIcon } from "../../../Assets/Drawer-NavBar/UsersIcon.svg";
import { ReactComponent as ThresholdTemplatesIcon } from "../../../Assets/Drawer-NavBar/thresholdsTemplate.svg";
import { theme } from "../../../theme";
import { WDProjectModal } from "../../CreateProjectModal/WDProjectModal";

export const ListDrawer: React.FC = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const origin = "/dashboard";

  const contextData = useContext(UserContext);

  const navigate = useNavigate();
  const handleNavigate = (name: string) => {
    if (localStorage.accesToken) {
      navigate(origin + "/" + name);
    } else {
      contextData?.logOut();
    }
  };
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.darkThemeGrey[100]}`
  );
  const colorBorder = useColorModeValue("blue.700", "blue.400");
  const colorBackground = useColorModeValue("blue.100", "darkThemeGrey.600");
  return (
    <Flex
      flexDirection="column"
      align="center"
      width="100%"
      justify="center"
      px={2}
    >
      <Box pl={3} pr={2} pb={2}>
        <WDProjectModal isCollapsed={true} />
      </Box>
      <VStack
        overflowY="scroll"
        overflowX="hidden"
        __css={{
          direction: "rtl",
          "&::-webkit-scrollbar-track": {
            background: colorBackground,
          },
        }}
        spacing="10px"
        align="center"
        p={1}
        ml={2}
        justifyItems="center"
        height={380}
      >
        <Flex
          __css={{
            direction: "ltr",
          }}
          p={2}
          width="100%"
          borderRight={
            location.pathname.includes("projects") ? "10px solid" : "0"
          }
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate("projects");
          }}
          cursor="pointer"
        >
          <Icon
            as={ProjectsIcon}
            stroke={colorStroke}
            width="100%"
            opacity={location.pathname.includes("projects") ? "100%" : "50%"}
            borderColor={colorBorder}
            fontFamily="Montserrat"
            boxSize={6}
          ></Icon>
        </Flex>
        {userContext?.user?.roleName === "Admin" && (
          <Flex
            __css={{
              direction: "ltr",
            }}
            p={2}
            width="100%"
            borderRight={
              location.pathname.includes("all-project") ? "10px solid" : "0"
            }
            borderColor={colorBorder}
            onClick={() => {
              handleNavigate("all-project");
            }}
            cursor="pointer"
          >
            <Icon
              as={AllProjects}
              stroke={colorStroke}
              width="100%"
              opacity={
                location.pathname.includes("all-project") ? "100%" : "50%"
              }
              fontFamily="Montserrat"
              boxSize={6}
            ></Icon>
          </Flex>
        )}
        {userContext?.user?.roleName === "Admin" && (
          <Flex
            __css={{
              direction: "ltr",
            }}
            p={2}
            width="100%"
            borderRight={
              location.pathname.includes("threshold-templates")
                ? "10px solid"
                : "0"
            }
            borderColor={colorBorder}
            onClick={() => {
              handleNavigate("threshold-templates");
            }}
            cursor="pointer"
          >
            <Icon
              as={ThresholdTemplatesIcon}
              stroke={colorStroke}
              width="100%"
              opacity={
                location.pathname.includes("threshold-templates")
                  ? "100%"
                  : "50%"
              }
              fontFamily="Montserrat"
              boxSize={6}
            ></Icon>
          </Flex>
        )}
        {userContext?.user?.roleName === "Admin" && (
          <Flex
            __css={{
              direction: "ltr",
            }}
            p={2}
            width="100%"
            borderRight={
              location.pathname.includes("developers") ? "10px solid" : "0"
            }
            borderColor={colorBorder}
            onClick={() => {
              handleNavigate("developers");
            }}
            cursor="pointer"
          >
            <Icon
              as={UsersIcon}
              stroke={colorStroke}
              width="100%"
              opacity={
                location.pathname.includes("developers") ? "100%" : "50%"
              }
              fontFamily="Montserrat"
              boxSize={6}
            ></Icon>
          </Flex>
        )}
        {userContext?.user?.roleName === "Admin" && (
          <Flex
            __css={{
              direction: "ltr",
            }}
            p={2}
            width="100%"
            borderRight={
              location.pathname.includes("clients") ? "10px solid" : "0"
            }
            borderColor={colorBorder}
            onClick={() => {
              handleNavigate("clients");
            }}
            cursor="pointer"
          >
            <Icon
              as={ClientsIcon}
              stroke={colorStroke}
              width="100%"
              opacity={location.pathname.includes("clients") ? "100%" : "50%"}
              fontFamily="Montserrat"
              boxSize={6}
            ></Icon>
          </Flex>
        )}
        <Flex
          __css={{
            direction: "ltr",
          }}
          p={2}
          width="100%"
          borderRight={location.pathname.includes("about") ? "10px solid" : "0"}
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate("about");
          }}
          cursor="pointer"
        >
          <Icon
            as={InfoOutlineIcon}
            stroke={colorStroke}
            width="100%"
            opacity={location.pathname.includes("about") ? "100%" : "50%"}
            borderColor={colorBorder}
            fontFamily="Montserrat"
            boxSize={6}
          ></Icon>
        </Flex>
        <Flex
          __css={{
            direction: "ltr",
          }}
          p={2}
          width="100%"
          borderRight={location.pathname.includes("helps") ? "10px solid" : "0"}
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate("help");
          }}
          cursor="pointer"
        >
          <Icon
            as={QuestionIcon}
            color={colorStroke}
            width="100%"
            opacity={location.pathname.includes("help") ? "100%" : "50%"}
            fontFamily="Montserrat"
            boxSize={6}
          ></Icon>
        </Flex>
        <Flex
          __css={{
            direction: "ltr",
          }}
          p={2}
          width="100%"
          borderRight={
            location.pathname.includes("whats-new") ? "10px solid" : "0"
          }
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate("whats-new");
          }}
          cursor="pointer"
        >
          <StarIcon
            stroke={colorStroke}
            width="100%"
            opacity={location.pathname.includes("whats-new") ? "100%" : "50%"}
            borderColor={colorBorder}
            fontFamily="Montserrat"
            boxSize={5}
          />
        </Flex>
      </VStack>
    </Flex>
  );
};
