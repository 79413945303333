import { useContext, useState } from "react";
import { ErrorContext } from "../../../App";
import { isValidFolderPath } from "../../../Utils/ValidatorsForInput";
import { defaultThresholdData } from "./defaultThresholdObject";
import { ThresholdsInterface } from "./types";

export const useSetters = (initialValues?: ThresholdsInterface) => {
  const errorContext = useContext(ErrorContext);
  const [modalData, setModalData] = useState<ThresholdsInterface>(
    initialValues ?? defaultThresholdData
  );

  const setCpuYellow = (val: number) => {
    setModalData({
      ...modalData,
      cpu: {
        yellow: val,
        red: modalData.cpu.red > val ? modalData.cpu.red : val,
      },
    });
  };

  const setCpuRed = (val: number) => {
    setModalData({
      ...modalData,
      cpu: {
        red: val,
        yellow: modalData.cpu.yellow < val ? modalData.cpu.yellow : val,
      },
    });
  };

  const setMemoryYellow = (val: number) => {
    setModalData({
      ...modalData,
      memory: {
        yellow: val,
        red: modalData.memory.red > val ? modalData.memory.red : val,
      },
    });
  };

  const setMemoryRed = (val: number) => {
    setModalData({
      ...modalData,
      memory: {
        red: val,
        yellow: modalData.memory.yellow < val ? modalData.memory.yellow : val,
      },
    });
  };

  const setDiskYellow = (val: number) => {
    setModalData({
      ...modalData,
      disk: {
        yellow: val,
        red: modalData.disk.red,
      },
    });
  };

  const setDiskRed = (val: number) => {
    setModalData({
      ...modalData,
      disk: {
        yellow: modalData.disk.yellow,
        red: val,
      },
    });
  };

  /////// PROCESSES ///////

  const addAnotherProcess = (val: string) => {
    const newProcess = {
      name: val,
      threshold: {
        yellow: 70,
        red: 90,
      },
    };

    if (val === "") {
      errorContext.createError(["EMPTY_PROCESS_NAME"]);
    } else if (
      modalData.processes.filter((p) => {
        return p.name === val;
      }).length === 0
    ) {
      setModalData({
        ...modalData,
        processes: [...modalData.processes, newProcess],
      });
    } else {
      errorContext.createError(["PROCESS_ALREADY_EXIST"]);
    }
  };

  const setProcessYellow = (index: number, val: number) => {
    setModalData({
      ...modalData,
      processes: modalData.processes.map((p, i) => {
        if (i !== index) {
          return p;
        } else {
          return {
            ...p,
            threshold: {
              yellow: val,
              red: p.threshold.red > val ? p.threshold.red : val,
            },
          };
        }
      }),
    });
  };

  const setProcessRed = (index: number, val: number) => {
    setModalData({
      ...modalData,
      processes: modalData.processes.map((p, i) => {
        if (i !== index) {
          return p;
        } else {
          return {
            ...p,
            threshold: {
              yellow: p.threshold.yellow < val ? p.threshold.yellow : val,
              red: val,
            },
          };
        }
      }),
    });
  };

  const handleDeleteProcess = (name: string) => {
    setModalData({
      ...modalData,
      processes: modalData.processes.filter((p) => {
        return p.name !== name;
      }),
    });
  };

  /////// FOLDERS ///////

  const addAnotherFolder = (val: string) => {
    const newFolder = {
      path: val,
      threshold: {
        yellow: 20971520,
        red: 41943040,
      },
    };

    if (
      modalData.foldersPath.filter((f) => {
        return f.path === val;
      }).length === 0
    ) {
      if (isValidFolderPath(val)) {
        setModalData({
          ...modalData,
          foldersPath: [...modalData.foldersPath, newFolder],
        });
      } else {
        errorContext.createError(["BAD_FOLDER_PATH"]);
      }
    } else {
      errorContext.createError(["FOLDER_ALREADY_EXIST"]);
    }
  };

  const setFolderYellow = (index: number, val: number) => {
    setModalData({
      ...modalData,
      foldersPath: modalData.foldersPath.map((f, i) => {
        if (i !== index) {
          return f;
        } else {
          return {
            ...f,
            threshold: {
              yellow: val,
              red: f.threshold.red,
            },
          };
        }
      }),
    });
  };

  const setFolderRed = (index: number, val: number) => {
    setModalData({
      ...modalData,
      foldersPath: modalData.foldersPath.map((f, i) => {
        if (i !== index) {
          return f;
        } else {
          return {
            ...f,
            threshold: {
              yellow: f.threshold.yellow,
              red: val,
            },
          };
        }
      }),
    });
  };

  const handleDeleteFolder = (name: string) => {
    setModalData({
      ...modalData,
      foldersPath: modalData.foldersPath.filter((f) => {
        return f.path !== name;
      }),
    });
  };

  return {
    modalData,
    setModalData,
    setCpuYellow,
    setCpuRed,
    setMemoryYellow,
    setMemoryRed,
    setDiskYellow,
    setDiskRed,
    addAnotherProcess,
    setProcessYellow,
    setProcessRed,
    handleDeleteProcess,
    addAnotherFolder,
    setFolderYellow,
    setFolderRed,
    handleDeleteFolder,
  };
};
