import { Box, HStack, Spacer, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { MotionButton } from "../../../Utils/MotionComponents";
import { ModalTaskContext } from "../WDTaskModal";

export const SecondStepFooter: React.FC = () => {
  const { handleSave, loading, saveEditChanges, currentInfo } =
    useContext(ModalTaskContext);
  const handleSaveClick = () => {
    if (currentInfo.idTask) saveEditChanges();
    else handleSave();
  };
  return (
    <HStack w="100%" py={2} h="100%">
      <Spacer />
      <Box pl={2}>
        <MotionButton
          color="white"
          isLoading={loading}
          tabIndex={3}
          w={"280px"}
          colorScheme="blue"
          onClick={handleSaveClick}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", bounce: 0.4 }}
          backgroundColor={useColorModeValue("blue.700", "blue.400")}
        >
          {currentInfo.idTask ? "Save changes" : "Save"}
        </MotionButton>
      </Box>
    </HStack>
  );
};
