import {
  Box,
  Flex,
  HStack,
  Spacer,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { requiredField } from "../../../Utils/ValidatorsForInput";
import { WDInput } from "../../Common/WDInput";
import { WDInputFile } from "../../Common/WDInputFile";
import { WDTextArea } from "../../Common/WDTextArea";
import { FirstPageContext } from "../ModalTask";
import { PopoverWarning } from "./PopoverWarning";
import { tasksIdFieldsInterface } from "../types";
import { ModalTaskContext } from "../WDTaskModal";

export const InputFieldsForGenericType: React.FC = () => {
  const {
    modalData,
    changeData,
    errorModalData,
    warningModalData,
    setWarningModalData,
  } = useContext(ModalTaskContext);
  const {
    disabledRequest,
    setDisabledRequest,
    requestFileName,
    setRequestFileName,
    disabledResponse,
    setDisabledResponse,
    responseFileName,
    setResponseFileName,
  } = useContext(FirstPageContext);
  const handleRequest = (value: string) => {
    changeData(
      { requestGeneric: value },
      { errorRequestGeneric: requiredField(value) }
    );
  };
  const handleExpectedResponse = (value: string) => {
    changeData(
      { expectedResponse: value },
      {
        errorExpectedResponse: modalData.ignoreResponse
          ? ""
          : requiredField(value),
      }
    );
  };
  const handleTaskName = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeData(
      { name: e.target.value },
      { errorName: requiredField(e.target.value) }
    );
  };
  const handleWarningRequest = (value: string) => {
    setWarningModalData({
      ...warningModalData,
      warningRequestGeneric: value,
    });
  };
  const handleWarningResponse = (value: string) => {
    setWarningModalData({
      ...warningModalData,
      warningExpectedResponse: value,
    });
  };
  const handleSwitch = () => {
    changeData({ ignoreResponse: !modalData.ignoreResponse }, {});
  };
  return (
    <>
      <VStack w="100%" py={2} justify={"flex-start"} align="flex-start">
        <Box w="100%" pb={2}>
          <Flex>
            <Text> Task Name</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDInput
            id={tasksIdFieldsInterface.idName}
            onChange={handleTaskName}
            value={modalData.name}
            placeholder="Task Name"
            error={errorModalData.errorName}
          />
        </Box>
        <HStack w="100%" align="flex-end">
          <Flex fontWeight={600}>
            <Text>Request</Text>
            <Text color="danger.500">*</Text>
          </Flex>

          <Spacer />
          <Box color="warning.600" pb={2}>
            <PopoverWarning warning={warningModalData.warningRequestGeneric} />
          </Box>
          <Box position="relative">
            <WDInputFile
              setWarning={handleWarningRequest}
              setValue={handleRequest}
              setDisabled={setDisabledRequest}
              disabled={disabledRequest}
              fileName={requestFileName}
              setFileName={setRequestFileName}
            />
          </Box>
        </HStack>

        <WDTextArea
          isDisabled={disabledRequest}
          id={tasksIdFieldsInterface.idRequestGeneric}
          onChange={handleRequest}
          value={modalData.requestGeneric}
          placeholder="Request Method"
          error={errorModalData.errorRequestGeneric}
        />
      </VStack>

      <VStack w="100%" py={2} spacing={0} align="flex-start">
        <HStack w="100%" align="center">
          <Flex fontWeight={600}>
            <Text>Response</Text>
            <Text color="danger.500">*</Text>
          </Flex>
          <Box color="warning.600" pb={2}>
            <PopoverWarning
              warning={warningModalData.warningExpectedResponse}
            />
          </Box>
          <Spacer />
          <Box position="relative">
            <WDInputFile
              setWarning={handleWarningResponse}
              setValue={handleExpectedResponse}
              setDisabled={setDisabledResponse}
              disabled={disabledResponse}
              fileName={responseFileName}
              setFileName={setResponseFileName}
            />
          </Box>
        </HStack>
        <WDTextArea
          isDisabled={disabledResponse}
          id={tasksIdFieldsInterface.idExpectedResponse}
          onChange={handleExpectedResponse}
          value={modalData.expectedResponse}
          placeholder="Expected Response"
          error={errorModalData.errorExpectedResponse}
        />
        <HStack justify="center" align={"center"} pt={3}>
          <Box>Ignore response?</Box>
          <Switch
            isChecked={modalData.ignoreResponse}
            onChange={() => {
              handleSwitch();
            }}
          />
        </HStack>
      </VStack>
    </>
  );
};
