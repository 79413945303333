import { ErrorsModalServerInterface, ModalServerInterface } from "../types";

export const defaultData: ModalServerInterface = {
  projectId: "",
  serverId: "",
  jobId: "",
  projectName: "",
  serverName: "",
  enviroments: ["development", "qa", "production"],
  IPv4Address: "",
  IPv6Address: "",
  status: 20,
  isProduction: false,
  isEnabled: true,
  startDateTime: new Date().toISOString(),
  reccuringTime: "1",
  notificationLevel: "",
  timeout: "15",
  levelTimeoutNotification: "30",
};
export const defaultErrorModalData: ErrorsModalServerInterface = {
  errorServerName: "",
  errorEnviroments: "",
  errorIPv4Address: "",
  errorIPv6Address: "",
  errorStartDateTime: "",
  errorRecurringTime: "",
  errorNotificationLevel: "",
  errorTimeout: "",
  errorLevelTimeoutNotification: "",
};
