import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React from "react";

interface StepBarProps {
  step: number;
  setStep: (val: number) => void;
}

export const StepBar: React.FC<StepBarProps> = ({ step, setStep }) => {
  const colorButtonPrimary = useColorModeValue("blue.800", "blue.400");
  const colorButtonSecondary = useColorModeValue(
    "neutralGrey",
    "darkThemeGrey.100"
  );
  return (
    <Flex>
      {[0, 1, 2].map((i) => {
        return (
          <Box
            key={i}
            onClick={() => {
              setStep(i);
            }}
            width={16}
            backgroundColor={
              step === i ? colorButtonPrimary : colorButtonSecondary
            }
            height={2}
            marginLeft={1}
            borderRadius={5}
            style={{ cursor: "pointer" }}
          />
        );
      })}
    </Flex>
  );
};
