import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { motion, Variants } from "framer-motion";
import React, { useContext, useState } from "react";
import { ErrorContext } from "../../App";
import { WDConfirmDelete } from "../Common/WDConfirmDelete";
import { ManageThresholdsModal } from "../ProjectDetailsPage/ManageThresholdsModal";
import { ThresholdsInterface } from "../ProjectDetailsPage/ManageThresholdsModal/types";
import { apiClient, authorise } from "../utils/apiClient";
import { ThresholdTemplateInterface } from "./types";

interface TableRowProps {
  index: number;
  template: ThresholdTemplateInterface;
  allTemplates: ThresholdTemplateInterface[];
  editTemplate: (val: ThresholdTemplateInterface[]) => void;
}

export const TableRow: React.FC<TableRowProps> = ({
  index,
  template,
  allTemplates,
  editTemplate,
}) => {
  const errorContext = useContext(ErrorContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const colorTextSecondary = useColorModeValue("blue.700", "darkThemeGrey.100");
  const rowVariants: Variants = {
    offscreen: {
      y: 100,
    },
    onscreen: {
      y: 0,

      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };
  const MotionTr = motion(Tr);

  return (
    <MotionTr
      fontWeight="600"
      initial={index <= 7 ? "onscreen" : "offscreen"}
      variants={rowVariants}
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      <Td>
        <Text>{template.name}</Text>
      </Td>
      <Td color={colorTextSecondary}>
        <Text
          onClick={() => {
            setIsOpen(true);
          }}
          cursor="pointer"
        >
          Edit
        </Text>
        <ManageThresholdsModal
          template={true}
          templateName={template.name}
          initialValues={template.value}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSuccess={(
            val: ThresholdTemplateInterface | ThresholdsInterface
          ) => {
            apiClient
              .put(`/api/template/edit/${template.id}`, val, authorise())
              .then((res) => {
                errorContext.createToast("Templated edited successfully!");
                editTemplate(
                  allTemplates.map((t) => {
                    if (t.id === template.id) {
                      return res.data;
                    } else {
                      return t;
                    }
                  })
                );
              })
              .catch((err) => {
                errorContext.createError([...err.response.data]);
              });
          }}
        />
      </Td>

      <Td cursor="pointer" textColor="red.500">
        <WDConfirmDelete
          id={template.name}
          handleDelete={(id, onClose) => {
            apiClient
              .delete(`/api/template/delete/${template.id}`, authorise())
              .then((res) => {
                editTemplate(
                  allTemplates.filter((t) => {
                    return t.name !== template.name;
                  })
                );
              })
              .catch((err) => {
                errorContext.createError([...err.response.data]);
              })
              .finally(() => {
                onClose();
              });
          }}
        >
          <Text>Delete</Text>
        </WDConfirmDelete>
      </Td>
    </MotionTr>
  );
};
