import { ModalDataTaskInterface, optionTaskRequestMethod } from "../types";
import { defaultModalData } from "./defaultObjects";

const curlHeaderFormatter = (curl: string, type: "-H" | "--header") => {
  return curl
    .split(type)
    .slice(1)
    .map((curlH, index) => {
      if (curlH.includes("'"))
        return {
          keyV: curlH.split("'")[1]?.split(":")[0],
          value: curlH.split("'")[1]?.split(":")[1],
          index: index.toString(),
        };
      else
        return {
          keyV: curlH.split('"')[1]?.split(":")[0],
          value: curlH.split('"')[1]?.split(":")[1],
          index: index.toString(),
        };
    });
};
const curlMethodFormatter = (curl: string) => {
  return optionTaskRequestMethod.filter((optM) =>
    curl.split("\\")[0]?.includes(optM.display.toUpperCase())
  )[0].value;
};

const curlRouteFormatter = (curl: string, type: "-X" | "--request") => {
  if (type === "-X")
    return curl
      .split("\\")[1]
      ?.split("?")[0]
      ?.replaceAll("'", "")
      .replaceAll("?", "")
      ?.replaceAll("'", "");
  else
    return curl.split("\\")[0]?.split(" ")[4]?.replaceAll("'", "").slice(0, -1);
};

const curlQuerryParamsFormatter = (curl: string, type: "-X" | "--request") => {
  if (type === "-X")
    return curl.split("\\")[1]?.split("?")[1]?.replaceAll("'", "");
  else return curl.split("\\")[0]?.split("?")[1]?.replaceAll("'", "");
};

const curlDataFormatter = async (
  curl: string,
  createError: (error: string[]) => void,
  setFormatRequestBody: (format: number) => void
) => {
  if (curl.includes("--data-raw")) {
    if (curl.includes("xml")) {
      //IF DATA TYPE IS XML SET TEXTAREA FORMATTER TO 10 == XML TYPE
      setFormatRequestBody(10);
      return curl.split("--data-raw")[1].replaceAll("'", "");
    } else
      try {
        const JSON_Request = JSON.stringify(
          await JSON.parse(curl.split("--data-raw")[1].split("'")[1]),
          null,
          2
        );
        //IF DATA TYPE IS JSON SET TEXTAREA FORMATTER TO 0 == JSON TYPE
        setFormatRequestBody(0);
        return JSON_Request;
      } catch {
        createError(["JSON_NOT_VALID"]);
        return "";
      }
  } else if (curl.includes("--form")) {
    //IF DATA TYPE IS raw SET TEXTAREA FORMATTER TO 0 == RAW TYPE
    setFormatRequestBody(20);
    return curl.split("--form")[1].replaceAll("'", "");
  } else if (curl.includes("--data-binary")) {
    //IF DATA TYPE IS raw SET TEXTAREA FORMATTER TO 0 == RAW TYPE
    setFormatRequestBody(20);
    return curl.split("--data-binary")[1].replaceAll("'", "");
  } else if (curl.includes("-d"))
    try {
      const JSON_Request = JSON.stringify(
        await JSON.parse(curl.split("-d")[1].split("'")[1]),
        null,
        2
      );
      return JSON_Request;
    } catch {
      createError(["JSON_NOT_VALID"]);
      return "";
    }
  else return "";
};

export const extractDataFromCurl = async (
  curl: string,
  createError: (error: string[]) => void,
  setModalData: (modalData: ModalDataTaskInterface) => void,
  setFormatRequestBody: (format: number) => void,
  modalData: ModalDataTaskInterface,
  setHeaderNumber: (numberOfHeaders: number) => void
) => {
  if (!curl.includes("curl")) {
    createError(["CURL_NOT_VALID"]);
    return; //Check if its curl
  }

  if (curl.includes("-H") || curl.includes("-X")) {
    setHeaderNumber(curlHeaderFormatter(curl, "-H").length);
    //IF ITS CURL FROM SWAGGER DO:
    setModalData({
      ...defaultModalData,
      ...modalData,
      taskHeaders: curlHeaderFormatter(curl, "-H"),
      requestBody: await curlDataFormatter(
        curl,
        createError,
        setFormatRequestBody
      ).then((res) => {
        return res;
      }),
      httpMethods: curlMethodFormatter(curl),
      route: curlRouteFormatter(curl, "-X"),
      queryParameters:
        curlQuerryParamsFormatter(curl, "-X") === "="
          ? ""
          : curlQuerryParamsFormatter(curl, "-X"),
    });
  } else if (curl.includes("--headers") || curl.includes("--request")) {
    setHeaderNumber(curlHeaderFormatter(curl, "--header").length);
    //IF ITS CURL FROM POSTMAN DO
    setModalData({
      ...defaultModalData,
      ...modalData,
      taskHeaders: curlHeaderFormatter(curl, "--header"),
      httpMethods: curlMethodFormatter(curl),
      route: curlRouteFormatter(curl, "--request"),
      queryParameters:
        curlQuerryParamsFormatter(curl, "--request") === "="
          ? ""
          : curlQuerryParamsFormatter(curl, "--request"),
      requestBody: await curlDataFormatter(
        curl,
        createError,
        setFormatRequestBody
      ).then((res) => {
        return res || "";
      }),
    });
  }
};
