import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

interface InfoWrapperProps {
  pictureURL: string;
  text: string;
}

export const InfoWrapper: React.FC<InfoWrapperProps> = ({
  pictureURL,
  text,
}) => {
  return (
    <Flex pl={8} pr={8} pb={4} w="100%" align="center" justify="space-around">
      <Image src={pictureURL} w={450} />
      <Flex
        p={20}
        direction="column"
        align="center"
        justify="center"
        w="100%"
        h="100%"
      >
        <Text
          textAlign="left"
          color={useColorModeValue("blue.700", "blue.100")}
          fontWeight="500"
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};
