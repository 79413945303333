import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import React, { createContext, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NavBarHeight } from "../Dashboard/DashboardWrapper/DashboardWrapper";
import { SideBar } from "./SideBar";
import { HelpCenterContextInterface } from "./types";
import { useHelpCenter } from "./useHelpCenter";
import { navigateToTopic } from "./utils";
export const HelpCenterContext = createContext<HelpCenterContextInterface>(
  {} as HelpCenterContextInterface
);
interface HelpCenterProps {
  children?: ReactNode;
}
export const HelpCenter: React.FC<HelpCenterProps> = ({ children }) => {
  const colorText = useColorModeValue("blue.700", "darkThemeGrey.100");

  const location = useLocation();
  useEffect(() => {
    const id = location.hash.replace("#", "");
    navigateToTopic(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const helpHook = useHelpCenter();
  return (
    <HelpCenterContext.Provider value={helpHook}>
      <HStack
        w="100%"
        alignItems={"flex-start"}
        color={colorText}
        h="100%"
        px={4}
        spacing={4}
      >
        <Box
          w="25%"
          pt={4}
          h={`calc(100vh - ${NavBarHeight})`}
          maxH={`calc(100vh - ${NavBarHeight})`}
        >
          <SideBar />
        </Box>
        <Box h="100%" w="75%" overflow={"auto"} pt={12} pb={40}>
          {children}
        </Box>
      </HStack>
    </HelpCenterContext.Provider>
  );
};
