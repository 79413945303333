import { useDisclosure } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { ErrorContext } from "../../App";
import { apiClient, authorise } from "../utils/apiClient";
import {
  ModalServiceContextInterface,
  ModalServiceErrorsInterface,
  ModalServiceInterface,
} from "./type";
import {
  createPostObject,
  setCurrentProject,
  setCurrentProjectEdit,
  setDataModalEdit,
} from "./Utils/ApiHandling";
import {
  defaultDataModal,
  defaultErrorsDataModal,
} from "./Utils/DefaultObject";
import {
  checkErrors,
  checkInfoForJobPage,
  checkInfoForTheFirstPage,
} from "./Utils/Validators";
import { ServiceModalProps } from "./WDServiceModal";

export const useModal = (
  currentInfo: ServiceModalProps
): ModalServiceContextInterface => {
  const errorContext = useContext(ErrorContext);
  const { currentProject, currentServer, idService } = { ...currentInfo };

  const [errorsData, setErrorsData] = useState<ModalServiceErrorsInterface>(
    defaultErrorsDataModal
  );
  const [spinnerLoading, setSpinnerLoading] = useState<boolean>(
    idService === ""
  );

  const [loading, setLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();
  const [maxStep, setMaxStep] = useState<number>(2);
  const [step, setStep] = useState<number>(0);
  const [dataModal, setDataModal] = useState<ModalServiceInterface>({
    ...defaultDataModal,

    ipV4: currentInfo.currentServer.ipAddress,
  });
  const [initialDataModal, setInitialDataModal] =
    useState<ModalServiceInterface>({
      ...defaultDataModal,
      ipV4: currentInfo.currentServer.ipAddress,
    });
  const changeData = (
    partialModalData: Partial<ModalServiceInterface>,
    partialErrorModalData: Partial<ModalServiceErrorsInterface>
  ) => {
    setErrorsData({ ...errorsData, ...partialErrorModalData });
    setDataModal({
      ...dataModal,
      ...partialModalData,
    });
  };

  const handleNext = (nextStep: number) => {
    setLoading(false);
    if (
      step === 0 &&
      checkErrors(checkInfoForTheFirstPage(dataModal, setErrorsData), step)
    ) {
      setTimeout(() => {
        setStep(nextStep);
      }, 200);
    }
    if (
      step !== 0 &&
      checkErrors(checkInfoForJobPage(dataModal, setErrorsData), step)
    ) {
      setTimeout(() => {
        setStep(nextStep);
      }, 200);
    }
  };

  const handleSave = async () => {
    if (checkErrors(checkInfoForJobPage(dataModal, setErrorsData), step)) {
      setLoading(true);

      apiClient
        .post(
          `api/services/create/${currentInfo.currentProject.projectId}/${currentInfo.currentServer.serverId}`,
          createPostObject(dataModal),
          authorise()
        )
        .then((res) => {
          errorContext.createToast("You have created a service");
          setCurrentProject(currentInfo, {
            data: { ...res.data, ...res.data.serviceJob },
          });
          reset();
        })
        .catch((err) => {
          errorContext.createError([...err.response.data]);
          reset();
        });
    }
  };

  const reset = () => {
    onClose();
    setDataModal({
      ...defaultDataModal,
      ipV4: currentInfo.currentServer.ipAddress,
    });
    setErrorsData(defaultErrorsDataModal);
    setStep(0);
    setMaxStep(2);
  };
  const onOpenEdit = async () => {
    await apiClient
      .get(`/api/services/${idService}`, authorise())
      .then((res) => {
        onOpen();
        setDataModalEdit(
          { data: { ...res.data, ...res.data.job } },
          setDataModal
        );
        setDataModalEdit(
          { data: { ...res.data, ...res.data.job } },
          setInitialDataModal
        );
        setSpinnerLoading(false);
      })
      .catch((err) => errorContext.createError([...err.response.data]));
  };
  const handleSaveEditChanges = () => {
    if (checkErrors(checkInfoForJobPage(dataModal, setErrorsData), step)) {
      setLoading(true);

      apiClient
        .put(
          `/api/services/update/${idService}`,
          createPostObject(dataModal),
          authorise()
        )

        .then((res) => {
          errorContext.createToast("You have edited the service");
          setCurrentProjectEdit(currentInfo, {
            data: { ...res.data, ...res.data.serviceJob },
          });
          reset();
        })
        .catch((err) => {
          errorContext.createError([...err.response.data]);
          reset();
        });
    }
  };
  const onCloseBigModal = () => {
    if (!currentInfo.idService) {
      if (
        JSON.stringify({ ...dataModal, ipV4: "" }) ===
        JSON.stringify(defaultDataModal)
      ) {
        reset();
      } else onOpenConfirm();
    } else if (
      JSON.stringify({ ...dataModal }) === JSON.stringify(initialDataModal)
    ) {
      reset();
    } else {
      onOpenConfirm();
    }
  };
  return {
    onCloseBigModal,
    onCloseConfirm,
    isOpenConfirm,
    handleSaveEditChanges,
    onOpenEdit,
    spinnerLoading,
    idService,
    loading,
    currentProject,
    currentServer,
    onOpen,
    isOpen,
    errorsData,
    changeData,
    maxStep,
    step,
    setStep,
    dataModal,
    setDataModal,
    reset,
    handleNext,
    handleSave,
  };
};
