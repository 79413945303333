import { Box, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { DisabledInput } from "../DisabledInput";
import { StepBar } from "../StepBar";
import { ModalTaskContext } from "../WDTaskModal";

import { FirstStepFooter } from "./FirstStepFooter";
import { InputFieldsForGenericType } from "./InputFieldsForGenericType";
import { InputsForWebApiType } from "./InputsForWebApiType";

export const FirstStep: React.FC = () => {
  const { modalData } = useContext(ModalTaskContext);

  return (
    <VStack w="100%" py={4} overflowY="hidden" minH="fit-content">
      <Box w="100%" h="100%" top={0}>
        <StepBar />
        <DisabledInput />
      </Box>
      <VStack overflowY={"auto"} h={380} w="100%" px={2}>
        {modalData.taskType === "0" ? (
          <InputFieldsForGenericType />
        ) : (
          <InputsForWebApiType />
        )}
      </VStack>
      <FirstStepFooter />
    </VStack>
  );
};
