import { Avatar, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { UserContext } from "../../../App";

export const ProfileInfo: React.FC = () => {
  const contextUser = useContext(UserContext);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      h={174}
      justifyContent="center"
      mt={-3}
      borderBottomWidth={1}
      borderColor={useColorModeValue("neutralGrey", "darkThemeGrey.200")}
      mb={3}
    >
      <Avatar
        fontWeight={600}
        name={contextUser?.user?.lastName + " " + contextUser?.user?.firstName}
        bg={useColorModeValue("blue.700", "blue.400")}
        color={"white"}
        mb={2}
        boxSize={16}
        src={contextUser?.user?.profilePictureUrl ?? ""}
      />
    </Flex>
  );
};
