import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorContext } from "../../App";
import { ReactComponent as GoogleIcon } from "../../Assets/GoogleIcon.svg";
import { CustomSpinner } from "../Common/Spinner";
import { apiClient, GOOGLE_CLIENT_ID, REDIRECT_URL } from "../utils/apiClient";
import { currentStepEnum } from "./LoginComponent";
interface WelcomeBackProps {
  setCurrentStep: (currentStep: currentStepEnum) => void;
  setQRCode: (QRCode: string) => void;
  setClientJWT: (clientJWT: string) => void;
}
export const WelcomeBackComponent: React.FC<WelcomeBackProps> = ({
  setCurrentStep,
  setClientJWT,
  setQRCode,
}) => {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const errContext = useContext(ErrorContext);

  const location = useLocation();
  const handleGoogleClick = () => {
    const form = document.getElementById("google-sign-in-form");
    const currentDate = new Date();

    const params = {
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: `${REDIRECT_URL}login`,
      response_type: "id_token",
      scope: "openid email profile",
      nonce: currentDate.toISOString(),
      state: "pass-through value",
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
      //@@ts-expect-error hello there
      var input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p); //@ts-expect-error hello there
      input.setAttribute("value", params[p]);
      form?.appendChild(input);
    }

    //@ts-expect-error no-form-preset
    form?.submit();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.hash);

    if (searchParams.get("id_token")) {
      setLoadingState(true);
      apiClient
        .get(
          `/api/authentication/verify-google-token?clientJWT=${searchParams.get(
            "id_token"
          )}`
        )
        .then((res) => {
          console.log(res.data);

          if (res.data.qrCode) {
            setCurrentStep("QR");
            setQRCode(res.data.qrCode);
          } else setCurrentStep("2FACode");
          setClientJWT(res.data.clientJWT);
          // localStorage.setItem("accesToken", res.data.jwtToken);
          // userContext?.logIn();
        })
        .catch((err) => {
          errContext?.createError([...err.response.data], {});
          navigate("/login");
          setLoadingState(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadingState ? (
    <CustomSpinner />
  ) : (
    <Flex
      bgColor={"blue.100"}
      maxW="700px"
      height="40%"
      minHeight="300px"
      borderRadius={8}
      borderColor={"neutralGrey"}
      flexDirection={"column"}
      justifyContent={"space-evenly"}
      alignItems={"center"}
      textAlign={"center"}
      style={{ position: "relative" }}
      p={6}
      color="blue.800"
    >
      <Heading size="lg">Welcome back </Heading>
      <Text mt={6} mb={6} fontSize="xl">
        Connect to WatchDog App using your gmail account
      </Text>

      <form
        onSubmit={handleGoogleClick}
        id="google-sign-in-form"
        method="GET"
        action="https://accounts.google.com/o/oauth2/v2/auth"
      ></form>
      <Button
        border="1px"
        borderColor={"neutralGrey"}
        borderRadius={4}
        bgColor={"blue.100"}
        width="80%"
        height="60%"
        minHeight="120px"
        flexDirection={"column"}
        leftIcon={<GoogleIcon />}
        onClick={handleGoogleClick}
      >
        Send me to Google.
      </Button>
    </Flex>
  );
};
