import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as Trash } from "../../Assets/ModalTrashIcon/trash.svg";
import { theme } from "../../theme";
import { customNotifications } from "../../Utils/defaultObjects";

import { SelectOptionsInterface } from "../Common/types";
import { WDDropdown } from "../Common/WDDropdown";
import { WDSearchSelect } from "../Common/WDSearchSelect";
import {
  ModalTypesInterface,
  ProjectsEmailInterface,
  ProjectsUserInterface,
} from "./types";

interface UserInputProps {
  disabled?: boolean;
  onChange: (val: ModalTypesInterface) => void;
  onDelete: (val: number) => void;
  value: ProjectsUserInterface;
  currentUserName: string;
  index: number;
  initialData: ModalTypesInterface;
  users: ProjectsEmailInterface[];
  roles: SelectOptionsInterface[];
}

export const UserInput: React.FC<UserInputProps> = ({
  disabled,
  onChange,
  onDelete,
  value,
  currentUserName,
  index,
  initialData,
  users,
  roles,
}) => {
  const trashClickEventHandler = () => {
    if (!disabled) {
      onDelete(index);
    }
  };

  const getNotifValue = (val: string) => {
    for (let notif of customNotifications) {
      if (notif.value === val) {
        return `${notif.value}`;
      }
    }

    return "";
  };
  const allUsersId = initialData.users
    .filter((user) => user.id)
    .map((user) => {
      return user.id;
    });

  return (
    <Flex justifyContent={"space-between"} marginTop={5} h="fit-content">
      <VStack width={"13em"} align="stretch" spacing={0}>
        <Box>
          <Text>User's email</Text>
        </Box>
        <Box h="100%" pb={2}>
          <WDSearchSelect
            isDisabled={index === 0 ? true : false}
            onChange={(val) => {
              onChange({
                ...initialData,
                users: initialData.users.map((user, i) => {
                  if (i === index) {
                    return {
                      id: val ?? "",
                      role: value.role,
                      notificationLevel: value.notificationLevel,
                    };
                  } else {
                    return user;
                  }
                }),
              });
            }}
            options={[
              ...users.filter((user) => !allUsersId.includes(user.value)),
              {
                value: initialData.users[index].id || "",
                label: currentUserName,
              },
            ]}
            value={initialData.users[index].id || "empty"}
          />
        </Box>
      </VStack>
      <Box>
        <Text>Role</Text>
        <WDDropdown
          onChange={(val) => {
            onChange({
              ...initialData,
              users: initialData.users.map((user, i) => {
                if (i === index) {
                  return {
                    id: value.id,
                    role: val,
                    notificationLevel: value.notificationLevel,
                  };
                } else {
                  return user;
                }
              }),
            });
          }}
          options={roles}
          placeholder="Select role"
          isDisabled={disabled ?? false}
          width={"13em"}
          value={value ? value.role : ""}
        />
      </Box>
      <Box>
        <Text>Notification Level</Text>
        <WDDropdown
          onChange={(val) => {
            onChange({
              ...initialData,
              users: initialData.users.map((user, i) => {
                if (i === index) {
                  return {
                    id: value.id,
                    role: value.role,
                    notificationLevel: getNotifValue(val),
                  };
                } else {
                  return user;
                }
              }),
            });
          }}
          options={customNotifications}
          placeholder="Select notification level"
          width={"13em"}
          value={value ? value.notificationLevel : ""}
        />
      </Box>
      <Flex alignItems={"center"} direction="column">
        <Box
          style={{ backgroundColor: "inherit", height: "2em", width: 1 }}
        ></Box>
        <Trash
          onClick={trashClickEventHandler}
          cursor={disabled ? "inherit" : "pointer"}
          stroke={
            (disabled && theme.colors.neutralGrey) || theme.colors.danger[500]
          }
        />
      </Flex>
    </Flex>
  );
};
