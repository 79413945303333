import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TaskLogsDataContext } from ".";
import { theme } from "../../theme";
import { OnlineStatus } from "../ProjectsComponent/OnlineStatus/OnlineStatus";
import { TabsTaskPage } from "./TabsTaskPageComponent";

export const TaskLogs: React.FC = () => {
  const taskLogsContext = useContext(TaskLogsDataContext);
  const tabsName = ["Last 24 hours", "Last week", "Last month"];
  const colorPrimary = useColorModeValue("blue.700", "blue.400");
  const colorText = useColorModeValue("neutralGrey", "darkThemeGrey.100");
  const colorDescription = useColorModeValue("blue.900", "darkThemeGrey.100");
  const navigate = useNavigate();
  const handleNavigate = (projectId: string, serverId: string) => {
    navigate("/dashboard/" + projectId + "/" + serverId + "/logs");
  };
  return taskLogsContext.loading ? (
    <Flex h="80vh" justifyContent={"center"} alignItems={"center"}>
      <Spinner
        size="xl"
        color="blue.500"
        thickness="4px"
        speed="0.65s"
        emptyColor="blue.100"
      />
    </Flex>
  ) : (
    <Box height="100%" overflowY="auto">
      <HStack w={"100%"} px={6} py={3}>
        <Flex alignItems={"flex-end"}>
          <Flex
            mr={4}
            mb={1}
            alignItems={"center"}
            justifyContent={"center"}
            w={8}
            h={8}
            borderRadius="100%"
            bgColor={"blue.100"}
            cursor="pointer"
            onClick={() => {
              handleNavigate(
                taskLogsContext.taskDetails.projectId,
                taskLogsContext.taskDetails.serverId
              );
            }}
          >
            <ArrowBackIcon
              w={5}
              h={5}
              cursor="pointer"
              stroke={"blue.700"}
              color="blue.700"
            />
          </Flex>
          <Box mb={1}>
            <OnlineStatus
              status={taskLogsContext.taskDetails.projectStatus}
              type={5}
            />
          </Box>

          <Flex
            ml={4}
            alignItems="center"
            h="100%"
            fontWeight="bold"
            fontSize="3xl"
          >
            {taskLogsContext.taskDetails.projectName}
          </Flex>
        </Flex>
      </HStack>

      <Box w={"100%"} px={6} ml={3}>
        <Text fontWeight={"bold"} color={colorDescription} mb={1}>
          Description
        </Text>
        <Text color={theme.colors.neutralGrey}>
          {taskLogsContext.taskDetails.projectDescription}
        </Text>
      </Box>

      <Box key={taskLogsContext.taskDetails.taskId} mx={6} my={2}>
        <Spacer />
        <Tabs
          variant="unstyled"
          mt={5}
          onChange={(index) => taskLogsContext.setTabIndex(index)}
          index={taskLogsContext.tabIndex}
        >
          <TabList>
            <Flex ml={3}>
              <Flex direction={"column"}>
                <Text fontSize={20} fontWeight="bold">
                  {taskLogsContext.taskDetails.taskName} task's logs
                </Text>
                <Text fontSize={15} fontWeight="600" color="neutralGrey">
                  {`Server: ${taskLogsContext.taskDetails.serverName} `}
                </Text>
              </Flex>
            </Flex>
            <Spacer />
            {tabsName.map((tab, i) => {
              return (
                <Tab
                  tabIndex={i}
                  key={i}
                  color={colorText}
                  fontWeight="semibold"
                  _selected={{ color: colorPrimary, fontWeight: "bold" }}
                >
                  {tab}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            <TabsTaskPage />
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
