import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { ChangeEvent, useContext } from "react";
import { WDInput } from "../../Common/WDInput";
import { DeleteHeader } from "./DeleteHeader";
import { TaskHeader } from "../types";
import { ModalTaskContext } from "../WDTaskModal";

export const TaskHeaderComponent: React.FC<TaskHeader> = ({
  keyV,
  value,
  id,
  index,
}) => {
  const { handleChangeHeader } = useContext(ModalTaskContext);
  const handleKey = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeHeader({ index: index, keyV: e.target.value });
  };
  const handleValue = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeHeader({ index: index, value: e.target.value });
  };

  return (
    <>
      <HStack
        w="100%"
        h="100%"
        align={"center"}
        justify="center"
        fontWeight={600}
      >
        <VStack align={"flex-start"} spacing={0} w="30%">
          <Box>Key</Box>
          <WDInput value={keyV} onChange={handleKey} placeholder="Key" />
        </VStack>

        <Box fontSize={20} fontWeight={600} pt={2}>
          {" "}
          :
        </Box>
        <VStack align={"flex-start"} spacing={0} w="100%">
          <Box>Value</Box>
          <WDInput value={value} onChange={handleValue} placeholder="Value" />
        </VStack>
        <DeleteHeader index={index} />
      </HStack>
    </>
  );
};
