import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export type SelectedInterval = "24h" | "7d" | "30d";

interface TimeIntervalWrapperProps extends PropsWithChildren {
  onIntervalChange: (val: SelectedInterval) => void;
  title: string;
  isConfigured?: boolean;
  initialInterval?: SelectedInterval;
  seeFullDetailsUrl?: string;
  hasTitleIcon?: boolean;
  onResourcePage?: () => void;
}

export const TimeIntervalWrapper: React.FC<TimeIntervalWrapperProps> = ({
  onIntervalChange,
  initialInterval,
  title,
  isConfigured,
  children,
  seeFullDetailsUrl,
  hasTitleIcon,
  onResourcePage,
}) => {
  const [selectedInterval, setSelectedInterval] = useState<SelectedInterval>(
    initialInterval ?? "24h"
  );

  const textColor = useColorModeValue("gray.400", "gray.600");
  const selectedColor = useColorModeValue("black", "white");

  useEffect(() => {
    if (initialInterval !== selectedInterval)
      onIntervalChange(selectedInterval);
  }, [selectedInterval]);

  useEffect(() => {
    if (selectedInterval !== initialInterval && initialInterval) {
      setSelectedInterval(initialInterval);
    }
  }, [initialInterval]);
  return (
    <Box
      w="100%"
      my={1}
      py={1}
      px={2}
      border="0px solid"
      borderBottom={`1px solid`}
      borderColor={textColor}
    >
      <Flex w="100%" justifyContent={"space-between"}>
        <Flex
          cursor={onResourcePage && isConfigured ? "pointer" : "default"}
          onClick={isConfigured ? onResourcePage : undefined}
        >
          <Text fontWeight={"semibold"} fontSize="lg">
            {title}{" "}
          </Text>
          {hasTitleIcon && isConfigured === true ? (
            <ExternalLinkIcon fontSize={20} mx={2} mt={1} />
          ) : (
            <></>
          )}
        </Flex>

        <Flex gap={4} fontWeight="semibold">
          <Flex gap={4} color={textColor}>
            <Box
              cursor="pointer"
              _hover={{
                color: selectedColor,
              }}
              color={selectedInterval === "24h" ? selectedColor : "inherit"}
              onClick={() => setSelectedInterval("24h")}
            >
              <Text>{"24 h"}</Text>
            </Box>
            <Box
              cursor="pointer"
              _hover={{
                color: selectedColor,
              }}
              color={selectedInterval === "7d" ? selectedColor : "inherit"}
              onClick={() => setSelectedInterval("7d")}
            >
              <Text>{"7 d"}</Text>
            </Box>
            <Box
              cursor="pointer"
              _hover={{
                color: selectedColor,
              }}
              color={selectedInterval === "30d" ? selectedColor : "inherit"}
              onClick={() => setSelectedInterval("30d")}
            >
              <Text>{"30 d"}</Text>
            </Box>

            {seeFullDetailsUrl && isConfigured === true && (
              <Link to={seeFullDetailsUrl}>
                <Flex
                  ml={2}
                  pl={2}
                  color={selectedColor}
                  borderLeft="2px solid"
                  borderColor={selectedColor}
                  alignItems="center"
                >
                  <ExternalLinkIcon fontSize={20} mx={2} />
                  See full details
                </Flex>
              </Link>
            )}
          </Flex>
        </Flex>
      </Flex>

      {children}
    </Box>
  );
};
