import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { ModalServerContext } from ".";
import { customNotifications } from "../../Utils/defaultObjects";
import {
  requiredField,
  validatorNumericField,
} from "../../Utils/ValidatorsForInput";
import { WDDatePicker } from "../Common/WDDatePicker";
import { WDDropdown } from "../Common/WDDropdown";
import { WDInput } from "../Common/WDInput";

import { SelectOptionsInterface } from "./types";

export const StepTwoAddNewServerComponent: React.FC = () => {
  const options: SelectOptionsInterface[] = [
    { value: "1", display: "Yes", color: "neutralDarkGrey" },
    { value: "0", display: "No", color: "neutralDarkGrey" },
  ];
  const {
    modalDataServer,
    setModalDataServer,
    errorServerDataModal,
    setErrorServerDataModal,
  } = useContext(ModalServerContext);
  const handleEnabledSelectChange = (value: string) => {
    setModalDataServer({
      ...modalDataServer,
      isEnabled: value === "1" ? true : false,
    });
  };

  const handleStartDateTimeChange = (value: string) => {
    setErrorServerDataModal({
      ...errorServerDataModal,
      errorStartDateTime: requiredField(value),
    });

    setModalDataServer({ ...modalDataServer, startDateTime: value });
  };

  const handleRecurringTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrorServerDataModal({
      ...errorServerDataModal,
      errorRecurringTime:
        requiredField(e.target.value) ||
        validatorNumericField(e.target.value) ||
        e.target.value === "0"
          ? "Recurring Time cannot be 0"
          : "" || parseInt(e.target.value) > 1000
          ? "Input too big"
          : "",
    });

    setModalDataServer({ ...modalDataServer, reccuringTime: e.target.value });
  };

  const handleNotificationLevelSelectChange = (value: string) => {
    setErrorServerDataModal({
      ...errorServerDataModal,
      errorNotificationLevel: requiredField(value),
    });

    setModalDataServer({ ...modalDataServer, notificationLevel: value });
  };

  const handleTimeoutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorServerDataModal({
      ...errorServerDataModal,
      errorTimeout:
        requiredField(e.target.value) ||
        validatorNumericField(e.target.value) ||
        e.target.value === "0"
          ? "Timeout cannot be 0"
          : "" || parseInt(e.target.value) > 1000
          ? "Input too big"
          : "",
    });

    setModalDataServer({ ...modalDataServer, timeout: e.target.value });
  };

  const handleLevelTimeoutNotificationSelectChange = (value: string) => {
    setErrorServerDataModal({
      ...errorServerDataModal,
      errorLevelTimeoutNotification: requiredField(value),
    });

    setModalDataServer({ ...modalDataServer, levelTimeoutNotification: value });
  };
  const colorPrimary = useColorModeValue("blue.800", "darkThemeGrey.100");
  const colorDisabledInput = useColorModeValue("blue.100", "darkThemeGrey.200");
  const focusBorderColor = useColorModeValue("blue.500", "blue.400");
  return (
    <Flex direction="column" p={2}>
      <FormControl mt={4}>
        <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
          Project's name
        </FormLabel>

        <WDInput
          _disabled={{
            bgColor: colorDisabledInput,
            color: "black",
            fontWeight: 400,
          }}
          isDisabled
          defaultValue={modalDataServer.projectName}
          focusBorderColor={focusBorderColor}
          placeholder="Enter project's name"
        />
      </FormControl>

      <Flex>
        <FormControl mt={4} w="32%" mr={5}>
          <Flex>
            <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
              Enabled
            </FormLabel>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDropdown
            value={modalDataServer.isEnabled ? "1" : "0"}
            h={14}
            options={options}
            onChange={handleEnabledSelectChange}
            placeholder={"Select.."}
          />
        </FormControl>

        <FormControl mt={4} w="32%" mr={5}>
          <Flex>
            <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
              Start Date Time
            </FormLabel>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDatePicker
            value={modalDataServer.startDateTime}
            h={14}
            focusBorderColor={focusBorderColor}
            onChange={handleStartDateTimeChange}
            placeholder="06-06-2022"
            error={errorServerDataModal.errorStartDateTime}
          />
        </FormControl>

        <FormControl mt={4} w="32%">
          <Flex>
            <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
              Recurring time
            </FormLabel>
            <Text color="danger.500">*</Text>
          </Flex>
          <InputGroup>
            <WDInput
              value={modalDataServer.reccuringTime}
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              focusBorderColor={focusBorderColor}
              onChange={handleRecurringTimeChange}
              placeholder="Recurring time"
              h={14}
              error={errorServerDataModal.errorRecurringTime}
            />
            <InputRightElement
              children={"min"}
              mt={2}
              mr={1}
              color={"neutralGrey"}
            />
          </InputGroup>
        </FormControl>
      </Flex>

      <Flex>
        <FormControl mt={4} w="32%" mr={5}>
          <Flex>
            <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
              Notification Level
            </FormLabel>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDropdown
            h={14}
            value={modalDataServer.notificationLevel}
            onChange={handleNotificationLevelSelectChange}
            options={customNotifications}
            placeholder={"Select.."}
            error={errorServerDataModal.errorNotificationLevel}
          />
        </FormControl>
        <FormControl mt={4} w="32%" mr={5}>
          <Flex>
            <FormLabel fontWeight={"normal"} textColor={colorPrimary}>
              Timeout
            </FormLabel>
            <Text color="danger.500">*</Text>
          </Flex>
          <InputGroup>
            <WDInput
              value={modalDataServer.timeout}
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              focusBorderColor={focusBorderColor}
              onChange={handleTimeoutChange}
              placeholder="Timeout"
              h={14}
              error={errorServerDataModal.errorTimeout}
            />
            <InputRightElement
              children={"min"}
              mt={2}
              mr={1}
              color={"neutralGrey"}
            />
          </InputGroup>
        </FormControl>
        <FormControl mt={4} w="32%">
          <Flex>
            <FormLabel
              fontWeight={"normal"}
              textColor={colorPrimary}
              fontSize={"medium"}
            >
              Notification level timeout
            </FormLabel>
            <Text color="danger.500">*</Text>
          </Flex>
          <WDDropdown
            value={modalDataServer.levelTimeoutNotification}
            h={14}
            options={customNotifications}
            onChange={handleLevelTimeoutNotificationSelectChange}
            placeholder={"Select..."}
            error={errorServerDataModal.errorLevelTimeoutNotification}
          />
        </FormControl>
      </Flex>
    </Flex>
  );
};
