import { AccordionPanel, Box, HStack, Spacer } from "@chakra-ui/react";
import React from "react";
import { TaskInterface } from "../Common/types";
import { OnlineStatus } from "./OnlineStatus/OnlineStatus";

interface TaskProps {
  tasks: TaskInterface[];
}
export const Task: React.FC<TaskProps> = (props) => {
  return (
    <React.Fragment>
      {props.tasks.length === 0 && (
        <AccordionPanel w="100%" p={0}>
          <Box
            textAlign={"center"}
            w="100%"
            fontSize={12}
            fontWeight="normal"
            color="gray.500"
            h="30px"
          >
            There are no tasks for this service.
          </Box>
        </AccordionPanel>
      )}
      {props.tasks.map((tas: TaskInterface, i: number) => {
        return (
          <AccordionPanel key={tas.id} w="100%">
            <HStack w="100%">
              <HStack
                w="75%"
                justifyContent="space-evenly"
                pl={12}
                fontWeight={600}
                fontSize={12}
                color="neutralGrey"
                align={"center"}
              >
                <Box
                  w="100%"
                  textAlign={"left"}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  maxW="150px"
                >
                  {tas.name}
                </Box>
                <Box minW="fit-content" w="100%" textAlign={"left"}>
                  {tas.taskType === 0 ? "Generic Task" : "WepAPI Task"}
                </Box>
                <Box
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  maxW="150px"
                  w="100%"
                  textAlign={"left"}
                >
                  {tas.taskType === 0 ? tas.requestBody : tas.route}
                </Box>
              </HStack>
              <Spacer />
              <Box pr={4}>
                <OnlineStatus status={tas.status} type={3} />
              </Box>
            </HStack>
          </AccordionPanel>
        );
      })}
    </React.Fragment>
  );
};
