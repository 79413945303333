import {
  Box,
  Flex,
  IconButton,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { ReactComponent as ScaleIcon } from "../../../Assets/ScaleIcon.svg";
import { theme } from "../../../theme";
import { labels } from "../AreaGraph/settingsObjects";
import { SelectedInterval } from "../TimeIntervalWrapper";
import { LineChart } from "./types";
import { arrWithColors } from "./utils";

interface WDProcessChartProps {
  lineChart?: LineChart;
  selectedInterval: SelectedInterval;
}

export const WDProcessChart: React.FC<WDProcessChartProps> = ({
  lineChart,
  selectedInterval,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const ticksCallback = (value: number | string, index: number, ticks: any) => {
    if (selectedInterval === "24h") {
      if ((index + 1) % 12 === 0) {
        // return moment(new Date(), "HH:mm:ss A")
        //   .add(parseInt(value.toString()), "minutes")
        //   .format("HH:mm");
        return moment(lineChart?.processes[0]?.chartData[index].date).format(
          "HH:mm"
        );
      } else return;
    } else if (selectedInterval === "7d") {
      if ((index + 1) % 41 === 0)
        // return moment(new Date())
        //   .add(parseInt(value.toString()), "minutes")
        //   .format("dddd");
        return moment(lineChart?.processes[0]?.chartData[index].date).format(
          "dddd"
        );
      else return;
    } else if (selectedInterval === "30d") {
      if ((index + 1) % 9 === 0) {
        // return moment(new Date())
        //   .add(parseInt(value.toString()), "minutes")
        //   .format("Do");
        return moment(lineChart?.processes[0]?.chartData[index].date).format(
          "Do"
        );
      } else return;
    }
  };

  const tooltipCallback = (tool: TooltipItem<"line">): string | string[] => {
    if (tool.parsed.y === -1) return "";
    if (selectedInterval === "24h") {
      return `${moment(
        lineChart?.processes[0].chartData[tool.parsed.x].date
      ).format("HH:mm")} - ${Math.round(tool.parsed.y)}%`;
    } else if (selectedInterval === "7d") {
      const date = moment(
        lineChart?.processes[0].chartData[tool.parsed.x].date
      );
      return `${date.format("dddd")} ${date.format("HH:mm")}- ${Math.round(
        tool.parsed.y
      )}%`;
    } else if (selectedInterval === "30d") {
      const date = moment(
        lineChart?.processes[0].chartData[tool.parsed.x].date
      );
      return `${date.format("MMMM Do")} ${date.format("HH:mm")} - ${Math.round(
        tool.parsed.y
      )}%`;
    }
    return "";
  };
  const invisibleLineColor = useColorModeValue(
    "white",
    `${theme.colors.darkThemeGrey[700]}`
  );
  const shadowColor = useColorModeValue("#000", "#fff");
  const chartColor = useColorModeValue(
    theme.colors.blue[800],
    theme.colors.blue[100]
  );
  const [scale, setScale] = useState<boolean>(false);

  const [data, setData] = useState<ChartData<"line">>({ datasets: [] });
  const [options, setOptions] = useState<ChartOptions<any>>({
    elements: {
      point: { radius: 0.1 },
      line: {
        fill: false,
      },
    },
    responsive: true,
    plugins: {
      boxWidth: 7,
      boxHeight: 7,
      tooltip: {
        callbacks: {
          title: () => {
            return "";
          },
          label: tooltipCallback,
        },
      },
      legend: {
        position: "top" as const,
        align: "start" as const,
        labels: {
          boxWidth: 6,
          boxHeight: 6,
          font: {
            size: 14,
            weight: 500,
          },
        },
      },
    },
    scales: {
      y: {
        max: lineChart?.maxPossibleValue,
        min: 0,
        grid: {
          borderColor: `${chartColor}`,
          tickColor: `${chartColor}`,
        },
        ticks: {
          color: `${chartColor}`,
          callback: (value: number | string, index: number, ticks: any) => {
            return value + "%";
          },
        },
      },
      x: {
        grid: {
          borderColor: `${chartColor}`,
          tickColor: `${chartColor}`,
        },
        ticks: {
          color: `${chartColor}`,
          callback: ticksCallback,
        },
      },
    },
  });
  console.log(lineChart);

  const updateGraphData = (scale?: boolean) => {
    const mH = scale ? lineChart?.maxValue : undefined;

    if (lineChart) {
      const data = {
        labels: lineChart?.processes[0]?.chartData.map((c) => {
          return c.date;
        }),
        datasets: [
          {
            label: "",
            data: labels().map((l) => ({ x: l, y: -20 })),
            borderColor: invisibleLineColor,
            backgroundColor: invisibleLineColor,
            borderWidth: 0,
          },

          ...lineChart.processes.map((l, i) => {
            return {
              label: l.name,
              data: l.chartData.map((d, i) => ({
                y: d.percentage,
                x: i,
              })),
              // tension: 1, //

              // borderWidth: 2,
              backgroundColor: arrWithColors[i],
              borderColor: arrWithColors[i],
              // borderDash: [2, l.isSystem ? 8 : 0], //pt linie punctata: 10 reprezinta lungimea dashului si 8 lungimea spatiului dintre dashuri};
            };
          }),
        ],
      };
      const options = {
        elements: {
          point: { radius: 0.1 },
          line: {
            fill: false,
          },
        },
        responsive: true,
        plugins: {
          tooltip: {
            boxWidth: 7,
            boxHeight: 7,
            callbacks: {
              title: () => {
                return "";
              },
              label: tooltipCallback,
            },
          },
          legend: {
            position: "top" as const,
            align: "start" as const,
            labels: {
              boxWidth: 7,
              boxHeight: 6,
              font: {
                size: 14,
                weight: 500,
              },
            },
          },
        },
        scales: {
          y: {
            max: mH ?? lineChart?.maxPossibleValue,
            min: 0,
            grid: {
              borderColor: `${chartColor}`,
              tickColor: `${chartColor}`,
            },
            ticks: {
              color: `${chartColor}`,
              callback: (value: number | string, index: number, ticks: any) => {
                return value + "%";
              },
            },
          },
          x: {
            grid: {
              borderColor: `${chartColor}`,
              tickColor: `${chartColor}`,
            },
            ticks: {
              color: `${chartColor}`,
              callback: ticksCallback,
            },
          },
        },
      };
      setData(data);
      setOptions(options);
    }
  };

  const handleScaleClick = () => {
    setScale(!scale);
    if (selectedInterval === "24h") {
      updateGraphData(!scale);
    } else if (selectedInterval === "7d") {
      updateGraphData(!scale);
    } else if (selectedInterval === "30d") {
      updateGraphData(!scale);
    }
  };

  useEffect(() => {
    if (selectedInterval === "24h") {
      updateGraphData();
    } else if (selectedInterval === "7d") {
      updateGraphData();
    } else if (selectedInterval === "30d") {
      updateGraphData();
    }
  }, [lineChart, chartColor]);

  return (
    <VStack
      w="100%"
      direction={"column"}
      spacing={0}
      align={"start"}
      boxShadow={`0px 0px 6px ${shadowColor}`}
      position={"relative"}
    >
      <Flex
        mr={4}
        mt={1}
        position={"absolute"}
        top={0}
        right={0}
        bg="rgb(0,0,0,0)"
        justify={"center"}
        align="center"
        cursor={"pointer"}
        onClick={() => {
          handleScaleClick();
        }}
      >
        <IconButton
          aria-label="scale button"
          boxSize={4}
          icon={<ScaleIcon />}
          p={0}
          variant={"unstyled"}
        />
        <Box color={chartColor} fontWeight={600} ml={-5}>
          Scale
        </Box>
      </Flex>

      <Line options={options} data={data} height={70} />
    </VStack>
  );
};
