import {
  Heading,
  HStack,
  Spacer,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { dataObject, formerTeamMembers, teamMembers } from "./dataObject";

import { InfoWrapper } from "./InfoWrapper";
import { Members } from "./Members";

export const AboutPage: React.FC = () => {
  return (
    <VStack h={"fit-content"} w={"100%"} marginBottom={16}>
      <HStack w={"100%"} py={6} pl={8} pr={6}>
        <Heading color={useColorModeValue("blue.700", "blue.100")}>
          About 🐕 WatchDog
        </Heading>
        <Spacer />
      </HStack>
      {dataObject.map((data, i) => {
        return (
          <InfoWrapper key={i} pictureURL={data.pictureUrl} text={data.text} />
        );
      })}

      <Members members={teamMembers} />
      <Members former={true} members={formerTeamMembers} />
    </VStack>
  );
};
