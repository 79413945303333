import { TooltipItem, ChartArea } from "chart.js";
import moment from "moment";
import { theme } from "../../../theme";
import { DataPoint, Threshold } from "./types";

////////////////////////////////////////////////////////////////
export const toolTipCallback24h = (tool: TooltipItem<"line">) => {
  if (tool.parsed.y === 0) {
    return null;
  }

  return `${tool.label}-${Math.round(tool.parsed.y)}%`;
};

export const label24h = (values: DataPoint[]) => {
  return values.map((v) => {
    return moment(v.date).format("HH:mm");
  });
};
export const ticksCallback24h = (
  value: number | string,
  index: number,
  ticks: any,
  values: DataPoint[]
) => {
  if ((index + 1) % 12 === 0) return moment(values[index].date).format("HH:mm");
  else return;
};
////////////////////////////////////////////////////////////
export const toolTipCallback7d = (
  tool: TooltipItem<"line">,
  values?: DataPoint[]
) => {
  if (tool.parsed.y === 0) {
    return null;
  }
  const date = values ? moment(values[tool.dataIndex].date) : moment();
  return `${date.format("dddd") + " " + date.format("HH:mm")}-${Math.round(
    tool.parsed.y
  )}%`;
};

export const label7d = (values: DataPoint[]) => {
  return values.map((v) => {
    return moment(v.date).format("dddd");
  });
};
export const ticksCallback7d = (
  value: number | string,
  index: number,
  ticks: any,
  values: DataPoint[]
) => {
  if ((index + 1) % 41 === 0) return moment(values[index].date).format("dddd");
  else return;
};
////////////////////////////////////////////////////////////
export const toolTipCallback30d = (
  tool: TooltipItem<"line">,
  values?: DataPoint[]
) => {
  if (tool.parsed.y === 0) {
    return null;
  }

  const date = moment(values ? values[tool.dataIndex].date : "");
  return `${date.format("MMMM Do HH:mm")}-${Math.round(tool.parsed.y)}%`;
};

export const label30d = (values: DataPoint[]) => {
  return values.map((v) => {
    return moment(v.date).format("Do");
  });
};
export const ticksCallback30d = (
  value: number | string,
  index: number,
  ticks: any,
  values: DataPoint[]
) => {
  if ((index + 1) % 9 === 0) return moment(values[index].date).format("Do");
  else return;
};
////////////////////////////////////////////////////////////
export const createGradient = (
  ctx: CanvasRenderingContext2D,
  area: ChartArea,
  thresholds?: Threshold,
  maxValue?: number
) => {
  const colorStart = `${theme.colors.green[500]}`;
  const colorMid = `${theme.colors.warning[500]}`;
  const colorEnd = `${theme.colors.danger[500]}`;

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  gradient.addColorStop(0, colorStart);
  if (maxValue && thresholds) {
    if (maxValue >= thresholds?.red) {
      gradient.addColorStop((thresholds?.yellow || 50) / 100, colorMid);
      gradient.addColorStop(1, colorEnd);
    }
    if (maxValue >= thresholds?.red) {
      gradient.addColorStop((thresholds?.yellow || 50) / 100, colorMid);
    }
  } else {
    gradient.addColorStop((thresholds?.yellow || 50) / 100, colorMid);
    gradient.addColorStop(1, colorEnd);
  }
  return gradient;
};
