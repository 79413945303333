import { validateIPv4Address } from "../../../Utils/ValidatorsForInput";
import { ErrorsModalServerInterface, ModalServerInterface } from "../types";

export const createErrorOBjectForValidation = (
  modalDataServer: ModalServerInterface,
  step: number
) => {
  const errors: ErrorsModalServerInterface = {
    errorServerName: "",
    errorEnviroments: "",
    errorIPv4Address: "",
    errorIPv6Address: "",
    errorStartDateTime: "",
    errorRecurringTime: "",
    errorNotificationLevel: "",
    errorTimeout: "",
    errorLevelTimeoutNotification: "",
  };
  if (step === 0) {
    if (!modalDataServer.serverName) {
      errors.errorServerName = "This field should not be empty";
    }

    if (modalDataServer.enviroments.length === 0) {
      errors.errorEnviroments = "This field should not be empty";
    }

    if (!modalDataServer.IPv4Address) {
      errors.errorIPv4Address = "This field should not be empty";
    } else {
      if (!validateIPv4Address(modalDataServer.IPv4Address)) {
        errors.errorIPv4Address = "IP Address is invalid";
      }
    }
  } else {
    if (!modalDataServer.startDateTime) {
      errors.errorStartDateTime = "This field should not be empty";
    }
    if (!modalDataServer.reccuringTime) {
      errors.errorRecurringTime = "This field should not be empty";
    }
    if (!modalDataServer.notificationLevel) {
      errors.errorNotificationLevel = "This field should not be empty";
    }
    if (!modalDataServer.timeout) {
      errors.errorTimeout = "This field should not be empty";
    }
    if (!modalDataServer.levelTimeoutNotification) {
      errors.errorLevelTimeoutNotification = "This field should not be empty";
    }
  }
  return errors;
};

export const checkErrorsModalServer = (
  errorModalServer: ErrorsModalServerInterface,
  step: number
) => {
  if (step === 0) {
    if (
      errorModalServer.errorEnviroments ||
      errorModalServer.errorIPv4Address ||
      errorModalServer.errorIPv6Address ||
      errorModalServer.errorServerName
    )
      return false;
  } else if (
    errorModalServer.errorStartDateTime ||
    errorModalServer.errorTimeout ||
    errorModalServer.errorRecurringTime ||
    errorModalServer.errorNotificationLevel ||
    errorModalServer.errorLevelTimeoutNotification
  )
    return false;
  return true;
};
