import { MenuItem, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { MotionButton } from "../../Utils/MotionComponents";
import { ModalTaskContext } from "./WDTaskModal";

export const ButtonModal: React.FC = () => {
  const { onOpen, onOpenEdit, currentInfo } = useContext(ModalTaskContext);
  const colorBackground = useColorModeValue("blue.700", "blue.400");
  const handleOpen = () => {
    if (currentInfo.idTask) onOpenEdit();
    else onOpen();
  };
  return (
    <>
      {currentInfo.idTask ? (
        <MenuItem onClick={handleOpen}>Edit task</MenuItem>
      ) : (
        <MotionButton
          color={colorBackground}
          onClick={handleOpen}
          colorScheme="blue"
          variant={"ghost"}
          borderRadius={8}
          border="2px solid"
          borderColor={colorBackground}
          fontSize={14}
          fontFamily="Montserrat"
          fontWeight={600}
          lineHeight={5}
          width="90%"
          ml={2}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", bounce: 0.4 }}
        >
          + Add Task
        </MotionButton>
      )}
    </>
  );
};
