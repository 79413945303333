import { Flex, Spinner, useColorModeValue } from "@chakra-ui/react";
import React from "react";
export const CustomSpinner: React.FC = () => {
  const colorSpinner = useColorModeValue("blue.500", "blue.400");
  return (
    <Flex h="60vh" justifyContent={"center"} alignItems={"center"}>
      <Spinner
        size="xl"
        color={colorSpinner}
        thickness="4px"
        speed="0.65s"
        emptyColor="blue.100"
      />
    </Flex>
  );
};
