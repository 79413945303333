import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  InputProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
interface WDInputProps extends InputProps {
  error?: string;
  ref?: React.MutableRefObject<null>;
  setDisabled: (value: boolean) => void;
  disabled: boolean;
  setValue: (value: string) => void;
  setFileName: (fileName: string) => void;
  setWarning: (value: string) => void;
  fileName: string;
}

export const WDInputFile: React.FC<WDInputProps> = (props) => {
  const {
    error,
    disabled,
    fileName,
    setValue,
    setDisabled,
    setFileName,
    setWarning,
    ...others
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [internalValue, setIntervalValue] = useState<string>("");
  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0].size > 1e6 / 4) {
        setDisabled(true);
        setLoading(false);
        setWarning("File size is too big");
        e.target.value = "";
        return;
      }
      setLoading(true);
      const file = e.target.files[0];
      setFileName(file?.name || "");
      setDisabled(true);
      const reader = new FileReader();
      try {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setValue(reader.result?.toString() || "");
          setIntervalValue(reader.result?.toString() || "");
          closeLoading();
          e.target.value = "";
        };
      } catch {
        reader.onerror = function (error) {
          setWarning("File format isn't valid");
        };
      }
    }
  };
  const closeLoading = () => {
    setLoading(false);
  };
  const clearFileInput = () => {
    setValue("");
    setWarning("");
    setLoading(false);
    setFileName("No file selected");
    setDisabled(false);
  };
  const debugBase64 = (base64URL: string) => {
    var win = window.open();
    win?.document.write(
      '<iframe src="' +
        base64URL +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
  };
  const handleOpen = () => {
    debugBase64(internalValue);
  };
  const colorButton = useColorModeValue("blue.700", "blue.400");

  return (
    <Box cursor="pointer" pr={2}>
      <Flex>
        <Flex
          cursor="pointer"
          border="1px solid"
          borderColor={"neutralGrey"}
          borderRadius={"0.475rem"}
        >
          {disabled ? (
            <Button
              isLoading={loading}
              colorScheme={"danger"}
              bg={"danger.500"}
              color="white"
              size={"xs"}
              onClick={clearFileInput}
            >
              Delete file
            </Button>
          ) : (
            <Button
              colorScheme={"blue"}
              bg={colorButton}
              color="white"
              size={"xs"}
              isLoading={loading}
            >
              Choose file
            </Button>
          )}
          <Box px={2}>{fileName}</Box>
        </Flex>
        <ViewIcon onClick={handleOpen} cursor="pointer" boxSize={6} ml={2} />
      </Flex>
      <Input
        display={disabled ? "none" : "block"}
        cursor="pointer"
        id="chakra-input-file"
        type={"file"}
        ref={props.ref}
        position={"absolute"}
        top={0}
        zIndex={1}
        onChange={handleFileInput}
        opacity={0}
        {...others}
      />
      <Box h={2}>{error && <Text color={"danger.500"}>{error}</Text>}</Box>
    </Box>
  );
};
