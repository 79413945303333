import { Flex, Icon } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ReactComponent as Trash } from "../../../Assets/ModalTrashIcon/trash.svg";
import { ModalTaskContext } from "../WDTaskModal";
interface DeleteHeaderProps {
  index: string;
}
export const DeleteHeader: React.FC<DeleteHeaderProps> = ({ index }) => {
  const { deleteHeader } = useContext(ModalTaskContext);
  const handleDelete = () => {
    deleteHeader(index);
  };
  return (
    <Flex
      onClick={handleDelete}
      justify="center"
      align={"center"}
      pt={2}
      cursor="pointer"
    >
      <Icon as={Trash} stroke="danger.500" color="blue" boxSize={8} />
    </Flex>
  );
};
