import React from "react";
import { ReactComponent as Crown } from "../../Assets/IconsComponent/crown.svg";
import { ReactComponent as Flag } from "../../Assets/IconsComponent/flag.svg";
import { ReactComponent as Flag2 } from "../../Assets/IconsComponent/flag-2.svg";
import { ReactComponent as Cup } from "../../Assets/IconsComponent/cup.svg";
import { ReactComponent as EmojiHappy } from "../../Assets/IconsComponent/emoji-happy.svg";
import { ReactComponent as EmojiNormal } from "../../Assets/IconsComponent/emoji-normal.svg";
import { ReactComponent as EmojiSad } from "../../Assets/IconsComponent/emoji-sad.svg";
import { ReactComponent as TagCross } from "../../Assets/IconsComponent/tag-cross.svg";
import { ReactComponent as Menu } from "../../Assets/IconsComponent/menu.svg";
import { ReactComponent as CD } from "../../Assets/IconsComponent/cd.svg";

import { Box, Flex, Icon, useColorModeValue } from "@chakra-ui/react";

interface IconInterface {
  iconIndex: number;
  options?: any;
}
export const IconProjectChooser: React.FC<IconInterface> = (props) => {
  const colorStroke = useColorModeValue("neutralGrey", "darkThemeGrey.100");
  return (
    <Box>
      <Flex
        bg={useColorModeValue("blue.100", "darkThemeGrey.600")}
        h={10}
        w={10}
        justifyContent="center"
        alignItems="center"
        {...props.options}
      >
        {
          {
            0: (
              <Icon
                as={Crown}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
            1: (
              <Icon
                as={Flag}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
            2: (
              <Icon
                as={Flag2}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
            3: (
              <Icon
                as={Cup}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
            4: (
              <Icon
                as={EmojiHappy}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
            5: (
              <Icon
                as={EmojiNormal}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
            6: (
              <Icon
                as={EmojiSad}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
            7: (
              <Icon
                as={TagCross}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
            8: (
              <Icon
                as={Menu}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
            9: (
              <Icon
                as={CD}
                stroke={colorStroke}
                boxSize={6}
                {...props.options}
              />
            ),
          }[props.iconIndex]
        }
      </Flex>
    </Box>
  );
};
