import { Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import React from "react";
export const TableHead: React.FC = () => {
  const colorPrimary = useColorModeValue("blue.100", "darkThemeGrey.500");
  const colorSecondary = useColorModeValue(
    "neutralDarkGrey",
    "darkThemeGrey.100"
  );
  return (
    <Thead
      bg={colorPrimary}
      color={colorSecondary}
      h={10}
      top={0}
      left={0}
      position="sticky"
      zIndex={1}
      fontSize="13px"
    >
      <Tr minW="fit-content">
        <Th fontWeight={600} fontSize={11} color={colorSecondary}>
          Project name
        </Th>
        <Th fontWeight={600} fontSize={11} color={colorSecondary}>
          Designer
        </Th>
        <Th
          fontWeight={600}
          textAlign="right"
          fontSize={11}
          color={colorSecondary}
        >
          Servers
        </Th>
        <Th
          fontWeight={600}
          fontSize={11}
          textAlign="right"
          color={colorSecondary}
        >
          Services
        </Th>
        <Th
          fontWeight={600}
          fontSize={11}
          textAlign="right"
          color={colorSecondary}
        >
          Tasks
        </Th>
        <Th fontWeight={600} fontSize={11} color={colorSecondary}>
          Members
        </Th>
        <Th fontWeight={600} fontSize={11} color={colorSecondary}>
          Project Status
        </Th>
        <Th></Th>
      </Tr>
    </Thead>
  );
};
