import { Box, Flex, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorContext } from "../../../App";

import { CollapsedDrawer } from "../../Drawer/CollapsedDrawer";
import { DrawerComponent } from "../../Drawer/DrawerComponent";
import { NavBar } from "../../NavBar/NavBar";
export const NavBarHeight: string = "64px";
interface ProtectedRoutesInterface {
  children: ReactNode;
}
export const DashboardWrapper: React.FC<ProtectedRoutesInterface> = (props) => {
  const [active, setActive] = useState<boolean>(true);
  const errorContext = useContext(ErrorContext);
  const openDrawer = () => {
    setActive(!active);
  };
  useEffect(() => {
    if (localStorage.getItem("NEW_PROJECT") === "CREATED_PROJECT") {
      errorContext.createToast("You have created a project");
      localStorage.removeItem("NEW_PROJECT");
    }
  });

  const MotionFlex = motion(Flex);
  const animationVariants = {
    active: { width: 280 },
    inactive: { width: 80 },
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("accesToken")) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex align="start" w="100%">
      <MotionFlex
        top={0}
        variants={animationVariants}
        animate={active ? "active" : "inactive"}
      >
        {active ? (
          <DrawerComponent active={active} />
        ) : (
          <CollapsedDrawer active={active} />
        )}
      </MotionFlex>

      <VStack overflow="hidden" h="100vh" width={"100%"} spacing={0}>
        <Box width="100%" top={0}>
          <NavBar openDrawer={openDrawer} />
        </Box>
        <Box w="100%" h="100%" overflow={"auto"} position="relative" pb={5}>
          {props.children}
        </Box>
      </VStack>
    </Flex>
  );
};
