import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { MotionButton } from "../../Utils/MotionComponents";
import { FirstStep } from "./FirstStep/FirstStep";
import { SecondStep } from "./SecondStep/SecondStep";
import { StepBar } from "./StepBar";
import { ModalServiceContext } from "./WDServiceModal";
export const ModalService: React.FC = () => {
  const modalContext = useContext(ModalServiceContext);

  const colorHeader = useColorModeValue("blue.800", "darkThemeGrey.100");

  return (
    <>
      <Modal
        isOpen={modalContext?.isOpen ?? false}
        onClose={modalContext.onCloseBigModal}
        scrollBehavior={"inside"}
        closeOnOverlayClick={false}
        isCentered
        size={"4xl"}
        motionPreset="slideInRight"
      >
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalHeader
            borderRadius={"8px 8px 0px 0px"}
            backgroundColor={useColorModeValue("blue.100", "darkThemeGrey.600")}
            color={colorHeader}
          >
            {modalContext?.step === 0
              ? modalContext.idService
                ? "Edit service"
                : "Add new service"
              : modalContext.idService
              ? "Edit job"
              : "Create service's job"}
          </ModalHeader>
          <ModalCloseButton
            onClick={modalContext.onCloseBigModal}
            margin={"5px 8px 0px 0px"}
            color={colorHeader}
            borderWidth={2}
            borderRadius={5}
            borderColor={colorHeader}
          />
          <ModalBody
            p={4}
            bg={useColorModeValue("white", "darkThemeGrey.700")}
            pr={8}
            pl={8}
          >
            {modalContext.spinnerLoading ? (
              <Spinner />
            ) : (
              <>
                <StepBar />

                {modalContext.step === 0 ? <FirstStep /> : <SecondStep />}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalContext.isOpenConfirm}
        onClose={modalContext.onCloseConfirm}
        isCentered
        blockScrollOnMount={false}
      >
        <ModalCloseButton
          onClick={modalContext.onCloseConfirm}
          margin={"5px 8px 0px 0px"}
          color="blue.800"
          borderWidth={2}
          borderRadius={5}
          borderColor={"blue.800"}
        />
        <ModalOverlay backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalBody bg={useColorModeValue("white", "darkThemeGrey.700")}>
            <VStack>
              <Box
                fontSize={20}
                textAlign="left"
                w="100%"
                fontWeight={600}
                py={2}
              >
                Discard all changes?
              </Box>
              <Box fontSize={16} textAlign="left" w="100%" fontWeight={400}>
                We won’t be able to save your data if you move away from this
                page.
              </Box>
              <Spacer />
              <HStack w="100%">
                <Spacer />
                <Box>
                  <MotionButton
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", bounce: 0.4 }}
                    tabIndex={2}
                    onClick={modalContext.onCloseConfirm}
                  >
                    {" "}
                    Go back
                  </MotionButton>
                </Box>
                <Box>
                  <MotionButton
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", bounce: 0.4 }}
                    onClick={() => {
                      modalContext.onCloseConfirm();
                      modalContext?.reset();
                    }}
                    colorScheme="danger"
                    bg="danger.500"
                    tabIndex={1}
                    autoFocus
                  >
                    Exit
                  </MotionButton>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
