import { useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { MotionButton } from "../../../Utils/MotionComponents";
import { ModalServiceContext } from "../WDServiceModal";

export const ButtonModal: React.FC = () => {
  const modalContext = useContext(ModalServiceContext);
  const colorBackground = useColorModeValue("blue.700", "blue.400");

  return (
    <MotionButton
      onClick={modalContext?.onOpen}
      borderRadius={8}
      fontSize={14}
      fontFamily="Montserrat"
      fontWeight={600}
      lineHeight={5}
      variant="outline"
      border="2px solid"
      width="100%"
      color={colorBackground}
      borderColor={colorBackground}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", bounce: 0.4 }}
    >
      + Add New Service
    </MotionButton>
  );
};
