import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Spacer,
  useColorModeValue,
  VStack,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext } from "../../App";
import { MotionButton } from "../../Utils/MotionComponents";
import { CustomSpinner } from "../Common/Spinner";
import { NavBarHeight } from "../Dashboard/DashboardWrapper/DashboardWrapper";
import { ManageThresholdsModal } from "../ProjectDetailsPage/ManageThresholdsModal";
import { ThresholdsInterface } from "../ProjectDetailsPage/ManageThresholdsModal/types";
import { apiClient, authorise } from "../utils/apiClient";
import { ThresholdTemplatesTable } from "./ThreshholdTemplatesTable";
import { ThresholdTemplateInterface } from "./types";

export const ThresholdTemplates: React.FC = () => {
  const errorContext = useContext(ErrorContext);
  const colorPrimary = useColorModeValue("blue.700", "darkThemeGrey.100");
  const colorBackground = useColorModeValue("blue.700", "blue.400");
  const [loading, setLoading] = useState<boolean>(true);
  const [templates, setTemplates] = useState<ThresholdTemplateInterface[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [templateName] = useState<string>("");

  const handleAddTemplate = (template: ThresholdTemplateInterface) => {
    setTemplates([...templates, template]);
  };

  useEffect(() => {
    apiClient
      .get("api/template/all", authorise())
      .then((res) => {
        setTemplates(res.data);
        setLoading(false);
      })
      .catch((err) => {
        errorContext.createError([...err.response.data]);
      });
  }, []);

  return (
    <Box px={6}>
      {loading ? (
        <CustomSpinner />
      ) : (
        <VStack maxH={`calc(100vh - ${NavBarHeight})`} w="100%">
          <Flex justifyContent={"space-between"} m={4} h="10%" w="100%">
            <Heading color={colorPrimary}>Threshold Templates</Heading>
            <Spacer />
            <MotionButton
              leftIcon={<AddIcon w={3} />}
              m={2}
              colorScheme={"blue"}
              bgColor={colorBackground}
              textColor={"white"}
              onClick={() => {
                setIsOpen(true);
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", bounce: 0.4 }}
            >
              Add template
            </MotionButton>
            <ManageThresholdsModal
              isOpen={isOpen}
              template={true}
              onSuccess={(
                val: ThresholdTemplateInterface | ThresholdsInterface
              ) => {
                apiClient
                  .post("/api/template/create", val, authorise())
                  .then((res) => {
                    setIsOpen(false);
                    handleAddTemplate(res.data);
                  })
                  .catch((err) => {
                    errorContext.createError([...err.response.data]);
                  });
              }}
              setIsOpen={setIsOpen}
              templateName={templateName}
            />
          </Flex>

          {templates.length ? (
            <ThresholdTemplatesTable
              templates={templates}
              editTemplate={setTemplates}
            />
          ) : (
            <Flex h={500} w="100%" align="center" justify="center">
              <Text>There is no template, create one!</Text>
            </Flex>
          )}
        </VStack>
      )}
    </Box>
  );
};
