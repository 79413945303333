import { Box, HStack, Spacer, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { MotionButton } from "../../../Utils/MotionComponents";
import { ModalTaskContext } from "../WDTaskModal";
import { CurlAutocomplete } from "./CurlAutocomplete";

export const FirstStepFooter: React.FC = () => {
  const { step, handleNext, modalData, loadingTest, testRequest } =
    useContext(ModalTaskContext);
  return (
    <HStack w="100%" py={2}>
      <Spacer />
      <Box>
        <MotionButton
          tabIndex={2}
          isLoading={loadingTest}
          colorScheme="blue"
          backgroundColor={useColorModeValue("blue.700", "blue.400")}
          w={"60px"}
          color="white"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", bounce: 0.4 }}
          onClick={() => {
            testRequest();
          }}
        >
          Test
        </MotionButton>
      </Box>
      <Box>{modalData.taskType === "10" ? <CurlAutocomplete /> : null}</Box>
      <Box pr={2}>
        <MotionButton
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", bounce: 0.4 }}
          backgroundColor={useColorModeValue("blue.700", "blue.400")}
          tabIndex={3}
          colorScheme="blue"
          w={"280px"}
          color="white"
          onClick={() => {
            handleNext(step + 1);
          }}
        >
          Next
        </MotionButton>
      </Box>
    </HStack>
  );
};
