import {
  Badge,
  Box,
  Flex,
  HStack,
  Spacer,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { IconProjectChooser } from "../Common/DictForIcons";
import { OnlineStatus } from "./OnlineStatus/OnlineStatus";
interface CardsHeaderInterface {
  title: string;
  iconId: number;
  status: number;
  projectRole: string;
}
export const ProjectCardHeader: React.FC<CardsHeaderInterface> = (props) => {
  return (
    <HStack w={"100%"} px={6} pt={3}>
      <Flex alignItems={"center"} justifyContent={"center"}>
        <Flex
          bg={useColorModeValue("blue.100", "darkThemeGrey.600")}
          p={4}
          my={4}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <IconProjectChooser
            iconIndex={props.iconId}
            options={{ boxSize: 10 }}
          />
        </Flex>
        <Box
          ml={2}
          fontWeight={600}
          fontSize={20}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          color={useColorModeValue("blue.700", "darkThemeGrey.100")}
          maxW="200px"
        >
          {props.title}
        </Box>
        <Badge
          ml={2}
          variant="outline"
          colorScheme={
            props.projectRole === "Designer" ? "green" : "darkThemeGrey"
          }
        >
          {props.projectRole}
        </Badge>
      </Flex>
      <Spacer />
      <Box>
        <OnlineStatus status={props.status} type={1} />
      </Box>
    </HStack>
  );
};
