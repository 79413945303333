import { theme } from "../../../theme";

export const arrWithColors = [
  theme.colors.sunYellow,
  theme.colors.red,
  theme.colors.forestGreen,
  theme.colors.skyBlue,
  theme.colors.orange,
  theme.colors.purple,
  theme.colors.brown,
  theme.colors.deepPink,
  theme.colors.darkBlue,
  theme.colors.aquamarine,
  theme.colors.springGreen,
  theme.colors.salmon,
  theme.colors.olive,
  theme.colors.chocolate,
  theme.colors.paleViolet,
];
