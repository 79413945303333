import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ErrorContext, UserContext } from "../../App";
import { ProjectInterface, SelectOptionsInterface } from "../Common/types";
import { apiClient, authorise } from "../utils/apiClient";
import { StepBar } from "./StepBar";
import { StepOnePage } from "./StepOnePage";
import { StepTwoPage } from "./StepTwoPage";

import {
  ModalTypesInterface,
  ProjectsEmailInterface,
  RolesFromBackend,
  UsersFromBackend,
} from "./types";

import { requiredField } from "../../Utils/ValidatorsForInput";
import { CustomSpinner } from "../Common/Spinner";
import { MotionButton } from "../../Utils/MotionComponents";

export interface errorMessageInterface {
  name: string;
  description: string;
}

interface WDProjectModalProps {
  isCollapsed?: boolean;
  isOnPage?: boolean;
  onSucces?: (val: ProjectInterface) => void;
}

export const WDProjectModal: React.FC<WDProjectModalProps> = ({
  isCollapsed,
  isOnPage,
  onSucces,
}) => {
  const colorBody = useColorModeValue("white", "darkThemeGrey.700");

  const userContext = useContext(UserContext);
  const useError = useContext(ErrorContext);
  const [roles, setRoles] = useState<SelectOptionsInterface[]>([]);
  const [weHaveRoles, setWeHaveRoles] = useState<boolean>(false);
  const [users, setUsers] = useState<ProjectsEmailInterface[]>([]);
  const [weHaveUsers, setWeHaveUsers] = useState<boolean>(false);
  const defaultData: ModalTypesInterface = {
    name: "",
    icon: 0,
    description: "",
    users: [
      {
        id: userContext?.user?.id,
        role: roles.filter((user) => {
          return user.display.toLowerCase() === "designer";
        })[0]?.value,
        notificationLevel: "0",
      },
    ],
  };
  const [modalData, setModalData] = useState<ModalTypesInterface>(defaultData);
  const [errorMessage, setErrorMessage] = useState<errorMessageInterface>({
    name: "",
    description: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure();
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (userContext?.user && roles.length) {
      setModalData({
        name: "",
        icon: 0,
        description: "",
        users: [
          {
            id: userContext?.user?.id,
            role: roles.filter((user) => {
              return user.display.toLowerCase() === "designer";
            })[0]?.value,
            notificationLevel: "0",
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext?.user, roles]);

  const onCloseBigModal = () => {
    if (JSON.stringify(modalData) === JSON.stringify(defaultData)) {
      reset();
    } else {
      onOpenConfirm();
    }
  };

  const reset = () => {
    setStep(0);
    setModalData(defaultData);
    setErrorMessage({ name: "", description: "" });
    onClose();
  };

  const addAnotherUser = () => {
    let newUsers = modalData.users;
    newUsers.push({
      id: "",
      role: roles.filter((user) => {
        return user.display.toLowerCase() === "watcher";
      })[0].value,
      notificationLevel: "0",
    });
    setModalData({
      ...modalData,
      users: newUsers,
    });
  };

  const deleteUser = (i: number) => {
    setModalData({
      ...modalData,
      users: modalData.users.filter((user, index) => {
        return i !== index;
      }),
    });
  };

  const onClickHandler = () => {
    if (step === 0) {
      if (
        modalData?.name === "" ||
        modalData?.description === "" ||
        modalData?.name.length > 40
      ) {
        setErrorMessage({
          name:
            requiredField(modalData?.name) || modalData?.name.length > 40
              ? "Name too long"
              : "",
          description: requiredField(modalData?.description),
        });
      } else {
        setStep(step + 1);
        setErrorMessage({
          name: "",
          description: "",
        });

        if (modalData.users.length === 1) {
          for (let i = 0; i < 3; i++) {
            addAnotherUser();
          }
        }
      }
    } else {
      reset();

      const usersToSend = modalData.users
        .filter((user, index) => {
          return index !== 0 && user.id !== "";
        })
        .map((user) => {
          return {
            userId: user.id,
            minNotificationLevel: parseInt(user.notificationLevel),
            roleId: user.role,
          };
        });

      apiClient
        .post(
          "/api/private/projects/create",
          {
            name: modalData.name,
            description: modalData.description,
            icon: modalData.icon,
            userNotificationLevel: parseInt(
              modalData.users[0].notificationLevel
            ),
            users: usersToSend,
          },
          authorise()
        )
        .then((res) => {
          if (isOnPage) {
            onSucces?.(res.data);
          } else {
            localStorage.setItem("NEW_PROJECT", "CREATED_PROJECT");
            window.location.reload();
          }
        })
        .catch((err) => useError?.createError(err.response.data));
    }
  };

  const getUsersEmails = async () => {
    await apiClient
      .get("/api/private/users/user-emails", authorise())
      .then((res) => {
        setUsers(
          res.data
            .map((user: UsersFromBackend) => {
              return {
                value: user.userId,
                label: user.email,
              };
            })
            .sort((a: ProjectsEmailInterface, b: ProjectsEmailInterface) => {
              if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) {
                return 1;
              } else if (
                a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()
              ) {
                return -1;
              }
              return 0;
            })
        );
        setWeHaveUsers(true);
      })
      .catch((err) => {
        useError?.createError(["EMAIL"]);
      });
  };

  const getUsersRoles = async () => {
    await apiClient
      .get("/api/project-roles/all", authorise())
      .then((res) => {
        setRoles(
          res.data.map((role: RolesFromBackend) => {
            return {
              value: role.roleId,
              display: role.roleName,
              color: "black",
            };
          })
        );
        setWeHaveRoles(true);
      })
      .catch((err) => {
        useError?.createError(["ROLES"]);
      });
  };

  useEffect(() => {
    getUsersEmails();
    getUsersRoles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeModalDataHandler = (val: ModalTypesInterface) => {
    setModalData(val);
  };
  useEffect(() => {
    if (modalData.name !== "") {
      setErrorMessage({ name: "", description: errorMessage.description });
    }

    if (modalData.description !== "") {
      setErrorMessage({
        name: errorMessage.name,
        description: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);
  const colorPrimary = useColorModeValue("blue.700", "blue.400");
  const colorSecondary = useColorModeValue("blue.800", "darkThemeGrey.100");

  return (
    <>
      <MotionButton
        onClick={onOpen}
        color="white"
        colorScheme={"blue"}
        backgroundColor={colorPrimary}
        borderRadius={8}
        fontSize={16}
        fontFamily="Montserrat"
        fontWeight={600}
        lineHeight={5}
        width="90%"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: "spring", bounce: 0.4 }}
      >
        {isCollapsed ? "+" : "+ Create new Project"}
      </MotionButton>

      <Modal
        scrollBehavior={"inside"}
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onCloseBigModal}
        size={"3xl"}
        motionPreset="slideInRight"
      >
        <ModalOverlay backdropFilter="blur(10px)">
          <ModalContent top={"5vh"} maxBlockSize={"l"}>
            <ModalHeader
              borderRadius={"8px 8px 0px 0px"}
              backgroundColor={useColorModeValue(
                "blue.100",
                "darkThemeGrey.600"
              )}
              color={colorSecondary}
            >
              {step === 0 ? "Create new project" : "Assign users to project"}
            </ModalHeader>

            <ModalCloseButton
              onClick={onCloseBigModal}
              margin={"5px 8px 0px 0px"}
              borderWidth={2}
              borderRadius={5}
              color={colorSecondary}
              borderColor={colorSecondary}
            />
            <ModalBody
              padding={5}
              bg={useColorModeValue("white", "darkThemeGrey.700")}
            >
              <StepBar
                setErrorMessage={setErrorMessage}
                value={modalData}
                step={step}
                setStep={setStep}
                setModalData={setModalData}
              />
              {step === 0 ? (
                <StepOnePage
                  error={errorMessage}
                  changeModalDataHandler={changeModalDataHandler}
                  value={modalData}
                />
              ) : weHaveUsers && weHaveRoles ? (
                <StepTwoPage
                  roles={roles}
                  users={users}
                  values={modalData}
                  changeModalDataHandler={changeModalDataHandler}
                  onDelete={deleteUser}
                />
              ) : (
                <CustomSpinner />
              )}
            </ModalBody>
            <ModalFooter bg={useColorModeValue("white", "darkThemeGrey.700")}>
              {step === 1 && (
                <Button
                  onClick={addAnotherUser}
                  color={colorPrimary}
                  variant={"link"}
                  mr={"15em"}
                  fontSize="sm"
                >
                  +Add another user
                </Button>
              )}
              <MotionButton
                tabIndex={1}
                backgroundColor={colorPrimary}
                colorScheme={useColorModeValue("blue", "blue")}
                color={"white"}
                fontSize={"sm"}
                width={"50%"}
                mr={3}
                onClick={onClickHandler}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", bounce: 0.4 }}
              >
                {step === 0 ? "Next" : "Save"}
              </MotionButton>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      <Modal
        isOpen={isOpenConfirm} //isOpenConfirm
        onClose={onCloseConfirm} //onCloseConfirm
        isCentered
        blockScrollOnMount={false}
      >
        <ModalCloseButton
          onClick={onCloseConfirm} //onCloseConfirm
          margin={"5px 8px 0px 0px"}
          color="blue.800"
          borderWidth={2}
          borderRadius={5}
          borderColor={"blue.800"}
        />
        <ModalOverlay backdropFilter="blur(10px)" backdropBlur={"10px"} />

        <ModalContent>
          <ModalBody bg={colorBody}>
            <VStack>
              <Box
                fontSize={20}
                textAlign="left"
                w="100%"
                fontWeight={600}
                py={2}
              >
                Discard all changes?
              </Box>
              <Box fontSize={16} textAlign="left" w="100%" fontWeight={400}>
                We won't be able to save your data if you move away from this
                page.
              </Box>
              <Spacer />
              <HStack w="100%">
                <Spacer />
                <Box>
                  <Button
                    tabIndex={2}
                    onClick={onCloseConfirm} //onCloseConfirm
                  >
                    Go back
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      reset();
                      onCloseConfirm();
                    }}
                    colorScheme="danger"
                    bg="danger.500"
                    tabIndex={1}
                    autoFocus
                  >
                    Exit
                  </Button>
                </Box>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
