import { Box, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { theme } from "../../../theme";
import { WDInput } from "../../Common/WDInput";
import { WDThresholdsInputCard } from "../../Common/WDThresholdsInputCard";
import { ProcessInterface } from "./types";

interface SecondStepProps {
  processes: ProcessInterface[];
  addAnotherProcess: (val: string) => void;
  setProcessYellow: (i: number, val: number) => void;
  setProcessRed: (i: number, val: number) => void;
  handleDeleteProcess: (val: string) => void;
}

export const SecondStep: React.FC<SecondStepProps> = ({
  processes,
  addAnotherProcess,
  setProcessYellow,
  setProcessRed,
  handleDeleteProcess,
}) => {
  const [processName, setProcessName] = useState<string>("");
  const colorPrimary = useColorModeValue("blue.700", "blue.400");

  return (
    <Flex direction="column" mt={5} align="center">
      <Box width="50%">
        <Formik
          initialValues={{}}
          onSubmit={() => {
            addAnotherProcess(processName);
            setProcessName("");
          }}
        >
          {() => (
            <Form>
              <Flex>
              <WDInput
                variant="flushed"
                borderRadius={0}
                mb={5}
                value={processName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setProcessName(e.target.value);
                }}
                placeholder="Process name"
              />
              <Button mt='2' type="submit" variant='ghost' color={colorPrimary}>Add</Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
      <Flex
        wrap="wrap"
        justify="space-evenly"
        align="center"
        rowGap={5}
        minH={200}
        minW="100%"
      >
        {processes.length ? (
          processes.map((p, i) => {
            return (
              <WDThresholdsInputCard
                type="slider"
                canBeDeleted={true}
                onDelete={(name) => {
                  handleDeleteProcess(name);
                }}
                key={p.name + "-" + i}
                name={p.name}
                value={p.threshold}
                setValueYellow={(val) => {
                  setProcessYellow(i, val);
                }}
                setValueRed={(val) => {
                  setProcessRed(i, val);
                }}
              />
            );
          })
        ) : (
          <Text color={theme.colors.neutralGrey}>No processes yet.</Text>
        )}
      </Flex>
    </Flex>
  );
};
