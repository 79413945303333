import { InfoOutlineIcon, QuestionIcon, StarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { ReactComponent as AllProjects } from "../../Assets/Drawer-NavBar/AllProjects.svg";
import { ReactComponent as ClientsIcon } from "../../Assets/Drawer-NavBar/clients.svg";
import { ReactComponent as ProjectsIcon } from "../../Assets/Drawer-NavBar/ProjectsIcon.svg";
import { ReactComponent as ThresholdTemplatesIcon } from "../../Assets/Drawer-NavBar/thresholdsTemplate.svg";
import { ReactComponent as UsersIcon } from "../../Assets/Drawer-NavBar/UsersIcon.svg";
import { theme } from "../../theme";
import { WDProjectModal } from "../CreateProjectModal/WDProjectModal";

export const ListDrawer: React.FC = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const origin = "/dashboard";

  const contextData = useContext(UserContext);

  const navigate = useNavigate();
  const handleNavigate = (name: string) => {
    if (localStorage.accesToken) {
      navigate(origin + "/" + name);
    } else {
      contextData?.logOut();
    }
  };
  const colorStroke = useColorModeValue(
    `${theme.colors.blue[700]}`,
    `${theme.colors.darkThemeGrey[100]}`
  );
  const colorText = useColorModeValue("blue.700", "darkThemeGrey.100");
  const colorBackground = useColorModeValue("blue.100", "darkThemeGrey.600");
  const colorBorder = useColorModeValue("blue.700", "blue.400");
  return (
    <Flex flexDirection="column" align="center" width="100%" minH="120px">
      <Box pl={4} pb={4} width="100%">
        <WDProjectModal />
      </Box>
      <VStack
        overflowY="scroll"
        overflowX="hidden"
        __css={{
          direction: "rtl",
          "&::-webkit-scrollbar-track": {
            background: 'inherit',
          },
        }}
        h="100%"
        spacing="10px"
        align="start"
        width="100%"
        p={1}
        ml={2}
      >
        <Flex
          __css={{
            direction: "ltr",
          }}
          justify="flex-start"
          p={2}
          width="100%"
          borderRight={
            location.pathname.includes("projects") ? "10px solid" : "0"
          }
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate("projects");
          }}
          cursor="pointer"
        >
          <ButtonGroup>
            <Button
              leftIcon={<ProjectsIcon stroke={colorStroke} />}
              colorScheme="blue"
              bg={colorBackground}
              width="100%"
              color={colorText}
              variant="link"
              opacity={location.pathname.includes("projects") ? "100%" : "50%"}
              iconSpacing={10}
              fontFamily="Montserrat"
              fontWeight={600}
              lineHeight={5}
            >
              Your Projects
            </Button>
          </ButtonGroup>
        </Flex>
        {userContext?.user?.roleName === "Admin" && (
          <Flex
            __css={{
              direction: "ltr",
            }}
            p={2}
            width="100%"
            borderRight={
              location.pathname.includes("all-project") ? "10px solid" : "0"
            }
            borderColor={colorBorder}
            onClick={() => {
              handleNavigate("all-project");
            }}
            cursor="pointer"
          >
            <ButtonGroup>
              <Button
                leftIcon={<AllProjects stroke={colorStroke} />}
                colorScheme="blue"
                bg={colorBackground}
                width="100%"
                color={colorText}
                variant="link"
                iconSpacing={10}
                opacity={
                  location.pathname.includes("all-project") ? "100%" : "50%"
                }
                fontFamily="Montserrat"
                fontWeight={600}
                lineHeight={5}
              >
                All Projects
              </Button>
            </ButtonGroup>
          </Flex>
        )}
        {userContext?.user?.roleName === "Admin" && (
          <Flex
            __css={{
              direction: "ltr",
            }}
            p={2}
            width="100%"
            borderRight={
              location.pathname.includes("threshold-templates")
                ? "10px solid"
                : "0"
            }
            borderColor={colorBorder}
            onClick={() => {
              handleNavigate("threshold-templates");
            }}
            cursor="pointer"
          >
            <ButtonGroup>
              <Button
                leftIcon={<ThresholdTemplatesIcon stroke={colorStroke} />}
                colorScheme="blue"
                bg={colorBackground}
                width="100%"
                color={colorText}
                variant="link"
                iconSpacing={10}
                opacity={
                  location.pathname.includes("threshold-templates")
                    ? "100%"
                    : "50%"
                }
                fontFamily="Montserrat"
                fontWeight={600}
                lineHeight={5}
              >
                Threshold Templates
              </Button>
            </ButtonGroup>
          </Flex>
        )}

        {userContext?.user?.roleName === "Admin" && (
          <Flex
            __css={{
              direction: "ltr",
            }}
            p={2}
            width="100%"
            borderRight={
              location.pathname.includes("developers") ? "10px solid" : "0"
            }
            borderColor={colorBorder}
            onClick={() => {
              handleNavigate("developers");
            }}
            cursor="pointer"
          >
            <ButtonGroup>
              <Button
                leftIcon={<UsersIcon stroke={colorStroke} />}
                colorScheme="blue"
                bg={colorBackground}
                width="100%"
                color={colorText}
                variant="link"
                iconSpacing={10}
                opacity={
                  location.pathname.includes("developers") ? "100%" : "50%"
                }
                fontFamily="Montserrat"
                fontWeight={600}
                lineHeight={5}
              >
                Developers
              </Button>
            </ButtonGroup>
          </Flex>
        )}
        {userContext?.user?.roleName === "Admin" && (
          <Flex
            __css={{
              direction: "ltr",
            }}
            p={2}
            width="100%"
            borderRight={
              location.pathname.includes("clients") ? "10px solid" : "0"
            }
            borderColor={colorBorder}
            onClick={() => {
              handleNavigate("clients");
            }}
            cursor="pointer"
          >
            <ButtonGroup>
              <Button
                leftIcon={<ClientsIcon stroke={colorStroke} />}
                colorScheme="blue"
                bg={colorBackground}
                width="100%"
                color={colorText}
                variant="link"
                iconSpacing={10}
                opacity={location.pathname.includes("clients") ? "100%" : "50%"}
                fontFamily="Montserrat"
                fontWeight={600}
                lineHeight={5}
              >
                Clients
              </Button>
            </ButtonGroup>
          </Flex>
        )}
        <Flex
          __css={{
            direction: "ltr",
          }}
          justify="flex-start"
          p={2}
          width="100%"
          borderRight={location.pathname.includes("about") ? "10px solid" : "0"}
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate("about");
          }}
          cursor="pointer"
        >
          <ButtonGroup>
            <Button
              leftIcon={<InfoOutlineIcon stroke={colorStroke} />}
              colorScheme="blue"
              bg={colorBackground}
              width="100%"
              color={colorText}
              variant="link"
              opacity={location.pathname.includes("about") ? "100%" : "50%"}
              iconSpacing={10}
              fontFamily="Montserrat"
              fontWeight={600}
              lineHeight={5}
            >
              About us
            </Button>
          </ButtonGroup>
        </Flex>

        <Flex
          __css={{
            direction: "ltr",
          }}
          p={2}
          width="100%"
          borderRight={location.pathname.includes("help") ? "10px solid" : "0"}
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate("help");
          }}
          cursor="pointer"
        >
          <ButtonGroup>
            <Button
              leftIcon={<QuestionIcon color={colorStroke} />}
              colorScheme="blue"
              bg={colorBackground}
              width="100%"
              color={colorText}
              variant="link"
              iconSpacing={10}
              opacity={location.pathname.includes("help") ? "100%" : "50%"}
              fontFamily="Montserrat"
              fontWeight={600}
              lineHeight={5}
            >
              Help Center
            </Button>
          </ButtonGroup>
        </Flex>
        <Flex
          __css={{
            direction: "ltr",
          }}
          justify="flex-start"
          p={2}
          width="100%"
          borderRight={
            location.pathname.includes("whats-new") ? "10px solid" : "0"
          }
          borderColor={colorBorder}
          onClick={() => {
            handleNavigate("whats-new");
          }}
          cursor="pointer"
        >
          <ButtonGroup>
            <Button
              leftIcon={<StarIcon stroke={colorStroke} />}
              colorScheme="blue"
              bg={colorBackground}
              width="100%"
              color={colorText}
              variant="link"
              opacity={location.pathname.includes("whats-new") ? "100%" : "50%"}
              iconSpacing={10}
              fontFamily="Montserrat"
              fontWeight={600}
            >
              What's New
            </Button>
          </ButtonGroup>
        </Flex>
      </VStack>
    </Flex>
  );
};
