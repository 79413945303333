import { Box, HStack, Spacer, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { MotionButton } from "../../../Utils/MotionComponents";
import { ModalServiceContext } from "../WDServiceModal";

export const SecondStepFooter: React.FC = () => {
  const modalContext = useContext(ModalServiceContext);
  const handleSave = () => {
    if (modalContext.idService) modalContext.handleSaveEditChanges();
    else modalContext.handleSave();
  };
  return (
    <HStack w="100%" bg={useColorModeValue("white", "darkThemeGrey.700")}>
      <Spacer />
      <Box pl={2}>
        <MotionButton
          isLoading={modalContext?.loading}
          tabIndex={3}
          w={"280px"}
          color="white"
          colorScheme={useColorModeValue("blue", "blue")}
          backgroundColor={useColorModeValue("blue.700", "blue.400")}
          onClick={handleSave}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", bounce: 0.4 }}
        >
          {modalContext.idService ? "Save changes" : "Save"}
        </MotionButton>
      </Box>
    </HStack>
  );
};
